export function formatSeparatorNumber(
  value: number,
  thousandsSeparator = ',',
  decimalSeparator?: string
) {
  let strValue = value.toString();

  if (decimalSeparator) {
    strValue = strValue.replace('.', decimalSeparator);
  }

  return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
}
