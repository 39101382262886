import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  EXTEND_SUBSCRIPTION_INSTANTLY_PATH,
  ExtendSubscriptionInstantlyRequest,
  ExtendSubscriptionInstantlyResponseError
} from '@elfsight-universe/service-core-contracts/billing/extend-subscription-instantly';
import { client } from '../client';

export function useExtendSubscriptionInstantlyMutation(
  options: UseMutationOptions<
    void,
    ExtendSubscriptionInstantlyResponseError,
    ExtendSubscriptionInstantlyRequest
  > = {}
) {
  return useMutation<
    void,
    ExtendSubscriptionInstantlyResponseError,
    ExtendSubscriptionInstantlyRequest
  >(
    async ({ pid }) => {
      await client.post(EXTEND_SUBSCRIPTION_INSTANTLY_PATH, {
        json: { pid },
        retry: {
          limit: 0
        }
      });
    },
    {
      retry: false,
      ...options
    }
  );
}
