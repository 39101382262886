import styled from 'styled-components';

export const ContextMenuDivider = styled.hr`
  border: none;
  border-radius: 1px;
  margin: 8px auto;
  height: 1px;
  width: calc(100% - 32px);
  background: ${({ theme }) => theme.colors.gray10};
`;
