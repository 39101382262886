import { Exclude, Expose, Type } from 'class-transformer';
import { IsBoolean, IsOptional } from 'class-validator';

export const GET_CATALOG_APPS_PATH = 'widget/get-catalog-apps';

export class GetCatalogAppsRequest {
  @IsBoolean()
  @Type(() => Boolean)
  @IsOptional()
  withComingSoon = false;
}

@Exclude()
export class GetCatalogAppsResponseMeta {
  @Expose()
  requestFeatureLink: string;
}

@Exclude()
export class GetCatalogAppsResponse {
  @Expose()
  @Type(() => GetCatalogAppsResponseApp)
  payload: GetCatalogAppsResponseApp[];

  @Expose()
  @Type(() => GetCatalogAppsResponseMeta)
  meta: GetCatalogAppsResponseMeta;
}

@Exclude()
export class GetCatalogAppsResponseApp {
  @Expose()
  alias: string;

  @Expose()
  name: string;

  @Expose()
  caption: string;

  @Expose()
  keywords: string;

  @Expose()
  icon: string;

  @Expose()
  createdAt: string;

  @Expose()
  @Type(() => GetCatalogAppsResponseAppBadge)
  badge?: {
    title: string;
    alias: string;
  };

  // @TODO remove IMPORT TEMP flag
  @Expose()
  comingSoon: boolean;

  @Expose()
  wishlistLink: string;

  @Expose()
  @Type(() => GetCatalogAppsResponseAppCategory)
  category: {
    color: string;
    alias: string;
  };
}

@Exclude()
export class GetCatalogAppsResponseAppCategory {
  @Expose()
  color: string;

  @Expose()
  alias: string;
}

@Exclude()
export class GetCatalogAppsResponseAppBadge {
  @Expose()
  title: string;

  @Expose()
  alias: string;
}
