import {
  Context,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

export type TabPage = {
  title: ReactNode;
  id: string;
  alert?: boolean;
};

export type ITabContext<T extends string = string> = {
  tabs: TabPage[];
  activeTab: T;
  setActiveTab: (tabID: T) => void;
};

export const TabsContext = createContext<ITabContext | null>(null);

type TabProviderProps = {
  children: ReactNode;
  defaultTab: string;
  queryParam?: string;
  onSetActiveTab?: (tabID: string) => void;
  tabs: TabPage[];
};

export function TabsProvider({
  children,
  defaultTab,
  queryParam,
  onSetActiveTab,
  tabs
}: TabProviderProps) {
  const [activeTabID, setActiveTabID] = useState<string>(defaultTab || '');

  useEffect(() => {
    if (queryParam && tabs.some(({ id }) => id === queryParam)) {
      setActiveTabID(queryParam);
      return;
    }

    setActiveTabID(defaultTab);
  }, [queryParam, defaultTab]);

  const setActiveTab = (tabID: typeof activeTabID) => {
    setActiveTabID(tabID);

    if (onSetActiveTab) {
      onSetActiveTab(tabID);
    }
  };

  return (
    <TabsContext.Provider
      value={{ activeTab: activeTabID, setActiveTab, tabs }}
    >
      {children}
    </TabsContext.Provider>
  );
}

export function useTabs<T extends string = string>() {
  const context = useContext(TabsContext as unknown as Context<ITabContext<T>>);

  if (context === null) {
    throw new Error('`useTabs` must be nested inside an `TabsProvider`.');
  }

  return context;
}
