export enum OnboardingActionType {
  REVIEW_WELCOME_MESSAGE = 'REVIEW_WELCOME_MESSAGE',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  CREATE_WIDGET = 'CREATE_WIDGET',
  INSTALL_WIDGET = 'INSTALL_WIDGET',
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  CHOOSE_OCCUPATION = 'CHOOSE_OCCUPATION',
  EXPLORE_COMMUNITY = 'EXPLORE_COMMUNITY',
  DISCOVER_APPS = 'DISCOVER_APPS',
  FINISH = 'FINISH'
}
