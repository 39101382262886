import { SelectOption } from '../select-option';
import { GroupedSelectOptions } from '../select-types';

export const getGroupedSelectOptions = (options: SelectOption[]) =>
  options.reduce<GroupedSelectOptions[]>((groups, option, currentIndex) => {
    // order ungrouped options
    if (!option.group) {
      if (!groups[groups.length - 1]?.groupName && currentIndex > 0) {
        groups[groups.length - 1].options.push(option);
      } else {
        groups.push({ options: [option] });
      }
      return groups;
    }

    const group = groups.find((group) => group.groupName === option.group);
    if (group) {
      group.options.push(option);
    } else {
      groups.push({
        groupName: option.group as string,
        options: [option]
      });
    }

    return groups;
  }, []);
