import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  SIGN_IN_BY_TRANSITION_TOKEN_PATH,
  SignInByTransitionTokenResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useSignInByTransitionTokenMutation(
  options: Pick<
    UseMutationOptions<unknown, SignInByTransitionTokenResponseError, string>,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<unknown, SignInByTransitionTokenResponseError, string>(
    (transitionToken) =>
      client.post(SIGN_IN_BY_TRANSITION_TOKEN_PATH, {
        json: { token: transitionToken }
      }),
    options
  );
}
