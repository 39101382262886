import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  COMMIT_USAGE_INSTALLED_APP_PATH,
  CommitUsageInstalledAppResponseError,
  CommitUsageInstalledAppRequest
} from '@elfsight-universe/service-core-contracts/widget/commit-usage-installed-app';
import { client } from '../client';

export function useCommitUsageInstalledAppMutation(
  options: UseMutationOptions<
    Response,
    CommitUsageInstalledAppResponseError,
    CommitUsageInstalledAppRequest
  > = {}
) {
  return useMutation<
    Response,
    CommitUsageInstalledAppResponseError,
    CommitUsageInstalledAppRequest
  >(
    (data) =>
      client.post(COMMIT_USAGE_INSTALLED_APP_PATH, {
        json: data,
        retry: { limit: 0 }
      }),
    {
      retry: false,
      ...options
    }
  );
}
