import { createContext, useContext } from 'react';
import { WixIntegration } from './wix-integration';

export const WixIntegrationContext = createContext<WixIntegration | null>(null);

export function useWixIntegration(): WixIntegration {
  const wixIntegration = useContext(WixIntegrationContext);
  if (!wixIntegration) {
    throw new Error(
      'The `useWixIntegration` is expected to be called only within `WixIntegrationContext`.'
    );
  }
  return wixIntegration;
}
