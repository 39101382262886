import { IsEmail, IsNotEmpty } from 'class-validator';

export const INVITE_ACCOUNT_MEMBER_PATH = 'iam/invite-account-member';

export class InviteAccountMemberRequest {
  @IsNotEmpty({ message: 'Please enter a valid email' })
  @IsEmail(undefined, { message: 'Please enter a valid email' })
  email: string;
}

export type InviteAccountMemberResponseError =
  | 'COLLABORATORS_LIMIT_REACHED'
  | 'ACCOUNT_MEMBER_ALREADY_EXIST';
