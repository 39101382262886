import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  REMOVE_WIDGET_FROM_PROJECT_PATH,
  RemoveWidgetFromProjectRequest,
  RemoveWidgetFromProjectResponseError
} from '@elfsight-universe/service-core-contracts/widget';
import { client } from '../client';

export function useRemoveWidgetFromProjectMutation(
  options: UseMutationOptions<
    Response,
    RemoveWidgetFromProjectResponseError,
    RemoveWidgetFromProjectRequest
  > = {}
) {
  return useMutation<
    Response,
    RemoveWidgetFromProjectResponseError,
    RemoveWidgetFromProjectRequest
  >(
    (data) =>
      client.post(REMOVE_WIDGET_FROM_PROJECT_PATH, {
        json: data
      }),
    {
      retry: false,
      ...options
    }
  );
}
