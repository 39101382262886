import { Exclude, Expose, Type } from 'class-transformer';
import { IsString } from 'class-validator';
import { WidgetError } from '../errors';

export const GET_APP_BY_ALIAS_PATH = 'widget/get-app-by-alias';

export class GetAppByAliasRequest {
  @IsString()
  alias: string;
}

@Exclude()
export class GetAppByAliasResponse {
  @Expose()
  alias: string;

  @Expose()
  name: string;

  @Expose()
  caption: string;

  @Expose()
  icon: string;

  @Expose()
  @Type(() => GetPaidSubscriptionAppInfoCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetPaidSubscriptionAppInfoCategory {
  @Expose()
  color: string;
}

export type GetAppByAliasResponseError = WidgetError.APP_DOES_NOT_EXIST;
