import { ReactNode } from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBaseBoundary } from './error-base-boundary';

export function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <QueryErrorResetBoundary>
      {() => <ErrorBaseBoundary>{children}</ErrorBaseBoundary>}
    </QueryErrorResetBoundary>
  );
}
