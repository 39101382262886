import styled from 'styled-components';
import { AngleBoldLeft20 } from '@elfsight/icons';
import { IconButton } from '@elfsight-universe/ui-common';
import { SwitchAccountControl } from './switch-account-control';

interface SwitchAccountPageProps {
  onBack: () => void;
  onClose: () => void;
}

export function SwitchAccountPage({ onBack, onClose }: SwitchAccountPageProps) {
  return (
    <Container>
      <Header>
        <BackButton onClick={() => onBack()} icon={AngleBoldLeft20} />

        <Title>Switch Workspace</Title>
      </Header>

      <SwitchAccountControl onClose={onClose} />
    </Container>
  );
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
`;

const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding-right: 48px;
  align-items: center;
  gap: 16px;
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 22px;
  font-size: 16px;
  flex-grow: 1;
  text-align: center;
`;

const BackButton = styled(IconButton)``;
