import { IsUUID } from 'class-validator';
import { BillingError } from '../errors';

export const REVOKE_SUBSCRIPTION_CANCELLATION_PATH =
  'billing/revoke-subscription-cancellation';

export class RevokeSubscriptionCancellationRequest {
  @IsUUID()
  pid: string;
}

export type RevokeSubscriptionCancellationResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANCELLATION_CANNOT_BE_REVOKED;
