import styled from 'styled-components';
import { PricingTableColumn } from './pricing-table-column';
import { PricingTablePlan, PricingTableProps } from './pricing-table-types';

export type PricingTableRegularViewProps = Pick<
  PricingTableProps,
  'billingInterval' | 'colorTheme'
> & {
  plans: PricingTablePlan[];
};

export function PricingTableRegularView({
  plans,
  billingInterval,
  colorTheme
}: PricingTableRegularViewProps) {
  const shouldDisplayYouSaveBanner = plans.some(
    ({ discountTotal }) => !!discountTotal
  );

  return (
    <Container $columns={plans.length}>
      {plans.map((plan) => (
        <PricingTableColumn
          shouldDisplayYouSaveBanner={shouldDisplayYouSaveBanner}
          key={plan.name}
          plan={plan}
          colorTheme={colorTheme}
          billingInterval={billingInterval}
        />
      ))}
    </Container>
  );
}

const Container = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ $columns }) => $columns},
    minmax(min-content, 250px)
  );
  position: relative;
  margin-top: 8px;
`;
