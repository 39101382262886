import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty, IsString } from 'class-validator';

export const CREATE_ANNOUNCEMENT_PATH = 'announcement/create-announcement';

export class CreateAnnouncementRequest {
  @IsString()
  @IsNotEmpty({ message: 'Title is required.' })
  title: string;
}

@Exclude()
export class CreateAnnouncementResponse {
  @Expose()
  id: number;
}
