import { useMutation } from '@tanstack/react-query';
import {
  DELETE_USER_PATH,
  DeleteUserResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/delete-user';
import { client } from '../client';
import { toast } from 'react-hot-toast';
import { useSignOutMutation } from './use-sign-out.mutation';

export function useDeleteAccountMutation() {
  const { mutate: signOut } = useSignOutMutation();

  return useMutation<unknown, DeleteUserResponseError>(
    (data) => client.post(DELETE_USER_PATH, { json: data }),
    {
      onSuccess: () => {
        signOut();
        toast.success(
          'Your account deletion request is received. Your account will be deleted within 7 days.'
        );
      }
    }
  );
}
