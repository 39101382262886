import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import { client } from '../client';
import { useUser } from '@modules/_app';
import {
  GetAppByAliasRequest,
  GetAppByAliasResponse,
  GetAppByAliasResponseError,
  GET_APP_BY_ALIAS_PATH
} from '@elfsight-universe/service-core-contracts/widget';

export function useAppByAliasQuery(
  dto: GetAppByAliasRequest,
  options: UseQueryOptions<
    GetAppByAliasResponse,
    GetAppByAliasResponseError
  > = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetAppByAliasResponse, GetAppByAliasResponseError>(
    [GET_APP_BY_ALIAS_PATH, ...Object.values(dto)],
    () =>
      client
        .get(GET_APP_BY_ALIAS_PATH, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json<GetAppByAliasResponse>(),
    {
      enabled: isAuthenticated,
      ...options
    }
  );
}
