import millify from 'millify';
import { formatSeparatorNumber } from './format-separator-number';

export function formatAbbrNumber(
  value: number,
  decimalSeparator = ',',
  units = ['', 'K', 'M', 'B', 'T', 'P', 'E']
) {
  return value >= 10000
    ? millify(value, { decimalSeparator, units })
    : formatSeparatorNumber(value, decimalSeparator);
}
