import { forwardRef } from 'react';
import { AngleBoldRight16 } from '@elfsight/icons';
import { Button, ButtonProps, ButtonSize } from './button';

export type MenuButtonProps = Omit<
  ButtonProps,
  'fontWeight' | 'height' | 'rightIcon' | 'small'
> & {
  withArrowIcon?: boolean;
};

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  function _MenuButton(
    {
      withArrowIcon,
      href,
      large,
      active,
      selectedBackgroundColor: selectedOverlayColor,
      selected,
      ...forwardingProps
    },
    forwardingRef
  ) {
    const size: ButtonSize = large ? 'large' : 'medium';
    const height = { large: 40, medium: 36 }[size];

    return (
      <Button
        fontWeight={500}
        padding={12}
        negativeOffset={-12}
        textColor="black"
        interactTextColor="black"
        fullWidth
        href={href}
        height={height}
        active={active}
        ref={forwardingRef}
        selectedBackgroundColor={selectedOverlayColor}
        selected={selected}
        iconSize={withArrowIcon ? { large: 16, medium: 12 }[size] : undefined}
        rightIcon={withArrowIcon ? AngleBoldRight16 : undefined}
        {...forwardingProps}
      />
    );
  }
);
