import { Exclude, Expose, Type } from 'class-transformer';
import { IsObject, IsString, ValidateNested } from 'class-validator';

export const GENERATE_APP_PATH = 'help-scout/generate-help-scout-app';

class GenerateAppRequestCustomer {
  @IsString()
  id: string;
}

export class GenerateAppRequest {
  @IsObject()
  @ValidateNested()
  @Type(() => GenerateAppRequestCustomer)
  customer: GenerateAppRequestCustomer;
}

@Exclude()
export class GenerateAppResponse {
  @Expose()
  html: string;
}
