import { Exclude, Expose, Type } from 'class-transformer';
import { PaymentType } from './enums';

export const GET_PAYMENT_HISTORY_PATH = 'billing/get-payment-history';

@Exclude()
export class GetPaymentHistoryResponse {
  @Expose()
  @Type(() => GetPaymentHistoryResponseModel)
  payload: GetPaymentHistoryResponseModel[];
}

@Exclude()
export class GetPaymentHistoryResponseModel {
  @Expose()
  pid: string;

  @Expose()
  type: PaymentType;

  @Expose()
  createdAt: string;

  @Expose()
  @Type(() => GetPaymentHistoryResponseModelPlan)
  plan: {
    name: string;
  };

  @Expose()
  amount: number;

  @Expose()
  @Type(() => GetPaymentHistoryResponseModelData)
  data: {
    nextBillDate: string;
    receiptUrl: string;
  };
}

@Exclude()
export class GetPaymentHistoryResponseModelPlan {
  @Expose()
  name: string;
}

@Exclude()
export class GetPaymentHistoryResponseModelData {
  @Expose()
  eventTime: string;

  @Expose()
  nextBillDate: string;

  @Expose()
  receiptUrl: string;
}
