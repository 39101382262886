import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { Button, Link, P } from '@elfsight-universe/ui-common';
import { helpscoutArticles } from '@constants';
import {
  Coupon,
  DefaultContainer,
  HowToApply,
  HowToApplyListItem
} from '@modules/deals/ui';
import { beacon } from '@modules/beacon';
import { getDealCaption } from '@modules/deals/utils/get-deal-caption';

const howToUseCouponLink = (
  <Link
    color="accent"
    onClick={() => beacon.article(helpscoutArticles.coupon.id)}
  >
    How to use coupon
  </Link>
);

type BirthdayDetailTemplateProps = AccountDeal;

export function BirthdayDetailTemplate(
  accountDeal: BirthdayDetailTemplateProps
) {
  const { deal, customData, data } = accountDeal;
  const { title, textImage, alias } = deal;

  return (
    <DefaultContainer title={title} textImage={textImage} alias={alias}>
      <P big>{getDealCaption(accountDeal)}</P>

      {data.hasActivePaidSubscription ? (
        <Button backgroundColor="brand" href="/birthday-sale">
          Explore Offer
        </Button>
      ) : (
        <HowToApply>
          <HowToApplyListItem>
            <span>1. Copy the Birthday coupon code below:</span>
            <div>
              <Coupon
                color="promoTransparent"
                borderColor="promo"
                coupon={customData?.couponCode as string}
              />
            </div>
          </HowToApplyListItem>
          <HowToApplyListItem>
            <span>
              2. Pick any annual plan and apply the coupon during checkout.
            </span>
          </HowToApplyListItem>
          <HowToApplyListItem>
            <span>{howToUseCouponLink}</span>
          </HowToApplyListItem>
        </HowToApply>
      )}
    </DefaultContainer>
  );
}
