import { useQuery } from '@tanstack/react-query';
import {
  GET_CANCELLATION_REASONS_PATH,
  GetCancellationReasonsResponse
} from '@elfsight-universe/service-core-contracts/billing/get-cancellation-reasons';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useCancellationReasonsQuery() {
  const { isAuthenticated } = useUser();

  return useQuery<GetCancellationReasonsResponse>(
    [GET_CANCELLATION_REASONS_PATH],
    () => client.get(GET_CANCELLATION_REASONS_PATH).json(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isAuthenticated
    }
  );
}
