import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumber, IsOptional, IsString, Min } from 'class-validator';
import { PaginatedRequest } from '../paginated-request';
import { PaginationResponse } from '../pagination-response';

export const GET_CATALOG_TEMPLATES_PATH = 'widget/get-catalog-templates';

export class GetCatalogTemplatesRequest extends PaginatedRequest {
  itemsPerPage = 18;

  @IsString()
  @IsOptional()
  search?: string;

  @IsString()
  @IsOptional()
  categoryAlias?: string;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  seed = 1;
}

@Exclude()
export class GetCatalogTemplatesResponseMeta extends PaginationResponse {
  @Expose()
  currentPage: number;
}

@Exclude()
export class GetCatalogTemplatesResponse {
  @Expose()
  @Type(() => GetCatalogTemplatesResponseMeta)
  meta: GetCatalogTemplatesResponseMeta;

  @Expose()
  @Type(() => GetCatalogTemplatesResponseTemplate)
  payload: GetCatalogTemplatesResponseTemplate[];
}

@Exclude()
export class GetCatalogTemplatesResponseTemplate {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  @Type(() => GetCatalogTemplatesResponseTemplateWidgetThumbnail)
  widgetThumbnail?: {
    url: string;
    width: number | null;
    height: number | null;
  };

  @Expose()
  @Type(() => GetCatalogTemplatesResponseTemplateApp)
  app: {
    name: string;
    alias: string;
  };
}

@Exclude()
export class GetCatalogTemplatesResponseTemplateApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;
}

@Exclude()
export class GetCatalogTemplatesResponseTemplateWidgetThumbnail {
  @Expose()
  url: string;

  @Expose()
  width: number | null;

  @Expose()
  height: number | null;
}
