import { useState, FocusEvent } from 'react';

type Callback<E> = (e: FocusEvent<E>) => void;

export function useFocused<E = HTMLInputElement>(
  onFocus?: Callback<E>,
  onBlur?: Callback<E>
): [boolean, { onFocus: Callback<E>; onBlur: Callback<E> }] {
  const [focused, setFocused] = useState(false);

  const handlers = {
    onFocus: (e: FocusEvent<E>) => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    onBlur: (e: FocusEvent<E>) => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    }
  };

  return [focused, handlers];
}
