import type { ReactNode } from 'react';
import { createContext, useMemo, useContext } from 'react';
import { useFinishImpersonateClientMutation } from '@api';
import { useUser } from '@modules/_app/app-user-provider';
import { ImpersonateSessionFrame } from './impersonate-session-frame';
import { useFinishImpersonateClientBeforeUnload } from '@modules/impersonate-session/use-finish-impersonate-client-before-unload';

export interface ImpersonateSession {
  finishImpersonateSession(): void;
  isInImpersonateSession: boolean;
}

export const ImpersonateSessionContext = createContext<
  ImpersonateSession | undefined
>(undefined);

export function useImpersonateSession() {
  const impersonateSession = useContext(ImpersonateSessionContext);
  if (!impersonateSession) {
    throw new Error(
      'It`s expected "useImpersonateSession" to be used within a "ImpersonateSessionContext".'
    );
  }
  return impersonateSession;
}

export interface ImpersonateSessionProviderProps {
  children: ReactNode;
}

export function ImpersonateSessionProvider({
  children
}: ImpersonateSessionProviderProps) {
  const { user } = useUser();
  const isInImpersonateSession = user?.isImpersonateSession ?? false;

  const { mutate: finishImpersonateSession } =
    useFinishImpersonateClientMutation();

  const impersonateSession = useMemo<ImpersonateSession>(
    () => ({
      finishImpersonateSession: () => {
        if (isInImpersonateSession) {
          finishImpersonateSession();
        }
      },
      isInImpersonateSession
    }),
    [finishImpersonateSession, isInImpersonateSession]
  );

  useFinishImpersonateClientBeforeUnload(
    impersonateSession.isInImpersonateSession
  );

  return (
    <ImpersonateSessionContext.Provider value={impersonateSession}>
      {isInImpersonateSession && (
        <ImpersonateSessionFrame
          onFinishImpersonateSessionRequest={
            impersonateSession.finishImpersonateSession
          }
          impersonatedUserEmail={user!.email}
        />
      )}
      {children}
    </ImpersonateSessionContext.Provider>
  );
}
