import { useTheme } from 'styled-components';
import { Modal, ModalProps } from '@elfsight-universe/ui-common';

type OnboardingMobileModalProps = Omit<ModalProps, 'title' | 'actions'>;

export function OnboardingMobileModal({
  isOpen,
  onRequestClose,
  children,
  ...forwardingProps
}: OnboardingMobileModalProps) {
  const { zIndex: themeZIndex } = useTheme();

  return (
    <Modal
      maxWidth={400}
      onRequestClose={onRequestClose}
      padding={[24, 24]}
      zIndex={themeZIndex.headerOnboardingMobile}
      isOpen={isOpen}
      withClose
      {...forwardingProps}
    >
      {children}
    </Modal>
  );
}
