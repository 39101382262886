import { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { MOBILE } from '@elfsight-universe/ui-common';

type LayoutStripeProps = PropsWithChildren<{
  margin?: number | [number, number];
  headerMargin?: number | [number, number];
  title?: ReactNode;
  caption?: ReactNode;
}>;

export function LayoutStripe({
  children,
  margin,
  headerMargin,
  title,
  caption,
  ...forwardingProps
}: LayoutStripeProps) {
  const withHeader = title || caption;

  return (
    <Container _margin={margin} {...forwardingProps}>
      {withHeader && (
        <Header _margin={headerMargin}>
          {title && <HeaderTitle>{title}</HeaderTitle>}
          {caption && <HeaderCaption>{caption}</HeaderCaption>}
        </Header>
      )}

      {children}
    </Container>
  );
}

const Container = styled.div<{ _margin?: number | [number, number] }>`
  ${({ _margin = [0, 40] }) => {
    const [mt, mb] = Array.isArray(_margin) ? _margin : [_margin, _margin];
    return css`
      margin-top: ${mt}px;
      margin-bottom: ${mb}px;
    `;
  }}
`;

const Header = styled.div<{ _margin?: number | [number, number] }>`
  ${({ _margin = [0, 20] }) => {
    const [mt, mb] = Array.isArray(_margin) ? _margin : [_margin, _margin];
    return css`
      margin-top: ${mt}px;
      margin-bottom: ${mb}px;
    `;
  }}
`;

const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.font.title2};

  ${MOBILE} {
    ${({ theme }) => theme.font.title3Semibold};
  }
`;

const HeaderCaption = styled.div`
  margin-top: 4px;
`;
