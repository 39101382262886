import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAnnouncementsContext } from '../announcements-provider';

export function AnnouncementsObserver() {
  const { data, nextPage, isLoading } = useAnnouncementsContext();
  const divider = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries, observer) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        observer.disconnect();
        nextPage();
      }
    });

    if (divider.current) {
      observer.current.observe(divider.current);
    }
  }, [data, divider]);

  if (isLoading) {
    return null;
  }

  return <Divider ref={divider} />;
}

const Divider = styled.div`
  height: 1px;
`;
