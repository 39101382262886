import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { client } from '../client';
import { useUser } from '@modules/_app';
import {
  GET_SUBSCRIPTION_APP_INFO_PATH,
  GetSubscriptionAppInfoResponse
} from '@elfsight-universe/service-core-contracts/billing';

export function useSubscriptionAppInfoQuery(
  appAlias: string | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<GetSubscriptionAppInfoResponse> = {}
) {
  const { isAuthenticated } = useUser();
  const canQuery = Boolean(appAlias) && isAuthenticated;

  return useQuery<GetSubscriptionAppInfoResponse>(
    [GET_SUBSCRIPTION_APP_INFO_PATH, appAlias],
    () =>
      client
        .get(GET_SUBSCRIPTION_APP_INFO_PATH, {
          searchParams: { appAlias: appAlias as string },
          retry: { limit: 0 }
        })
        .json(),
    {
      enabled: (enabled === undefined || enabled) && canQuery,
      retry: false,
      ...options
    }
  );
}
