import { Exclude, Expose } from 'class-transformer';

export const GET_CANCELLATION_REASONS_PATH = 'billing/get-cancellation-reasons';

@Exclude()
export class GetCancellationReasonsResponse {
  @Expose()
  payload: GetCancellationReasonsItem[];
}

@Exclude()
export class GetCancellationReasonsItem {
  @Expose()
  pid: string;

  @Expose()
  alias: string;

  @Expose()
  value: string;

  @Expose()
  hidden: boolean;
}
