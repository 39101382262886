import { Exclude, Expose } from 'class-transformer';

export const CSRF_HEADER_NAME = 'x-csrf-token';

export const CSRF_PATH = '/csrf';

@Exclude()
export class CSRFResponse {
  @Expose()
  token?: string;
}
