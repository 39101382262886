import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_WIDTH } from '@elfsight-universe/ui-common';

export const AppMobileContext = createContext<boolean | undefined | null>(null);

export function AppMobileProvider({ children }: PropsWithChildren) {
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const isMobileQuery = useMediaQuery({ maxWidth: MOBILE_WIDTH }); // @TODO test with onChange impl once more

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);

  return (
    <AppMobileContext.Provider value={isMobile}>
      {children}
    </AppMobileContext.Provider>
  );
}

export function useIsMobile() {
  const context = useContext(AppMobileContext);

  if (context === null) {
    throw new Error(
      '`useIsMobile` must be nested inside an `AppMobileProvider`.'
    );
  }

  return context;
}
