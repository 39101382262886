import { useMutation } from '@tanstack/react-query';
import {
  CREATE_LITE_SUBSCRIPTION_PATH,
  CreateLiteSubscriptionRequest
} from '@elfsight-universe/service-core-contracts/billing';
import { client } from '../client';

export function useCreateLiteSubscriptionMutation(onSuccess?: () => void) {
  return useMutation<Response, unknown, CreateLiteSubscriptionRequest>(
    (data) =>
      client.post(CREATE_LITE_SUBSCRIPTION_PATH, {
        json: data
      }),
    {
      onSuccess
    }
  );
}
