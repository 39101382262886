import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Loader,
  Modal,
  ModalProps,
  useTheme,
  Y_OFFSET as YOffsetModal
} from '@elfsight-universe/ui-common';

const RATIO = 9 / 16;
const POPUP_WIDTH = 1280;

type VideoModalProps = Omit<ModalProps, 'title' | 'actions'> & {
  url: string;
};

export function VideoModal({
  isOpen,
  zIndex,
  onRequestClose,
  url,
  ...forwardingProps
}: VideoModalProps) {
  const { colors, zIndex: themeZIndex } = useTheme();
  const [popupWidth, setPopupWidth] = useState<number>(POPUP_WIDTH);

  const updatePopupWidthBasedWindowHeight = () => {
    const popupHeight = popupWidth * RATIO;
    const windowHeight = window.innerHeight - YOffsetModal * 2;
    if (popupHeight > windowHeight) {
      setPopupWidth(windowHeight / RATIO);
    } else {
      setPopupWidth(POPUP_WIDTH);
    }
  };

  useEffect(() => {
    updatePopupWidthBasedWindowHeight();
    window.addEventListener('resize', updatePopupWidthBasedWindowHeight);
    return () =>
      window.removeEventListener('resize', updatePopupWidthBasedWindowHeight);
  }, []);

  return (
    <Modal
      withClose
      maxWidth={popupWidth}
      shouldFocusAfterRender={false}
      onRequestClose={onRequestClose}
      zIndex={zIndex || themeZIndex.videoPlayerModal}
      isOpen={isOpen}
      withoutBoxShadow
      style={{
        content: {
          padding: 0
        },
        overlay: {
          background: colors.gray90,
          width: '100%',
          height: '100%'
        }
      }}
      fitInViewPort
      theme="clear"
      {...forwardingProps}
    >
      <Content>
        <PlayerWrapper>
          <PlayerIframe
            src={url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </PlayerWrapper>
        <StyledLoader absolute color="white" large />
      </Content>
    </Modal>
  );
}

const Content = styled.div`
`;

const PlayerWrapper = styled.div`
  aspect-ratio: 16 / 9;
`;

const PlayerIframe = styled.iframe`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 12px;

  video {
    outline: none;
  }
`;

const StyledLoader = styled(Loader)`
  z-index: -1;
`;
