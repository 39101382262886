import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  HAS_ACTIVE_PAID_SUBSCRIPTION_PATH,
  HasActivePaidSubscriptionResponse
} from '@elfsight-universe/service-core-contracts/billing/has-active-paid-subscription';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useHasActivePaidSubscriptionQuery(
  options: UseQueryOptions<HasActivePaidSubscriptionResponse>
) {
  const { isAuthenticated } = useUser();
  return useQuery<HasActivePaidSubscriptionResponse>(
    [HAS_ACTIVE_PAID_SUBSCRIPTION_PATH],
    () =>
      client
        .get(HAS_ACTIVE_PAID_SUBSCRIPTION_PATH)
        .json<HasActivePaidSubscriptionResponse>(),
    {
      enabled:
        (options.enabled === undefined || options.enabled) && isAuthenticated,
      ...options
    }
  );
}
