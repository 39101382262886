import { Exclude, Expose } from 'class-transformer';
import { IsEmail, IsNotEmpty } from 'class-validator';

export const USER_EXIST_PATH = 'identity-and-access/user-exist';

export class UserExistRequest {
  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail(undefined, { message: 'Email is invalid.' })
  email: string;
}

@Exclude()
export class UserExistResponse {
  @Expose()
  exist: boolean;
}
