import { IsEmail, IsNotEmpty, MaxLength, MinLength } from 'class-validator';
import { IdentityAndAccessError } from '../errors';

export const SIGN_UP_BY_CREDENTIALS_PATH =
  'identity-and-access/sign-up-by-credentials';

export class SignUpByCredentialsRequest {
  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail(undefined, { message: 'Email is invalid.' })
  email: string;

  @MinLength(6, { message: 'Password is too weak.' })
  @MaxLength(32, { message: 'Password is too long.' })
  password: string;
}

export type SignUpByCredentialsResponseError =
  IdentityAndAccessError.USER_ALREADY_EXISTS;
