import { IsString } from 'class-validator';
import { BillingError, WidgetError } from '../errors';
import { UpdateSubscriptionError } from './interfaces';

export const SWITCH_SUBSCRIPTION_APP_PATH = 'billing/switch-subscription-app';

export class SwitchSubscriptionAppRequest {
  @IsString()
  newAppAlias: string;

  @IsString()
  currentAppAlias: string;
}

export type SwitchSubscriptionAppResponseErrorReason =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANNOT_BE_UPDATED
  | BillingError.PLAN_DOES_NOT_EXIST
  | WidgetError.APP_DOES_NOT_EXIST;

export type SwitchSubscriptionAppResponseError =
  | UpdateSubscriptionError
  | SwitchSubscriptionAppResponseErrorReason;
