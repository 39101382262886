import { SelectOption } from '@elfsight-universe/ui-common';

export const platformList = [
  {
    groupName: 'Popular',
    items: [
      'Shopify',
      'WordPress',
      'Squarespace',
      'BigCommerce',
      'Wix',
      'Google Sites',
      'Weebly'
    ]
  },
  {
    groupName: 'More Platforms',
    items: [
      'Big Cartel',
      'Blogger',
      'OpenCart',
      'Joomla',
      'Webflow',
      'GoDaddy',
      'Lightspeed',
      'Jumpseller',
      'Webnode',
      'Drupal',
      'Duda',
      'Jimdo',
      'Ecwid',
      'iFrame',
      'Notion',
      'Prestashop',
      'Canva'
    ]
  }
];

const suggestionOptions = [
  {
    label: 'Other',
    value: 'Other',
    suggestion: true
  },
  {
    label: "I Don't Know",
    value: "I Don't Know",
    suggestion: true
  }
];
export const requestInstallationPlatformList = [
  ...platformList.reduce<SelectOption[]>((acc, group) => {
    const groupItems = group.items.map((item) => ({
      label: item,
      value: item,
      group: group.groupName
    }));

    return [...acc, ...groupItems];
  }, []),
  ...suggestionOptions
];
