import { theme } from '../../theme';
import { getColorWithAlpha } from '../../utils';

export const defaultStateColors = {
  $hoverOverlayColor: getColorWithAlpha(theme.colors.white, 0.1),
  $activeOverlayColor: getColorWithAlpha(theme.colors.black, 0.1),
  $selectedOverlayColor: getColorWithAlpha(theme.colors.black, 0.1)
};

export const buttonColorsMap = {
  primary: {
    $backgroundColor: 'black',
    $textColor: 'white',
    ...defaultStateColors
  },
  secondary: {
    $backgroundColor: 'gray5',
    $textColor: 'black',
    $hoverOverlayColor: getColorWithAlpha(theme.colors.black, 0.1),
    $activeOverlayColor: getColorWithAlpha(theme.colors.black, 0.15),
    $selectedOverlayColor: getColorWithAlpha(theme.colors.black, 0.15)
  },
  accentPrimary: {
    $backgroundColor: 'accent',
    $textColor: 'white',
    ...defaultStateColors
  },
  accentSecondary: {
    $backgroundColor: 'accentTransparent',
    $textColor: 'accent',
    $hoverOverlayColor: getColorWithAlpha(theme.colors.accent, 0.1),
    $activeOverlayColor: getColorWithAlpha(theme.colors.accent, 0.15),
    $selectedOverlayColor: getColorWithAlpha(theme.colors.accent, 0.15)
  },
  promoPrimary: {
    $backgroundColor: 'promo',
    $textColor: 'white',
    ...defaultStateColors
  },
  promoSecondary: {
    $backgroundColor: 'promoTransparent',
    $textColor: 'promo',
    $hoverOverlayColor: getColorWithAlpha(theme.colors.promo, 0.1),
    $activeOverlayColor: getColorWithAlpha(theme.colors.promo, 0.15),
    $selectedOverlayColor: getColorWithAlpha(theme.colors.promo, 0.15)
  },
  alertPrimary: {
    $backgroundColor: 'alert',
    $textColor: 'white',
    ...defaultStateColors
  },
  alertSecondary: {
    $backgroundColor: 'alertTransparent',
    $textColor: 'alert',
    $hoverOverlayColor: getColorWithAlpha(theme.colors.alert, 0.1),
    $activeOverlayColor: getColorWithAlpha(theme.colors.alert, 0.15),
    $selectedOverlayColor: getColorWithAlpha(theme.colors.alert, 0.15)
  },
  successPrimary: {
    $backgroundColor: 'success',
    $textColor: 'white',
    ...defaultStateColors
  },
  successSecondary: {
    $backgroundColor: 'successTransparent',
    $textColor: 'success',
    $hoverOverlayColor: getColorWithAlpha(theme.colors.success, 0.1),
    $activeOverlayColor: getColorWithAlpha(theme.colors.success, 0.15),
    $selectedOverlayColor: getColorWithAlpha(theme.colors.success, 0.15)
  },
  text: {
    $backgroundColor: 'transparent',
    $textColor: 'gray50',
    $interactTextColor: 'black',
    $hoverOverlayColor: getColorWithAlpha(theme.colors.black, 0.05),
    $activeOverlayColor: getColorWithAlpha(theme.colors.black, 0.1),
    $selectedOverlayColor: getColorWithAlpha(theme.colors.black, 0.1)
  }
} as const;
