import { PlanGrade } from '@elfsight-universe/service-core-contracts/billing';

export const getHumanizedPlanGradeName = (planGrade: PlanGrade) => {
  return {
    [PlanGrade.PRO]: 'Pro',
    [PlanGrade.BASIC]: 'Basic',
    [PlanGrade.PREMIUM]: 'Premium',
    [PlanGrade.ENTERPRISE_T1]: 'Enterprise T1',
    [PlanGrade.ENTERPRISE_T2]: 'Enterprise T2',
    [PlanGrade.ENTERPRISE_T3]: 'Enterprise T3',
    [PlanGrade.ENTERPRISE_T4]: 'Enterprise T4'
  }[planGrade];
};
