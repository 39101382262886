import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  DECLINE_ACCOUNT_MEMBER_INVITATION_PATH,
  DeclineAccountMemberInvitationRequest,
  DeclineAccountMemberInvitationResponseError
} from '@elfsight-universe/service-core-contracts/iam';
import { client } from '../client';

export function useDeclineAccountMemberInvitationMutation(
  options: UseMutationOptions<
    undefined,
    DeclineAccountMemberInvitationResponseError,
    DeclineAccountMemberInvitationRequest
  > = {}
) {
  return useMutation<
    undefined,
    DeclineAccountMemberInvitationResponseError,
    DeclineAccountMemberInvitationRequest
  >(
    ({ accountMemberInvitationPid }) =>
      client
        .post(DECLINE_ACCOUNT_MEMBER_INVITATION_PATH, {
          json: { accountMemberInvitationPid }
        })
        .json<undefined>(),
    {
      retry: false,
      ...options
    }
  );
}
