import styled from 'styled-components';
import { Icon } from '@elfsight-universe/ui-common';
import { Toast } from '@modules/_app/app-toaster/toast';
import OnlineIcon from '@icons/online-20.svg';
import OfflineIcon from '@icons/offline-20.svg';

type ToastOnlineStatusProps = {
  isOnline: boolean;
  onClose: () => void;
};

export function ToastOnlineStatus({
  isOnline,
  onClose
}: ToastOnlineStatusProps) {
  const text = !isOnline
    ? 'You are currently offline.'
    : 'Your internet connection was restored.';

  return (
    <Toast
      message={text}
      icon={isOnline ? <StyledOnlineIcon /> : <StyledOfflineIcon />}
      iconColor={isOnline ? 'success' : 'rgba(255, 255, 255, 0.5)'}
      withClose
      onClose={onClose}
    />
  );
}

const StyledOnlineIcon = styled(Icon).attrs({
  size: 20,
  component: OnlineIcon
})``;

const StyledOfflineIcon = styled(Icon).attrs({
  size: 20,
  component: OfflineIcon
})``;
