import { IsObject, IsOptional, IsUUID } from 'class-validator';
import { WidgetError } from '../errors';
import type { JsonObject } from '../json.type';

export const PUBLISH_UNPUBLISHED_WIDGET_REVISION_PATH =
  'widget/publish-unpublished-widget-revision';

export class PublishUnpublishedWidgetRevisionRequest {
  @IsUUID()
  widgetPid: string;

  @IsOptional()
  @IsObject()
  settings?: JsonObject;
}

export type PublishUnpublishedWidgetRevisionResponseError =
  | WidgetError.WIDGET_DOES_NOT_EXIST
  | WidgetError.WIDGETS_LIMIT_REACHED;
