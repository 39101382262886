import { useId } from 'react';
import styled from 'styled-components';
import ElfsightGlyphIcon from './assets/elfsight-glyph.svg';

export interface ElfsightIconWithContainerProps {
  size: number;
}

export function ElfsightIconWithContainer({
  size,
  ...forwardingProps
}: ElfsightIconWithContainerProps) {
  const gradientId = useId();
  const glyphContainerRatio = 0.65;
  const glyphSize = size * glyphContainerRatio;
  return (
    <Container $size={size} {...forwardingProps}>
      <HiddenSVG>
        <defs>
          <linearGradient id={gradientId} gradientTransform="rotate(180)">
            <stop offset="0%" stopColor="#FFFFFF" />
            <stop offset="100%" stopColor="#FDE9EE" />
          </linearGradient>
        </defs>
      </HiddenSVG>
      <StyledElfsightGlyph $size={glyphSize} $fill={`url(#${gradientId})`} />
    </Container>
  );
}

const Container = styled.div<{ $size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ $size }) => $size / 3.2}px;
  background: linear-gradient(180deg, #fa527a 0%, #f80741 172.5%);
`;

const StyledElfsightGlyph = styled(ElfsightGlyphIcon)<{
  $size: number;
  $fill: string;
}>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;

  path {
    fill: ${({ $fill }) => $fill};
  }
`;

const HiddenSVG = styled.svg.attrs({ xmlns: 'http://www.w3.org/2000/svg' })`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
`;
