import { io, Socket } from 'socket.io-client';
import { ManagerOptions } from 'socket.io-client/build/esm/manager';
import { SocketOptions } from 'socket.io-client/build/esm/socket';
import { catchUnhandledError } from '@modules/_error';

let socket: Socket;

const { NEXT_PUBLIC_APP_DASHBOARD__BASE_URL } = process.env;

export async function connectToWebsocketApi(
  options?: Partial<ManagerOptions & SocketOptions>
) {
  try {
    socket = io(NEXT_PUBLIC_APP_DASHBOARD__BASE_URL as string, options);
  } catch (e) {
    catchUnhandledError(e);
  }

  return socket;
}
