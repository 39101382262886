import { useTheme } from 'styled-components';
import {
  Modal,
  ModalProps,
  useWindowHeight,
  Y_OFFSET as YOffsetModal
} from '@elfsight-universe/ui-common';
import { AnnouncementsContent } from './announcements-content';

type AnnouncementsMobileModalProps = Omit<ModalProps, 'title' | 'actions'>;

export function AnnouncementsMobileModal({
  isOpen,
  onRequestClose,
  ...forwardingProps
}: AnnouncementsMobileModalProps) {
  const { zIndex: themeZIndex } = useTheme();
  const windowHeight = useWindowHeight();

  return (
    <Modal
      maxWidth={360}
      onRequestClose={onRequestClose}
      padding={[0, 0]}
      zIndex={themeZIndex.announcementsMobile}
      isOpen={isOpen}
      {...forwardingProps}
    >
      <AnnouncementsContent
        maxHeight={windowHeight ? windowHeight - YOffsetModal : 0}
        onCloseButtonClick={onRequestClose}
        withClose
      />
    </Modal>
  );
}
