import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import {
  ButtonPrimitive,
  SearchInput,
  H2,
  Link,
  Icon
} from '@elfsight-universe/ui-common';
import { useIsMobile } from '@modules/_app';
import { SearchQueryType } from '@elfsight-universe/service-core-contracts/analytics/enums';
import { beacon } from '@modules/beacon';
import { embedToWebsiteTutorialsList } from './embed-to-website-tutorials-list';
import { debouncedSaveSearchQuery, sendAnalyticsEvent } from '@api';
import { ExternalLink } from '@elfsight/icons';

export type EmbedToWebsiteTutorialsProps = {
  articleOpenAt?: 'modal' | 'sidebar' | undefined;
};

export function EmbedToWebsiteTutorials({
  articleOpenAt,
  ...forwardingProps
}: EmbedToWebsiteTutorialsProps) {
  const [filter, setFilter] = useState('');
  const isMobile = useIsMobile();

  const filteredTutorials = embedToWebsiteTutorialsList.filter(({ platform }) =>
    platform.toLowerCase().includes(filter.toLowerCase())
  );

  const handleChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setFilter(value);

    if (value) {
      debouncedSaveSearchQuery({
        type: SearchQueryType.WIDGET_PLATFORM_TUTORIALS,
        search: value
      });
    }
  };

  const handleArticleClick = (platform: string, articleID: string) => () => {
    beacon.article(articleID, articleOpenAt);
    sendAnalyticsEvent({
      name: 'widget_platform_tutorials_select',
      params: { item_id: platform }
    });
  };

  if (isMobile) {
    return (
      <Container {...forwardingProps}>
        <Link
          color="accent"
          target="_blank"
          href="https://help.elfsight.com/article/422-installation-tutorials-by-platform-cms"
        >
          Installation Tutorials by Platform
          <ExternalLinkIcon component={ExternalLink} size={16} />
        </Link>
      </Container>
    );
  }

  return (
    <Container {...forwardingProps}>
      <H2 gb>Platform Tutorials</H2>

      <SearchInput
        value={filter}
        placeholder="Search your website platform"
        name="platformsFilter"
        onReset={() => setFilter('')}
        onChange={handleChange}
      />

      {filteredTutorials.length > 0 ? (
        <Grid _oneColumn={Boolean(filter)}>
          {filteredTutorials.map(({ platform, articleID, iconURL }) => (
            <Item
              key={platform}
              onClick={handleArticleClick(platform, articleID)}
            >
              <Logo src={iconURL} />
              {platform}
            </Item>
          ))}
        </Grid>
      ) : (
        <Empty>No results found</Empty>
      )}
    </Container>
  );
}

const Container = styled.div``;

const ExternalLinkIcon = styled(Icon)`
  margin-left: 4px;
  display: inline-block;
  vertical-align: top;
`;

const Logo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;

const Item = styled(ButtonPrimitive)`
  ${({ theme }) => theme.font.text};
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  height: 36px;
  border-radius: 4px;
  border: none;
  transition: background 0.2s;
  background: ${({ theme }) => theme.colors.white};
  outline: none;
  :hover {
    background: ${({ theme }) => theme.colors.gray5};
  }
  :active,
  :focus {
    background: ${({ theme }) => theme.colors.gray10};
  }
`;

const Grid = styled.div<{ _oneColumn: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  margin: 20px -8px 0 -8px;
  width: calc(100% + 16px);
`;

const Empty = styled.div`
  ${({ theme }) => theme.font.text};
  color: ${({ theme }) => theme.colors.gray50};
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 36px;
`;
