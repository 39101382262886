import styled from 'styled-components';
import { CloseBold } from '@elfsight/icons';
import { Icon, Link } from '@elfsight-universe/ui-common';

export interface ImpersonateSessionFrameProps {
  onFinishImpersonateSessionRequest: () => void;
  impersonatedUserEmail: string;
}

export function ImpersonateSessionFrame({
  onFinishImpersonateSessionRequest,
  impersonatedUserEmail
}: ImpersonateSessionFrameProps) {
  return (
    <>
      <Frame />
      <Notice>
        <Text>You&apos;re impersonating customer {impersonatedUserEmail}</Text>
        <Link
          color="white"
          onClick={onFinishImpersonateSessionRequest}
          underline
        >
          <Icon component={CloseBold} size={12} />
        </Link>
      </Notice>
    </>
  );
}

const Frame = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.impersonateSessionFrame};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 5px ${({ theme }) => theme.colors.alert},
    inset 0 0 15px ${({ theme }) => theme.colors.alert};
  pointer-events: none;
`;

const Notice = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.impersonateSessionFrame};
  top: 0;
  left: 50%;
  padding: 6px 8px 5px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: ${({ theme }) => theme.colors.alert};
  transform: translateX(-50%);
  box-shadow: 0 0 5px ${({ theme }) => theme.colors.alert};
`;

const Text = styled.div`
  margin-right: 8px;
  ${({ theme }) => theme.font.caption};
  color: ${({ theme }) => theme.colors.white};
`;
