import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  CREATE_SUBSCRIPTION_PATH,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  CreateSubscriptionResponseError
} from '@elfsight-universe/service-core-contracts/billing/create-subscription';
import { client } from '../client';

export function useCreateSubscriptionMutation(
  options: Pick<
    UseMutationOptions<
      CreateSubscriptionResponse,
      CreateSubscriptionResponseError,
      CreateSubscriptionRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    CreateSubscriptionResponse,
    CreateSubscriptionResponseError,
    CreateSubscriptionRequest
  >(
    (data) => client.post(CREATE_SUBSCRIPTION_PATH, { json: data }).json(),
    options
  );
}
