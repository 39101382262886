import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { PROMO_PAGE_URL } from '@modules/pack-plans-promo/constants';
import {
  BIRTHDAY_DEAL_ALIAS,
  BLACK_FRIDAY_DEAL_ALIAS,
  REFER_A_FRIEND_DEAL_ALIAS,
  SWITCH_TO_ANNUAL_DEAL_ALIAS,
  SWITCH_TO_PACK_DEAL_ALIAS,
  WELCOME_DEAL_ALIAS,
  XMAS_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';
import { createUpgradeURL } from '@modules/upgrade';

export function getDealURLForButton({ deal, customData }: AccountDeal) {
  const standardURL = `/deals/${deal.alias}`;
  let switchToAnnualURL = standardURL;

  if (deal.alias === SWITCH_TO_ANNUAL_DEAL_ALIAS) {
    const upgradeYearlyURLParams = new URLSearchParams({
      to: customData?.switchPlanParams?.to as string,
      from: customData?.switchPlanParams?.from as string
    });
    switchToAnnualURL = `/upgrade-confirm?${upgradeYearlyURLParams}`;
  }

  const dealURLForButtonMapping: Record<string, string> = {
    [REFER_A_FRIEND_DEAL_ALIAS]: '/refer-a-friend',
    [SWITCH_TO_PACK_DEAL_ALIAS]: PROMO_PAGE_URL,
    [BIRTHDAY_DEAL_ALIAS]: '/birthday-sale',
    [BLACK_FRIDAY_DEAL_ALIAS]: '/black-friday-sale',
    [XMAS_DEAL_ALIAS]: '/christmas-sale',
    [SWITCH_TO_ANNUAL_DEAL_ALIAS]: switchToAnnualURL,
    [WELCOME_DEAL_ALIAS]: customData?.lastUsedInstalledAppAlias
      ? createUpgradeURL({ app: customData.lastUsedInstalledAppAlias })
      : PROMO_PAGE_URL
  };

  return dealURLForButtonMapping[deal.alias] || standardURL;
}
