import { Exclude, Expose } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { WidgetError, IdentityAndAccessError } from '../errors';

export const CONNECT_WIDGET_PATH = 'wix-integration/connect-widget';

@Exclude()
export class ConnectWidgetRequest {
  @Expose()
  wixWidgetId: string;

  @Expose()
  @IsUUID()
  widgetPid: string;
}

export type ConnectWidgetResponseError =
  | WidgetError.WIDGET_DOES_NOT_EXIST
  | IdentityAndAccessError.NOT_AUTHORIZED;
