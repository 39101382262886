import {
  ChangeEvent,
  ChangeEventHandler,
  MouseEventHandler,
  ComponentType,
  forwardRef
} from 'react';
import styled, { css } from 'styled-components';
import { ThemeColor } from '../../../theme';
import { Icon } from '../../icons';

export type SelectOption = {
  value: string;
  label: string;
  group?: string;
  suggestion?: boolean;
  leftIcon?: ComponentType;
  rightIcon?: ComponentType;
  iconSize?: number;
  iconColor?: string | ThemeColor;
};

export type SelectPopoverItemProps = SelectOption & {
  selected: boolean;
  focused: boolean;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
};

export const SelectPopoverItem = forwardRef<
  HTMLDivElement,
  SelectPopoverItemProps
>(function SelectPopoverItem(
  {
    value,
    label,
    selected,
    focused,
    leftIcon,
    rightIcon,
    iconSize = 20,
    iconColor,
    onChange,
    onClick,
    onMouseEnter,
    onMouseLeave,
    ...forwardingProps
  },
  forwardingRef
) {
  const hasIcon = !!leftIcon || !!rightIcon;

  return (
    <Option
      ref={forwardingRef}
      key={value}
      _isSelected={selected}
      _isFocused={focused}
      _hasIcon={hasIcon}
      onClick={(event) => {
        const changeEvent = {
          ...event,
          target: { ...event.target, value }
        } as unknown as ChangeEvent<HTMLSelectElement>;
        onChange && onChange(changeEvent);
        onClick && onClick(event);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...forwardingProps}
    >
      <>
        {leftIcon && (
          <OptionIconSlot _left>
            <Icon size={iconSize} fill={iconColor} component={leftIcon} />
          </OptionIconSlot>
        )}
        {label}
        {rightIcon && (
          <OptionIconSlot _right>
            <Icon size={iconSize} fill={iconColor} component={rightIcon} />
          </OptionIconSlot>
        )}
      </>
    </Option>
  );
});

const Option = styled.div<{
  _isSelected: boolean;
  _isFocused: boolean;
  _hasIcon: boolean;
}>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  padding: 7px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.transparent};

  :active {
    background: ${({ theme }) => theme.colors.gray10};
  }

  :focus {
    outline: 0;
  }

  ${({ _isSelected, _isFocused, theme }) => {
    if (_isSelected)
      return css`
        background: ${theme.colors.gray10};
      `;

    if (_isFocused)
      return css`
        background: ${theme.colors.gray5};
      `;

    if (_isSelected && _isFocused)
      return css`
        background: ${theme.colors.gray10};
      `;
  }}

  ${({ _hasIcon }) =>
    _hasIcon &&
    css`
      justify-content: space-between;
    `}
`;

const OptionIconSlot = styled.div<{
  _left?: boolean;
  _right?: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ _left }) =>
    _left &&
    css`
      margin-right: 12px;
    `}
  ${({ _right }) =>
    _right &&
    css`
      margin-left: 12px;
    `}
`;
