import { Exclude, Expose } from 'class-transformer';
import { IdentityAndAccessError } from '../errors';
import { SortWidgetsVariant, ViewWidgetsVariant } from '../widget';

export const GET_USER_PREFERENCES_PATH =
  'identity-and-access/get-user-preferences';

@Exclude()
export class GetUserPreferencesResponse {
  @Expose()
  viewsLimitNotification: boolean;

  @Expose()
  dealsNotification: boolean;

  @Expose()
  marketingNotification: boolean;

  @Expose()
  communityNotification: boolean;

  @Expose()
  affiliatesNotification: boolean;

  @Expose()
  viewWidgetsVariant: ViewWidgetsVariant | null;

  @Expose()
  sortWidgetsVariant: SortWidgetsVariant | null;
}

export type GetUserPreferencesResponseError =
  IdentityAndAccessError.USER_DOES_NOT_EXISTS;
