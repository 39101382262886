import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export function useRouterQueryReady<T extends string | string[]>(
  queryParam: string,
  onReady: (queryParamValue: T) => void
) {
  const { query, isReady } = useRouter();

  const onReadyRef = useRef(onReady);
  useEffect(() => {
    onReadyRef.current = onReady;
  }, [onReady]);

  const queryParamValueRef = useRef(query[queryParam]);
  useEffect(() => {
    queryParamValueRef.current = query[queryParam];
  }, [query[queryParam]]);

  useEffect(() => {
    if (isReady && queryParamValueRef.current !== undefined) {
      onReadyRef.current(queryParamValueRef.current as T);
    }
  }, [isReady]);
}
