import { beacon } from '../beacon';
import { Link, LinkProps } from '@elfsight-universe/ui-common';

type ContactSupportProps = Omit<LinkProps, 'href'>;

export function ContactSupport({
  children = 'contact support',
  ...forwardingProps
}: ContactSupportProps) {
  return (
    <Link onClick={() => beacon.openChat()} {...forwardingProps}>
      {children}
    </Link>
  );
}
