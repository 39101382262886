import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  SCHEDULE_SUBSCRIPTION_CANCELLATION_PATH,
  ScheduleSubscriptionCancellationRequest,
  ScheduleSubscriptionCancellationResponseError
} from '@elfsight-universe/service-core-contracts/billing/schedule-subscription-cancellation';
import { client } from '../client';

export function useScheduleSubscriptionCancellationMutation(
  options: Pick<
    UseMutationOptions<
      Response,
      ScheduleSubscriptionCancellationResponseError,
      ScheduleSubscriptionCancellationRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    Response,
    ScheduleSubscriptionCancellationResponseError,
    ScheduleSubscriptionCancellationRequest
  >(
    (data) =>
      client.post(SCHEDULE_SUBSCRIPTION_CANCELLATION_PATH, { json: data }),
    options
  );
}
