import { Exclude, Expose } from 'class-transformer';
import { BillingInterval, PlanGrade } from '../enums';

@Exclude()
export class StandardPaidPlanProperties {
  appId?: number;

  @Expose()
  grade: PlanGrade;

  @Expose()
  billingInterval: BillingInterval;

  @Expose()
  price: number;

  @Expose()
  paddleId: number;

  @Expose()
  icon?: string;
}

export class LitePlanProperties {
  @Exclude()
  appId: number;

  @Expose()
  hidden?: boolean;
}
