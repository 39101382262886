import {
  useInfiniteQuery,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import {
  GET_CATALOG_TEMPLATES_PATH,
  GetCatalogTemplatesRequest,
  GetCatalogTemplatesResponse
} from '@elfsight-universe/service-core-contracts/widget';
import { client } from '@api';
import { useUser } from '@modules/_app';

export function useCatalogTemplatesInfiniteQuery(
  dto: Omit<Partial<GetCatalogTemplatesRequest>, 'page'>,
  options?: UseInfiniteQueryOptions<GetCatalogTemplatesResponse>
) {
  const { isAuthenticated } = useUser();
  const queryKey = [GET_CATALOG_TEMPLATES_PATH, ...Object.values(dto)];

  return useInfiniteQuery<GetCatalogTemplatesResponse>(
    queryKey,
    ({ pageParam }) =>
      client
        .get(GET_CATALOG_TEMPLATES_PATH, {
          searchParams: pickBy(
            { ...dto, page: pageParam },
            (value) =>
              typeof value === 'number' || typeof value === 'boolean' || !!value
          )
        })
        .json<GetCatalogTemplatesResponse>(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      getNextPageParam: (data) =>
        data.meta.lastPage > data.meta.currentPage
          ? data.meta.currentPage + 1
          : undefined,
      enabled: isAuthenticated,
      ...options
    }
  );
}
