import { Conversation } from './conversation';
import { DeletedConversation } from './deleted-conversation';
import { Chat } from './chat';

export const HELP_SCOUT_WEBHOOKS_PATH = 'help-scout/webhooks';

export type HelpScoutWebhooksRequest =
  | Conversation
  | DeletedConversation
  | Chat;

export enum HelpScoutWebhooksEvent {
  CONVERSATION_ASSIGNED = 'convo.assigned',
  CONVERSATION_CREATED = 'convo.created',
  CONVERSATION_DELETED = 'convo.deleted',
  CONVERSATION_MERGED = 'convo.merged',
  CONVERSATION_MOVED = 'convo.moved',
  CONVERSATION_STATUS = 'convo.status',
  CONVERSATION_TAGS = 'convo.tags',
  CONVERSATION_CUSTOM_FIELDS = 'convo.custom-fields',
  CONVERSATION_CUSTOMER_REPLY_CREATED = 'convo.customer.reply.created',
  CONVERSATION_AGENT_REPLY_CREATED = 'convo.agent.reply.created',
  CONVERSATION_NOTE_CREATED = 'convo.note.created',
  BEACON_CHAT_CREATED = 'beacon.chat.created',
  BEACON_CHAT_CUSTOMER_REPLIED = 'beacon.chat.customer.replied'
}
