import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: number | null) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => callbackRef.current();

    if (delay === null) {
      return;
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
