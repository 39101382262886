export function OfferSlideInHeaderIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(-8 -4)"
        d="m27.623 6.477 2.662 2.638 3.614.986.986 3.614 2.638 2.662L36.569 20l.954 3.623-2.638 2.662-.986 3.614-3.614.986-2.662 2.638L24 32.569l-3.623.954-2.662-2.638-3.614-.986-.986-3.614-2.638-2.662.954-3.623-.954-3.623 2.638-2.662.986-3.614 3.614-.986 2.662-2.638L24 7.431l3.623-.954zm-.287 7.8c-.293 0-.454.103-.659.41l-3.215 4.71-3.26 4.731a.748.748 0 0 0-.146.455c0 .344.293.563.644.563.264 0 .425-.095.616-.388l3.325-4.87 3.157-4.6a.72.72 0 0 0 .146-.44c0-.329-.27-.57-.608-.57zm.315 5.288c-1.391 0-2.27 1.033-2.27 2.776 0 1.736.864 2.79 2.27 2.79 1.406 0 2.27-1.061 2.27-2.797 0-1.743-.878-2.769-2.27-2.769zm0 1.055c.557 0 .901.564.901 1.721 0 1.158-.344 1.736-.9 1.736-.558 0-.902-.578-.902-1.736 0-1.157.344-1.72.901-1.72zm-7.287-6.32c-1.392 0-2.271 1.032-2.271 2.768 0 1.736.864 2.798 2.27 2.798 1.407 0 2.278-1.07 2.278-2.798 0-1.743-.879-2.769-2.277-2.769zm0 1.054c.556 0 .9.557.9 1.714 0 1.164-.337 1.743-.9 1.743-.557 0-.901-.579-.901-1.736s.344-1.721.9-1.721z"
      />
    </svg>
  );
}
