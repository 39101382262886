import styled, { css } from 'styled-components';
import { getThemeOrCustomColor } from '../../utils';
import { ThemeColor } from '../../theme';

type ThemeColorOrString = ThemeColor | 'currentColor' | 'transparent' | string;

export const IconContainer = styled.div<{
  _fill?: ThemeColorOrString;
  _stroke?: ThemeColorOrString;
  _size: number;
}>`
  user-select: none;
  ${({ theme, _fill }) =>
    _fill &&
    css`
      color: ${getThemeOrCustomColor(_fill, theme)};
      fill: currentColor;
    `}
  ${({ theme, _stroke }) =>
    _stroke &&
    css`
      stroke: ${getThemeOrCustomColor(_stroke, theme)};
    `}

  & svg {
    display: block;
    ${({ _size }) => css`
      width: ${_size}px;
      height: ${_size}px;
      flex: 0 0 ${_size}px;
    `}
  }
`;
