import { IsString } from 'class-validator';
import { WidgetError } from '../errors';

export const COMMIT_USAGE_INSTALLED_APP_PATH =
  'widget/commit-usage-installed-app';

export class CommitUsageInstalledAppRequest {
  @IsString()
  appAlias: string;
}

export type CommitUsageInstalledAppResponseError =
  WidgetError.INSTALL_APP_DOES_NOT_EXIST;
