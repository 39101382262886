import { ReactElement } from 'react';
import { Link, Tooltip, TooltipProps } from '@elfsight-universe/ui-common';
import { createUpgradeURL } from '@modules/upgrade';

type BlockedTooltipProps = Omit<TooltipProps, 'content' | 'children'> & {
  children: ReactElement;
  appAlias: string;
};

export function BlockedViewsLimitTooltip({
  children,
  appAlias,
  closeDelay = 300,
  ...forwardingProps
}: BlockedTooltipProps) {
  return (
    <Tooltip
      closeDelay={closeDelay}
      content={
        <span>
          Widget has been blocked due view limit.
          <br />
          To unblock widget{' '}
          <Link
            underline
            color="promoOnBlack"
            href={createUpgradeURL({
              app: appAlias,
              claim: 'upgradePlan',
              redirectBack: true
            })}
          >
            upgrade your plan
          </Link>
        </span>
      }
      {...forwardingProps}
    >
      {children}
    </Tooltip>
  );
}
