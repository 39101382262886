import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  UPDATE_PROJECT_NAME_PATH,
  UpdateProjectNameRequest,
  UpdateProjectNameResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useUpdateProjectNameMutation(
  project: {
    pid: string;
    name?: string | null;
  },
  options: UseMutationOptions<
    Omit<UpdateProjectNameRequest, 'pid'> | undefined,
    UpdateProjectNameResponseError,
    Omit<UpdateProjectNameRequest, 'pid'>
  > = {}
) {
  return useMutation<
    Omit<UpdateProjectNameRequest, 'pid'> | undefined,
    UpdateProjectNameResponseError,
    Omit<UpdateProjectNameRequest, 'pid'>
  >(
    async (data) => {
      if (data.name === project.name) return undefined;

      await client.post(UPDATE_PROJECT_NAME_PATH, {
        json: { pid: project.pid, ...data }
      });

      return data;
    },
    {
      retry: false,
      ...options
    }
  );
}
