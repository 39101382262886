import { JsonObject, JsonValue } from '../../json.type';
import { Transform } from 'class-transformer';
import { IsString } from 'class-validator';
import { BillingError } from '../../errors';
import { PaddleRefundType, PaddleSubscriptionStatus } from '../enums';
import type { PaddlePassthrough } from '../interfaces/paddle-passthrough';
import { PaddleAlertName } from '../enums/paddle-alert-name';
import { toJSON } from '../../utils/to-json';

export class SubscriptionPaymentRefundedRequest implements JsonObject {
  [K: string]: JsonValue;

  @IsString()
  alert_id: string;

  @IsString()
  alert_name: PaddleAlertName;

  @IsString()
  amount: string;

  @IsString()
  balance_currency: string;

  @IsString()
  balance_earnings_decrease: string;

  @IsString()
  balance_fee_refund: string;

  @IsString()
  balance_gross_refund: string;

  @IsString()
  balance_tax_refund: string;

  @IsString()
  checkout_id: string;

  @IsString()
  currency: string;

  @IsString()
  earnings_decrease: string;

  @IsString()
  email: string;

  @IsString()
  event_time: string;

  @IsString()
  fee_refund: string;

  @IsString()
  gross_refund: string;

  @IsString()
  initial_payment: string;

  @IsString()
  instalments: string;

  @IsString()
  marketing_consent: string;

  @IsString()
  order_id: string;

  @Transform(({ value }) => toJSON(value))
  passthrough: PaddlePassthrough;

  @IsString()
  quantity: string;

  @IsString()
  refund_reason: string;

  @IsString()
  refund_type: PaddleRefundType;

  @IsString()
  status: PaddleSubscriptionStatus;

  @IsString()
  subscription_id: string;

  @IsString()
  subscription_payment_id: string;

  @IsString()
  subscription_plan_id: string;

  @IsString()
  tax_refund: string;

  @IsString()
  unit_price: string;

  @IsString()
  user_id: string;

  @IsString()
  p_signature: string;
}

export type SubscriptionPaymentRefundedResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.PAYMENT_DOES_NOT_EXIST
  | undefined;
