import { useMutation } from '@tanstack/react-query';
import {
  ACTIVATE_SUBSCRIPTION_PATH,
  ActivateSubscriptionRequest,
  ActivateSubscriptionResponseError
} from '@elfsight-universe/service-core-contracts/billing/activate-subscription';
import { client } from '../client';

export function useActivateSubscriptionMutation(onSuccess?: () => void) {
  return useMutation<
    Response,
    ActivateSubscriptionResponseError,
    ActivateSubscriptionRequest
  >(
    (data) =>
      client.post(ACTIVATE_SUBSCRIPTION_PATH, {
        json: data
      }),
    {
      onSuccess
    }
  );
}
