import { Length } from 'class-validator';
import { SecureLinkError } from '../errors';

export const CONFIRM_VERIFICATION_PATH =
  'identity-and-access/confirm-verification';

export class ConfirmVerificationRequest {
  @Length(128)
  token: string;
}

export type ConfirmVerificationResponseError =
  SecureLinkError.SECURE_LINK_DOES_NOT_EXIST;
