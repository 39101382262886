import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';

export type ParsedUrlQueryInputValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | null
  | undefined;

export function useRouterSetQuery(queryParam: string) {
  const { query, pathname, replace } = useRouter();

  return (nextQueryParamValue: ParsedUrlQueryInputValue | undefined) => {
    const newQuery: ParsedUrlQueryInput = { ...query };

    if ([null, undefined, ''].some((check) => check === nextQueryParamValue)) {
      delete newQuery[queryParam];
    } else {
      newQuery[queryParam] = nextQueryParamValue;
    }

    return replace({
      pathname,
      query: newQuery
    });
  };
}
