import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  ACCEPT_ACCOUNT_MEMBER_INVITATION_PATH,
  AcceptAccountMemberInvitationRequest,
  AcceptAccountMemberInvitationResponseError
} from '@elfsight-universe/service-core-contracts/iam';
import { client } from '../client';

export function useAcceptAccountMemberInvitationMutation(
  options: Pick<
    UseMutationOptions<
      Response,
      AcceptAccountMemberInvitationResponseError,
      AcceptAccountMemberInvitationRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    Response,
    AcceptAccountMemberInvitationResponseError,
    AcceptAccountMemberInvitationRequest
  >(
    (data) =>
      client.post(ACCEPT_ACCOUNT_MEMBER_INVITATION_PATH, {
        json: data
      }),
    {
      ...options,
      retry: false
    }
  );
}
