import { useMutation } from '@tanstack/react-query';
import {
  ReportChangelogVisitRequest,
  REPORT_CHANGELOG_VISIT_PATH
} from '@elfsight-universe/service-core-contracts/community-integration';
import { client } from '../client';

export function useReportChangelogVisitMutation(onSuccess?: () => void) {
  return useMutation<Response, unknown, ReportChangelogVisitRequest>(
    (data) => client.post(REPORT_CHANGELOG_VISIT_PATH, { json: data }),
    {
      onSuccess
    }
  );
}
