import styled from 'styled-components';
import { Lock16 } from '@elfsight/icons';
import { H4, Icon } from '@elfsight-universe/ui-common';

export function SecurityResponsibility({ ...forwardingProps }) {
  return (
    <Container {...forwardingProps}>
      <Caption>
        <IconWrapper>
          <Icon size={16} component={Lock16} fill="success" />
        </IconWrapper>
        <H4>Your security is our top priority at Elfsight. We ensure:</H4>
      </Caption>

      <List>
        <ListItem>
          <b>Encryption.</b> All data, including credentials, is securely
          encrypted.
        </ListItem>
        <ListItem>
          <b>Confidential</b> Handling. Access details are strictly used for
          installation and handled only by authorized personnel.
        </ListItem>
        <ListItem>
          <b>Data Deletion.</b> After installation, your credentials are
          promptly and permanently deleted.
        </ListItem>
        <ListItem>
          <b>Transparency.</b> Have questions about our security practices? Feel
          free to contact us.
        </ListItem>
      </List>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 20px;
  gap: 8px;
  border-radius: 4px;
  background: linear-gradient(
    268deg,
    rgba(14, 193, 47, 0.06) 0%,
    rgba(14, 150, 193, 0.05) 100%
  );
`;

const Caption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  padding: 6px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.successTransparent};
  background-color: ${({ theme }) => theme.colors.successTransparent};
`;

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 19px;
  ${({ theme }) => theme.font.caption};
`;

const ListItem = styled.li`
  ${({ theme }) => theme.font.caption};

  > b {
    font-weight: 600;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
