import styled from 'styled-components';
import { ArrowBoldRight16 } from '@elfsight/icons';
import { Link, Icon, MOBILE, useTheme } from '@elfsight-universe/ui-common';

export interface RegularEnterpriseeBannerSwitchProps {
  isEnterprise: boolean;
  onSwitchRequest: (toEnterprise: boolean) => void;
}

export function RegularEnterpriseBannerSwitch({
  isEnterprise,
  onSwitchRequest
}: RegularEnterpriseeBannerSwitchProps) {
  const theme = useTheme();
  function handleClick() {
    onSwitchRequest(!isEnterprise);
  }
  return (
    <Container onClick={handleClick}>
      <Caption>
        {isEnterprise
          ? 'Need less expensive plan?'
          : 'Plans with higher limits are available'}
      </Caption>
      <Action>
        <div>{isEnterprise ? 'See Regular plans' : 'See Enterprise plans'}</div>
        <StyledIcon
          component={ArrowBoldRight16}
          size={16}
          fill={theme.colors.accent}
        />
      </Action>
    </Container>
  );
}

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: 20px 24px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.accentTransparent};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.accentTransparent};

  &:hover {
    text-decoration: none;
  }

  ${MOBILE} {
    margin-top: 32px;
  }
`;

const Caption = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.accent};

  ${Container}:hover & {
    text-decoration: underline;
    text-underline-offset: 2.5px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 10px;
`;
