import { IsOptional, IsString, IsUUID } from 'class-validator';
import { BillingError } from '../errors';

export const SCHEDULE_SUBSCRIPTION_CANCELLATION_PATH =
  'billing/schedule-subscription-cancellation';

export class ScheduleSubscriptionCancellationRequest {
  @IsString()
  reasonPid: string;

  @IsOptional()
  @IsString()
  details: string;

  @IsUUID()
  pid: string;
}

export type ScheduleSubscriptionCancellationResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANCELLATION_CANNOT_BE_SCHEDULED
  | BillingError.SUBSCRIPTION_DOES_NOT_HAVE_BILLING_DATE
  | BillingError.CANCELLATION_REASON_DOES_NOT_EXISTS;
