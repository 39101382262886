import Router from 'next/router';
import styled from 'styled-components';
import { WarningTriangleFilled } from '@elfsight/icons';
import {
  Button,
  formatSeparatorNumber,
  H1,
  H3,
  Icon,
  Loader,
  Modal,
  ModalProps,
  P,
  ThemeColor
} from '@elfsight-universe/ui-common';
import {
  useCollaboratorsUsageQuery,
  useCollaboratorsFeatureSummaryQuery
} from '@api';
import { beacon } from '@modules/beacon';
import { createUpgradeURL } from '@modules/upgrade';
import { useAppCurrentAppAliasContext } from '@modules/_app';

type CollaboratorsLimitReachedModalProps = Omit<
  ModalProps,
  'title' | 'actions'
>;

// @TODO Combine with other limit modals
export function CollaboratorsLimitReachedModal({
  isOpen,
  onRequestClose,
  ...forwardingProps
}: CollaboratorsLimitReachedModalProps) {
  const { currentAppAlias } = useAppCurrentAppAliasContext();
  const { data: collaboratorUsageData } = useCollaboratorsUsageQuery();
  const { data: collaboratorFeatureSummaryData } =
    useCollaboratorsFeatureSummaryQuery();

  if (!collaboratorUsageData || !collaboratorFeatureSummaryData) return null;

  const { collaboratorsUsage, collaboratorsLimit, collaboratorsCount } =
    collaboratorUsageData;

  const membersWordSentence = collaboratorsLimit === 1 ? 'member' : 'members';
  const description =
    collaboratorsLimit >=
    collaboratorFeatureSummaryData.maxCollaboratorsLimit ? (
      <>
        Your plan allows up to {collaboratorsLimit} {membersWordSentence}. To
        extend your limit, please contact our sales team.
      </>
    ) : (
      <>
        You&apos;ve reached your plan&apos;s limit of {collaboratorsLimit}{' '}
        {membersWordSentence}. Upgrade to a higher plan to add more members.
      </>
    );

  const buttonText =
    collaboratorsLimit >= collaboratorFeatureSummaryData.maxCollaboratorsLimit
      ? 'Contact Sales'
      : 'View Plans';

  const onButtonClick = () => {
    if (
      collaboratorsLimit >= collaboratorFeatureSummaryData.maxCollaboratorsLimit
    ) {
      beacon.openChat();
      return;
    }

    Router.push(
      createUpgradeURL({
        app: currentAppAlias,
        claim: 'upgradePlan',
        redirectBack: true
      })
    );
  };

  const isLoading = !collaboratorUsageData || !collaboratorFeatureSummaryData;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      padding={[40, 40]}
      maxWidth={460}
      withClose
      {...forwardingProps}
    >
      {isLoading ? (
        <>
          <H1 alignCenter gb>
            Member Limit Reached
          </H1>
          <Loader />
        </>
      ) : (
        <>
          <LimitBubble
            _backgroundColor={getLimitBubbleBackgroundColor(collaboratorsUsage)}
          >
            {collaboratorsUsage >= 1 && (
              <StyledWarningIcon
                component={WarningTriangleFilled}
                size={16}
                fill={collaboratorsUsage > 1 ? 'alert' : 'warning'}
              />
            )}
            <H3 alignCenter>
              {formatSeparatorNumber(collaboratorsCount)}{' '}
              <LimitSeparator>/</LimitSeparator>{' '}
              {formatSeparatorNumber(collaboratorsLimit)}
            </H3>
          </LimitBubble>

          <H1 alignCenter mb={8}>
            Member Limit Reached
          </H1>
          <P big alignCenter mb={20}>
            {description}
          </P>

          <CenteredButton
            large
            center
            variation="successPrimary"
            onClick={onButtonClick}
          >
            {buttonText}
          </CenteredButton>
        </>
      )}
    </Modal>
  );
}

const getLimitBubbleBackgroundColor = (usage: number) => {
  if (usage > 1) {
    return 'alertTransparent';
  }

  if (usage === 1) {
    return 'warningTransparent';
  }

  return 'accentTransparent';
};

const CenteredButton = styled(Button)`
  margin: 0 auto;
`;

const StyledWarningIcon = styled(Icon)`
  margin-right: 8px;
`;

const LimitBubble = styled.div<{ _backgroundColor: ThemeColor }>`
  display: flex;
  justify-content: center;
  width: fit-content;
  flex-shrink: 0;
  margin: 0 auto 20px;
  align-items: center;
  padding: 8px 16px 8px 12px;
  border-radius: 40px;
  background: ${({ _backgroundColor, theme }) => theme.colors[_backgroundColor]};
`;

const LimitSeparator = styled.span`
  color: ${({ theme }) => theme.colors.gray30};
`;
