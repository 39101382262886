import { IsUUID } from 'class-validator';
import { BillingError } from '../errors';

export const CREATE_LITE_SUBSCRIPTION_PATH = 'billing/create-lite-subscription';

export class CreateLiteSubscriptionRequest {
  @IsUUID()
  planPid: string;
}

export type CreateLiteSubscriptionResponseError =
  BillingError.PLAN_DOES_NOT_EXIST;
