import { PropsWithChildren } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRequestInstallationForm } from './index';

type RequestInstallationFormProviderProps = PropsWithChildren<{
  widgetPid: string;
  appAlias: string;
}>;

export function RequestInstallationFormProvider({
  children,
  widgetPid,
  appAlias
}: RequestInstallationFormProviderProps) {
  const form = useRequestInstallationForm(widgetPid, appAlias);

  return <FormProvider {...form}>{children}</FormProvider>;
}
