import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';
import { BillingError, IdentityAndAccessError, PaddleError } from '../errors';

export const CREATE_SUBSCRIPTION_PATH = 'billing/create-subscription';

export class CreateSubscriptionRequest {
  @IsNotEmpty()
  planPid: string;
}

@Exclude()
export class CreateSubscriptionResponse {
  @Expose()
  payLink: string;

  @Expose()
  pid: string;
}

export type CreateSubscriptionResponseError =
  | BillingError.PLAN_DOES_NOT_EXIST
  | PaddleError.PAY_LINK_CREATION_FAILED
  | IdentityAndAccessError.USER_DOES_NOT_EXISTS;
