import styled from 'styled-components';
import { MOBILE } from '@elfsight-universe/ui-common';
import { useUpgradeContext } from '../upgrade-context';

export function CTAAppTitle() {
  const { data } = useUpgradeContext();

  const haveAllAppsPackSubscription =
    data?.haveAllAppsPackSubscription && data.displayAllAppsPackPlans;

  if (!data?.app) return null;

  if (haveAllAppsPackSubscription) {
    return (
      <AppTitle>
        &nbsp;<b>All Apps Pack</b>
      </AppTitle>
    );
  }

  return (
    <AppTitle>
      &nbsp;<b>{data.app.name}</b>
    </AppTitle>
  );
}

const AppTitle = styled.span`
  > b {
    font-weight: 700;

    ${MOBILE} {
      font-weight: 600;
    }
  }
`;
