import { RemoveScroll } from 'react-remove-scroll';
import { useState } from 'react';
import styled from 'styled-components';
import { Divider, User } from '@elfsight-universe/ui-common';
import { createCommunityAuthLink } from '@utils';
import {
  useDealsCountQuery,
  useSignOutMutation,
  useProjectsFeatureSummaryQuery,
  useReportProjectsVisitMutation,
  useCollaboratorsFeatureSummaryQuery,
  useUserAccountsQuery
} from '@api';
import { useAnnouncementsContext } from '@modules/announcements/announcements-provider';
import { AnnouncementsMobileModal } from '@modules/announcements/announcements-mobile-modal';
import { beacon } from '../beacon';
import { useIsMobile, useUser, useAppCurrentAppAliasContext } from '../_app';
import { HeaderMobileNavItem } from './header-mobile-nav-item';
import { useHeader } from './header-provider';
import { HeaderOnboardingContainer } from './header-onboarding-container';
import { SwitchAccountModal } from './switch-account';

export type HeaderMobileNavProps = { offsetTop: number };

export function HeaderMobileNav({ offsetTop = 60 }: HeaderMobileNavProps) {
  const { user } = useUser();
  const { data: accountsResponse } = useUserAccountsQuery();
  const isMobile = useIsMobile();
  const { isMobileNavOpen, toggleMobileNav } = useHeader();
  const [switchAccountModalOpened, toggleSwitchAccountModalOpened] =
    useState(false);
  const { myAppsNavLink } = useAppCurrentAppAliasContext();
  const { mutate: signOut } = useSignOutMutation();
  const { data: dealsCount } = useDealsCountQuery();
  const { data: collaboratorsFeatureSummaryData } =
    useCollaboratorsFeatureSummaryQuery();
  const { data: projectsAvailability, refetch: refetchProjectsAvailability } =
    useProjectsFeatureSummaryQuery();
  const { mutate: reportProjectsVisit } = useReportProjectsVisitMutation(() => {
    refetchProjectsAvailability();
  });
  const { numberOfUnreadAnnouncements, isOpen, toggleOpen } =
    useAnnouncementsContext();

  const openBeacon = () => {
    beacon.openChat();
    toggleMobileNav(false);
  };

  const onProjectsMenuItemClick = () => {
    if (!projectsAvailability?.visited) {
      reportProjectsVisit();
    }
    toggleMobileNav(false);
  };

  if (
    !isMobile ||
    !isMobileNavOpen ||
    !projectsAvailability ||
    !collaboratorsFeatureSummaryData
  ) {
    return null;
  }

  const showSwitchAccountMenuButton =
    accountsResponse && accountsResponse.accounts.length > 1;
  const showNotificationsMenuButton =
    user && user.owningAccountPid === user.effectiveAccount.pid;

  return (
    <RemoveScroll enabled={isMobile && isMobileNavOpen}>
      <Container _top={offsetTop}>
        <Content>
          {user && <User {...user} large />}

          <HeaderOnboardingContainer isMobile />

          <Divider _marginY={8} />

          <div role="navigation">
            <HeaderMobileNavItem href={myAppsNavLink}>
              My Apps
            </HeaderMobileNavItem>

            {projectsAvailability.available && (
              <HeaderMobileNavItem
                href="/projects"
                alert={!projectsAvailability.visited}
                onClick={onProjectsMenuItemClick}
              >
                Projects
              </HeaderMobileNavItem>
            )}

            <HeaderMobileNavItem href="/catalog">Catalog</HeaderMobileNavItem>

            <HeaderMobileNavItem href="/templates">
              Templates
            </HeaderMobileNavItem>

            <HeaderMobileNavItem href="/deals" badge={dealsCount?.dealsCount}>
              Deals
            </HeaderMobileNavItem>

            <Divider _marginY={8} />

            <HeaderMobileNavItem href="/account/profile">
              Profile
            </HeaderMobileNavItem>

            {showNotificationsMenuButton && (
              <HeaderMobileNavItem href="/account/notifications">
                Notifications
              </HeaderMobileNavItem>
            )}

            <HeaderMobileNavItem href="/account/security">
              Account & Security
            </HeaderMobileNavItem>

            <HeaderMobileNavItem href="/billing">Billing</HeaderMobileNavItem>

            {collaboratorsFeatureSummaryData.available && (
              <HeaderMobileNavItem href="/members">Members</HeaderMobileNavItem>
            )}

            <Divider _marginY={12} />

            {showSwitchAccountMenuButton && (
              <>
                <HeaderMobileNavItem
                  onClick={() => toggleSwitchAccountModalOpened(true)}
                >
                  Switch Workspace
                </HeaderMobileNavItem>

                <Divider _marginY={12} />
              </>
            )}

            <HeaderMobileNavItem
              onClick={() => toggleOpen(true)}
              badge={numberOfUnreadAnnouncements}
            >
              What’s New
            </HeaderMobileNavItem>

            <HeaderMobileNavItem
              href={createCommunityAuthLink('https://community.elfsight.com/')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ask the Community
            </HeaderMobileNavItem>

            <HeaderMobileNavItem
              href="https://help.elfsight.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Explore Help Center
            </HeaderMobileNavItem>

            <HeaderMobileNavItem onClick={openBeacon}>
              Contact Support
            </HeaderMobileNavItem>

            <HeaderMobileNavItem
              href={createCommunityAuthLink(
                'https://community.elfsight.com/c/wishlist/8/l/votes'
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              Request a Feature
            </HeaderMobileNavItem>

            <HeaderMobileNavItem
              href={createCommunityAuthLink(
                'https://community.elfsight.com/c/elfsight-changelog/48'
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              Changelog
            </HeaderMobileNavItem>

            <Divider _marginY={8} />

            {!user?.isAffiliate && (
              <HeaderMobileNavItem href="/refer-a-friend">
                Refer a Friend
                <ReferBadge>GET $15</ReferBadge>
              </HeaderMobileNavItem>
            )}

            <HeaderMobileNavItem onClick={() => signOut()}>
              Log out
            </HeaderMobileNavItem>
          </div>
        </Content>

        <SwitchAccountModal
          isOpen={switchAccountModalOpened}
          onRequestClose={() => toggleSwitchAccountModalOpened(false)}
        />

        <AnnouncementsMobileModal
          isOpen={isOpen}
          onRequestClose={() => toggleOpen(false)}
        />
      </Container>
    </RemoveScroll>
  );
}

const ReferBadge = styled.span`
  ${({ theme }) => theme.font.captionSmallUppercase};
  color: ${({ theme }) => theme.colors.brand};
  position: relative;
  bottom: 1px;
  margin-left: 8px;
`;

const Container = styled.div<{ _top: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  position: fixed;
  top: ${({ _top }) => _top}px;
  left: 0;
  right: 0;
  bottom: 0;
  //height: calc(100dvh - 60px);
  overflow-y: auto;
  padding: 40px;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.headerNavigationMobile};
`;

const Content = styled.div`
  max-width: 400px;
  width: 100%;
`;
