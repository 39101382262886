import { toast } from 'react-hot-toast';
import { captureMessage } from '@sentry/nextjs';
import { IdentityAndAccessError } from '@elfsight-universe/service-core-contracts/errors';
import { ContactSupport } from './contact-support';

const TOAST_ID = 'UNHANDLED_ERROR';
const AUTH_ERRORS = [
  IdentityAndAccessError.NOT_AUTHENTICATED,
  IdentityAndAccessError.NOT_AUTHORIZED
];

export function catchUnhandledError(
  error: unknown | string | Error,
  expectedErrors: string[] = []
) {
  let errorMessage = undefined;

  if (error instanceof Error) {
    errorMessage = error.message;
  }
  if (typeof error === 'string') {
    errorMessage = error;
  }

  const finalExpectedErrors = [...expectedErrors, ...AUTH_ERRORS];
  if (errorMessage && finalExpectedErrors.includes(errorMessage)) {
    return;
  }

  if (errorMessage) {
    captureMessage(errorMessage, 'error');
  }

  toast.dismiss(TOAST_ID);
  toast.error(
    <>
      A server issue has occurred. Please try again, and if the problem
      persists,{' '}
      <ContactSupport underline>contact our Support Team.</ContactSupport>
    </>,
    { id: TOAST_ID, icon: null }
  );
}
