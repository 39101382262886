import debounce from 'lodash/debounce';
import {
  SAVE_SEARCH_QUERY_PATH,
  SaveSearchQueryRequest
} from '@elfsight-universe/service-core-contracts/analytics/save-search-query';
import { client } from '../client';

export const saveSearchQuery = (data: SaveSearchQueryRequest) => {
  return client
    .post(SAVE_SEARCH_QUERY_PATH, {
      json: data
    })
    .catch();
};

export const debouncedSaveSearchQuery = debounce(saveSearchQuery, 1000);
