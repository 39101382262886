import { HTMLAttributes, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { getColorWithAlpha } from '@elfsight-universe/ui-common';
import { useAvatarLoad } from './use-avatar-load';

type PlaceholderArgs = {
  lastName: string | null | undefined;
  firstName: string | null | undefined;
  email: string;
};

export type UserAvatarProps = HTMLAttributes<HTMLDivElement> & {
  src: string | null | undefined;
  placeholderArgs: PlaceholderArgs;
  size?: number;
  offset?: number;
};

export function UserAvatar({
  src,
  placeholderArgs,
  size = 28,
  ...forwardingProps
}: UserAvatarProps) {
  const state = useAvatarLoad(src || undefined);

  return (
    <Container _size={size} {...forwardingProps}>
      {src && state !== 'failed' ? (
        <Image src={src || undefined} />
      ) : (
        <Placeholder _fontSize={Math.floor(size / 1.8)}>
          {getAvatarPlaceholder(placeholderArgs)}
        </Placeholder>
      )}
    </Container>
  );
}

const getAvatarPlaceholder = ({
  email,
  firstName,
  lastName
}: PlaceholderArgs) =>
  firstName?.charAt(0) || lastName?.charAt(0) || email.charAt(0);

const Container = styled.div<{
  _size: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'inherit')};

  ${({ _size }) => css`
    width: ${_size}px;
    height: ${_size}px;
  `}

  ${({ onClick }) =>
    !!onClick &&
    css`
      &:hover::before {
        background: ${() => getColorWithAlpha('#fff', 0.1)};
      }
      &:active::before {
        background: ${({ theme }) => theme.colors.gray10};
      }
    `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.gray10};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background 0.2s;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Placeholder = styled.div<{ _fontSize: number }>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.avatar};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ _fontSize }) => _fontSize}px;
  line-height: 1;
`;
