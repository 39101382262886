import { IsString } from 'class-validator';
import { WidgetError } from '../errors';

export const UNINSTALL_APP_PATH = 'widget/uninstall-app';

export class UninstallAppRequest {
  @IsString()
  appAlias: string;
}

export type UninstallAppResponseError =
  | WidgetError.INSTALL_APP_DOES_NOT_EXIST
  | WidgetError.APP_CANNOT_BE_UNINSTALLED;
