import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class ViewsCountingPeriod {
  @Expose()
  beginsAt: Date;

  @Expose()
  endsAt: Date;
}
