import { useQuery } from '@tanstack/react-query';
import {
  GET_BEACON_IDENTITY_PATH,
  GetBeaconIdentityResponse
} from '@elfsight-universe/service-core-contracts/help-scout';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useBeaconIdentity() {
  const { isAuthenticated } = useUser();
  return useQuery<GetBeaconIdentityResponse>(
    [GET_BEACON_IDENTITY_PATH],
    () =>
      client.get(GET_BEACON_IDENTITY_PATH).json<GetBeaconIdentityResponse>(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isAuthenticated
    }
  );
}
