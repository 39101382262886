export enum WidgetError {
  WIDGET_DOES_NOT_EXIST = 'WIDGET_DOES_NOT_EXIST',
  WIDGETS_LIMIT_REACHED = 'WIDGETS_LIMIT_REACHED',
  APP_DOES_NOT_EXIST = 'APP_DOES_NOT_EXIST',
  APP_RELEASE_DOES_NOT_EXIST = 'APP_RELEASE_DOES_NOT_EXIST',
  APP_RELEASE_TAG_IS_INVALID = 'APP_RELEASE_TAG_IS_INVALID',
  APP_RELEASE_ARCHIVE_IS_INVALID = 'APP_RELEASE_ARCHIVE_IS_INVALID',
  APP_RELEASE_ARTIFACTS_UPLOADING_FAILED = 'APP_RELEASE_ARTIFACTS_UPLOADING_FAILED',
  WIDGET_IS_ALREADY_DELETED = 'WIDGET_IS_ALREADY_DELETED',
  WIDGET_UNPUBLISHED_REVISION_DOES_NOT_EXIST = 'WIDGET_UNPUBLISHED_REVISION_DOES_NOT_EXIST',
  WIDGET_IS_NOT_DELETED = 'WIDGET_IS_NOT_DELETED',
  APP_RELEASE_ALREADY_APPLIED_TO_WIDGET = 'APP_RELEASE_ALREADY_APPLIED_TO_WIDGET',
  BAD_OPTIONS = 'BAD_OPTIONS',
  MIGRATION_DOES_NOT_EXIST = 'MIGRATION_DOES_NOT_EXIST',
  MIGRATION_IS_INVALID = 'MIGRATION_IS_INVALID',
  INSTALL_APP_DOES_NOT_EXIST = 'INSTALL_APP_DOES_NOT_EXIST',
  APP_CANNOT_BE_UNINSTALLED = 'APP_CANNOT_BE_UNINSTALLED',
  PROJECT_DOES_NOT_EXIST = 'PROJECT_DOES_NOT_EXIST',
  UPDATE_UNPUBLISHED_WIDGET_REVISION_FAILED = 'UPDATE_UNPUBLISHED_WIDGET_REVISION_FAILED'
}
