import clamp from 'lodash/clamp';

export function getMaskedEmail(email: string) {
  const [local, domain, zone] = email.split(/@|\.(?=[^\.]+$)/);
  return `${mangle(local)}@${mangle(domain)}.${zone}`;
}

function mangle(input: string) {
  if (input.length < 2) {
    return '*'.repeat(input.length);
  }

  if (input.length === 2) {
    return `${input.substr(0, 1)}*`;
  }

  const mask = '*'.repeat(clamp(input.length - 2, 1, 3));

  return [input.substr(0, 1), mask, input.substr(-1, 1)].join('');
}
