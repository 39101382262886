import { useQuery } from '@tanstack/react-query';
import {
  CAN_DELETE_ACCOUNT_PATH,
  CanDeleteAccountResponse
} from '@elfsight-universe/service-core-contracts/identity-and-access/can-delete-account';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useCanDeleteAccountQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<CanDeleteAccountResponse>(
    [CAN_DELETE_ACCOUNT_PATH],
    () => client.get(CAN_DELETE_ACCOUNT_PATH).json(),
    {
      enabled: isAuthenticated
    }
  );
}
