export function getVideoID(url: string) {
  const { host, pathname, search } = new URL(url);

  const normalizedHost = host.replace(/^www\./, '');
  const searchParams = new URLSearchParams(search);

  switch (normalizedHost) {
    case 'youtube.com':
    case 'youtube-nocookie.com':
      if (pathname.startsWith('/embed')) {
        return pathname.split('/embed/')[1];
      }

      if (searchParams.has('v')) {
        return searchParams.get('v');
      }

      return null;
    case 'youtu.be':
      return pathname.substr(1);
    default:
      return null;
  }
}
