export function useEmbedSDKMessages() {
  const sendAddWidgetMessage = (widgetId: string) => {
    sendPostMessageToOpener('addWidget', { widgetId });
  };

  const sendEditWidgetMessage = () => {
    sendPostMessageToOpener('editWidget');
  };

  return { sendAddWidgetMessage, sendEditWidgetMessage };
}

const sendPostMessageToOpener = (
  event: string,
  data?: Record<string, unknown>
) => {
  if (window.opener) {
    window.opener.postMessage({ event, data }, '*');
  }
};
