import styled from 'styled-components';

export const AppCardGrid = styled.div<{ _itemWidth?: number }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ _itemWidth = 360 }) => _itemWidth}px, 1fr)
  );
  grid-gap: 8px;
  margin: -16px;
`;
