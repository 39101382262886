import { useQuery } from '@tanstack/react-query';
import {
  GET_CONFIGURATOR_TEMPLATES_PATH,
  GetConfiguratorTemplatesResponse
} from '@elfsight-universe/service-core-contracts/widget';
import { client } from '../client';

export function useConfiguratorTemplatesQuery(
  appAlias: string,
  enabled = true
) {
  return useQuery<GetConfiguratorTemplatesResponse | undefined>(
    [GET_CONFIGURATOR_TEMPLATES_PATH, appAlias],
    () =>
      client
        .get(GET_CONFIGURATOR_TEMPLATES_PATH, {
          searchParams: { appAlias }
        })
        .json(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled
    }
  );
}
