import {
  PaginationItemParams,
  PaginationItemType,
  PaginationParams
} from './pagination-types';

export function usePagination({
  boundaryCount = 1,
  siblingCount = 1,
  disabled = false,
  count,
  onPageChange,
  page,
  hideNextButton = false,
  hidePrevButton = false,
  showFirstButton = false,
  showLastButton = false
}: PaginationParams): PaginationItemParams[] {
  const startPages = range(1, Math.min(boundaryCount, count));
  const endPages = range(
    Math.max(count - boundaryCount + 1, boundaryCount + 1),
    count
  );

  const siblingsStart = Math.max(
    Math.min(page - siblingCount, count - boundaryCount - 1),
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(page + siblingCount, boundaryCount + 2),
    endPages.length > 0 ? endPages[0] - 2 : count - 1
  );

  const itemList = [
    ...(showFirstButton ? [PaginationItemType.FIRST] : []),
    ...(hidePrevButton ? [] : [PaginationItemType.PREVIOUS]),
    ...startPages,

    ...(siblingsStart > boundaryCount + 2
      ? [PaginationItemType.START_ELLIPSIS]
      : boundaryCount + 1 < count - boundaryCount
        ? [boundaryCount + 1]
        : []),

    ...range(siblingsStart, siblingsEnd),

    ...(siblingsEnd < count - boundaryCount - 1
      ? [PaginationItemType.END_ELLIPSIS]
      : count - boundaryCount > boundaryCount
        ? [count - boundaryCount]
        : []),

    ...endPages,
    ...(hideNextButton ? [] : [PaginationItemType.NEXT]),
    ...(showLastButton ? [PaginationItemType.LAST] : [])
  ];

  const buttonPage = (type: PaginationItemType): number | undefined =>
    ({
      [PaginationItemType.FIRST]: 1,
      [PaginationItemType.PREVIOUS]: page - 1,
      [PaginationItemType.NEXT]: page + 1,
      [PaginationItemType.LAST]: count,
      [PaginationItemType.START_ELLIPSIS]: undefined,
      [PaginationItemType.END_ELLIPSIS]: undefined,
      [PaginationItemType.PAGE]: undefined
    })[type];

  return itemList.map((item) => {
    if (typeof item === 'number') {
      return {
        onClick: () => {
          onPageChange(item);
        },
        type: PaginationItemType.PAGE,
        page: item,
        selected: item === page,
        disabled
      };
    }

    const isLastOrNext =
      item === PaginationItemType.NEXT || item === PaginationItemType.LAST;
    const isEllipsis =
      item === PaginationItemType.START_ELLIPSIS ||
      item === PaginationItemType.END_ELLIPSIS;

    return {
      onClick: () => {
        const nextPage = buttonPage(item);
        if (nextPage) {
          onPageChange(nextPage);
        }
      },
      type: item,
      page: buttonPage(item),
      selected: false,
      disabled:
        disabled || isEllipsis || (isLastOrNext ? page >= count : page <= 1)
    };
  });
}

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};
