import { IsUUID } from 'class-validator';
import { BillingError } from '../errors';

export const CONFIRM_LITE_SUBSCRIPTION_PATH =
  'billing/confirm-lite-subscription';

export class ConfirmLiteSubscriptionRequest {
  @IsUUID()
  planPid: string;
}

export type ConfirmLiteSubscriptionResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANNOT_BE_CONFIRMED;
