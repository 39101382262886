import styled from 'styled-components';
import {
  useDealsCountQuery,
  useProjectsFeatureSummaryQuery,
  useReportProjectsVisitMutation,
  useCollaboratorsFeatureSummaryQuery
} from '@api';
import { useIsMobile } from '@modules/_app';
import { useAppCurrentAppAliasContext } from '@modules/_app/app-current-app-alias-provider';
import { CollaboratorsInvitationModalContainer } from '@modules/collaborators/components';
import { AnnouncementsPopover } from '@modules/announcements/announcements-popover';
import { HeaderLayout, HeaderLayoutProps } from './header-layout';
import { HeaderLogo } from './header-logo';
import { HeaderNav } from './header-nav';
import { HeaderUser } from './header-user';
import { HeaderMobileTrigger } from './header-mobile-trigger';
import { HeaderNavItem } from './header-nav-item';
import { HeaderOnboardingContainer } from './header-onboarding-container';
import { HeaderUserHelp } from './header-user-help';

type HeaderProps = Omit<HeaderLayoutProps, 'leftSlot' | 'rightSlot'>;

export function Header({ ...forwardingProps }: HeaderProps) {
  const isMobile = useIsMobile();
  const {
    data: projectsAvailabilityData,
    refetch: refetchProjectsAvailability
  } = useProjectsFeatureSummaryQuery();
  const { mutate: reportProjectsVisit } = useReportProjectsVisitMutation(() => {
    refetchProjectsAvailability();
  });
  const { data: collaboratorsFeatureSummaryData } =
    useCollaboratorsFeatureSummaryQuery();
  const { data: dealsCountData } = useDealsCountQuery();

  const { myAppsNavLink } = useAppCurrentAppAliasContext();

  const onProjectsMenuItemClick = () => {
    if (!projectsAvailabilityData?.visited) {
      reportProjectsVisit();
    }
  };

  return (
    <HeaderLayout
      leftSlot={
        <>
          <HeaderLogo href="/" />

          {!isMobile && projectsAvailabilityData && (
            <HeaderNav>
              <HeaderNavItem href={myAppsNavLink}>My Apps</HeaderNavItem>
              {projectsAvailabilityData.available && (
                <HeaderNavItem
                  href="/projects"
                  alert={!projectsAvailabilityData.visited}
                  onClick={onProjectsMenuItemClick}
                >
                  Projects
                </HeaderNavItem>
              )}
              <HeaderNavItem href="/catalog">Catalog</HeaderNavItem>

              <HeaderNavItem href="/templates">Templates</HeaderNavItem>

              <HeaderNavItem href="/deals" badge={dealsCountData?.dealsCount}>
                Deals
              </HeaderNavItem>
            </HeaderNav>
          )}
        </>
      }
      rightSlot={
        collaboratorsFeatureSummaryData && (
          <>
            {!isMobile && <HeaderOnboardingContainer />}

            <HeaderUserWrapper>
              <HeaderUserAncillaryButton>
                {!isMobile && collaboratorsFeatureSummaryData.available && (
                  <CollaboratorsInvitationModalContainer placement="header" />
                )}
                {!isMobile && <AnnouncementsPopover />}
                {!isMobile && <HeaderUserHelp />}
              </HeaderUserAncillaryButton>
              {!isMobile && <HeaderUser />}
            </HeaderUserWrapper>

            {isMobile && <HeaderMobileTrigger />}
          </>
        )
      }
      {...forwardingProps}
    />
  );
}

const HeaderUserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -4px;
  gap: 14px;
`;

const HeaderUserAncillaryButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
