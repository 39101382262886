import { useMutation } from '@tanstack/react-query';
import {
  DISCARD_UNPUBLISHED_WIDGET_REVISION_PATH,
  DiscardUnpublishedWidgetRevisionResponseError
} from '@elfsight-universe/service-core-contracts/widget/discard-unpublished-widget-revision';
import { client } from '../client';

type Result = { unpublishedWidgetRevision: undefined };

export function useDiscardUnpublishedRevisionMutation(
  widgetPid: string,
  onSuccess: (newData: Result) => void
) {
  return useMutation<Result, DiscardUnpublishedWidgetRevisionResponseError>(
    async () => {
      await client.post(DISCARD_UNPUBLISHED_WIDGET_REVISION_PATH, {
        json: { widgetPid }
      });
      return { unpublishedWidgetRevision: undefined };
    },
    {
      onSuccess
    }
  );
}
