import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import {
  REVOKE_SUBSCRIPTION_CANCELLATION_PATH,
  RevokeSubscriptionCancellationRequest,
  RevokeSubscriptionCancellationResponseError
} from '@elfsight-universe/service-core-contracts/billing/revoke-subscription-cancellation';
import { client } from '../client';

type UseRevokeSubscriptionCancellationMutationData =
  RevokeSubscriptionCancellationRequest & {
    planName: string;
  };

export function useRevokeSubscriptionCancellationMutation(
  { pid, planName }: UseRevokeSubscriptionCancellationMutationData,
  options: Pick<
    UseMutationOptions<Response, RevokeSubscriptionCancellationResponseError>,
    'onError' | 'onSuccess'
  > = {}
) {
  return useMutation<Response, RevokeSubscriptionCancellationResponseError>(
    () =>
      client.post(REVOKE_SUBSCRIPTION_CANCELLATION_PATH, {
        json: { pid }
      }),
    {
      ...options,
      onSuccess(...args) {
        toast.success(
          `Auto-renewal has been successfully activated for ${planName} subscription.`
        );
        options.onSuccess && options.onSuccess(...args);
      }
    }
  );
}
