import { useQuery } from '@tanstack/react-query';
import { useUser } from '@modules/_app';
import {
  GET_ALL_PROJECTS_PATH,
  GetAllProjectsResponse
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useAllProjectsQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetAllProjectsResponse>(
    [GET_ALL_PROJECTS_PATH],
    () => client.get(GET_ALL_PROJECTS_PATH).json<GetAllProjectsResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
