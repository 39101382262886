import { useQuery } from '@tanstack/react-query';
import {
  GetHighestPlanResponse,
  GET_HIGHEST_PLAN_PATH
} from '@elfsight-universe/service-core-contracts/billing';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useHighestPlanQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetHighestPlanResponse>(
    [GET_HIGHEST_PLAN_PATH],
    () => client.get(GET_HIGHEST_PLAN_PATH).json<GetHighestPlanResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
