import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumber } from 'class-validator';
import { Announcement, AnnouncementTag } from './classes';

export const GET_ANNOUNCEMENT_DETAIL_PATH =
  'announcement/get-announcement-detail';

export class GetAnnouncementDetailRequest {
  @Type(() => Number)
  @IsNumber()
  id: number;
}

@Exclude()
export class GetAnnouncementDetailResponse extends Announcement {
  @Expose()
  id: number;

  @Expose()
  updatedAt: Date;

  @Expose()
  published: boolean;

  @Expose()
  ctaButtonLinkSSOEnabled: boolean;

  @Expose()
  @Type(() => GetAnnouncementDetailResponseTag)
  tags: GetAnnouncementDetailResponseTag[];
}

@Exclude()
export class GetAnnouncementDetailResponseAnnouncementTag extends AnnouncementTag {
  @Expose()
  id: number;
}

@Exclude()
export class GetAnnouncementDetailResponseTag {
  @Expose()
  @Type(() => GetAnnouncementDetailResponseAnnouncementTag)
  announcementTag: GetAnnouncementDetailResponseAnnouncementTag;
}

export type GetAnnouncementDetailResponseError = 'ANNOUNCEMENT_DOES_NOT_EXISTS';
