import { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { ThemeColor } from '../../theme';

export type ToggleSwitchProps = InputHTMLAttributes<HTMLInputElement> & {
  uncheckedColor?: ThemeColor;
  checkedColor?: ThemeColor;
};

export function ToggleSwitch({
  uncheckedColor,
  checkedColor,
  ...forwardingProps
}: ToggleSwitchProps) {
  return (
    <Label>
      <Input
        {...forwardingProps}
        _uncheckedColor={uncheckedColor}
        _checkedColor={checkedColor}
        type="checkbox"
      />
      <Handle />
    </Label>
  );
}

const Label = styled.label`
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  margin: 0 12px;
  overflow: hidden;
  cursor: pointer;
`;

const Handle = styled.div`
  width: 100%;
  height: 100%;

  ::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    transition: transform 0.2s;
  }
`;

const Input = styled.input<{
  checked?: boolean;
  _uncheckedColor?: ThemeColor;
  _checkedColor?: ThemeColor;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;

  ${({
    theme: { colors },
    checked,
    _uncheckedColor = 'gray70',
    _checkedColor = 'accent'
  }) =>
    checked
      ? css`
          & + ${Handle} {
            background-color: ${colors[_checkedColor]};
            ::after {
              transform: translateX(20px);
            }
          }
        `
      : css`
          & + ${Handle} {
            background-color: ${colors[_uncheckedColor]};
            ::after {
              transform: translateX(0);
            }
          }
        `}
`;
