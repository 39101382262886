import { KeyboardEvent } from 'react';

export type KeyCodeNames =
  | 'Tab'
  | 'Enter'
  | 'Escape'
  | 'Space'
  | 'ArrowLeft'
  | 'ArrowUp'
  | 'ArrowRight'
  | 'ArrowDown';

export const isKey = (
  keyName: KeyCodeNames,
  event: KeyboardEvent<HTMLInputElement>
) => {
  return event.code === keyName;
};
