import { useQueryClient } from '@tanstack/react-query';
import { GET_PUBLISHED_ANNOUNCEMENTS_PATH } from '@elfsight-universe/service-core-contracts/announcement';

export function useInvalidateAnnouncementsRelatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.resetQueries({
      predicate: ({ queryKey }) =>
        [GET_PUBLISHED_ANNOUNCEMENTS_PATH].includes(queryKey[0] as string)
    });
  };
}
