import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  SIGN_IN_BY_MINDBOX_TICKET_PATH,
  SignInByMindboxTicketResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useSignInByMindboxTicketMutation(
  options: Omit<
    UseMutationOptions<unknown, SignInByMindboxTicketResponseError, string>,
    'mutationFn'
  >
) {
  return useMutation<unknown, SignInByMindboxTicketResponseError, string>(
    (ticket) =>
      client.post(SIGN_IN_BY_MINDBOX_TICKET_PATH, {
        json: { ticket }
      }),
    options
  );
}
