import {
  AdditionalFeatures,
  INSTAGRAM_FEED_FEATURES,
  REVIEWS_FEATURES
} from './additional-features';

export const ADDITIONAL_FEATURES_MAP: { [K: string]: AdditionalFeatures } = {
  ['google-reviews']: REVIEWS_FEATURES,
  ['amazon-reviews']: REVIEWS_FEATURES,
  ['instagram-feed']: INSTAGRAM_FEED_FEATURES
};

export const mapAdditionalFeatures = (
  appAlias: string
): AdditionalFeatures | undefined => ADDITIONAL_FEATURES_MAP[appAlias];
