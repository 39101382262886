import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { PaginationItem } from './pagination-item';
import { PaginationParams } from './pagination-types';
import { usePagination } from './use-pagination';

export type PaginationProps = HTMLAttributes<HTMLElement> & PaginationParams;

export function Pagination({
  count,
  onPageChange,
  page,
  boundaryCount,
  siblingCount,
  disabled,
  hideNextButton,
  hidePrevButton,
  showFirstButton,
  showLastButton,
  ...forwardingProps
}: PaginationProps) {
  const items = usePagination({
    count,
    onPageChange,
    page,
    boundaryCount,
    siblingCount,
    disabled,
    hideNextButton,
    hidePrevButton,
    showFirstButton,
    showLastButton
  });

  return (
    <Container role="navigation" {...forwardingProps}>
      <List>
        {items.map((item, index) => (
          <PaginationItem key={index} {...item} />
        ))}
      </List>
    </Container>
  );
}

const Container = styled.nav``;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
`;
