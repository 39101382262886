import { Modal, useTheme } from '@elfsight-universe/ui-common';
import { SwitchAccountControl } from './switch-account-control';

interface SwitchAccountModal {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function SwitchAccountModal({
  isOpen,
  onRequestClose
}: SwitchAccountModal) {
  const { colors, zIndex: themeZIndex } = useTheme();

  return (
    <Modal
      withClose={true}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      maxWidth={300}
      closeColor="black"
      titleAlign="left"
      style={{
        content: {
          padding: 0
        },
        overlay: {
          zIndex: themeZIndex.selectAppModalBottomSheet,
          background: colors.gray50
        }
      }}
      title="Switch Workspace"
    >
      <SwitchAccountControl onClose={() => onRequestClose()} />
    </Modal>
  );
}
