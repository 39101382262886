import { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DESKTOP, MOBILE } from '@elfsight-universe/ui-common';

export type LayoutBlockProps = PropsWithChildren<{
  title?: ReactNode;
  caption?: ReactNode;
  contextMenu?: ReactNode;
  paddingBottom?: number;
}>;

export function LayoutBlock({
  children,
  title,
  caption,
  contextMenu,
  paddingBottom,
  ...forwardingProps
}: LayoutBlockProps) {
  const withHeader = title || caption;

  return (
    <Container {...forwardingProps} _paddingBottom={paddingBottom}>
      {withHeader && (
        <Header>
          {title && (
            <HeaderTitle>
              {title}
              {contextMenu}
            </HeaderTitle>
          )}
          {caption && <HeaderCaption>{caption}</HeaderCaption>}
        </Header>
      )}

      {children}
    </Container>
  );
}

const Container = styled.div<{ _paddingBottom?: number }>`
  padding: 20px 24px 24px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  ${({ _paddingBottom }) =>
    _paddingBottom !== undefined &&
    css`
      padding-bottom: ${_paddingBottom}px;
    `}

  ${DESKTOP} {
    :not(:last-child) {
      margin-bottom: 32px;
    }
  }
  ${MOBILE} {
    :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const Header = styled.div`
  margin-bottom: 20px;
`;

const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.font.title2};
`;

const HeaderCaption = styled.div`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.gray70};
  ${({ theme }) => theme.font.caption};
`;
