import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { AngleBoldLeft24, AngleBoldRight24 } from '@elfsight/icons';
import { Icon } from '../icons';

type TabsNavArrowProps = HTMLAttributes<HTMLButtonElement> & {
  left?: boolean;
  right?: boolean;
};

export function TabsNavArrow({
  left,
  right,
  ...forwardingProps
}: TabsNavArrowProps) {
  if (left) {
    return (
      <Container _left {...forwardingProps}>
        <Icon component={AngleBoldLeft24} size={16} fill="currentColor" />
      </Container>
    );
  }

  if (right) {
    return (
      <Container _right {...forwardingProps}>
        <Icon component={AngleBoldRight24} size={16} fill="currentColor" />
      </Container>
    );
  }

  return null;
}

// @TODO make touch area bigger

const Container = styled.button<{ _right?: boolean; _left?: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  position: absolute;
  top: 0;
  z-index: 1;

  color: ${({ theme }) => theme.colors.gray70};
  :hover {
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ theme: { colors }, _left, _right }) => {
    if (_right) {
      return css`
        right: -13px;
        padding-right: 1px;
        justify-content: flex-end;
        background: linear-gradient(
          90deg,
          ${colors.transparent} 0%,
          ${colors.white} 100%
        );
      `;
    }
    if (_left) {
      return css`
        left: -13px;
        padding-left: 1px;
        justify-content: flex-start;
        background: linear-gradient(
          90deg,
          ${colors.white} 0%,
          ${colors.transparent} 100%
        );
      `;
    }
  }}
`;
