import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const DISCARD_UNPUBLISHED_WIDGET_REVISION_PATH =
  'widget/discard-unpublished-widget-revision';

export class DiscardUnpublishedWidgetRevisionRequest {
  @IsUUID()
  widgetPid: string;
}

export type DiscardUnpublishedWidgetRevisionResponseError =
  WidgetError.WIDGET_DOES_NOT_EXIST;
