import {
  Button,
  Callout,
  CalloutProps,
  Icon
} from '@elfsight-universe/ui-common';
import { WarningFilled } from '@elfsight/icons';

type WidgetInstallDraftCalloutProps = Omit<
  CalloutProps,
  'icon' | 'backgroundColor' | 'button'
> & {
  onPublishDraft: () => void;
  isPublishingDraft?: boolean;
};

export function WidgetInstallDraftCallout({
  onPublishDraft,
  isPublishingDraft,
  children,
  ...forwardingProps
}: WidgetInstallDraftCalloutProps) {
  return (
    <Callout
      icon={<Icon size={16} component={WarningFilled} fill="warning" />}
      backgroundColor="warning"
      button={
        <Button
          variation="accentPrimary"
          onClick={onPublishDraft}
          isLoading={isPublishingDraft}
        >
          Publish Widget
        </Button>
      }
      {...forwardingProps}
    >
      {children}
    </Callout>
  );
}
