import { Exclude, Expose } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { BillingError, PaddleError } from '../errors';

export const REQUEST_REACTIVATE_SUBSCRIPTION_PATH =
  'billing/request-reactivate-subscription';

export class RequestReactivateSubscriptionRequest {
  @IsUUID()
  subscriptionPid: string;
}

@Exclude()
export class RequestReactivateSubscriptionResponse {
  @Expose()
  payLink: string;
}

export type RequestReactivateSubscriptionResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | PaddleError.PAY_LINK_CREATION_FAILED
  | BillingError.SUBSCRIPTION_CANNOT_BE_ACTIVATED;
