import styled from 'styled-components';
import { FAQItem } from './faq-types';
import { FAQGroup } from './faq-group';

export type FAQProps = {
  items: FAQItem[];
  toggleMode?: boolean;
};

export function FAQ({ items, toggleMode }: FAQProps) {
  const groups = items.reduce<Record<string, FAQItem[]>>((acc, item) => {
    const group = item.group || '';
    return { ...acc, [group]: [...(acc[group] ? acc[group] : []), item] };
  }, {});

  return (
    <Container>
      {Object.entries(groups).map(([group, items]) => (
        <FAQGroup
          key={group}
          title={group}
          items={items}
          toggleMode={toggleMode}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 24px;
`;
