import {
  UseMutationOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import {
  SWITCH_SUBSCRIPTION_PLAN_PATH,
  SwitchSubscriptionPlanRequest,
  SwitchSubscriptionPlanResponseError
} from '@elfsight-universe/service-core-contracts/billing/switch-subscription-plan';
import { GET_ME_PATH } from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useSwitchSubscriptionPlanMutation(
  options: Pick<
    UseMutationOptions<
      Response,
      SwitchSubscriptionPlanResponseError,
      SwitchSubscriptionPlanRequest
    >,
    'onSuccess' | 'onError'
  >
) {
  const queryClient = useQueryClient();

  return useMutation<
    Response,
    SwitchSubscriptionPlanResponseError,
    SwitchSubscriptionPlanRequest
  >(
    ({ subscriptionPid, planPid }) =>
      client.post(SWITCH_SUBSCRIPTION_PLAN_PATH, {
        json: {
          subscriptionPid,
          planPid
        }
      }),
    {
      ...options,
      retry: false,
      onSuccess: (...args) => {
        options.onSuccess && options.onSuccess(...args);
        queryClient.refetchQueries([GET_ME_PATH]);
      }
    }
  );
}
