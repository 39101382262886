import { useQuery } from '@tanstack/react-query';
import {
  GET_PREFERRED_MONETIZATION_LIST_PATH,
  GetPreferredMonetizationListResponse
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function usePreferredMonetizationListQuery() {
  const { isAuthenticated } = useUser();

  return useQuery<GetPreferredMonetizationListResponse>(
    [GET_PREFERRED_MONETIZATION_LIST_PATH],
    () => client.get(GET_PREFERRED_MONETIZATION_LIST_PATH).json(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isAuthenticated
    }
  );
}
