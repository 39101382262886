import { useRouter } from 'next/router';

// @TODO deprecate

export function useReplaceQueryParam<T extends string>(
  queryParam: string | undefined
): [T | undefined, (nextQueryParamValue: T | undefined) => Promise<boolean>] {
  const { query, pathname, replace } = useRouter();

  const queryParamValue = queryParam
    ? (query[queryParam] as T | undefined)
    : undefined;

  const replaceQueryParam = async (nextQueryParamValue: T | undefined) => {
    if (!queryParam) {
      return false;
    }

    if (!nextQueryParamValue) {
      delete query[queryParam];
    }

    if (nextQueryParamValue) {
      query[queryParam] = nextQueryParamValue;
    }

    return replace({ pathname, query });
  };

  return [queryParamValue, replaceQueryParam];
}
