import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export type IContextMenuContext = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const ContextMenuContext = createContext<IContextMenuContext | null>(
  null
);

export function useContextMenu() {
  const context = useContext(ContextMenuContext);

  if (context === null) {
    throw new Error(
      '`useContextMenu` must be nested inside an `ContextMenuProvider`.'
    );
  }

  return context;
}
