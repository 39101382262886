import { IsBoolean, IsEnum, IsOptional } from 'class-validator';
import { IdentityAndAccessError } from '../errors';
import { SortWidgetsVariant, ViewWidgetsVariant } from '../widget';

export const SET_USER_PREFERENCES_PATH =
  'identity-and-access/set-user-preferences';

export class SetUserPreferencesRequest {
  @IsOptional()
  @IsBoolean()
  viewsLimitNotification?: boolean;

  @IsOptional()
  @IsEnum(ViewWidgetsVariant)
  viewWidgetsVariant?: ViewWidgetsVariant;

  @IsOptional()
  @IsEnum(SortWidgetsVariant)
  sortWidgetsVariant?: SortWidgetsVariant;
}

export type SetUserPreferencesResponseError =
  IdentityAndAccessError.USER_PREFERENCE_NOT_EXISTS;
