import { useState } from 'react';
import { VideoPreview } from './video-preview';
import { VideoModal } from './video-modal';

export type VideoComponentProps = {
  videoUrl: string;
  containerWidth?: string;
};

export function VideoPlayerDialog({
  videoUrl,
  containerWidth
}: VideoComponentProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <VideoPreview
        onClick={() => setModalOpen(true)}
        videoUrl={videoUrl}
        containerWidth={containerWidth}
      />

      {isModalOpen && (
        <VideoModal
          url={videoUrl}
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
