import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class AccountDeal {
  @Expose()
  pid: string;

  @Expose()
  expiredAt?: Date;

  @Expose()
  usedAt?: Date;

  @Expose()
  @Type(() => Deal)
  deal: {
    alias: string;
    type: string;
    title: string;
    caption: string;
    shortCaption?: string;
    textImage?: string;
    expiredAt?: Date;
  };

  @Expose()
  @Type(() => AccountDealData)
  data: {
    referralAccountId?: number;
    hasActivePaidSubscription?: boolean;
    eligibleForInstantExtension?: boolean;
  };

  @Expose()
  @Type(() => Coupon)
  coupon?: {
    code: string;
  };

  @Expose()
  @Type(() => CustomData)
  customData?: {
    couponCode?: string;
    expiredAt?: Date;
    switchPlanParams?: {
      from: string;
      to: string;
    };
    lastUsedInstalledAppAlias?: string;
  };
}

@Exclude()
export class Deal {
  @Expose()
  alias: string;

  @Expose()
  type: string;

  @Expose()
  title: string;

  @Expose()
  caption: string;

  @Expose()
  shortCaption?: string;

  @Expose()
  textImage?: string;

  @Expose()
  expiredAt?: Date;
}

@Exclude()
export class Coupon {
  @Expose()
  code: string;
}

@Exclude()
export class CustomData {
  @Expose()
  couponCode?: string;

  @Expose()
  expiredAt?: Date;

  @Expose()
  @Type(() => CustomDataSwitchPlanParams)
  switchPlanParams?: {
    from: string;
    to: string;
  };

  @Expose()
  lastUsedInstalledAppAlias?: string;
}

@Exclude()
export class CustomDataSwitchPlanParams {
  @Expose()
  from: string;

  @Expose()
  to: string;
}

@Exclude()
export class AccountDealData {
  @Expose()
  referralAccountId?: number;

  @Expose()
  hasActivePaidSubscription?: boolean;

  @Expose()
  eligibleForInstantExtension?: boolean;
}
