import styled from 'styled-components';
import { ReactNode } from 'react';
import { DESKTOP, MOBILE } from '@elfsight-universe/ui-common';

export type HeaderNavProps = {
  children: ReactNode[];
};

export function HeaderNav({ children }: HeaderNavProps) {
  return <Container role="navigation">{children}</Container>;
}

const Container = styled.nav`
  display: flex;
  height: 100%;

  ${DESKTOP} {
    gap: 0px 32px;
  }
  ${MOBILE} {
    gap: 0px 24px;
  }
`;
