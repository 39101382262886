import styled, { css } from 'styled-components';
import { DESKTOP, MOBILE } from '../../breakpoints';

export type FormProps = {
  _offsetBetween?: [number, number];
};

export const Form = styled.form<FormProps>`
  display: flex;
  flex-direction: column;
  ${({ _offsetBetween = [20, 16] }) => css`
    display: flex;
    flex-direction: column;
    ${DESKTOP} {
      gap: ${_offsetBetween[0]}px 0;
    }
    ${MOBILE} {
      gap: ${_offsetBetween[1]}px 0;
    }
  `}
`;
