import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export type LabelBlockProps = PropsWithChildren<{ label: string }>;

export function LabelBlock({ label, children }: LabelBlockProps) {
  return (
    <Container>
      <Label>{label}</Label>

      {children}
    </Container>
  );
}

const Container = styled.div``;

const Label = styled.div`
  ${({ theme }) => theme.font.captionSmallUppercase};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray50};
  margin-bottom: 4px;
`;
