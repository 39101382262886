import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Check12 } from '@elfsight/icons';
import { Icon } from './icons';

type RadioCardButtonType = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  checked: boolean;
};

export const RadioCardButton = ({
  name,
  value,
  onChange,
  checked,
  children
}: RadioCardButtonType) => {
  return (
    <Label _isActive={checked}>
      <Input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      {checked && (
        <IconContainer>
          <Icon component={Check12} size={12} fill="white" />
        </IconContainer>
      )}
      <Content>{children}</Content>
    </Label>
  );
};

const Label = styled.label<{ _isActive?: boolean }>`
  cursor: pointer;
  border-radius: 4px;
  padding: 12px;
  min-height: 38px;
  border: 1px
    ${({ theme, _isActive = false }) =>
      _isActive ? theme.colors.accent : theme.colors.gray30}
    solid;
  width: 100%;
  max-width: 100%;
  position: relative;
`;
const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 4px;
  top: 4px;
`;
const Input = styled.input`
  display: none;
`;
const Content = styled.div``;
