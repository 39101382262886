import { forwardRef, InputHTMLAttributes } from 'react';
import { Control } from './control';
import { ControlInput } from './control-layout';
import { ControlProps, ControlSize } from './control-types';
import { useFocused } from './use-focused';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & ControlProps;

export const Input = forwardRef<HTMLInputElement, InputProps>(function _Input(
  {
    label,
    large,
    captionTop,
    captionBottom,
    fullWidth,
    maxWidth,
    className,
    error,
    onFocus,
    onBlur,
    ...forwardingProps
  },
  forwardingRef
) {
  const [focused, focusHandlers] = useFocused(onFocus, onBlur);
  const size: ControlSize = large ? 'large' : 'medium';
  const fontSize = { large: 16, medium: 14 }[size];
  const height = { large: 48, medium: 36 }[size];

  return (
    <Control
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fontSize={fontSize}
      height={height}
      focused={focused}
      label={label}
      captionTop={captionTop}
      captionBottom={captionBottom}
      error={error}
      className={className}
    >
      <ControlInput
        ref={forwardingRef}
        {...focusHandlers}
        {...forwardingProps}
      />
    </Control>
  );
});
