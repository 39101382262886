import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import isString from 'lodash/isString';
import addDays from 'date-fns/addDays';
import {
  AffiseParams,
  AFFISE_PARAMS_COOKIE_NAME
} from '@elfsight-universe/service-core-contracts/affiliate/affise-params';

const { NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN } = process.env;

export const useCatchAffiseParams = () => {
  const { query } = useRouter();
  const partnerId = query.affise_pid;
  const offerId = query.affise_offer_id;
  const clickId = query.affise_click_id;
  const [, setCookie] = useCookies();

  useEffect(() => {
    if (!isString(partnerId) || !isString(offerId)) {
      return;
    }

    const affiseParams: AffiseParams = {
      partnerId: Number(partnerId),
      offerId: Number(offerId)
    };

    if (isString(clickId) && !!clickId) {
      affiseParams.clickId = clickId;
    }

    setCookie(AFFISE_PARAMS_COOKIE_NAME, JSON.stringify(affiseParams), {
      domain: NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN,
      path: '/',
      secure: true,
      sameSite: 'none',
      expires: addDays(new Date(), 60)
    });
  }, [partnerId, offerId, clickId]);
};
