import { useCallback, useEffect, useRef } from 'react';

type OpenWindowOptions = {
  size?: typeof DEFAULT_SIZE;
};

export function useWindowOpen({ size = DEFAULT_SIZE }: OpenWindowOptions = {}) {
  const windowRef = useRef<Window | null>(null);

  useEffect(() => {
    return () => {
      windowRef.current?.close();
    };
  }, []);

  return useCallback(
    (url: string, target: string) => {
      const features = target !== '_blank' ? makeFeatures(size) : undefined;
      windowRef.current = window.open(url, target, features);
    },
    [size]
  );
}

const makeFeatures = ({ width, height } = DEFAULT_SIZE) => {
  const { availWidth, availHeight } = window.screen;
  const { left, top } = {
    left: window.screenLeft + availWidth / 2 - width / 2,
    top: window.screenTop + availHeight / 2 - height / 2
  };

  const features = {
    noopener: 'no',
    noreferrer: 'no',
    width,
    height,
    top,
    left
  };

  return Object.entries(features)
    .map(([key, value]) => `${key}=${value}`)
    .toString();
};

const DEFAULT_SIZE = {
  width: 600,
  height: 600
};
