import { ReactNode } from 'react';
import styled from 'styled-components';
import { DESKTOP } from '@elfsight-universe/ui-common';
import { useIsMobile } from '@modules/_app';
import { Image } from './image';
import { getDealBgImage } from '@modules/deals/utils';

export interface DefaultContainerProps {
  children: ReactNode;
  title?: string;
  textImage?: string;
  alias: string;
}

export function DefaultContainer({
  children,
  title,
  textImage,
  alias
}: DefaultContainerProps) {
  const isMobile = useIsMobile();

  return (
    <Container>
      <ContentContainer>{children}</ContentContainer>

      {!isMobile && textImage && (
        <ImageContainer>
          <Image
            alt={title}
            bgImageSrc={getDealBgImage(alias)}
            textImageSrc={textImage}
          />
        </ImageContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${DESKTOP} {
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  height: 280px;
  border-radius: 8px;
  overflow: hidden;

  ${DESKTOP} {
    width: 50%;
  }
`;
