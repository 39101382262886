import { useQuery } from '@tanstack/react-query';
import { useUser } from '@modules/_app';
import {
  GET_COLLABORATORS_PATH,
  GetCollaboratorsResponse
} from '@elfsight-universe/service-core-contracts/iam';
import { client } from '../client';

export function useCollaboratorsQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetCollaboratorsResponse>(
    [GET_COLLABORATORS_PATH],
    () => client.get(GET_COLLABORATORS_PATH).json<GetCollaboratorsResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
