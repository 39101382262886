import { IsUUID } from 'class-validator';

export const REPORT_PROJECT_USAGE_PATH = 'project/report-project-usage';

export class ReportProjectUsageRequest {
  @IsUUID()
  pid: string;
}

export type ReportProjectUsageResponseError = 'PROJECT_DOES_NOT_EXIST';
