import {
  BIRTHDAY_DEAL_ALIAS,
  BLACK_FRIDAY_DEAL_ALIAS,
  REFER_A_FRIEND_DEAL_ALIAS,
  SECOND_APP_DEAL_ALIAS,
  SWITCH_TO_ANNUAL_DEAL_ALIAS,
  SWITCH_TO_PACK_DEAL_ALIAS,
  WELCOME_DEAL_ALIAS,
  XMAS_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';

export const getDealBgImage = (alias: string): string => {
  const getImagePath = (filename: string) => `/assets/deals/${filename}`;

  const dealBgImageMapping = {
    [WELCOME_DEAL_ALIAS]: getImagePath('background-welcome.jpg'),
    [SECOND_APP_DEAL_ALIAS]: getImagePath('background-second.jpg'),
    [REFER_A_FRIEND_DEAL_ALIAS]: getImagePath('background-refer-a-friend.jpg'),
    [SWITCH_TO_PACK_DEAL_ALIAS]: getImagePath('background-all-apps-pack.jpg'),
    [SWITCH_TO_ANNUAL_DEAL_ALIAS]: getImagePath('background-annual.jpg'),
    [BLACK_FRIDAY_DEAL_ALIAS]: getImagePath('background-black-friday-2023.jpg'),
    [XMAS_DEAL_ALIAS]: getImagePath('background-xmas.jpg'),
    [BIRTHDAY_DEAL_ALIAS]: getImagePath('background-birthday.jpg')
  };

  return dealBgImageMapping[alias as keyof typeof dealBgImageMapping];
};
