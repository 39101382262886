import { IsUUID } from 'class-validator';

export const DELETE_PROJECT_PATH = 'project/delete-project';

export class DeleteProjectRequest {
  @IsUUID()
  pid: string;
}

export type DeleteProjectResponseError =
  | 'PROJECT_DOES_NOT_EXIST'
  | 'PROJECT_IS_ALREADY_DELETED'
  | 'PROJECT_HAS_WIDGET';
