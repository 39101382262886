import { ReactNode } from 'react';
import styled from 'styled-components';
import { H2 } from '@elfsight-universe/ui-common';

interface HowToApplyProps {
  children: ReactNode;
}

export function HowToApply({ children }: HowToApplyProps) {
  return (
    <Container>
      <H2>How to Apply?</H2>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HowToApplyListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ theme }) => theme.font.text2}
`;
