import {
  TabsProvider,
  useRouterQueryParamsState
} from '@elfsight-universe/ui-common';
import { PlansTabsNavigation } from './plans-tabs-navigation';
import { PlansSection } from './plans-section';
import { useUpgradeContext } from '../upgrade-context';
import { PlanTab } from '../constants';
import { useCountPublicAppsQuery } from '@api';

export function PlansTabs() {
  const { data } = useUpgradeContext();
  const [queryParam, setQueryParam] = useRouterQueryParamsState(0);
  const { data: countApps } = useCountPublicAppsQuery();

  const plansTabs = [
    {
      id: PlanTab.SINGLE,
      title: data?.app?.name ?? 'Single App'
    },
    {
      id: PlanTab.PACKS,
      title: `${countApps?.count ?? 'All'} Apps Pack`
    }
  ];

  return (
    <TabsProvider
      defaultTab={data?.displaySingleAppPlans ? PlanTab.SINGLE : PlanTab.PACKS}
      queryParam={queryParam}
      onSetActiveTab={setQueryParam}
      tabs={plansTabs}
    >
      <PlansTabsNavigation />
      <PlansSection />
    </TabsProvider>
  );
}
