import { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DESKTOP, H4, Icon, MOBILE } from '@elfsight-universe/ui-common';
import { AngleRight16 } from '@elfsight/icons';
import { ListItem } from '../../components/list-item';
import { OnboardingDoneItem } from './onboarding-done-item';

export type OnboardingItemProps = PropsWithChildren<{
  actions: ReactNode;
  title: string;
  done?: boolean;
  open?: boolean;
  onClick?: () => void;
}>;

export function OnboardingItem({
  open = false,
  children,
  actions,
  title,
  done,
  onClick,
  ...forwardingProps
}: OnboardingItemProps) {
  if (done) {
    return (
      <OnboardingDoneItem {...forwardingProps}>{title}</OnboardingDoneItem>
    );
  }

  return (
    <Container _offset={8} _intractable={!open} {...forwardingProps}>
      <Title onClick={onClick}>
        {title}

        <IndicatorIcon
          _active={open}
          component={AngleRight16}
          fill={open ? 'black' : 'gray30'}
          size={16}
        />
      </Title>

      <Content _open={open}>
        <Caption>{children}</Caption>
        <Actions>{actions}</Actions>
      </Content>
    </Container>
  );
}

const Container = styled(ListItem)`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ _open: boolean }>`
  opacity: ${({ _open }) => (_open ? 1 : 0)};
  max-height: ${({ _open }) => (_open ? 300 : 0)}px;
  transition: opacity 0.2s, max-height 0.2s;
  display: flex;
  flex-direction: column;
  cursor: default;
  gap: 8px;
  overflow: hidden;
`;

const Title = styled(H4)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: -16px;
    right: -16px;
    left: -16px;
    top: -16px;
  }
`;

const IndicatorIcon = styled(Icon)<{ _active: boolean }>`
  margin-left: 0;
  flex-shrink: 0;
  transition: all 0.2s;
  ${({ _active }) =>
    _active
      ? css`
          transform: rotate(90deg);
        `
      : css`
          transform: rotate(0deg);
        `};
`;

const Caption = styled.div`
  color: ${({ theme }) => theme.colors.gray70};
  margin-top: 8px;
  margin-bottom: 4px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;

  ${DESKTOP} {
    flex-direction: row;
  }
  ${MOBILE} {
    flex-direction: column;
  }
`;
