import { useCreateSubscriptionMutation } from '@api';
import { usePaddle, PaddleSuccessCheckoutData } from '../../paddle';

export function usePaddleSubscribe(
  onSuccess: (data: PaddleSuccessCheckoutData) => void
): [boolean, (planPid: string) => Promise<void>] {
  const { openCheckoutURL, setLoading, isLoading } = usePaddle();
  const { mutate: createSubscription } = useCreateSubscriptionMutation({
    onSuccess: ({ payLink }) => {
      openCheckoutURL({
        override: payLink,
        successCallback: (data) => {
          setLoading(false);
          onSuccess(data);
        }
      });
    }
  });

  const subscribe = async (planPid: string) => {
    setLoading(true);
    createSubscription({ planPid });
  };

  return [isLoading, subscribe];
}
