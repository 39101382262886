import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  UPDATE_WIDGET_META_PATH,
  UpdateWidgetMetaRequest,
  UpdateWidgetMetaResponseError
} from '@elfsight-universe/service-core-contracts/widget/update-widget-meta';
import { client } from '../client';

export function useUpdateWidgetMetaMutation(
  pid: string,
  options: UseMutationOptions<
    Omit<UpdateWidgetMetaRequest, 'pid'> | undefined,
    UpdateWidgetMetaResponseError,
    Omit<UpdateWidgetMetaRequest, 'pid'>
  > = {}
) {
  return useMutation<
    Omit<UpdateWidgetMetaRequest, 'pid'> | undefined,
    UpdateWidgetMetaResponseError,
    Omit<UpdateWidgetMetaRequest, 'pid'>
  >(async (data) => {
    await client.post(UPDATE_WIDGET_META_PATH, {
      json: { pid, ...data }
    });

    return data;
  }, options);
}
