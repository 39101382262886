import { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  DESKTOP,
  MOBILE,
  ThemeColor,
  useTabs
} from '@elfsight-universe/ui-common';
import { PlanTab } from '../constants';
import { useUpgradeContext } from '../upgrade-context';

export function PlansTabsNavigation() {
  const { data } = useUpgradeContext();
  const { activeTab, setActiveTab, tabs } = useTabs();

  const filteredTabs = useMemo(
    () =>
      tabs.filter(
        ({ id }) =>
          ({
            [PlanTab.SINGLE]: data?.displaySingleAppPlans,
            [PlanTab.PACKS]: data?.displayAllAppsPackPlans
          })[id]
      ),
    [data?.displaySingleAppPlans, data?.displayAllAppsPackPlans]
  );

  useEffect(() => {
    if (filteredTabs.length === 0) {
      return;
    }

    const hasTab = (tabId: PlanTab) =>
      filteredTabs.find(({ id }) => id === tabId);

    if (activeTab === PlanTab.SINGLE && !hasTab(PlanTab.SINGLE)) {
      setActiveTab(PlanTab.PACKS);
      return;
    }
    if (activeTab === PlanTab.PACKS && !hasTab(PlanTab.PACKS)) {
      setActiveTab(PlanTab.SINGLE);
      return;
    }
  }, [activeTab, filteredTabs.length]);

  if (filteredTabs.length === 1) {
    return null;
  }

  return (
    <Container>
      <Inner>
        <Nav role="navigation">
          {filteredTabs.map(({ id, title }) => (
            <TabItem
              key={id}
              _active={id === activeTab}
              _highlight={id === PlanTab.PACKS}
              onClick={() => setActiveTab(id)}
            >
              {title}
              {id === PlanTab.PACKS && (
                <BestValueBadge _backgroundColor="black">
                  Top deal
                </BestValueBadge>
              )}
            </TabItem>
          ))}
        </Nav>
      </Inner>
    </Container>
  );
}

const Container = styled.div`
  position: relative;

  &::after {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #e7e7e7;
    content: '';
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Nav = styled.nav`
  max-width: 600px;
  width: 100%;
  display: flex;
  gap: 8px;
`;

const TabItem = styled.div<{ _active?: boolean; _highlight: boolean }>`
  position: relative;
  z-index: 2;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #e7e7e7;
  border-bottom: 0;
  border-radius: 12px 12px 0 0;
  ${DESKTOP} {
    padding: 16px 20px;
  }
  ${MOBILE} {
    padding: 12px;
  }
  box-sizing: border-box;
  display: flex;
  flex: 1 1 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme, _highlight, _active }) =>
    _highlight && !_active ? theme.colors.accent : theme.colors.black};

  ${({ _active, theme: { colors } }) =>
    _active &&
    css`
      background: ${colors.background};
      color: ${colors.black};
    `};
`;

const BestValueBadge = styled.div<{ _backgroundColor?: ThemeColor }>`
  position: absolute;
  overflow: hidden;
  top: -4px;
  right: -5px;
  padding: 2px 6px;
  border-radius: 32px;
  ${({ theme }) => theme.font.captionSmall};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, _backgroundColor = 'brand' }) =>
    theme.colors[_backgroundColor]};
  text-transform: uppercase;
`;
