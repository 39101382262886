import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { ButtonPrimitive } from '@elfsight-universe/ui-common';
import { useHeader } from './header-provider';

export function HeaderMobileTrigger(
  props: ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { isMobileNavOpen, toggleMobileNav } = useHeader();

  return (
    <Container onClick={() => toggleMobileNav()} {...props}>
      <InnerWrapper _active={isMobileNavOpen}>
        <span />
        <span />
        <span />
      </InnerWrapper>
    </Container>
  );
}

const heightIcon = 14;
const widthLine = 24;
const heightLine = 2;

const transitionTime = 0.2; // sec
const rotation = 45; // deg
const translateY = (heightIcon - heightLine) / 2;
const translateX = 0;

export const Container = styled(ButtonPrimitive)`
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;
  padding: 0;

  ${({ theme: { colors } }) => css`
    ${InnerWrapper} {
      color: ${colors.gray50};
    }
    :hover {
      background: ${colors.gray5};
      ${InnerWrapper} {
        color: ${colors.gray70};
      }
    }
    :active {
      background: ${colors.gray10};
      ${InnerWrapper} {
        color: ${colors.black};
      }
    }
  `}
`;

export const InnerWrapper = styled.div<{ _active: boolean }>`
  position: relative;
  display: block;
  width: ${widthLine}px;
  height: ${heightIcon}px;

  span {
    position: absolute;
    left: 0;
    display: block;
    width: ${widthLine}px;
    height: ${heightLine}px;
    background-color: currentColor;
    border-radius: 1px;
    transition: all ${transitionTime}s;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 50%;
    margin-top: -${heightLine / 2}px;
  }

  span:nth-child(3) {
    top: 100%;
    margin-top: -${heightLine}px;
  }

  /*
    https://codepen.io/designcouch/pen/ExvwPY
   */

  ${({ _active }) =>
    _active &&
    css`
      span:nth-child(1) {
        transform: translateY(${translateY}px) translateX(${translateX})
          rotate(${rotation}deg);
      }

      span:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      span:nth-child(3) {
        transform: translateY(${translateY * -1}px) translateX(${translateX})
          rotate(${rotation * -1}deg);
      }
    `}
`;
