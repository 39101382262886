import { useEffect } from 'react';
import { useEventBus } from '@modules/_event-bus/event-bus-provider';
import { EventType } from '@modules/_event-bus/event.type';

export function useEvents(
  messages: string[],
  next: (event: EventType) => void
) {
  const eventBus = useEventBus();

  useEffect(() => {
    const subscription = eventBus.getEvents(messages).subscribe(next);

    return () => {
      subscription.unsubscribe();
    };
  }, []);
}
