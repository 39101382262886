import { PropsWithChildren } from 'react';
import { ImpersonateSessionProvider } from '@modules/impersonate-session/impersonate-session-provider';
import { EventBusProvider } from '@modules/_event-bus';
import { ClientMessageProvider } from '@modules/_app/client-message-provider';
import { PaddleProvider } from '@modules/paddle';
import { AppCurrentAppAliasProvider } from '@modules/_app/app-current-app-alias-provider';
import { WixIntegrationProvider } from '@modules/wix-integration/contexts/wix-integration';
import { BeaconProvider } from '@modules/beacon';
import { OfferProvider } from '../offer';
import { InstantExtensionCampaignProvider } from '@modules/instant-extension-campaign/context/instant-extension-campaign-context';
import { AppAccountToastProvider } from './app-account-toast-provider';
import { AppMobileProvider } from './app-mobile-provider';
import { UserPreferencesProvider } from './app-user-preferences-provider';
import { AppUserProvider } from './app-user-provider';
import { AppQueryClientProvider } from './app-query-client-provider';
import { AppHistoryProvider } from './app-history-provider';

export const AppProviders = ({ children }: PropsWithChildren) => (
  <AppQueryClientProvider>
    <EventBusProvider>
      <AppHistoryProvider>
        <ClientMessageProvider>
          <AppUserProvider>
            <AppAccountToastProvider>
              <WixIntegrationProvider>
                {/* <AppOnlineProvider> */}
                <ImpersonateSessionProvider>
                  <AppMobileProvider>
                    <AppCurrentAppAliasProvider>
                      <OfferProvider>
                        <UserPreferencesProvider>
                          <PaddleProvider>
                            <InstantExtensionCampaignProvider>
                              <BeaconProvider />
                              {children}
                            </InstantExtensionCampaignProvider>
                          </PaddleProvider>
                        </UserPreferencesProvider>
                      </OfferProvider>
                    </AppCurrentAppAliasProvider>
                  </AppMobileProvider>
                </ImpersonateSessionProvider>
                {/* </AppOnlineProvider> */}
              </WixIntegrationProvider>
            </AppAccountToastProvider>
          </AppUserProvider>
        </ClientMessageProvider>
      </AppHistoryProvider>
    </EventBusProvider>
  </AppQueryClientProvider>
);
