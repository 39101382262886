import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ThemeColor } from '../theme';
import { getThemeOrCustomColor } from '../utils';

export type LoaderProps = {
  size?: number;
  color?: ThemeColor | string;
  backgroundColor?: ThemeColor | string;
  absolute?: boolean;
  large?: boolean;
  small?: boolean;
  spacing?: number;
  delay?: number;
  style?: CSSProperties;
};

export function Loader({
  color = 'black',
  backgroundColor = 'transparent',
  large,
  small,
  absolute = false,
  size,
  spacing = 20,
  delay = 100,
  ...forwardingProps
}: LoaderProps) {
  const finalSize = size || (large ? 60 : small ? 20 : 40);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplay(true);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return (
    <Container
      {...forwardingProps}
      _absolute={absolute}
      _spacing={spacing}
      _backgroundColor={backgroundColor}
    >
      <Spinner
        _color={color}
        _size={finalSize}
        style={{ opacity: display ? 1 : 0 }}
      />
    </Container>
  );
}

type WithLoaderProps = {
  children: ReactNode;
  isLoading: boolean;
  placeholderHeight?: number;
  LoaderProps?: LoaderProps;
};

export function WithLoader({
  children,
  isLoading,
  placeholderHeight,
  LoaderProps
}: WithLoaderProps) {
  if (isLoading) {
    return (
      <Placeholder _height={placeholderHeight}>
        <Loader {...LoaderProps} />
      </Placeholder>
    );
  }

  return <>{children}</>;
}

const Placeholder = styled.div<{ _height?: number }>`
  min-height: ${({ _height }) => _height}px;
`;

const Container = styled.div<{
  _backgroundColor: ThemeColor | string;
  _absolute: boolean;
  _spacing: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  box-sizing: border-box;
  padding: ${({ _spacing }) => _spacing}px;
  background-color: ${({ theme, _backgroundColor }) =>
    getThemeOrCustomColor(_backgroundColor, theme)};
  ${({ _absolute }) =>
    _absolute &&
    css`
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `}
`;

const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{
  _size: number;
  _color: ThemeColor | string;
}>`
  position: relative;
  width: ${({ _size }) => _size}px;
  height: ${({ _size }) => _size}px;
  display: block;
  border-radius: 50%;
  background: conic-gradient(
    transparent 25%,
    ${({ theme, _color }) => getThemeOrCustomColor(_color, theme)}
  );
  mask-image: radial-gradient(
    circle,
    transparent ${({ _size }) => _size / 2 - 2}px,
    black 0
  );
  transition: opacity 0.5s;
  animation: ${spinning} 1s infinite cubic-bezier(0.4, 0, 0.2, 1);

  ::after {
    content: '';
    width: 2px;
    height: 2px;
    position: absolute;
    background: ${({ theme, _color }) => getThemeOrCustomColor(_color, theme)};
    border-radius: 50%;
    top: 0;
    left: calc(50% - 1px);
  }
`;
