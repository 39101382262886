import { useRouter } from 'next/router';
import { useHistory } from '@modules/_app';
import {
  APP_SUBSCRIPTION_SUCCESS_QUERY_PARAM,
  SECOND_APP_DEAL_PROMISING_QUERY_PARAM
} from '@modules/dashboard/app/app-subscription-success-modal';
import { REDIRECT_BACK_PARAM, REDIRECT_URL_PARAM } from '../constants';

export function useSubscribeRedirect() {
  const { query, push } = useRouter();
  const { back } = useHistory();

  const shouldRedirectBack = query[REDIRECT_BACK_PARAM] === 'true';
  let redirectURL = query[REDIRECT_URL_PARAM];

  return ({
    isPaidSubscription,
    secondAppDealPromising
  }: {
    isPaidSubscription?: boolean;
    secondAppDealPromising?: boolean;
  } = {}) => {
    const hasParams = redirectURL?.includes('?');
    const searchParams = new URLSearchParams();
    searchParams.append(APP_SUBSCRIPTION_SUCCESS_QUERY_PARAM, 'true');

    if (secondAppDealPromising) {
      searchParams.append(SECOND_APP_DEAL_PROMISING_QUERY_PARAM, 'true');
    }

    if (isPaidSubscription && redirectURL) {
      redirectURL = `${redirectURL}${hasParams ? '&' : '?'}${searchParams}`;
    }

    if (redirectURL) {
      return push(redirectURL as string);
    }
    if (shouldRedirectBack) {
      return back('/');
    }

    return push('/');
  };
}
