export interface AdditionalFeature {
  feature: string;
  tooltip: string;
}

export type AdditionalFeatures = AdditionalFeature[];

export const REVIEWS_FEATURES: AdditionalFeatures = [];

export const PACKS_FEATURES: AdditionalFeatures = [];

export const INSTAGRAM_FEED_FEATURES: AdditionalFeatures = [
  {
    feature: 'More sources (soon)',
    tooltip: 'You’ll be able to add more accounts and hashtags to one Feed!'
  },
  {
    feature: 'Reduced cache (soon)',
    tooltip: 'Your posts will be be updated on your website even faster!'
  }
];
