import { IsOptional, IsString } from 'class-validator';
import { WidgetError } from '../errors';
import { GetWidgetResponse } from './get-widget';
import { Exclude } from 'class-transformer';

export const CREATE_WIDGET_PATH = 'widget/create-widget';

export class CreateWidgetRequest {
  @IsString()
  appAlias: string;

  @IsOptional()
  @IsString()
  templatePid?: string;

  @IsOptional()
  @IsString()
  projectPid?: string;

  @IsString()
  @IsOptional()
  appReleaseVersion?: string;
}

@Exclude()
export class CreateWidgetResponse extends GetWidgetResponse {}

export type CreateWidgetResponseError =
  | WidgetError.APP_DOES_NOT_EXIST
  | WidgetError.APP_RELEASE_DOES_NOT_EXIST
  | WidgetError.WIDGETS_LIMIT_REACHED
  | WidgetError.WIDGET_DOES_NOT_EXIST;
