import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumber } from 'class-validator';

export const DUPLICATE_ANNOUNCEMENT_PATH =
  'announcement/duplicate-announcement';

export class DuplicateAnnouncementRequest {
  @Type(() => Number)
  @IsNumber()
  id: number;
}

@Exclude()
export class DuplicateAnnouncementResponse {
  @Expose()
  id: number;
}

export type DuplicateAnnouncementResponseError = 'ANNOUNCEMENT_DOES_NOT_EXISTS';
