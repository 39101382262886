export enum BillingError {
  PLAN_DOES_NOT_EXIST = 'PLAN_DOES_NOT_EXIST',
  SUBSCRIPTION_DOES_NOT_EXIST = 'SUBSCRIPTION_DOES_NOT_EXIST',
  SUBSCRIPTION_DOES_NOT_HAVE_BILLING_DATE = 'SUBSCRIPTION_DOES_NOT_HAVE_BILLING_DATE',
  PAYMENT_DOES_NOT_EXIST = 'PAYMENT_DOES_NOT_EXIST',
  SUBSCRIPTION_CANCELLATION_CANNOT_BE_SCHEDULED = 'SUBSCRIPTION_CANCELLATION_CANNOT_BE_SCHEDULED',
  SUBSCRIPTION_CANCELLATION_CANNOT_BE_REVOKED = 'SUBSCRIPTION_CANCELLATION_CANNOT_BE_REVOKED',
  SUBSCRIPTION_CANNOT_BE_ACTIVATED = 'SUBSCRIPTION_CANNOT_BE_ACTIVATED',
  SUBSCRIPTION_CANNOT_BE_UPDATED = 'SUBSCRIPTION_CANNOT_BE_UPDATED',
  SUBSCRIPTION_CANNOT_BE_CANCELLED = 'SUBSCRIPTION_CANNOT_BE_CANCELLED',
  CANCELLATION_REASON_DOES_NOT_EXISTS = 'CANCELLATION_REASON_DOES_NOT_EXISTS',
  SUBSCRIPTION_CANNOT_BE_CONFIRMED = 'SUBSCRIPTION_CANNOT_BE_CONFIRMED',
  SUBSCRIPTION_CANNOT_BE_INSTANTLY_EXTENDED = 'SUBSCRIPTION_CANNOT_BE_INSTANTLY_EXTENDED',
  SUBSCRIPTION_CANNOT_BE_SYNCED = 'SUBSCRIPTION_CANNOT_BE_SYNCED'
}
