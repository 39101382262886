import { CSRFResponse } from '@elfsight-universe/service-core-contracts/csrf';

export function useMemoizedCSRFToken(
  getCSRFTokenEndpointURL: string,
  cookie?: string
): () => Promise<string | undefined> {
  let memoizedToken: string | undefined;

  return async function getToken() {
    if (memoizedToken !== undefined) {
      return memoizedToken;
    }

    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    if (cookie) {
      headers.append('cookie', cookie);
    }

    const response = await fetch(getCSRFTokenEndpointURL, {
      credentials: 'include',
      headers
    });
    if (!response.ok) {
      throw new Error('A CSRF token retrieval has failed.');
    }

    const dto: CSRFResponse = await response.json();

    // removed `|| ''` - this is a whole fix actually!
    // so way we're no saving invalid value at memoizedToken and
    // going repeat getting csrf until `memoizedToken !== undefined`
    memoizedToken = dto.token;

    return memoizedToken;
  };
}
