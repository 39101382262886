import { Exclude, Expose, Type } from 'class-transformer';

export const GET_INSTANT_EXTENSION_CANDIDATES_PATH =
  'billing/get-instant-extension-candidates';

@Exclude()
export class InstantExtensionCandidateTerms {
  @Expose()
  defaultPrice: number;

  @Expose()
  discountedPrice: number;

  @Expose()
  defaultNextPaymentDate: string;

  @Expose()
  updatedNextPaymentDate: string;
}

@Exclude()
export class InstantExtensionCandidate {
  @Expose()
  subscriptionPid: string;

  @Expose()
  planFullName: string;

  @Expose()
  updatePaymentMethodURL: string;

  @Expose()
  numberOfExtensions: number;

  @Expose()
  canSubscriptionBeInstantlyExtended: boolean;

  @Expose()
  isEnterprisePlanSubscription: boolean;

  @Expose()
  @Type(() => InstantExtensionCandidateTerms)
  terms: InstantExtensionCandidateTerms;
}

@Exclude()
export class GetInstantExtensionCandidatesResponse {
  @Expose()
  @Type(() => InstantExtensionCandidate)
  payload: InstantExtensionCandidate[];
}
