import { PropsWithChildren, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { Cross } from '@elfsight/icons';
import {
  DESKTOP,
  Icon,
  MOBILE,
  ThemeColor
} from '@elfsight-universe/ui-common';
import { OfferSlideInHeaderIcon } from './offer-slide-in-header-icon';
import { useOffer } from '../offer-provider';

export type OfferSlideInProps = PropsWithChildren<{
  title: string;
  description: string;
  headerIconColor?: ThemeColor;
  headerBackgroundColor?: ThemeColor;
  headerColor?: ThemeColor;
  width?: number;
}>;

export function OfferSlideIn({
  title,
  description,
  children,
  headerBackgroundColor = 'promo',
  headerColor = 'white',
  headerIconColor = 'white',
  width = 280
}: OfferSlideInProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { slideInOpen, toggleSlideInOpen } = useOffer();

  return (
    <CSSTransition
      nodeRef={containerRef}
      classNames="slide-in"
      timeout={0}
      in
      appear
    >
      <Container ref={containerRef} _open={slideInOpen} _width={width}>
        <SlideInHeader
          onClick={() => toggleSlideInOpen()}
          _headerBackgroundColor={headerBackgroundColor}
        >
          <Icon
            fill={headerIconColor}
            component={OfferSlideInHeaderIcon}
            size={32}
          />
          <HeaderTitle
            _color={headerColor}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <CloseIconWrapper>
            {slideInOpen && (
              <CloseIcon fill={headerColor} component={Cross} size={17} />
            )}
          </CloseIconWrapper>
        </SlideInHeader>
        <Body>
          <Inner>
            <Description dangerouslySetInnerHTML={{ __html: description }} />

            {children}
          </Inner>
        </Body>
      </Container>
    </CSSTransition>
  );
}

const CloseIconWrapper = styled.div`
  width: 16px;
  margin-right: 4px;
  margin-left: 10px;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:focus,
  &:hover {
    opacity: 1;
  }
`;

const SlideInHeader = styled.div<{ _headerBackgroundColor: ThemeColor }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, _headerBackgroundColor }) =>
    theme.colors[_headerBackgroundColor]};
`;

const HeaderTitle = styled.div<{ _color: ThemeColor }>`
  ${({ theme }) => theme.font.title4Semibold};
  color: ${({ theme, _color }) => theme.colors[_color]};
`;

const Inner = styled.div`
  padding: 16px;
  box-sizing: border-box;
`;

const Body = styled.div`
  transition: all 0.45s;
  background: ${({ theme }) => theme.colors.white};
`;

const Description = styled.div`
  margin-bottom: 12px;
`;

const Container = styled.div<{ _open: boolean; _width: number }>`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.offerSlideIn};
  bottom: 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  width: ${({ _width }) => _width}px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);

  &.slide-in-appear-active {
    transform: translateY(100px);
    opacity: 0;
  }
  &.slide-in-appear-done {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.45s;
  }

  ${DESKTOP} {
    left: 12px;
  }
  ${MOBILE} {
    left: 8px;
  }

  ${({ _open }) =>
    css`
      ${Body} {
        max-height: ${_open ? 200 : 0}px;
      }
    `}
`;
