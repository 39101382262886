import {
  SUBSCRIBE_CLIENT_MESSAGE_PATH,
  SubscribeClientMessageResponseData
} from '@elfsight-universe/service-core-contracts/client-message/subscribe-client-message';
import { ACCOUNT_PID_SESSION_OPTION } from '@constants';

const { NEXT_PUBLIC_SERVICE_CORE__BASE_URL } = process.env;
const { NEXT_PUBLIC_APP_ENV } = process.env;
const { NEXT_PUBLIC_APP_DASHBOARD__BASE_URL } = process.env;

export function subscribeClientMessage(
  handleMessage: (message: SubscribeClientMessageResponseData) => void
) {
  const subscribeClientMessageURL = new URL(
    NEXT_PUBLIC_APP_ENV === 'production'
      ? `${NEXT_PUBLIC_APP_DASHBOARD__BASE_URL}/core/${SUBSCRIBE_CLIENT_MESSAGE_PATH}`
      : `${NEXT_PUBLIC_SERVICE_CORE__BASE_URL}/${SUBSCRIBE_CLIENT_MESSAGE_PATH}`
  );
  const accountPid = JSON.parse(
    sessionStorage.getItem(ACCOUNT_PID_SESSION_OPTION) as string
  );

  if (accountPid) {
    subscribeClientMessageURL.searchParams.append('asAccount', accountPid);
  }

  const eventSource = new EventSource(subscribeClientMessageURL, {
    withCredentials: true
  });

  eventSource.onmessage = (event: MessageEvent<string>) =>
    handleMessage(JSON.parse(event.data) as SubscribeClientMessageResponseData);

  return eventSource;
}
