import styled from 'styled-components';
import { Popover, IconButton } from '@elfsight-universe/ui-common';
import { Close20 } from '@elfsight/icons';
import { OnboardingPreview } from '@modules/onboarding/onboarding-preview';
import { OnboardingItemsWithDone } from '@modules/_header/header-onboarding-container';
import { useHeader } from '@modules/_header/header-provider';
import { OnboardingBlock } from '@modules/onboarding';

export type HeaderOnboardingProps = {
  items: OnboardingItemsWithDone[];
  done: boolean;
  progress: number;
  progressNote: string;
};

export function HeaderOnboarding({
  items,
  done,
  progressNote,
  progress
}: HeaderOnboardingProps) {
  const { isOnboardingOpen, toggleOnboardingOpen } = useHeader();

  return (
    <Popover
      isOpen={isOnboardingOpen}
      onOpenChange={() => toggleOnboardingOpen(false)}
      floatingStrategy="fixed"
      content={
        <PopoverContent>
          <OnboardingBlock items={items} done={done} note={progressNote} />
          <PopoverCloseButton
            large
            rounded
            icon={Close20}
            onClick={() => toggleOnboardingOpen(false)}
          />
        </PopoverContent>
      }
    >
      <OnboardingPreview
        active={isOnboardingOpen}
        done={done}
        progress={progress}
        onClick={() => toggleOnboardingOpen()}
      />
    </Popover>
  );
}

const PopoverContent = styled.div`
  position: relative;
  max-width: 400px;
  padding: 20px 24px 24px;
`;

const PopoverCloseButton = styled(IconButton)`
  position: absolute;
  right: 14px;
  top: 14px;
`;
