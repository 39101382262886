import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';

const KEY_PREFIX = 'elfsight-disposed';
export function useDisposedInLocalStorage(
  instanceId: string,
  accountPid: string | undefined
): [boolean, () => void] {
  if (typeof window === 'undefined') {
    return [false, noop];
  }

  const disposableKey = `${KEY_PREFIX}--${kebabCase(instanceId)}-${
    accountPid || 'guest'
  }`;

  return [
    window.localStorage.getItem(disposableKey) === 'true',
    () => {
      window.localStorage.setItem(disposableKey, 'true');
    }
  ];
}
