import type { JsonObject } from '../json.type';
import { IsObject, IsUUID } from 'class-validator';
import { Exclude, Expose } from 'class-transformer';
import { WidgetError } from '../errors';

export const UPDATE_UNPUBLISHED_WIDGET_REVISION_PATH =
  'widget/update-unpublished-widget-revision';

export class UpdateUnpublishedWidgetRevisionRequest {
  @IsUUID()
  widgetPid: string;

  @IsObject()
  settings: JsonObject;
}

@Exclude()
export class UpdateUnpublishedWidgetRevisionResponse {
  @Expose()
  pid: string;

  @Expose()
  updatedAt: string;

  @Expose()
  widgetToken: string;

  @Expose()
  settingsHash: string;
}

export type UpdateUnpublishedWidgetRevisionResponseError =
  | WidgetError.WIDGET_DOES_NOT_EXIST
  | WidgetError.UPDATE_UNPUBLISHED_WIDGET_REVISION_FAILED;
