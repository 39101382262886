import { IsEmail, IsNotEmpty, IsString } from 'class-validator';
import { IdentityAndAccessError } from '../errors';

export const SIGN_IN_BY_CREDENTIALS_PATH =
  'identity-and-access/sign-in-by-credentials';

export class SignInByCredentialsRequest {
  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail(undefined, { message: 'Email is invalid.' })
  email: string;

  @IsString({ message: 'Password is required.' })
  @IsNotEmpty({ message: 'Password is required.' })
  password: string;
}

export type SignInByCredentialsResponseError =
  | IdentityAndAccessError.USER_DOES_NOT_EXISTS
  | IdentityAndAccessError.AUTH_IDENTITY_DOES_NOT_EXIST
  | IdentityAndAccessError.PASSWORD_DOES_NOT_MATCH
  | IdentityAndAccessError.USER_DELETED
  | IdentityAndAccessError.ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT;
