import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useRect } from 'react-use-rect';
import styled from 'styled-components';
import { useWindowHeight } from '@elfsight-universe/ui-common';
import { HeaderMobileNav, HeaderProvider } from '@modules/_header';
import { AnnouncementsProvider } from '@modules/announcements/announcements-provider';

type PageLayoutProps = PropsWithChildren<{
  header?: ReactNode;
  headerActivePage?: string;
  leftSidebar?: ReactNode;
  rightSidebar?: ReactNode;
  withBackground?: boolean;
}>;

export function LayoutPage({
  children,
  header,
  headerActivePage,
  leftSidebar,
  rightSidebar,
  withBackground
}: PageLayoutProps) {
  const windowHeight = useWindowHeight();
  const [headerHeight, setHeaderHeight] = useState(60);
  const [headerRef] = useRect((rect) => setHeaderHeight(rect.height), {
    resize: true
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--layout-header-height',
      `${headerHeight}px`
    );
  }, [headerHeight]);

  return (
    <HeaderProvider activePage={headerActivePage}>
      <AnnouncementsProvider>
        <Page style={{ minHeight: windowHeight }}>
          <HeaderContainer ref={headerRef}>{header}</HeaderContainer>

          <Main _withBackground={withBackground}>
            {leftSidebar}

            <Content>{children}</Content>

            {rightSidebar}
          </Main>
        </Page>

        <HeaderMobileNav offsetTop={headerHeight} />
      </AnnouncementsProvider>
    </HeaderProvider>
  );
}

const Page = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.layoutPageHeader};
  background: ${({ theme }) => theme.colors.white};
`;

const Main = styled.main<{
  _withBackground?: boolean;
}>`
  position: relative;
  flex-grow: 1;
  display: flex;
  margin-top: var(--layout-header-height);
  background: ${({ theme, _withBackground }) =>
    _withBackground ? theme.colors.background : 'transparent'};
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
