import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Check } from '@elfsight/icons';
import { Icon } from '../icons';
import { ThemeColor } from '../../theme';

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  uncheckedColor?: ThemeColor;
  checkedColor?: ThemeColor;
  label?: ReactNode;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function _Checkbox(
    { uncheckedColor, checkedColor, label, ...forwardingProps },
    forwardingRef
  ) {
    return (
      <Container>
        <CheckboxContainer>
          <Input
            ref={forwardingRef}
            {...forwardingProps}
            _uncheckedColor={uncheckedColor}
            _checkedColor={checkedColor}
            type="checkbox"
          />
          <Handle>
            <Icon component={Check} size={12} fill="white" />
          </Handle>
        </CheckboxContainer>

        {label && <Label>{label}</Label>}
      </Container>
    );
  }
);

const Container = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxContainer = styled.span`
  display: inline-block;
  position: relative;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  overflow: hidden;
`;

const Label = styled.span`
  margin-left: 8px;
`;

const Handle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const Input = styled.input<{
  checked?: boolean;
  _uncheckedColor?: ThemeColor;
  _checkedColor?: ThemeColor;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;

  ${({
    theme: { colors },
    checked,
    _uncheckedColor = 'white',
    _checkedColor = 'black'
  }) =>
    checked
      ? css`
          & + ${Handle} {
            background-color: ${colors[_checkedColor]};
            box-shadow: inset 0 0 0 1px transparent;

            svg {
              opacity: 1;
            }
          }
        `
      : css`
          & + ${Handle} {
            background-color: ${colors[_uncheckedColor]};
            box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.gray30};

            svg {
              opacity: 0;
            }
          }
        `}
`;
