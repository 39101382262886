import { Exclude, Expose } from 'class-transformer';

export const GET_PACK_PLAN_UPGRADE_ELIGIBILITY =
  'billing/get-pack-plan-upgrade-eligibility';

@Exclude()
export class GetPackPlanUpgradeEligibilityResponse {
  @Expose()
  status: PackPlanUpgradeEligibilityStatus;
}

export enum PackPlanUpgradeEligibilityStatus {
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  ELIGIBLE = 'ELIGIBLE'
}
