import { Exclude, Expose, Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsString,
  IsEmail,
  IsUUID,
  MaxLength,
  IsOptional
} from 'class-validator';

export const CREATE_WIDGET_QUOTA_EXTENSION_APPEAL_PATH =
  'appeal/create-widget-quota-extension-appeal';

export class CreateWidgetsQuotaExtensionAppealRequest {
  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail(undefined, { message: 'Email is invalid.' })
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  contactEmail: string;

  @IsNotEmpty({ message: 'New quota should be set.' })
  @Type(() => Number)
  quota: number;

  @IsString()
  @MaxLength(4096, { message: 'Must be shorter than 4096 characters.' })
  why: string;

  @IsUUID()
  @IsOptional()
  appPid?: string;
}

@Exclude()
export class CreateWidgetsQuotaExtensionAppealResponse {
  @Expose()
  pid: string;
  @Expose()
  createdAt: Date;
}
