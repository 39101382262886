import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import {
  SignUpMeta,
  SIGN_UP_META_COOKIE_NAME
} from '@elfsight-universe/service-core-contracts/analytics/sign-up-meta';

const { NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN } = process.env;

export function useSignUpMeta(meta: SignUpMeta) {
  const [, setCookie] = useCookies();
  const { isReady } = useRouter();

  const setCookieHandler = () =>
    setCookie(SIGN_UP_META_COOKIE_NAME, meta, {
      domain: NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN,
      path: '/',
      secure: true,
      sameSite: 'none'
    });

  useEffect(() => {
    if (!isReady) return;

    setCookieHandler();
    window.addEventListener('focus', setCookieHandler);
    return () => {
      window.removeEventListener('focus', setCookieHandler);
    };
  }, [isReady, meta.source]);
}
