import { Type } from 'class-transformer';
import { IsNumber } from 'class-validator';

export const DELETE_ANNOUNCEMENT_PATH = 'announcement/delete-announcement';

export class DeleteAnnouncementRequest {
  @Type(() => Number)
  @IsNumber()
  id: number;
}

export type DeleteAnnouncementResponseError = 'ANNOUNCEMENT_DOES_NOT_EXISTS';
