import { Expose } from 'class-transformer';

export class AnnouncementTag {
  @Expose()
  title: string;

  @Expose()
  alias: string;

  @Expose()
  color: string;
}
