import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { Modal, ModalProps } from './modal';
import { useDisposedInLocalStorage } from '../../utils/use-disposed-in-local-storage';

export type DisposableModalProps = PropsWithChildren<
  Omit<ModalProps, 'isOpen' | 'onRequestClose'>
> & {
  active: boolean;
  instanceId: string;
  accountPid: string | undefined;
  _debug?: boolean;
};

export type DisposableModalAPI = {
  requestClose: () => void;
};

export const DisposableModal = forwardRef<
  DisposableModalAPI,
  DisposableModalProps
>(function _DisposableModal(
  {
    children,
    active,
    instanceId,
    accountPid,
    _debug = false,
    isLoading,
    ...forwardingProps
  },
  forwardingRef
) {
  const [disposed, confirmDispose] = useDisposedInLocalStorage(
    instanceId,
    accountPid
  );

  const [isOpen, setIsOpen] = useState((active && !disposed) || _debug);
  useEffect(() => {
    setIsOpen((active && !disposed) || _debug);
  }, [active, disposed, _debug]);

  const requestClose = () => {
    setIsOpen(false);
    confirmDispose();
  };

  useImperativeHandle(forwardingRef, () => ({
    requestClose
  }));

  if (!active || isLoading) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      withClose
      onRequestClose={requestClose}
      isLoading={isLoading}
      {...forwardingProps}
    >
      {children}
    </Modal>
  );
});
