import { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { AngleLeft16 } from '@elfsight/icons';
import { Button, MOBILE, Divider, H1, P } from '@elfsight-universe/ui-common';
import { useHistory } from '@modules/_app';

export type LayoutPageHeaderProps = PropsWithChildren<{
  actions?: ReactNode;
  caption?: ReactNode;
  withDivider?: boolean;
  offset?: number | [number, number];
  withBackButton?: boolean;
  backButtonText?: string;
  onBack?: () => void;
  noWrap?: boolean;
}>;

export function LayoutPageHeader({
  children,
  actions,
  caption,
  withDivider,
  withBackButton,
  onBack,
  backButtonText = 'Back',
  offset = 36,
  noWrap = false,
  ...forwardingProps
}: LayoutPageHeaderProps) {
  const { back } = useHistory();

  return (
    <Container _offset={offset} {...forwardingProps}>
      {withBackButton && (
        <BackButton
          onClick={() => {
            onBack ? onBack() : back('/');
          }}
          leftIcon={AngleLeft16}
          variation="text"
        >
          {backButtonText}
        </BackButton>
      )}

      <TitleRow _noWrap={noWrap}>
        <H1>{children}</H1>

        {actions && <Actions>{actions}</Actions>}
      </TitleRow>

      {caption && <StyledP mt={12}>{caption}</StyledP>}

      {withDivider && <Divider _marginY={20} />}
    </Container>
  );
}

const Container = styled.div<{ _offset: number | [number, number] }>`
  width: 100%;

  ${({ _offset }) =>
    Array.isArray(_offset)
      ? css`
          margin-bottom: ${_offset[0]}px;

          ${MOBILE} {
            margin-bottom: ${_offset[1]}px;
          }
        `
      : css`
          margin-bottom: ${_offset}px;

          ${MOBILE} {
            margin-bottom: ${Math.min(_offset, 20)}px;
          }
        `};
`;

const StyledP = styled(P)`
  max-width: 760px;
`;

const BackButton = styled(Button)`
  margin-left: -8px;
  margin-bottom: 4px;
`;

const TitleRow = styled.div<{ _noWrap: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ _noWrap }) =>
    _noWrap
      ? css`
          flex-wrap: nowrap;
          gap: 0 12px;

          ${Actions} {
            align-self: flex-end;
          }
        `
      : css`
          flex-wrap: wrap;
          gap: 12px 0;
        `}
`;

const Actions = styled.div``;
