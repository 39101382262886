import { IsEnum } from 'class-validator';
import { OnboardingActionType } from './onboarding-action-type.enum';

export const DONE_ONBOARDING_ACTION_PATH = 'onboarding/done-onboarding-action';

export class DoneOnboardingActionRequest {
  @IsEnum(OnboardingActionType)
  type: OnboardingActionType;
}

export class DoneOnboardingActionResult {}
