import { Exclude, Expose, Type } from 'class-transformer';
import { OfferType } from './enums';

export const GET_FEATURED_OFFER_PATH = 'deals/get-featured-offer';

@Exclude()
export class GetFeaturedOfferResponse {
  @Expose()
  type: OfferType;

  @Expose()
  title: string;

  @Expose()
  description: string;

  @Expose()
  @Type(() => GetFeaturedOfferResponseCoupon)
  deal: {
    couponCode?: string;
    expiredAt?: Date;
  };

  @Expose()
  isButtonOffer?: boolean;
}

@Exclude()
export class GetFeaturedOfferResponseCoupon {
  @Expose()
  couponCode?: string;

  @Expose()
  expiredAt?: Date;
}
