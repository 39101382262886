import { Exclude, Expose, Type } from 'class-transformer';
import { IsString } from 'class-validator';

export const GET_APP_TEMPLATE_CATEGORIES_PATH =
  'widget/get-app-template-categories';

export class GetAppTemplateCategoriesRequest {
  @IsString()
  appAlias: string;
}

@Exclude()
export class GetAppTemplateCategoriesResponse {
  @Expose()
  @Type(() => GetAppTemplateCategoriesResponseTemplateCategory)
  payload: GetAppTemplateCategoriesResponseTemplateCategory[];
}

@Exclude()
export class GetAppTemplateCategoriesResponseTemplateCategory {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  numberOfTemplates: number;
}
