import { Exclude, Expose } from 'class-transformer';

export const GET_USER_ACCOUNTS_PATH = 'identity-and-access/get-user-accounts';

export class GetUserAccountResponseAccount {
  @Expose()
  pid: string;

  @Expose()
  email: string;

  @Expose()
  firstName: string | null;

  @Expose()
  lastName: string | null;

  @Expose()
  avatar: string | null;

  @Expose()
  isOwned: boolean;
}

@Exclude()
export class GetUserAccountsResponse {
  @Expose()
  accounts: GetUserAccountResponseAccount[];
}
