import { css } from 'styled-components';
import { DESKTOP, MOBILE } from '../breakpoints';
import { Theme } from './theme-types';

export const theme: Theme = {
  colors: {
    white: '#ffffff',
    black: '#111111',
    background: '#f8f8f8',
    gray5: 'rgba(17,17,17,0.05)',
    gray10: 'rgba(17,17,17,0.1)',
    gray30: 'rgba(17,17,17,0.3)',
    gray50: 'rgba(17,17,17,0.5)',
    gray70: 'rgba(17,17,17,0.7)',
    gray90: 'rgba(17,17,17,0.9)',
    brand: '#f93262',
    brandTransparent: 'rgba(249, 50, 98, 0.1)',
    accent: '#197bff',
    accentTransparent: 'rgba(25,123,255,0.1)',
    accentOnBlack: '#549dff',
    promo: '#8d38ff',
    promoTransparent: 'rgba(141,56,255,0.1)',
    promoOnBlack: '#af73ff',
    success: '#0ec12f',
    successTransparent: 'rgba(14, 193, 47, 0.1)',
    warning: '#ffa000',
    warningTransparent: 'rgba(255, 160, 0, 0.1)',
    alert: '#ff5342',
    alertTransparent: 'rgba(255, 83, 66, 0.1)',
    transparent: 'rgba(255,255,255,0)',
    tooltip: '#3d3d3d',
    avatar: '#ef3e69',
    blackFriday: 'rgba(254, 224, 2)',
    blackFridayTransparent: 'rgba(254, 224, 2, 0.1)',
    xmas: 'rgba(165, 15, 0)',
    xmas2: 'rgba(255, 197, 49)',
    xmasRed: '#cb2c1d',
    birthdaySecondary: '#fcfaff',
    instantExtensionSuccess: '#119d30',
    instantExtensionLimitAccent: '#fee002'
  },
  font: {
    base: css`
      font: 400 normal 14px/18px system-ui, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    `,
    code: css`
      font: 400 normal 14px/18px source-code-pro, Menlo, Monaco, Consolas,
        Courier New, monospace;
    `,
    title0: css`
      font-size: 30px;
      line-height: 36px;
      font-weight: 500;
    `,
    title1: css`
      ${DESKTOP} {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
      }
      ${MOBILE} {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
      }
    `,
    title2: css`
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    `,
    title3: css`
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    `,
    title3Semibold: css`
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    `,
    title3Bold: css`
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
    `,
    title4: css`
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    `,
    title4Semibold: css`
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
    `,
    text: css`
      font-size: 14px;
      line-height: 18px;
    `,
    text2: css`
      font-size: 16px;
      line-height: 22px;
    `,
    caption: css`
      font-size: 13px;
      line-height: 16px;
    `,
    captionBold: css`
      font-size: 13px;
      line-height: 16px;
      font-weight: 600;
    `,
    captionUppercase: css`
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      font-weight: 500;
    `,
    captionSmall: css`
      font-size: 11px;
      line-height: 12px;
    `,
    captionSmallUppercase: css`
      font-size: 11px;
      line-height: 12px;
      text-transform: uppercase;
      font-weight: 600;
    `
  },
  zIndex: {
    appUsageBlocked: 500,
    incipientWidgetLimitReachedModal: 900,
    layoutPageHeader: 1000,
    popoverDefault: 1100,
    modalDefault: 1100,
    installModal: 1030,
    tooltip: 1100,
    paddleFrame: 1100,
    subscriptionSuccessModal: 1110,
    beacon: 100000,
    offerSlideIn: 100000,
    beaconNotification: 100000,
    coupon: 100001,
    toast: 100001,
    headerNavigationMobile: 100010,
    selectAppModalBottomSheet: 100010,
    headerOnboardingMobile: 100020,
    announcementsMobile: 100020,
    contextMenuDropdown: 100030,
    videoPlayerModal: 100100,
    impersonateSessionFrame: 1000000
  }
} as const;
