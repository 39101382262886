import { MouseEventHandler } from 'react';

export enum PaginationItemType {
  END_ELLIPSIS = 'END_ELLIPSIS',
  START_ELLIPSIS = 'START_ELLIPSIS',
  PREVIOUS = 'PREVIOUS',
  NEXT = 'NEXT',
  FIRST = 'FIRST',
  LAST = 'LAST',
  PAGE = 'PAGE'
}

export type PaginationItemParams = {
  disabled: boolean;
  page?: number;
  selected: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  type: PaginationItemType;
};

export type PaginationParams = {
  count: number;
  onPageChange: (page: number) => void;
  page: number;
  boundaryCount?: number;
  siblingCount?: number;
  disabled?: boolean;
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
  showFirstButton?: boolean;
  showLastButton?: boolean;
};
