import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { ThemeColor } from '../../theme';
import { ControlProps } from './control-types';
import {
  ControlBaseInner,
  ControlCaption,
  ControlContainer,
  ControlError,
  ControlLabel
} from './control-layout';
import { Radio } from './radio';

export type RadioGroupOption = {
  value: string;
  label: string;
  caption?: string;
};

export type RadioGroupProps = InputHTMLAttributes<HTMLInputElement> &
  ControlProps<{
    value: string;
    uncheckedColor?: ThemeColor;
    checkedColor?: ThemeColor;
    options: RadioGroupOption[];
  }>;

export function RadioGroup({
  uncheckedColor,
  checkedColor,
  options,
  label,
  captionTop,
  captionBottom,
  error,
  value,
  onChange,
  name,
  ...forwardingProps
}: RadioGroupProps) {
  return (
    <ControlContainer {...forwardingProps}>
      {label && <ControlLabel>{label}</ControlLabel>}
      {captionTop && <ControlCaption>{captionTop}</ControlCaption>}

      <ControlBaseInner>
        {options.map((option) => (
          <GroupItem key={option.value}>
            <Radio
              checked={value ? value.includes(option.value) : false}
              onChange={onChange}
              name={name}
              uncheckedColor={uncheckedColor}
              checkedColor={checkedColor}
              caption={option.caption}
              {...option}
            />
          </GroupItem>
        ))}
      </ControlBaseInner>

      {captionBottom && <ControlCaption>{captionBottom}</ControlCaption>}
      {error && <ControlError>{error}</ControlError>}
    </ControlContainer>
  );
}

const GroupItem = styled.div`
  padding: 6px 0;

  &:last-of-type {
    padding-bottom: 0;
  }
`;
