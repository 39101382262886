import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';

// @TODO refactor

type ListItemProps = {
  _offset?: number;
  _intractable?: boolean;
  _isInteracting?: boolean;
};

export const ListItem: StyledComponent<'div', DefaultTheme, ListItemProps> =
  styled.div<{
    _offset?: number;
    _intractable?: boolean;
    _isInteracting?: boolean;
  }>`
  display: flex;
  padding: 16px 0;
  cursor: ${({ _intractable = true }) => (_intractable ? 'pointer' : 'default')};
  position: relative;
  text-decoration: none;
  transition: border-color 0.2s;
  outline: none;
  &,
  & + & {
    border-top-color: transparent;
  }

  &:after {
    position: absolute;
    top: 0;
    left: -${({ _offset = 12 }) => _offset}px;
    right: -${({ _offset = 12 }) => _offset}px;
    bottom: 0;
    border-radius: 4px;
    transition: background-color 0.2s;
  }

  ${({ theme, _intractable = true }) =>
    _intractable &&
    css`
      &:after {
        content: '';
      }

      &:focus:after,
      &:hover:after {
        background-color: ${theme.colors.gray5};
      }

      &:focus,
      &:hover {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
      }

      &:focus + ${ListItem}, &:hover + ${ListItem} {
        border-top-color: transparent;
      }
    `}

  ${({ theme, _isInteracting = false }) => css`
    &,
    & + ${ListItem} {
      border-top: 1px solid
        ${_isInteracting ? 'transparent' : theme.colors.gray5};
    }

    &:last-child {
      border-bottom: 1px solid
        ${_isInteracting ? 'transparent' : theme.colors.gray5};
    }

    &:after {
      background-color: ${_isInteracting ? theme.colors.gray5 : 'transparent'};
    }
  `}
`;
