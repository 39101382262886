import { Exclude, Expose } from 'class-transformer';

export const GET_OCCUPATIONS_PATH = 'identity-and-access/get-occupations';

@Exclude()
export class GetOccupationsResponse {
  @Expose()
  payload: GetOccupationsItem[];
}

@Exclude()
export class GetOccupationsItem {
  @Expose()
  pid: string;

  @Expose()
  alias: string;

  @Expose()
  value: string;
}
