import { useQuery } from '@tanstack/react-query';
import {
  GET_ACCOUNT_DEALS_PATH,
  GetAccountDealsResponse
} from '@elfsight-universe/service-core-contracts/deals';
import { useUser } from '@modules/_app';
import { useEvents } from '@modules/_event-bus';
import { client } from '../client';

export function useDealsQuery() {
  const { isAuthenticated } = useUser();
  const query = useQuery<GetAccountDealsResponse>(
    [GET_ACCOUNT_DEALS_PATH],
    () => client.get(GET_ACCOUNT_DEALS_PATH).json<GetAccountDealsResponse>(),
    {
      enabled: isAuthenticated
    }
  );

  useEvents(['DealsUpdated'], () => query.refetch());

  return query;
}
