import { Exclude, Expose } from 'class-transformer';

export const GET_PROJECTS_FEATURE_SUMMARY_PATH =
  'project/get-projects-feature-summary';

@Exclude()
export class GetProjectsFeatureSummaryResponse {
  @Expose()
  available: boolean;

  @Expose()
  visited: boolean;

  @Expose()
  hasProject: boolean;

  @Expose()
  hasProjectWithWidget: boolean;
}
