import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useWebStorage } from '@elfsight-universe/react-web-storage';

export enum ScheduledToastType {
  ACCOUNT_SWITCHED = 'ACCOUNT_SWITCHED'
}

export interface UseScheduledToastProps {
  type: ScheduledToastType;
}

export interface ScheduledToastData {
  message?: string;
}

export interface UseScheduledToastResult {
  trigger: (message?: string) => void;
  schedule: (message?: string) => void;
  isScheduled: boolean;
  data?: ScheduledToastData;
}

const SESSION_KEY_PREFIX = 'SCHEDULED_TOAST';

export function useScheduledToast({
  type
}: UseScheduledToastProps): UseScheduledToastResult {
  const [isScheduled, setIsScheduled] = useState(false);
  const {
    clearStored: clearToastScheduling,
    storeValue: setScheduledToast,
    storedValue: scheduledToastData,
    isReady
  } = useWebStorage<ScheduledToastData>({
    key: `${SESSION_KEY_PREFIX}-${type}`,
    type: 'sessionStorage'
  });

  useEffect(() => {
    if (!scheduledToastData) {
      return;
    }

    setIsScheduled(true);
  }, [isReady, scheduledToastData]);

  const trigger = (message?: string) => {
    toast(message || scheduledToastData?.message || '');
    setIsScheduled(false);
    clearToastScheduling();
  };

  const schedule = (message?: string) =>
    setScheduledToast({
      message
    });

  return {
    trigger,
    schedule,
    isScheduled,
    data: scheduledToastData
  };
}
