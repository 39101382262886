import { Exclude, Expose } from 'class-transformer';

export const GET_COLLABORATORS_USAGE_PATH = 'iam/get-collaborators-usage';

@Exclude()
export class GetCollaboratorsUsageResponse {
  @Expose()
  collaboratorsLimit: number;

  @Expose()
  collaboratorsCount: number;

  @Expose()
  collaboratorsUsage: number;
}
