export type OnboardingCircularProgressProps = {
  progress: number;
  strokeWidth?: number;
  size?: number;
  color?: string;
};

export function OnboardingCircularProgress({
  progress,
  strokeWidth = 2,
  size = 20,
  color = '#197bff'
}: OnboardingCircularProgressProps) {
  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = circumference - (progress / 100) * circumference;
  const strokeLinecap = progress === 100 || progress === 0 ? 'butt' : 'round';

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        stroke="#1111111a"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        stroke={color}
        fill="transparent"
        strokeLinecap={strokeLinecap}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={dash}
        style={{
          transition: 'stroke-dashoffset 0.5s ease-in-out'
        }}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        transform={`rotate(${-90} ${size / 2} ${size / 2})`}
      />
    </svg>
  );
}
