import { useCallback, useEffect, useState } from 'react';
import kebabCase from 'lodash/kebabCase';

const PREFIX = 'elfsight';

type UseWebStorageAPI<D = unknown> = {
  storeValue: (value: D) => void;
  clearStored: () => void;
};

export type UseWebStorageOnReady<D = unknown> = (
  value: D | undefined,
  api: UseWebStorageAPI<D>
) => void;

type UseWebStorageProps<D = unknown> = {
  key: string;
  defaultValue?: D;
  onReady?: UseWebStorageOnReady<D>;
  type?: 'localStorage' | 'sessionStorage';
};

export const useWebStorage = <D = unknown>({
  key,
  defaultValue = undefined,
  onReady,
  type = 'localStorage'
}: UseWebStorageProps<D>) => {
  const [isReady, setReady] = useState(false);
  const [storedValue, setStoredValue] = useState<D | undefined>(defaultValue);
  const _key = kebabCase(`${PREFIX}-${key}`);

  let storage: Storage;

  useEffect(() => {
    try {
      storage = {
        localStorage: window.localStorage,
        sessionStorage: window.sessionStorage
      }[type];

      const item = storage.getItem(_key);
      const finalItem = item ? JSON.parse(item) : undefined;

      onReady && onReady(finalItem || defaultValue, api);
      setReady(true);
      setStoredValue(finalItem || defaultValue);
    } catch (error) {
      console.log(error);

      onReady && onReady(defaultValue, api);
      setReady(true);
      setStoredValue(defaultValue);
    }
  }, [_key, onReady]);

  const storeValue = useCallback(
    (value: D) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);
        storage.setItem(_key, JSON.stringify(valueToStore));
      } catch (error) {
        console.log(error);
      }
    },
    [_key]
  );

  const clearStored = useCallback(() => {
    try {
      setStoredValue(defaultValue);
      storage.removeItem(_key);
    } catch (error) {}
  }, [_key, defaultValue]);

  const api: UseWebStorageAPI<D> = { storeValue, clearStored };

  return { storedValue, isReady, ...api };
};
