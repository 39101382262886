export enum IdentityAndAccessError {
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  AUTH_IDENTITY_DOES_NOT_EXIST = 'AUTH_IDENTITY_DOES_NOT_EXIST',
  PASSWORD_DOES_NOT_MATCH = 'PASSWORD_DOES_NOT_MATCH',
  USER_DOES_NOT_EXISTS = 'USER_DOES_NOT_EXISTS',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  ACCOUNT_DOES_NOT_EXIST = 'ACCOUNT_DOES_NOT_EXIST',
  PROFILE_PICTURE_DOES_NOT_MEET_REQUIREMENTS = 'PROFILE_PICTURE_DOES_NOT_MEET_REQUIREMENTS',
  ACCOUNT_HAS_PAID_SUBSCRIPTION = 'ACCOUNT_HAS_PAID_SUBSCRIPTION',
  USER_IS_ALREADY_VERIFIED = 'USER_IS_ALREADY_VERIFIED',
  USER_DELETED = 'USER_DELETED',
  USER_PREFERENCE_NOT_EXISTS = 'USER_PREFERENCE_NOT_EXISTS',
  INDUSTRY_DOES_NOT_EXISTS = 'INDUSTRY_DOEN_NOT_EXISTS',
  OCCUPATION_DOES_NOT_EXISTS = 'OCCUPATION_DOES_NOT_EXISTS',
  PREFERRED_MONETIZATION_DOES_NOT_EXISTS = 'PREFERRED_MONETIZATION_DOES_NOT_EXISTS',
  TRANSITION_TOKEN_IS_INVALID = 'TRANSITION_TOKEN_IS_INVALID',
  MINDBOX_TICKET_IS_INVALID = 'MINDBOX_TICKET_IS_INVALID',
  ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT = 'ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT',
  EXTERNAL_ACCOUNT_HAS_NO_EMAIL = 'EXTERNAL_ACCOUNT_HAS_NO_EMAIL',
  USER_CANNOT_LOG_IN_USING_THIS_METHOD = 'USER_CANNOT_LOG_IN_USING_THIS_METHOD'
}
