import { Exclude, Expose, Type } from 'class-transformer';

export const GET_PACK_APPS_LIST_WITH_CATEGORIES_PATH =
  'widget/get-pack-apps-list-with-categories';

@Exclude()
export class GetPackAppsListWithCategoriesResponse {
  @Expose()
  @Type(() => GetPackAppsListWithCategoriesResponseApp)
  payload: GetPackAppsListWithCategoriesResponseApp[];
}

@Exclude()
export class GetPackAppsListWithCategoriesResponseApp {
  @Expose()
  @Type(() => GetPackAppsListWithCategoriesResponseAppCategory)
  category: {
    alias: string;
    name: string;
  };

  @Expose()
  @Type(() => GetPackAppsListWithCategoriesResponseApps)
  apps: {
    alias: string;
    name: string;
  }[];
}

@Exclude()
export class GetPackAppsListWithCategoriesResponseApps {
  @Expose()
  alias: string;

  @Expose()
  name: string;
}

@Exclude()
export class GetPackAppsListWithCategoriesResponseAppCategory {
  @Expose()
  alias: string;

  @Expose()
  name: string;
}
