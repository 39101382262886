import { useCallback, useEffect, useRef, useState, UIEvent } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useRect } from 'react-use-rect';
import styled, { css } from 'styled-components';
import { TabsNavItem } from './tabs-nav-item';
import { TabsNavArrow } from './tabs-nav-arrow';
import { useTabs } from './tabs-provider';
import { DESKTOP, MOBILE } from '../../breakpoints';

export type TabsNavProps = {
  height?: number;
  withBorder?: boolean;
  largeItems?: boolean;
  lightItems?: boolean;
  itemsHeight?: number;
  withBoldTextWidthFix?: boolean;
};

export function TabsNav({
  height,
  withBorder,
  itemsHeight,
  largeItems = false,
  lightItems = false,
  withBoldTextWidthFix = false,
  ...forwardingProps
}: TabsNavProps) {
  const { tabs, activeTab, setActiveTab } = useTabs();
  const tabsNavItemRefs = useRef<(HTMLDivElement | null)[]>(
    tabs.map(() => null)
  );
  const activeTabIndex = tabs.findIndex(({ id }) => id === activeTab);

  const [showArrows, setShowArrows] = useState([false, false]);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showThumb, setShowThumb] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const [innerWidth, setInnerWidth] = useState(0);
  const [innerLeft, setInnerLeft] = useState(0);
  const [innerMeasureRef] = useRect(
    (rect) => {
      setInnerWidth(rect.width);
      setInnerLeft(rect.left);
    },
    { resize: true }
  );

  const [thumbMatrix, setThumbMatrix] = useState<
    undefined | { width: number; position: number }
  >(undefined);

  const moveTabSelectionBar = useCallback(
    (rect: DOMRect) => {
      setThumbMatrix({
        width: rect.width / innerWidth,
        position: rect.left - innerLeft + scrollLeft
      });
    },
    [innerWidth, innerLeft, scrollLeft]
  );

  const handleScroll = ({ currentTarget }: UIEvent<HTMLDivElement>) => {
    setScrollLeft(currentTarget.scrollLeft);
    setShowArrows([
      currentTarget.scrollLeft > 0,
      currentTarget.scrollWidth - currentTarget.scrollLeft >
        Math.round(innerWidth)
    ]);
  };

  const moveToElement = (element: HTMLElement | null) => {
    if (!innerRef.current || !containerRef.current || !element) return;

    const scrollLeft =
      element.offsetLeft +
      element.offsetWidth / 2 -
      containerRef.current.offsetWidth / 2;

    innerRef.current.scrollTo({
      left: scrollLeft,
      behavior: 'smooth'
    });
  };

  const handleMove = (moveToDirection: 1 | -1) => () => {
    const newTab = tabs[activeTabIndex + moveToDirection];
    if (newTab) {
      setActiveTab(newTab.id);
    }
  };

  useEffect(() => {
    moveToElement(tabsNavItemRefs.current[activeTabIndex]);
  }, [activeTabIndex]);

  useEffect(() => {
    setTimeout(() => {
      setShowThumb(true);
    }, 600);
  }, []);

  useEffect(() => {
    if (!innerRef.current) return;
    setShowArrows([
      innerRef.current.scrollLeft > 0,
      innerRef.current.scrollWidth - innerRef.current.scrollLeft >
        Math.round(innerWidth)
    ]);
  }, [innerWidth]);

  const thumbMatrixTransform = thumbMatrix
    ? `matrix(${thumbMatrix.width}, 0, 0, 1, ${thumbMatrix.position}, 1)`
    : '';
  const thumbTranslateTransform = `translateY(${showThumb ? 0 : 4}px)`;

  return (
    <Container
      ref={containerRef}
      _withBorder={withBorder}
      _height={height}
      {...forwardingProps}
    >
      <Wrapper>
        {showArrows[0] && <TabsNavArrow left onClick={handleMove(-1)} />}

        <ScrollableInner
          ref={mergeRefs([innerRef, innerMeasureRef])}
          onScroll={handleScroll}
        >
          <Thumb
            style={{
              opacity: showThumb ? 1 : 0,
              transform: `${thumbMatrixTransform} ${thumbTranslateTransform}`
            }}
          />

          <Items role="tablist">
            {tabs.map(({ title, id, alert }, i) => (
              <TabsNavItem
                role="tab"
                ref={(element) => (tabsNavItemRefs.current[i] = element)}
                key={id}
                id={id}
                onStateChange={moveTabSelectionBar}
                large={largeItems}
                light={lightItems}
                height={itemsHeight}
                withBoldTextWidthFix={withBoldTextWidthFix}
                boldText={String(title)}
                alert={alert}
              >
                {title}
              </TabsNavItem>
            ))}
          </Items>
        </ScrollableInner>

        {showArrows[1] && <TabsNavArrow right onClick={handleMove(1)} />}
      </Wrapper>
    </Container>
  );
}

const Container = styled.nav<{ _height?: number; _withBorder?: boolean }>`
  position: relative;
  height: ${({ _height }) => (_height ? `${_height}px` : '100%')};
  min-width: 0;

  ${({ _withBorder, theme }) =>
    _withBorder &&
    css`
      ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        z-index: 1;
        background: ${theme.colors.gray10};
      }
    `}
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ScrollableInner = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0 12px;
  margin: 0 -12px;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Items = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;

  ${DESKTOP} {
    gap: 0 32px;
  }
  ${MOBILE} {
    gap: 0 24px;
  }
`;

export const Thumb = styled.div`
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  height: 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.black};
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
`;
