import { useQuery } from '@tanstack/react-query';
import {
  GetProjectsFeatureSummaryResponse,
  GET_PROJECTS_FEATURE_SUMMARY_PATH
} from '@elfsight-universe/service-core-contracts/project';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useProjectsFeatureSummaryQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetProjectsFeatureSummaryResponse>(
    [GET_PROJECTS_FEATURE_SUMMARY_PATH],
    () =>
      client
        .get(GET_PROJECTS_FEATURE_SUMMARY_PATH)
        .json<GetProjectsFeatureSummaryResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
