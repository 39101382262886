import { Transform } from 'class-transformer';
import {
  IsDate,
  IsOptional,
  IsPhoneNumber,
  IsString,
  IsUrl,
  IsUUID,
  MaxLength,
  ValidateIf,
  isNotEmpty
} from 'class-validator';
import { IdentityAndAccessError } from '../errors';

export const UPDATE_PROFILE_INFO_PATH =
  'identity-and-access/update-profile-info';

export class UpdateProfileInfoRequest {
  @IsString()
  @IsOptional()
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  firstName?: string;

  @IsString()
  @IsOptional()
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  lastName?: string;

  @IsUUID()
  @IsOptional()
  occupationPid?: string;

  @IsUUID()
  @IsOptional()
  industryPid?: string;

  @IsUUID()
  @IsOptional()
  preferredMonetizationPid?: string;

  @Transform(({ value }) => (isNotEmpty(value) ? new Date(value) : null))
  @IsOptional()
  @IsDate({ message: 'Must be a valid date.' })
  birthday?: string | null;

  @IsOptional()
  @ValidateIf((e: Pick<UpdateProfileInfoRequest, 'phone'>) => e.phone !== '')
  @IsPhoneNumber(undefined, {
    message:
      'Please enter a valid phone number including country code (e.g. +18141234567).'
  })
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  phone?: string;

  @IsOptional()
  @ValidateIf(
    (e: Pick<UpdateProfileInfoRequest, 'website'>) => e.website !== ''
  )
  @IsUrl(undefined, { message: 'Please enter a valid URL.' })
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  website?: string;
}

export type UpdateProfileInfoResponseError =
  | IdentityAndAccessError.OCCUPATION_DOES_NOT_EXISTS
  | IdentityAndAccessError.INDUSTRY_DOES_NOT_EXISTS
  | IdentityAndAccessError.PREFERRED_MONETIZATION_DOES_NOT_EXISTS;
