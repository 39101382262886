import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty, IsString } from 'class-validator';
import { AccountDeal } from './classes';

export const GET_ACCOUNT_DEAL_BY_ALIAS_PATH = 'deals/get-account-deal-by-alias';

export class GetAccountDealByAliasRequest {
  @IsString()
  @IsNotEmpty()
  alias: string;
}

@Exclude()
export class GetAccountDealByAliasResponse {
  @Expose()
  @Type(() => GetAccountDealByAliasResponseDeal)
  accountDeal: GetAccountDealByAliasResponseDeal | null;
}

export class GetAccountDealByAliasResponseDeal extends AccountDeal {}
