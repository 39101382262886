import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  DELETE_PROJECT_PATH,
  DeleteProjectResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useDeleteProjectMutation(
  pid: string,
  options: UseMutationOptions<Response, DeleteProjectResponseError> = {}
) {
  return useMutation<Response, DeleteProjectResponseError>(
    () => client.post(DELETE_PROJECT_PATH, { json: { pid } }),
    {
      retry: false,
      ...options
    }
  );
}
