export enum PlanTab {
  SINGLE = 'single',
  PACKS = 'packs'
}

export enum CTAClaim {
  REMOVE_BRANDING = 'REMOVE_BRANDING',
  UPGRADE_PLAN = 'UPGRADE_PLAN'
}

export const APP_PARAM = 'app';
export const BILLING_INTERVAL_PARAM = 'interval';
export const CLAIM_PARAM = 'claim';
export const FLOW_PARAM = 'flow';
export const REDIRECT_BACK_PARAM = 'redirectBack';
export const REDIRECT_URL_PARAM = 'redirectURL';
export const HEADER_CLOSE_URL_PARAM = 'headerCloseURL';
export const ENTERPRISE_PARAM = 'enterprise';
export const SUBSCRIPTION_PID = 'subscriptionPid';

export const APP_ALIAS_SLUG = 'alias';

export const UPGRADE_PLAN_CLICK_EVENT_NAME = 'elfsight_upgrade_plan_click';
