import { IsUUID } from 'class-validator';
import { BillingError } from '../errors';

export const EXTEND_SUBSCRIPTION_INSTANTLY_PATH =
  'billing/extend-subscription-instantly';

export class ExtendSubscriptionInstantlyRequest {
  @IsUUID()
  pid: string;
}

export type ExtendSubscriptionInstantlyResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANNOT_BE_INSTANTLY_EXTENDED;
