import { useMemoizedCSRFToken } from './use-memoized-csrf-token';

export interface InjectCSRFToken {
  (request: Request): Promise<void>;
}

export function useInjectCSRFToken(
  getCSRFTokenEndpointURL: string,
  headerName: string
): InjectCSRFToken {
  const getToken = useMemoizedCSRFToken(getCSRFTokenEndpointURL);

  return async function injectCSRFToken(request: Request) {
    if (request.method !== 'GET' && request.method !== 'OPTIONS') {
      const token = await getToken();
      if (token) {
        request.headers.set(headerName, token);
      }
    }
  };
}
