import styled from 'styled-components';
import { Typography } from '@elfsight-universe/ui-common';

export function AnnouncementsNewDivider({ ...forwardingProps }) {
  return (
    <Container {...forwardingProps}>
      <Typography variant="captionBold" color="gray30" alignCenter>
        Earlier
      </Typography>
    </Container>
  );
}

const Container = styled.div`
  padding: 2px 0 4px;
  text-align: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
`;
