import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  PUBLISH_UNPUBLISHED_WIDGET_REVISION_PATH,
  PublishUnpublishedWidgetRevisionResponseError,
  PublishUnpublishedWidgetRevisionRequest
} from '@elfsight-universe/service-core-contracts/widget/publish-unpublished-widget-revision';
import { client } from '../client';

export function usePublishUnpublishedRevisionMutation(
  widgetPid: string,
  options: UseMutationOptions<
    Response,
    PublishUnpublishedWidgetRevisionResponseError,
    Pick<PublishUnpublishedWidgetRevisionRequest, 'settings'>
  >
) {
  return useMutation<
    Response,
    PublishUnpublishedWidgetRevisionResponseError,
    Pick<PublishUnpublishedWidgetRevisionRequest, 'settings'>
  >(
    [PUBLISH_UNPUBLISHED_WIDGET_REVISION_PATH, widgetPid],
    ({ settings } = {}) =>
      client.post(PUBLISH_UNPUBLISHED_WIDGET_REVISION_PATH, {
        json: { widgetPid, settings }
      }),
    {
      retry: false,
      ...options
    }
  );
}
