import { Exclude, Expose, Type } from 'class-transformer';

export const GET_ALL_CATEGORIES_PATH = 'widget/get-all-categories';

@Exclude()
export class GetAllCategoriesResponse {
  @Expose()
  @Type(() => GetAllCategoriesResponseCategory)
  payload: GetAllCategoriesResponseCategory[];
}

@Exclude()
export class GetAllCategoriesResponseCategory {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  color: string;

  @Expose()
  icon: string;

  @Expose()
  order: string;

  @Expose()
  alias: string;
}
