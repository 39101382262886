import {
  cloneElement,
  ReactNode,
  ReactElement,
  CSSProperties,
  forwardRef,
  useState,
  useEffect
} from 'react';
import {
  useFloating,
  autoUpdate,
  offset as offsetMiddleware,
  flip,
  shift,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
  useTransitionStyles,
  size,
  Placement,
  Strategy,
  FloatingFocusManagerProps
} from '@floating-ui/react';
import styled from 'styled-components';
import { createDropdownSlideInAnimation } from './create-dropdown-slide-in-animation';
import { useTheme } from '../../theme';

const OFFSET = 4;

type PopoverOptions = {
  content: ReactNode;
  placement?: Placement;
  isOpen: boolean;
  onOpenChange?: (open: boolean) => void;
  containerStyle?: Partial<CSSProperties>;
  children: ReactElement;
  preventFocus?: boolean;
  offset?: number;
  maxHeight?: number;
  fitInViewPort?: boolean;
  getPopoverHeight?: (height: number) => void;
  focusManagerProps?: Partial<FloatingFocusManagerProps>;
  floatingStrategy?: Strategy;
};

export const Popover = forwardRef<HTMLDivElement, PopoverOptions>(
  function Popover(
    {
      content,
      placement = 'bottom-end',
      isOpen,
      onOpenChange,
      containerStyle,
      preventFocus = false,
      offset = OFFSET,
      maxHeight,
      fitInViewPort = false,
      getPopoverHeight,
      focusManagerProps,
      floatingStrategy,
      children,
      ...forwardingProps
    },
    forwardingRef
  ) {
    const { zIndex: themeZIndex } = useTheme();

    const [availableMaxHeight, setAvailableMaxHeight] = useState(0);

    useEffect(() => {
      getPopoverHeight && getPopoverHeight(availableMaxHeight);
    }, [availableMaxHeight]);

    const {
      context: floatingContext,
      refs: floatingRefs,
      floatingStyles
    } = useFloating({
      placement,
      open: isOpen,
      onOpenChange,
      strategy: floatingStrategy,
      whileElementsMounted: autoUpdate,
      middleware: [
        offsetMiddleware(offset),
        flip({ padding: 8 }),
        size({
          apply: ({ elements, availableHeight }) => {
            const finalMaxHeight =
              maxHeight && maxHeight < availableHeight
                ? maxHeight
                : availableHeight;

            setAvailableMaxHeight(finalMaxHeight);

            if (fitInViewPort || !!maxHeight) {
              Object.assign(elements.floating.style, {
                maxHeight: `${finalMaxHeight}px`
              });
            }
          },
          padding: 10
        }),
        shift({ padding: 5 })
      ]
    });

    const dismiss = useDismiss(floatingContext);
    const role = useRole(floatingContext);

    const { getFloatingProps, getReferenceProps } = useInteractions([
      dismiss,
      role
    ]);

    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([floatingRefs.setReference, childrenRef]);

    const floatingMergedRefs = useMergeRefs([
      floatingRefs.setFloating,
      forwardingRef
    ]);

    const trigger = cloneElement(
      children,
      getReferenceProps({
        ref,
        ...children.props
      })
    );

    const { isMounted, styles: transitionStyles } = useTransitionStyles(
      floatingContext,
      createDropdownSlideInAnimation()
    );

    if (!content) {
      return children;
    }

    return (
      <>
        {trigger}

        {isMounted && (
          <FloatingPortal>
            <FloatingFocusManager
              context={floatingContext}
              modal={false}
              initialFocus={preventFocus ? -1 : undefined}
              {...focusManagerProps}
            >
              <Content
                ref={floatingMergedRefs}
                style={{
                  ...floatingStyles,
                  ...transitionStyles,
                  zIndex: themeZIndex.popoverDefault.toString(),
                  ...containerStyle
                }}
                {...getFloatingProps()}
                {...forwardingProps}
              >
                {content}
              </Content>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </>
    );
  }
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
`;
