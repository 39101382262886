import { Exclude, Expose, Type } from 'class-transformer';
import { IsBoolean, IsString } from 'class-validator';
import { WidgetError } from '../errors';
import { BillingInterval, PlanGrade, PlanType } from './enums';
import { PlanPolicy } from './classes';
import { getTransformClassForPlanProps } from './utils';
import { TransformBool } from '../transform-bool';

export const GET_SINGLE_APP_PLANS_PATH = 'billing/get-single-app-plans';

export class GetSingleAppPlansRequest {
  @IsString()
  appAlias: string;

  @TransformBool()
  @IsBoolean()
  withoutLitePlans: boolean;
}

@Exclude()
export class GetSingleAppPlansResponse {
  @Expose()
  @Type(() => GetSingleAppPlansResponsePlan)
  payload: GetSingleAppPlansResponsePlan[];
}

@Exclude()
export class GetSingleAppPlansResponsePlan {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  type: PlanType;

  @Expose()
  @Type(getTransformClassForPlanProps)
  properties: {
    grade: PlanGrade;
    billingInterval: BillingInterval;
    price: number;
    paddleId?: number;
    hidden?: boolean;
  };

  @Expose()
  @Type(() => PlanPolicy)
  policy: {
    viewsLimit: number | null;
    widgetsLimit: number;
    projectsLimit: number;
    collaboratorsLimit: number;
  };

  @Expose()
  caption?: string;

  @Expose()
  highlight?: boolean;

  @Expose()
  pricePerMonth: number;

  @Expose()
  pricePerMonthDiscounted?: number;

  @Expose()
  pricePerWidget: number;

  @Expose()
  discountTotal?: number;

  @Expose()
  discountPercentage?: number;

  @Expose()
  isEnterprisePlan: boolean;
}

export type GetSingleAppPlansResponseError = WidgetError.APP_DOES_NOT_EXIST;
