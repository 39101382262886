import { Exclude, Expose } from 'class-transformer';

export const SIGN_IN_BY_GOOGLE_PATH = 'login/google';
export const SIGN_IN_BY_FACEBOOK_PATH = 'login/facebook';
export const SIGN_IN_BY_GOOGLE_ID_TOKEN = 'login/google-id-token';

export enum SignInByExternalAccountCallbackEventType {
  SIGN_IN_FAILURE = 'SIGN_IN_FAILURE',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
}

export type SignInByExternalAccountCallbackEvent =
  | {
      email?: string;
      type: SignInByExternalAccountCallbackEventType.SIGN_IN_FAILURE;
      reason:
        | 'NOT_VERIFIED'
        | 'USER_DELETED'
        | 'ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT'
        | 'EXTERNAL_ACCOUNT_HAS_NO_EMAIL';
    }
  | {
      email: string;
      type: SignInByExternalAccountCallbackEventType.SIGN_IN_SUCCESS;
      isNewSignUp?: boolean;
    };

@Exclude()
export class SignInByGoogleIDTokenResponse {
  @Expose()
  isNewSignUp?: boolean;
}

export type SignInByGoogleIDTokenResponseError =
  | 'NOT_VERIFIED'
  | 'USER_DELETED'
  | 'ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT';
