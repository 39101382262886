import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useIsMobile } from '@modules/_app';

type LayoutSidebarProps = PropsWithChildren<{
  withBackground?: boolean;
  width?: number;
  padding?: number | [number, number];
}>;

export function LayoutSidebar({
  children,
  width,
  withBackground,
  padding = 12,
  ...forwardingProps
}: LayoutSidebarProps) {
  const isMobile = useIsMobile();

  if (isMobile || isMobile === undefined) {
    return null;
  }

  return (
    <Container
      {...forwardingProps}
      _width={width}
      _withBackground={withBackground}
    >
      <Inner _top={0} _padding={padding}>
        {children}
      </Inner>
    </Container>
  );
}

const Container = styled.aside<{ _withBackground?: boolean; _width?: number }>`
  position: relative;
  display: flex;
  background: ${({ theme, _withBackground }) =>
    _withBackground ? theme.colors.background : 'transparent'};
  box-sizing: border-box;
  flex: 0 0 ${({ _width = 300 }) => _width}px;
`;

const Inner = styled.div<{ _top: number; _padding: number | [number, number] }>`
  padding: ${({ _padding }) =>
    Array.isArray(_padding)
      ? `${_padding[0]}px ${_padding[1]}px`
      : `${_padding}px`};
  box-sizing: border-box;
  top: ${({ _top }) => _top}px;
  flex-grow: 1;
`;
