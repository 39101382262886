import { Exclude, Expose, Type } from 'class-transformer';

export const GET_INSTALLED_APPS_PATH = 'widget/get-installed-apps';

@Exclude()
export class GetInstalledAppsResponse {
  @Expose()
  @Type(() => GetInstalledAppsResponseApp)
  payload: GetInstalledAppsResponseApp[];
}

@Exclude()
export class GetInstalledAppsResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  icon: string;

  @Expose()
  isLastActive: boolean;

  @Expose()
  @Type(() => GetInstalledAppsResponseCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetInstalledAppsResponseCategory {
  @Expose()
  color: string;
}
