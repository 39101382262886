import { useState } from 'react';
import { useActivateSubscriptionMutation } from '@api';

export function useActivateSubscription(
  onSuccess: () => void
): [boolean, (subscriptionPid: string, checkoutId: string) => void] {
  const [isLoading, setLoading] = useState(false);

  const { mutate: activateSubscriptionMutation } =
    useActivateSubscriptionMutation(() => {
      onSuccess();
      setLoading(false);
    });

  const activateSubscription = (
    subscriptionPid: string,
    checkoutId: string
  ) => {
    setLoading(true);
    activateSubscriptionMutation({
      pid: subscriptionPid,
      checkoutId
    });
  };

  return [isLoading, activateSubscription];
}
