import { forwardRef } from 'react';
import { Button, ButtonProps } from './button';
import styled from 'styled-components';

export type OutlineButtonProps = ButtonProps;

export const OutlineButton = forwardRef<HTMLButtonElement, OutlineButtonProps>(
  function _OutlineButton(forwardingProps, forwardingRef) {
    return (
      <StyledButton
        backgroundColor="transparent"
        textColor="black"
        fontWeight={500}
        ref={forwardingRef}
        {...forwardingProps}
      />
    );
  }
);

const StyledButton = styled(Button)`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray10};

  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
  }

  :active {
    border-color: ${({ theme }) => theme.colors.gray50};
  }
`;
