import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export type LayoutCenterProps = PropsWithChildren<{
  spacer?: [number, number] | null;
  stretch?: boolean;
  width?: number;
  padding?: number;
}>;

export function LayoutCenter({
  children,
  spacer = [100, 140],
  width,
  stretch = false,
  padding = 20
}: LayoutCenterProps) {
  return (
    <Container _padding={padding}>
      {spacer && <Spacer _size={spacer} />}
      <Content _width={width} _isCenter={!spacer} _stretch={stretch}>
        {children}
      </Content>
    </Container>
  );
}

const Container = styled.div<{ _padding: number }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ _padding = 20 }) => `${_padding}px`};
  margin: 0 auto;
`;

const Spacer = styled.div<{ _size: [number, number] }>`
  width: 100%;
  ${({ _size }) => css`
    min-height: ${_size[0]}px;
    max-height: ${_size[1]}px;
  `}
  flex-grow: 1;
`;

const Content = styled.div<{
  _width?: number;
  _isCenter?: boolean;
  _stretch?: boolean;
}>`
  ${({ _width }) =>
    _width &&
    css`
      max-width: ${_width}px;
    `}
  width: 100%;
  ${({ _isCenter = false }) => _isCenter && 'justify-content: center;'}
  display: flex;
  align-items: ${({ _stretch = false }) => (_stretch ? 'stretch' : 'center')};
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
`;
