import { Side, UseTransitionStylesProps } from '@floating-ui/react';
import { CSSProperties } from 'react';

export const createDropdownSlideInAnimation = ({
  openDuration,
  offset
}: {
  openDuration?: number;
  offset?: number;
} = {}): Partial<UseTransitionStylesProps> => {
  const finalOpenDuration = openDuration ?? 200;
  const finalOffset = offset ?? 8;

  const slideInAnimation: Record<Side, CSSProperties> = {
    top: { opacity: 0, top: `${finalOffset}px` },
    right: { opacity: 0, left: `-${finalOffset}px` },
    bottom: { opacity: 0, top: `-${finalOffset}px` },
    left: { opacity: 0, left: `${finalOffset}px` }
  };

  return {
    duration: {
      open: finalOpenDuration,
      close: 0
    },
    initial: ({ side }) => slideInAnimation[side],
    open: () => ({
      opacity: 1,
      top: 0,
      left: 0
    })
  };
};
