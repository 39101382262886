import { Exclude, Expose, Type } from 'class-transformer';

export const GET_ALL_APPS_PATH = 'widget/get-all-apps';

@Exclude()
export class GetAllAppsResponse {
  @Expose()
  @Type(() => GetAllAppsResponseApp)
  payload: GetAllAppsResponseApp[];
}

@Exclude()
export class GetAllAppsResponseApp {
  @Expose()
  alias: string;

  @Expose()
  name: string;
}
