import styled, { css } from 'styled-components';
import {
  Badge,
  MenuButton,
  MenuButtonProps,
  ThemeColor
} from '@elfsight-universe/ui-common';
import { useHeader } from './header-provider';
import { formatBadgeNumber } from '@modules/_header/utils';

export type HeaderNavItemProps = MenuButtonProps & {
  badge?: number;
  customColor?: ThemeColor;
  href?: string;
  alert?: boolean;
};

export function HeaderNavItem({
  children,
  href,
  badge,
  customColor,
  alert,
  ...forwardingProps
}: HeaderNavItemProps) {
  const { activePage } = useHeader();
  const active = Boolean(href?.startsWith(`/${activePage?.split('/')[1]}`));
  const badgeText = badge ? formatBadgeNumber(badge) : null;

  return (
    <Container $active={active}>
      <MenuButton
        {...forwardingProps}
        href={href}
        textColor={customColor || (active ? 'black' : 'gray70')}
        interactTextColor={customColor || 'black'}
        active={false}
      >
        {children}
        {badgeText ? <Badge>{badgeText}</Badge> : null}
        {alert && <AlertDot />}
      </MenuButton>
    </Container>
  );
}

const Container = styled.div<{
  $active: boolean;
}>`
  ${({ theme }) => theme.font.title4};
  position: relative;
  display: flex;
  align-items: center;

  ${({ $active, theme }) =>
    $active &&
    css`
      ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: ${theme.colors.black};
      }
    `}
`;

const AlertDot = styled.span`
  position: absolute;
  right: 7px;
  top: 8px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.alert};
`;
