import { IsString } from 'class-validator';

export const SEND_ANALYTICS_EVENT_PATH = 'analytics/send-analytics-event';

export class SendAnalyticsEventRequest {
  @IsString()
  name: string;

  params?: Record<string, unknown>;
}

export class SendAnalyticsEventResponse {}
