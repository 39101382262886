import { useCallback } from 'react';
import { useRouter } from 'next/router';

// @TODO refactor usage

export function useRouterQueryParamsState(
  index: number,
  slug = 'params'
): [string, (value: string) => void] {
  const { query, pathname, replace } = useRouter();
  const params = (query[slug] as string[]) || [];

  const setParam = useCallback(
    (value: string) => {
      params[index] = value;

      replace({
        pathname: pathname,
        query: { ...query, [slug]: params }
      });
    },
    [query, params, index, slug]
  );

  return [params[index], setParam];
}
