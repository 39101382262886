import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  INVITE_ACCOUNT_MEMBER_PATH,
  InviteAccountMemberRequest,
  InviteAccountMemberResponseError
} from '@elfsight-universe/service-core-contracts/iam';
import { client } from '../client';

export function useInviteAccountMemberMutation(
  options: UseMutationOptions<
    undefined,
    InviteAccountMemberResponseError,
    InviteAccountMemberRequest
  > = {}
) {
  return useMutation<
    undefined,
    InviteAccountMemberResponseError,
    InviteAccountMemberRequest
  >(
    ({ email }) =>
      client
        .post(INVITE_ACCOUNT_MEMBER_PATH, {
          json: { email }
        })
        .json<undefined>(),
    {
      retry: false,
      ...options
    }
  );
}
