import styled, { css } from 'styled-components';
import { WarningFilled } from '@elfsight/icons';
import { useOnboardingQuery } from '@api';
import { Button, Callout, Icon } from '@elfsight-universe/ui-common';

type ChooseOccupationCalloutProps = {
  margin?: number | [number, number];
};

export function ChooseOccupationCallout({
  margin
}: ChooseOccupationCalloutProps) {
  const { data } = useOnboardingQuery();

  if (!data) {
    return null;
  }

  return (
    <Container _margin={margin}>
      <Callout
        icon={<Icon size={16} component={WarningFilled} fill="warning" />}
        backgroundColor="warning"
        button={
          <Button variation="accentPrimary" href="/account/profile" small>
            Go To Profile
          </Button>
        }
      >
        Please, choose your professional role
      </Callout>
    </Container>
  );
}

const Container = styled.div<{
  _margin?: number | [number, number];
}>`
  width: 100%;
  max-width: 900px;

  ${({ _margin = [0, 16] }) => {
    const [mt, mb] = Array.isArray(_margin) ? _margin : [_margin, _margin];
    return css`
      margin-top: ${mt}px;
      margin-bottom: ${mb}px;
    `;
  }}
`;
