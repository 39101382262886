import { MutableRefObject } from 'react';
import styled from 'styled-components';
import { UseInteractionsReturn } from '@floating-ui/react/dist/floating-ui.react';
import { SelectOption, SelectPopoverItem } from '../select-option';
import { getGroupedSelectOptions } from '../utils';

export type GroupedSelectOptionsListProps = {
  items: SelectOption[];
  withGroups?: boolean;
  activeIndex: number | null;
  selectedItem: SelectOption | null;
  onSelectItem: (item: SelectOption, selectedIndex: number) => void;
  itemsListRef: MutableRefObject<(HTMLElement | null)[]>;
  floatingUIItemProps: UseInteractionsReturn['getItemProps'];
};

export function GroupedSelectOptionsList({
  items,
  withGroups = false,
  activeIndex,
  selectedItem,
  itemsListRef,
  floatingUIItemProps,
  onSelectItem
}: GroupedSelectOptionsListProps) {
  let itemListIndex = 0;

  const groupedOptions = getGroupedSelectOptions(items);
  return groupedOptions.map((group, groupIndex) => {
    const showSeparator = withGroups && groupIndex > 0;

    if (group.groupName && withGroups) {
      return (
        <GroupSection
          key={group.groupName}
          role="group"
          aria-labelledby={group.groupName}
        >
          {showSeparator && <Separator />}
          <GroupCaption id={group.groupName}>{group.groupName}</GroupCaption>
          <GroupInnerList role="presentation">
            {group.options.map((item) => {
              const index = itemListIndex++;
              return (
                <GroupSection key={item.value}>
                  <SelectPopoverItem
                    focused={activeIndex === index}
                    selected={selectedItem?.value === item.value}
                    label={item.label}
                    value={item.value}
                    onClick={() => undefined} // @TODO fix types and remove it
                    onChange={() => undefined}
                    {...floatingUIItemProps({
                      ref: (node) => {
                        itemsListRef.current[index] = node;
                      },
                      onClick: () => {
                        onSelectItem(item, index);
                      }
                    })}
                  />
                </GroupSection>
              );
            })}
          </GroupInnerList>
        </GroupSection>
      );
    }

    return group.options.map((item, innerMapIndex) => {
      const index = itemListIndex++;

      return (
        <GroupSection key={item.value}>
          {showSeparator && innerMapIndex === 0 && <Separator />}

          <SelectPopoverItem
            focused={activeIndex === index}
            selected={selectedItem?.value === item.value}
            label={item.label}
            value={item.value}
            onClick={() => undefined} // @TODO fix types and remove it
            onChange={() => undefined}
            {...floatingUIItemProps({
              ref: (node) => {
                itemsListRef.current[index] = node;
              },
              onClick: () => {
                onSelectItem(item, index);
              }
            })}
          />
        </GroupSection>
      );
    });
  });
}

const GroupSection = styled.li`
  list-style-type: none;
`;

const GroupInnerList = styled.ul`
  padding: 0;
  margin: 0;
`;

const Separator = styled.div.attrs({
  role: 'separator',
  'aria-disabled': true
})`
  height: 1px;
  margin: 8px 16px;
  background-color: ${({ theme }) => theme.colors.gray10};
`;

const GroupCaption = styled.div`
  padding: 0 16px;
  height: 26px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray50};
  background: ${({ theme }) => theme.colors.transparent};

  ${({ theme }) => theme.font.captionSmallUppercase};
  font-weight: 500;
`;
