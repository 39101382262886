import { useIsMobile } from '@modules/_app';
import { HeaderLayout, HeaderLayoutProps } from './header-layout';
import { HeaderClose } from './header-close';

export type HeaderMinimalProps = Omit<
  HeaderLayoutProps,
  'leftSlot' | 'rightSlot'
> & {
  withClose?: boolean;
  onClose?: () => void;
};

export function HeaderMinimal({
  withClose,
  onClose,
  ...forwardingProps
}: HeaderMinimalProps) {
  const isMobile = useIsMobile();

  const close = withClose ? <HeaderClose onClick={onClose} /> : null;

  return (
    <HeaderLayout
      leftSlot={isMobile && close}
      rightSlot={!isMobile && close}
      {...forwardingProps}
    />
  );
}
