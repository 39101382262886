import { useEffect } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import {
  AutosizeTextarea,
  Combobox,
  DESKTOP,
  Input,
  MOBILE,
  SelectChangeEvent,
  Typography
} from '@elfsight-universe/ui-common';
import { CreateInstallationRequestAppealRequest } from '@elfsight-universe/service-core-contracts/appeal';
import { requestInstallationPlatformList } from './constants/request-installation-platform-list';

export function RequestInstallationFormStep1() {
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    watch
  } = useFormContext<CreateInstallationRequestAppealRequest>();

  const watchPlatform = watch('platform');

  useEffect(() => {
    register('platform');
  }, [register]);

  const handlePlatformChange = (event: SelectChangeEvent) => {
    clearErrors('platform');
    setValue('platform', event.target.value);
  };

  return (
    <>
      <Row>
        <Input
          {...register('where', {
            onChange: () => {
              clearErrors('where');
            }
          })}
          error={errors?.where?.message}
          label="Website URL *"
          placeholder="example.com"
        />

        <Combobox
          options={requestInstallationPlatformList}
          label="Platform (CMS) *"
          error={errors?.platform?.message}
          placeholder="Select platform"
          onChange={handlePlatformChange}
          onInputChange={() => clearErrors('platform')}
          initialValue={watchPlatform}
          floatingStrategy="fixed"
          captionBottom={
            <Typography variant="text" color="gray50">
              Please specify the CMS or web platform your site is built on. For
              example, WordPress, Shopify, Wix, etc.
            </Typography>
          }
        />
      </Row>

      <div>
        <AutosizeTextarea
          {...register('details', {
            onChange: () => {
              clearErrors('details');
            }
          })}
          error={errors?.details?.message}
          label="Widget Placement Description *"
          placeholder="e.g., homepage, above the footer"
          captionTop={
            <Typography variant="text" color="black">
              Please describe the specific pages and the exact location on these
              pages where you want the widget to be installed.
            </Typography>
          }
        />
      </div>

      <div>
        <AutosizeTextarea
          {...register('additionalInfo', {
            onChange: () => {
              clearErrors('additionalInfo');
            }
          })}
          error={errors?.additionalInfo?.message}
          label="Additional Information"
          captionBottom={
            <Typography variant="text" color="gray50">
              You can add any comments in addition to your installation request.
            </Typography>
          }
        />
      </div>
    </>
  );
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${MOBILE} {
    gap: 16px;
    > * {
      flex-basis: 100%;
    }
  }
  ${DESKTOP} {
    gap: 40px;
    > * {
      flex-basis: calc(50% - 20px);
    }
  }
`;
