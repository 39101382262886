import styled from 'styled-components';
import { MOBILE } from '@elfsight-universe/ui-common';

export const CommunityCardGrid = styled.ul`
  margin: 0;
  padding: 0;

  @supports (grid-template-columns: subgrid) {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr repeat(3, max-content);

    ${MOBILE} {
      grid-column-gap: 0;
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;
