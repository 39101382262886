import {
  forwardRef,
  KeyboardEvent,
  ReactNode,
  SelectHTMLAttributes,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import { mergeRefs } from 'react-merge-refs';
import { isKey } from '@elfsight-universe/ui-common/src/utils';
import { Control } from '../control';
import { ControlInput } from '../control-layout';
import { ControlProps, ControlSize } from '../control-types';
import { SelectOption } from './select-option';
import {
  SelectChangeEvent,
  SelectPopoverLegacy
} from './select-popover-legacy';
import { SelectIndicator } from './select-indicator';
import { useFocused } from '../use-focused';

export type SelectProps = Omit<
  SelectHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> &
  ControlProps<{
    value: string;
    onChange: (event: SelectChangeEvent) => void;
    placeholder?: ReactNode;
    options: SelectOption[];
    withSearch?: boolean;
    popoverMaxHeight?: number;
    popoverMaxWidth?: number;
    withPositionFixedParent?: boolean;
  }>;

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  function _Select(
    {
      value,
      options,
      large,
      label,
      captionTop,
      captionBottom,
      error,
      placeholder,
      fullWidth,
      maxWidth,
      className,
      onFocus,
      onBlur,
      onChange,
      withSearch,
      popoverMaxHeight,
      popoverMaxWidth,
      withPositionFixedParent,
      ...forwardingProps
    },
    forwardingRef
  ) {
    const [focused, focusHandlers] = useFocused(onFocus, onBlur);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const mergedRefs = mergeRefs([inputRef, forwardingRef]);

    const currentOption = options.find(
      ({ value: optionValue }) => optionValue === value
    );

    const size: ControlSize = large ? 'large' : 'medium';
    const fontSize = { large: 16, medium: 14 }[size];
    const height = { large: 48, medium: 36 }[size];

    const handleSelectChange = (event: SelectChangeEvent) => {
      onChange(event);
      onPopoverClose();
    };

    const onControlInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (focused && !popoverOpen && isKey('Enter', event)) {
        setPopoverOpen(true);
      }
    };

    const onPopoverClose = () => {
      setPopoverOpen(false);
      inputRef.current?.focus();
    };

    return (
      <SelectPopoverLegacy
        withSearch={withSearch}
        onChange={handleSelectChange}
        options={options}
        value={value}
        isOpen={popoverOpen}
        onClose={onPopoverClose}
        onClickOutside={() => setPopoverOpen(false)}
        maxHeight={popoverMaxHeight}
        maxWidth={popoverMaxWidth}
        withPositionFixedParent={withPositionFixedParent}
      >
        <Control
          fontSize={fontSize}
          height={height}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          focused={focused}
          label={label}
          error={error}
          captionTop={captionTop}
          captionBottom={captionBottom}
          className={className}
        >
          <StyledControlInput
            onClick={() => setPopoverOpen(!popoverOpen)}
            ref={mergedRefs}
            value={currentOption?.label || ''}
            placeholder={placeholder ? `${placeholder}…` : undefined}
            onKeyDown={onControlInputKeyDown}
            readOnly
            {...focusHandlers}
            {...forwardingProps}
          />
          <SelectIndicator
            _opened={popoverOpen}
            onClick={() => setPopoverOpen(!popoverOpen)}
          />
        </Control>
      </SelectPopoverLegacy>
    );
  }
);

const StyledControlInput = styled(ControlInput)`
  cursor: pointer;
`;
