import { Exclude, Expose, Type } from 'class-transformer';
import { AnnouncementTag } from './classes';

export const GET_ANNOUNCEMENT_TAGS_PATH = 'announcement/get-announcement-tags';

@Exclude()
export class GetAnnouncementTagsResponse {
  @Expose()
  @Type(() => GetAnnouncementTagsResponseModel)
  payload: GetAnnouncementTagsResponseModel[];
}

export class GetAnnouncementTagsResponseModel extends AnnouncementTag {
  @Expose()
  id: number;
}
