import { Exclude, Expose, Type } from 'class-transformer';
import { ViewsCountingPeriod } from './classes';

export const GET_APPS_USAGE_PATH = 'billing/get-apps-usage';

@Exclude()
export class GetAppsUsageResponse {
  @Expose()
  @Type(() => GetAppsUsageResponseModel)
  payload: GetAppsUsageResponseModel[];
}

@Exclude()
export class GetAppsUsageResponseModel {
  @Expose()
  @Type(() => GetAppsUsageResponseApp)
  app: {
    name: string;
    alias: string;
  };

  @Expose()
  @Type(() => GetAppsUsageResponseAppUsage)
  appUsage: {
    viewsLimit: number;
    viewsCount: number;
    viewsUsage: number;
    viewsCountingPeriod: {
      beginsAt: Date;
      endsAt: Date;
    };
  };
}

@Exclude()
export class GetAppsUsageResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;
}

@Exclude()
export class GetAppsUsageResponseAppUsage {
  @Expose()
  viewsLimit: number;

  @Expose()
  viewsCount: number;

  @Expose()
  viewsUsage: number;

  @Expose()
  @Type(() => ViewsCountingPeriod)
  viewsCountingPeriod: {
    beginsAt: Date;
    endsAt: Date;
  };
}
