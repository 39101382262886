export const embedToWebsiteTutorialsList = [
  {
    platform: 'Shopify',
    articleID: '5d6e5c962c7d3a7a4d780325',
    iconURL: 'https://static.elfsight.com/icons/cms-shopify-multicolor.svg'
  },
  {
    platform: 'Wix',
    articleID: '5d5410bf2c7d3a68825ea77a',
    iconURL: 'https://static.elfsight.com/icons/cms-wix-multicolor.svg'
  },
  {
    platform: 'WordPress',
    articleID: '5d80c46c04286364bc8f3196',
    iconURL: 'https://static.elfsight.com/icons/cms-wordpress-multicolor.svg'
  },
  {
    platform: 'Squarespace',
    articleID: '5d7108f52c7d3a7e9ae0c621',
    iconURL: 'https://static.elfsight.com/icons/cms-squarespace-multicolor.svg'
  },
  {
    platform: 'BigCommerce',
    articleID: '5d8247c304286364bc8f4384',
    iconURL: 'https://static.elfsight.com/icons/cms-bigcommerce-multicolor.svg'
  },
  {
    platform: 'Blogger',
    articleID: '5d95c88a2c7d3a7e9ae1fd49',
    iconURL: 'https://static.elfsight.com/icons/cms-blogger-multicolor.svg'
  },
  {
    platform: 'OpenCart',
    articleID: '5d9c803a04286364bc901e47',
    iconURL: 'https://static.elfsight.com/icons/cms-opencart-multicolor.svg'
  },
  {
    platform: 'Big Cartel',
    articleID: '5f7f17b54cedfd0017dcfc29',
    iconURL: 'https://static.elfsight.com/icons/cms-bigcartel-multicolor.svg'
  },
  {
    platform: 'Joomla',
    articleID: '5d94b3cc04286364bc8fd9d8',
    iconURL: 'https://static.elfsight.com/icons/cms-joomla-multicolor.svg'
  },
  {
    platform: 'Webflow',
    articleID: '5d7f939104286364bc8f25f3',
    iconURL: 'https://static.elfsight.com/icons/cms-webflow-multicolor.svg'
  },
  {
    platform: 'GoDaddy',
    articleID: '5f7d902846e0fb001798a713',
    iconURL: 'https://static.elfsight.com/icons/cms-godaddy-multicolor.svg'
  },
  {
    platform: 'Google Sites',
    articleID: '5f2d42df042863444aa0006c',
    iconURL: 'https://static.elfsight.com/icons/cms-google-sites-multicolor.svg'
  },
  // {
  //   platform: 'Magento',
  //   articleID: '',
  //   iconURL: 'https://static.elfsight.com/icons/cms-magento-multicolor.svg'
  // },
  {
    platform: 'Lightspeed',
    articleID: '5f856902c9e77c001621724c',
    iconURL: 'https://static.elfsight.com/icons/cms-lightspeed-multicolor.svg'
  },
  {
    platform: 'Jumpseller',
    articleID: '5dfb4b4d04286364bc930bec',
    iconURL: 'https://static.elfsight.com/icons/cms-jumpseller-multicolor.svg'
  },
  {
    platform: 'Webnode',
    articleID: '5d8a24d72c7d3a7e9ae18c4d',
    iconURL: 'https://static.elfsight.com/icons/cms-webnode-multicolor.svg'
  },
  {
    platform: 'Drupal',
    articleID: '606c6a33e0324b5fdfd07fc5',
    iconURL: 'https://static.elfsight.com/icons/cms-drupal-multicolor.svg'
  },
  {
    platform: 'Duda',
    articleID: '5d83800e04286364bc8f506e',
    iconURL: 'https://static.elfsight.com/icons/cms-duda-multicolor.svg'
  },
  {
    platform: 'Jimdo',
    articleID: '5f9ad05a4cedfd0016107b2c',
    iconURL: 'https://static.elfsight.com/icons/cms-jimdo-multicolor.svg'
  },
  // {
  //   platform: 'Mobirise',
  //   articleID: '',
  //   iconURL: 'https://static.elfsight.com/icons/cms-mobirise-multicolor.svg'
  // },
  // {
  //   platform: 'HubSpot',
  //   articleID: '',
  //   iconURL: 'https://static.elfsight.com/icons/cms-hubspot-multicolor.svg'
  // },
  // {
  //   platform: 'Laravel',
  //   articleID: '',
  //   iconURL: 'https://static.elfsight.com/icons/cms-laravel-multicolor.svg'
  // },
  {
    platform: 'Weebly',
    articleID: '5d725b6f2c7d3a7e9ae0d75d',
    iconURL: 'https://static.elfsight.com/icons/cms-weebly-multicolor.svg'
  },
  // {
  //   platform: 'Adobe Muse',
  //   articleID: '',
  //   iconURL: 'https://static.elfsight.com/icons/cms-adobe-muse-multicolor.svg'
  // },

  // {
  //   platform: 'HTML',
  //   articleID: '',
  //   iconURL: 'https://static.elfsight.com/icons/cms-html-multicolor.svg'
  // },
  {
    platform: 'Ecwid',
    articleID: '60e81f709e87cb3d0124bbbf',
    iconURL: 'https://static.elfsight.com/icons/cms-ecwid-multicolor.svg'
  },
  {
    platform: 'Canva',
    articleID: '667be0381dd56877041ffead',
    iconURL: 'https://static.elfsight.com/icons/cms-canva-multicolor.svg'
  },
  {
    platform: 'Notion',
    articleID: '61309f3b2b380503dfded6ad',
    iconURL: 'https://static.elfsight.com/icons/cms-notion-multicolor.svg'
  },
  {
    platform: 'Prestashop',
    articleID: '5fe5debcf24ccf588e3fe84d',
    iconURL: 'https://static.elfsight.com/icons/cms-prestashop-multicolor.svg'
  },
  {
    platform: 'iFrame',
    articleID: '63f4a82e31c5487818e83451',
    iconURL: 'https://static.elfsight.com/icons/cms-iframe-multicolor.svg'
  }
];
