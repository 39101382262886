import { IsUUID } from 'class-validator';

export const ACCEPT_ACCOUNT_MEMBER_INVITATION_PATH =
  'iam/accept-account-member-invitation';

export class AcceptAccountMemberInvitationRequest {
  @IsUUID()
  accountMemberInvitationPid: string;
}

export type AcceptAccountMemberInvitationResponseError =
  | 'ACCOUNT_MEMBER_INVITATION_DOES_NOT_EXIST'
  | 'ACCOUNT_MEMBER_DEACTIVATED';
