import { useRef, useEffect } from 'react';

export function useWindowPostMessage<T>(
  callback: (event: MessageEvent<T>) => void
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const eventListener = (event: MessageEvent) => callbackRef.current(event);

    window.addEventListener('message', eventListener);
    return () => window.removeEventListener('message', eventListener);
  }, []);
}
