import styled from 'styled-components';
import { Close } from '@elfsight/icons';
import { Button, IconButton } from '@elfsight-universe/ui-common';
import { useWixIntegration } from '@modules/wix-integration/contexts/wix-integration';

export interface PricingHeaderProps {
  onCloseRequest: () => void;
}

export function PricingHeader({ onCloseRequest }: PricingHeaderProps) {
  const wixIntegration = useWixIntegration();

  return (
    <Container>
      {wixIntegration.isWixSession ? (
        <WixCloseButton variation="text" onClick={onCloseRequest}>
          Later
        </WixCloseButton>
      ) : (
        <CloseButton large icon={Close} onClick={onCloseRequest} />
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px;
`;

const WixCloseButton = styled(Button)`
  position: absolute;
  top: 12px;
  z-index: 1;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  z-index: 1;
`;
