import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

// @TODO refactor usage

export function useRouterQueryUpdate(
  param: string,
  onRouterReady?: (value?: string) => void
): (value?: string | number) => void {
  const { pathname, query, isReady, replace } = useRouter();

  useEffect(() => {
    if (isReady && onRouterReady) {
      onRouterReady(query[param] ? (query[param] as string) : undefined);
    }
  }, [isReady]);

  return useCallback(
    (value?: string | number) => {
      const strValue = `${value}`;

      if (value) {
        query[param] = strValue;
      } else {
        delete query[param];
      }

      replace(
        {
          pathname,
          query
        },
        undefined,
        { scroll: false }
      );
    },
    [param, query]
  );
}
