import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_ME_PATH,
  GetMeResponse
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useMeQuery(options: UseQueryOptions<GetMeResponse> = {}) {
  return useQuery<GetMeResponse>(
    [GET_ME_PATH],
    () => client.get(GET_ME_PATH).json(),
    {
      enabled: true,
      ...options
    }
  );
}
