import styled from 'styled-components';
import { OnboardingMobileModal } from '@modules/onboarding/onboarding-mobile-modal';
import { OnboardingBlock } from '@modules/onboarding';
import { OnboardingPreview } from '@modules/onboarding/onboarding-preview';
import { OnboardingItemsWithDone } from '@modules/_header/header-onboarding-container';
import { useHeader } from '@modules/_header/header-provider';

export type HeaderMobileOnboardingProps = {
  items: OnboardingItemsWithDone[];
  done: boolean;
  progress: number;
  progressNote: string;
};

export function HeaderMobileOnboarding({
  items,
  done,
  progress,
  progressNote
}: HeaderMobileOnboardingProps) {
  const { isOnboardingOpen, toggleOnboardingOpen } = useHeader();

  return (
    <>
      <StyledHeaderOnboardingPreview
        active={isOnboardingOpen}
        done={done}
        progress={progress}
        onClick={() => toggleOnboardingOpen()}
        large
      />
      <OnboardingMobileModal
        isOpen={isOnboardingOpen}
        onRequestClose={() => toggleOnboardingOpen(false)}
      >
        <OnboardingBlock items={items} done={done} note={progressNote} />
      </OnboardingMobileModal>
    </>
  );
}

const StyledHeaderOnboardingPreview = styled(OnboardingPreview)`
  margin: 20px 0;
`;
