import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  SIGN_IN_BY_CREDENTIALS_PATH,
  SignInByCredentialsRequest,
  SignInByCredentialsResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/sign-in-by-credentials';
import { client } from '../client';

export function useSignInByCredentialsMutation(
  options: Pick<
    UseMutationOptions<
      undefined,
      SignInByCredentialsResponseError,
      SignInByCredentialsRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    undefined,
    SignInByCredentialsResponseError,
    SignInByCredentialsRequest
  >((data) => client.post(SIGN_IN_BY_CREDENTIALS_PATH, { json: data }).json(), {
    retry: false,
    ...options
  });
}
