import { BillingInterval } from '@elfsight-universe/service-core-contracts/billing/enums';
import { getBillingIntervalCaption } from './get-billing-interval-caption';

export const getPlanNameWithInterval = (
  rawPlanName: string,
  billingInterval: BillingInterval,
  separator: 'brackets' | 'dash' | false = 'brackets'
) => {
  if (!separator) {
    return `${rawPlanName} ${getBillingIntervalCaption(billingInterval)}`;
  }

  return {
    brackets: `${rawPlanName} (${getBillingIntervalCaption(billingInterval)})`,
    dash: `${rawPlanName} — ${getBillingIntervalCaption(billingInterval)}`
  }[separator];
};
