import { Exclude, Expose, Type } from 'class-transformer';
import { IsString } from 'class-validator';
import { ViewsCountingPeriod } from './classes';
import { PlanGrade, PlanType } from './enums';

export const GET_SUBSCRIPTION_APP_INFO_PATH =
  'billing/get-subscription-app-info';

export class GetSubscriptionAppInfoRequest {
  @IsString()
  appAlias: string;
}

@Exclude()
export class GetSubscriptionAppInfoResponsePlanFeatures {
  @Expose()
  disableShareByLink?: boolean;
}

@Exclude()
export class GetSubscriptionAppInfoResponsePlan {
  @Expose()
  grade?: PlanGrade;

  @Expose()
  type: PlanType;

  @Expose()
  @Type(() => GetSubscriptionAppInfoResponsePlanFeatures)
  features?: GetSubscriptionAppInfoResponsePlanFeatures;
}

@Exclude()
export class GetSubscriptionAppInfoResponse {
  @Expose()
  isPackSubscription: boolean;

  @Expose()
  isLiteSubscription: boolean;

  @Expose()
  isLitePlanSubscriptionConfirmed: boolean;

  @Expose()
  @Type(() => GetSubscriptionAppInfoResponseApp)
  app: {
    name: string;
    alias: string;
    canExportData: boolean;
    icon: string;
    category: {
      color: string;
    };
  };

  @Expose()
  @Type(() => GetSubscriptionAppInfoResponseAppUsage)
  appUsage: {
    widgetsCount: number;
    widgetsLimit: number;
    widgetsUsage: number;
    viewsLimit: number | null;
    viewsCount: number;
    viewsUsage: number | null;
    viewsCountingPeriod: {
      beginsAt: Date;
      endsAt: Date;
    };
  };

  @Expose()
  @Type(() => GetSubscriptionAppInfoResponsePlan)
  plan: GetSubscriptionAppInfoResponsePlan;

  @Expose()
  canAppBeDeleted: boolean;

  @Expose()
  widgetsTotalCount: number;
}

@Exclude()
export class GetSubscriptionAppInfoResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  icon: string;

  @Expose()
  canExportData: boolean;

  @Expose()
  @Type(() => GetSubscriptionAppInfoResponseCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetSubscriptionAppInfoResponseAppUsage {
  @Expose()
  widgetsCount: number;

  @Expose()
  widgetsLimit: number;

  @Expose()
  widgetsUsage: number;

  @Expose()
  viewsLimit: number | null;

  @Expose()
  viewsCount: number;

  @Expose()
  viewsUsage: number | null;

  @Expose()
  @Type(() => ViewsCountingPeriod)
  viewsCountingPeriod: {
    beginsAt: Date;
    endsAt: Date;
  };
}

@Exclude()
export class GetSubscriptionAppInfoResponseCategory {
  @Expose()
  color: string;
}
