import { IsUUID } from 'class-validator';

export const REMOVE_ACCOUNT_MEMBER_PATH = 'iam/remove-account-member';

export class RemoveAccountMemberRequest {
  @IsUUID()
  accountMemberPid: string;
}

export type RemoveAccountMemberResponseError = 'ACCOUNT_MEMBER_DOES_NOT_EXIST';
