import { useQuery } from '@tanstack/react-query';
import {
  GET_ALL_APPS_PATH,
  GetAllAppsResponse
} from '@elfsight-universe/service-core-contracts/widget/get-all-apps';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useAllAppsQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetAllAppsResponse>(
    [GET_ALL_APPS_PATH],
    () => client.get(GET_ALL_APPS_PATH).json<GetAllAppsResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
