import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import {
  APP_PARAM,
  BILLING_INTERVAL_PARAM,
  CLAIM_PARAM,
  FLOW_PARAM,
  REDIRECT_BACK_PARAM,
  REDIRECT_URL_PARAM,
  HEADER_CLOSE_URL_PARAM,
  ENTERPRISE_PARAM,
  SUBSCRIPTION_PID
} from './constants';

export type UpgradePageParams = Partial<{
  tab: 'single' | 'packs';
  [APP_PARAM]: string;
  [REDIRECT_BACK_PARAM]: boolean;
  [REDIRECT_URL_PARAM]: string;
  [FLOW_PARAM]: boolean;
  [BILLING_INTERVAL_PARAM]: 'monthly' | 'yearly';
  [CLAIM_PARAM]: 'removeBranding' | 'upgradePlan';
  [HEADER_CLOSE_URL_PARAM]: string;
  [ENTERPRISE_PARAM]: boolean;
  [SUBSCRIPTION_PID]: string;
}>;

const addSearchParams = (params = {}) =>
  !!params.toString() ? `?${params}` : '';

export const createUpgradeURL = ({
  tab = 'single',
  app,
  ...params
}: UpgradePageParams = {}) => {
  const urlParams = new URLSearchParams(
    omitBy(params, isUndefined) as unknown as URLSearchParams
  );
  return `/apps/${app}/pricing/${tab}${addSearchParams(urlParams)}`;
};
