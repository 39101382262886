import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import addDays from 'date-fns/addDays';
import { REFERRER_COOKIE_NAME } from '@elfsight-universe/service-core-contracts/referral';

const { NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN } = process.env;

export function useCatchRefParam() {
  const { query } = useRouter();
  const [, setCookie] = useCookies();

  useEffect(() => {
    if (query.ref) {
      setCookie(REFERRER_COOKIE_NAME, query.ref, {
        domain: NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN,
        path: '/',
        secure: true,
        sameSite: 'none',
        expires: addDays(new Date(), 30)
      });
    }
  }, [query.ref]);
}
