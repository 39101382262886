import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { useTabs } from './tabs-provider';

export type TabProps = PropsWithChildren<{
  tabID: string;
  stayMounted?: boolean;
}>;

export function TabPanel({
  children,
  tabID,
  stayMounted,
  ...forwardingProps
}: TabProps) {
  const { activeTab } = useTabs();

  if (activeTab === tabID || stayMounted) {
    return (
      <Container
        role="tabpanel"
        _hidden={activeTab !== tabID && stayMounted}
        {...forwardingProps}
      >
        {children}
      </Container>
    );
  }

  return null;
}

const Container = styled.div<{ _hidden?: boolean }>`
  ${({ _hidden }) =>
    _hidden
      ? css`
          opacity: 0;
          height: 0;
          visibility: hidden;
        `
      : css`
          display: flex;
          opacity: 1;
          height: 100%;
          visibility: visible;
          transition: opacity 0.2s;
        `};
  width: 100%;
  flex-direction: column;
`;
