import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const ACTIVATE_WIDGET_PATH = 'widget/activate-widget';

export class ActivateWidgetRequest {
  @IsUUID()
  widgetPid: string;
}

export type ActivateWidgetResponseError =
  | WidgetError.WIDGETS_LIMIT_REACHED
  | WidgetError.WIDGET_DOES_NOT_EXIST;
