import { Exclude, Expose } from 'class-transformer';

export const GET_PREFERRED_MONETIZATION_LIST_PATH =
  'identity-and-access/get-preferred-monetization-list';

@Exclude()
export class GetPreferredMonetizationListResponse {
  @Expose()
  payload: GetPreferredMonetizationListItem[];
}

@Exclude()
export class GetPreferredMonetizationListItem {
  @Expose()
  pid: string;

  @Expose()
  alias: string;

  @Expose()
  value: string;

  @Expose()
  description: string;
}
