import styled, { css } from 'styled-components';
import parse, { DOMNode } from 'html-react-parser';
import isSameYear from 'date-fns/isSameYear';
import { Icon, Link, Typography } from '@elfsight-universe/ui-common';
import { GetPublishedAnnouncementsResponseModel } from '@elfsight-universe/service-core-contracts/announcement';
import { formatRelativeDate } from '@utils';
import AngleBoldRight16 from '@icons/angle-bold-right-16.svg';
import { VideoPlayerDialog, AnnouncementImage } from './components';

export type AnnouncementCardProps = {
  announcement: GetPublishedAnnouncementsResponseModel;
};

export function AnnouncementCard({
  announcement,
  ...forwardingProps
}: AnnouncementCardProps) {
  const { title, tags, body, createdAt, ctaButtonText, ctaButtonLink } =
    announcement;

  const showYear = !isSameYear(new window.Date(), new window.Date(createdAt));
  const formattedDate = formatRelativeDate(createdAt, showYear);

  const bodyWithVideoPlayer = parse(body, {
    replace: replaceNodes(ctaButtonLink)
  });

  return (
    <Container {...forwardingProps}>
      <Header>
        <TagsList>
          {tags.map(({ announcementTag }) => (
            <Tag
              key={announcementTag.alias}
              variant="captionSmallUppercase"
              _color={announcementTag.color}
            >
              {announcementTag.title}
            </Tag>
          ))}
        </TagsList>

        <Date variant="captionSmall" color="gray50">
          {formattedDate}
        </Date>
      </Header>

      <TitleLink href={ctaButtonLink} $withUnderline={!!ctaButtonLink}>
        {title}
      </TitleLink>

      <Body>{bodyWithVideoPlayer}</Body>

      {ctaButtonLink && ctaButtonText && (
        <CTAWrapper>
          <CTA href={ctaButtonLink} color="accent">
            {ctaButtonText}
          </CTA>
          <StyledIcon component={AngleBoldRight16} size={12} fill="accent" />
        </CTAWrapper>
      )}
    </Container>
  );
}

// eslint-disable-next-line react/display-name
const replaceNodes = (ctaLink?: string) => (domhandlerNode: DOMNode) => {
  if (
    domhandlerNode.type === 'tag' &&
    domhandlerNode.name === 'div' &&
    domhandlerNode.attribs.hasOwnProperty('data-youtube-video') &&
    domhandlerNode.childNodes[0].type === 'tag'
  ) {
    const videoURL = domhandlerNode.childNodes[0].attribs.src;
    return <VideoPlayerDialog videoUrl={videoURL} />;
  }

  if (domhandlerNode.type === 'tag' && domhandlerNode.name === 'img') {
    return (
      <AnnouncementImage link={ctaLink} src={domhandlerNode.attribs.src} />
    );
  }
};

const Container = styled.div`
  padding: 24px;
  overflow: hidden;
  word-break: break-word;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
`;

const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Tag = styled(Typography)<{ _color: string }>`
  color: ${({ _color }) => _color};
`;

const Date = styled(Typography)`
  flex-shrink: 0;
  line-height: 1;
`;

const TitleLink = styled(Link)<{ $withUnderline?: boolean }>`
  ${({ theme }) => theme.font.title3Semibold};

  &:hover {
    ${({ $withUnderline }) =>
      $withUnderline
        ? css`
      cursor: pointer;
      text-decoration: underline;
        `
        : css`
      cursor: default;
      text-decoration: none;
        `}
`;

const CTA = styled(Link)`
  ${({ theme }) => theme.font.captionBold};
`;

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
  margin-top: 8px;
`;

const StyledIcon = styled(Icon)`
  margin-top: 2px;
`;

const Body = styled.div`
  margin-top: 8px;

  ul, ol {
    margin: 0;
    padding: 0 0 0 21px;
  }

  li {
    margin: 8px 0;
    padding: 0;
  }

  p {
    margin: 8px 0;
    ${({ theme }) => theme.font.caption};
  }

  strong {
    font-weight: 600;
  }

  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;

    &:focus {
      outline: none;
    }

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    ${({ theme }) => theme.font.caption};
    border-left: 2px solid ${({ theme }) => theme.colors.gray30};
    margin: 8px 0;
    padding-left: 8px;
  }
`;
