import { IsOptional, IsString, MaxLength, MinLength } from 'class-validator';
import { IdentityAndAccessError, SecureLinkError } from '../errors';

export const UPDATE_PASSWORD_PATH = 'identity-and-access/update-password';

export class UpdatePasswordRequest {
  @IsString()
  @IsOptional()
  token?: string;

  @MinLength(6, { message: 'Password is too weak.' })
  @MaxLength(32, { message: 'Password is too long.' })
  password: string;
}

export type UpdatePasswordResponseError =
  | IdentityAndAccessError.USER_DOES_NOT_EXISTS
  | SecureLinkError.SECURE_LINK_DOES_NOT_EXIST;
