export enum PaddleEvent {
  CheckoutLoaded = 'Checkout.Loaded',
  CheckoutClose = 'Checkout.Close',
  CheckoutComplete = 'Checkout.Complete',
  CheckoutUserSubscribed = 'Checkout.User.Subscribed',
  CheckoutQuantityChange = 'Checkout.Quantity.Change',
  CheckoutLogin = 'Checkout.Login',
  CheckoutLogout = 'Checkout.Logout',
  CheckoutPaymentMethodSelected = 'Checkout.PaymentMethodSelected',
  CheckoutCouponAdd = 'Checkout.Coupon.Add',
  CheckoutCouponSubmit = 'Checkout.Coupon.Submit',
  CheckoutCouponCancel = 'Checkout.Coupon.Cancel',
  CheckoutCouponApplied = 'Checkout.Coupon.Applied',
  CheckoutCouponRemove = 'Checkout.Coupon.Remove',
  CheckoutError = 'Checkout.Error',
  CheckoutLocationSubmit = 'Checkout.Location.Submit',
  CheckoutLanguageChange = 'Checkout.Language.Change',
  CheckoutVatAdd = 'Checkout.Vat.Add',
  CheckoutVatCancel = 'Checkout.Vat.Cancel',
  CheckoutVatSubmit = 'Checkout.Vat.Submit',
  CheckoutVatApplied = 'Checkout.Vat.Applied',
  CheckoutVatRemove = 'Checkout.Vat.Remove',
  CheckoutWireTransferComplete = 'Checkout.WireTransfer.Complete',
  CheckoutPaymentComplete = 'Checkout.PaymentComplete',
  CheckoutPaymentMethodChange = 'Checkout.PaymentMethodChange',
  CheckoutWireTransferPaymentMethodChange = 'Checkout.WireTransfer.PaymentMethodChange'
}
