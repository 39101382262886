import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { PaddleCheckoutOptions } from './paddle-types';

export interface IPaddleContext {
  openDetailsPopup: (checkoutId: string) => void;
  openCheckoutURL: (
    options: PaddleCheckoutOptions & { override: string }
  ) => void;
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const PaddleContext = createContext<IPaddleContext | null>(null);

export function usePaddle() {
  const context = useContext(PaddleContext);

  if (context === null) {
    throw new Error('`usePaddle` must be nested inside an `PaddleProvider`.');
  }

  return context;
}
