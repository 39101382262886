import { ReactNode } from 'react';
import styled from 'styled-components';
import { Check } from '@elfsight/icons';
import { H4, Icon } from '@elfsight-universe/ui-common';
import { ListItem } from '../../components/list-item';

export type OnboardingDoneItemProps = {
  children?: ReactNode;
};

export function OnboardingDoneItem({
  children,
  ...forwardingProps
}: OnboardingDoneItemProps) {
  return (
    <Container {...forwardingProps} _intractable={false} _offset={8}>
      <IconContainer>
        <Icon component={Check} size={12} fill="success" />
      </IconContainer>

      <Title>{children}</Title>
    </Container>
  );
}

const IconContainer = styled.div`
  background: ${({ theme }) => theme.colors.successTransparent};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const Title = styled(H4)`
  color: ${({ theme }) => theme.colors.success};
`;

const Container = styled(ListItem)`
  display: flex;
  gap: 8px;
`;
