import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

export const SVF_DEFS_ID = '__SVG_DEFS__';

export function injectSVGDef(svgEl: ReactElement<SVGElement>, id: string) {
  const container = document.getElementById(SVF_DEFS_ID);

  if (!container) {
    console.warn(`[injectSVGDef]: Container to inject not found`);
    return;
  }

  if (!document.getElementById(id)) {
    try {
      const svgHtml = renderToStaticMarkup(svgEl); // @TODO fix warning throw "<linearGradient /> is using incorrect casing"

      container.innerHTML = container.innerHTML + svgHtml;
    } catch (e) {}
  }
}

export function SVGDefsRoot() {
  return (
    <div
      id="__SVG_DEFS_CONTAINER__"
      style={{
        position: 'absolute',
        height: 0,
        width: 0,
        left: -10000,
        top: 0
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs id={SVF_DEFS_ID} />
      </svg>
    </div>
  );
}
