import { css } from 'styled-components';
import { PopoverPosition } from 'react-tiny-popover';

type GetSlideInTranslateMixinProps = { _position?: PopoverPosition };

export const slideInTranslateMixin =
  (offset = 4) =>
  ({ _position }: GetSlideInTranslateMixinProps) =>
    _position &&
    {
      left: css`
        transform: translateX(${offset}px);
        opacity: 0;
        &.slide-in-appear-done {
          transform: translateX(0);
          opacity: 1;
          transition: all 0.2s;
        }
      `,
      right: css`
        transform: translateX(-${offset}px);
        opacity: 0;
        &.slide-in-appear-done {
          transform: translateX(0);
          opacity: 1;
          transition: all 0.2s;
        }
      `,
      bottom: css`
        transform: translateY(-${offset}px);
        opacity: 0;
        &.slide-in-appear-done {
          transform: translateY(0);
          opacity: 1;
          transition: all 0.2s;
        }
      `,
      top: css`
        transform: translateY(${offset}px);
        opacity: 0;
        &.slide-in-appear-done {
          transform: translateY(0);
          opacity: 1;
          transition: all 0.2s;
        }
      `
    }[_position];
