import { Exclude, Expose, Type } from 'class-transformer';

export const GET_RECOMMENDED_APPS_PATH = 'widget/get-recommended-apps';

@Exclude()
export class GetRecommendedAppsResponse {
  @Expose()
  @Type(() => GetRecommendedAppsResponseApp)
  payload: GetRecommendedAppsResponseApp[];
}

@Exclude()
export class GetRecommendedAppsResponseApp {
  @Expose()
  alias: string;

  @Expose()
  name: string;

  @Expose()
  caption: string;

  @Expose()
  icon: string;

  @Expose()
  @Type(() => GetRecommendedAppsResponseAppBadge)
  badge: {
    title: string;
  } | null;

  @Expose()
  @Type(() => GetRecommendedAppsResponseAppCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetRecommendedAppsResponseAppCategory {
  @Expose()
  color: string;
}

@Exclude()
export class GetRecommendedAppsResponseAppBadge {
  @Expose()
  title: string;
}
