import { useRef } from 'react';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  UPDATE_UNPUBLISHED_WIDGET_REVISION_PATH,
  UpdateUnpublishedWidgetRevisionRequest,
  UpdateUnpublishedWidgetRevisionResponse,
  UpdateUnpublishedWidgetRevisionResponseError
} from '@elfsight-universe/service-core-contracts/widget/update-unpublished-widget-revision';
import { WidgetError } from '@elfsight-universe/service-core-contracts/errors';
import { catchUnhandledError } from '@modules/_error';
import { client } from '../client';

const REDLOCK_ERROR_RETRY_COUNT = 3;

export function useUpdateUnpublishedRevisionMutation(
  widgetPid: string,
  options: UseMutationOptions<
    Pick<UpdateUnpublishedWidgetRevisionRequest, 'settings'> &
      UpdateUnpublishedWidgetRevisionResponse,
    UpdateUnpublishedWidgetRevisionResponseError | Error,
    Pick<UpdateUnpublishedWidgetRevisionRequest, 'settings'>
  >
) {
  const abortControllerRef = useRef<AbortController | null>(null);

  const mutation = useMutation<
    Pick<UpdateUnpublishedWidgetRevisionRequest, 'settings'> &
      UpdateUnpublishedWidgetRevisionResponse,
    UpdateUnpublishedWidgetRevisionResponseError | Error,
    Pick<UpdateUnpublishedWidgetRevisionRequest, 'settings'>
  >(
    [UPDATE_UNPUBLISHED_WIDGET_REVISION_PATH, widgetPid],
    async ({ settings }) => {
      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      const data = await client
        .post(UPDATE_UNPUBLISHED_WIDGET_REVISION_PATH, {
          json: {
            settings,
            widgetPid
          },
          signal,
          retry: { limit: 0 }
        })
        .json<UpdateUnpublishedWidgetRevisionResponse>();

      return { settings, ...data };
    },
    {
      onError: (error) => {
        if (error instanceof Error && error.name === 'AbortError') {
          return;
        }

        catchUnhandledError(error, [
          WidgetError.UPDATE_UNPUBLISHED_WIDGET_REVISION_FAILED
        ]);
      },
      retry: (failureCount, error) => {
        return (
          error === WidgetError.UPDATE_UNPUBLISHED_WIDGET_REVISION_FAILED &&
          failureCount < REDLOCK_ERROR_RETRY_COUNT
        );
      },
      ...options
    }
  );

  const abort = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  return { ...mutation, abort };
}
