import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { useDealsQuery } from '@api';
import {
  SECOND_APP_DEAL_ALIAS,
  SWITCH_TO_ANNUAL_DEAL_ALIAS,
  SWITCH_TO_PACK_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';

export function useCongratulationsSuitableDeal({
  suitableDealsByPriority = [
    SECOND_APP_DEAL_ALIAS,
    SWITCH_TO_ANNUAL_DEAL_ALIAS,
    SWITCH_TO_PACK_DEAL_ALIAS
  ]
}: {
  suitableDealsByPriority?: string[];
} = {}): {
  suitableDeal: AccountDeal | undefined;
  isLoading: boolean;
} {
  const { data, isLoading } = useDealsQuery();

  const getSuitableDealPriority = (dealAlias: string) =>
    suitableDealsByPriority.findIndex(
      (suitableDealAlias) => dealAlias === suitableDealAlias
    );

  const deals = (data?.payload || [])
    .filter(({ deal }) => suitableDealsByPriority.includes(deal.alias))
    .sort(
      (a, b) =>
        getSuitableDealPriority(a.deal.alias) -
        getSuitableDealPriority(b.deal.alias)
    );

  return {
    suitableDeal: deals[0],
    isLoading
  };
}
