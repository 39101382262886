import styled from 'styled-components';
import { useState } from 'react';
import {
  ContextMenuProps,
  Link,
  Popover,
  useTheme
} from '@elfsight-universe/ui-common';
import { GetCollaboratorsResponseProject } from '@elfsight-universe/service-core-contracts/iam';
import { CollaboratorsProjectsPopover } from '../components/collaborators-projects-popover';

export type CollaboratorsProjectsProps = Omit<ContextMenuProps, 'children'> & {
  projects: GetCollaboratorsResponseProject[];
};

export function CollaboratorsProjects({
  projects
}: CollaboratorsProjectsProps) {
  const { zIndex: themeZIndex } = useTheme();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const projectsWordSentence = projects.length === 1 ? 'project' : 'projects';

  return (
    <Popover
      isOpen={isPopoverOpen}
      onOpenChange={() => setPopoverOpen(false)}
      placement="bottom-start"
      containerStyle={{
        zIndex: themeZIndex.contextMenuDropdown.toString()
      }}
      content={<CollaboratorsProjectsPopover projects={projects} />}
    >
      <TextButton
        forwardedAs="button"
        color="accent"
        onClick={() => setPopoverOpen(!isPopoverOpen)}
      >
        {projects.length} {projectsWordSentence}
      </TextButton>
    </Popover>
  );
}

const TextButton = styled(Link)`
  padding: 0;
  border: none;
  background-color: transparent;
`;
