import styled, { css } from 'styled-components';
import { ArrowBoldDown24 } from '@elfsight/icons';
import { Icon } from './icon';

export const OrderArrowIcon = styled(Icon).attrs({
  component: ArrowBoldDown24,
  size: 12
})<{
  _order: 'asc' | 'desc';
}>`
  margin-left: 4px;
  display: inline-block;
  transition: transform 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  ${({ _order }) =>
    ({
      asc: css`
        transform: rotateX(0);
      `,
      desc: css`
        transform: rotateX(180deg);
      `
    })[_order]}
`;
