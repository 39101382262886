import { MouseEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { FAQItem } from './faq-types';
import { FaqCollapseItem } from './faq-collapse-item';

export type FAQGroupProps = {
  items: FAQItem[];
  title?: string;
  toggleMode?: boolean;
};

export function FAQGroup({ title, items, toggleMode }: FAQGroupProps) {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const toggle = useCallback(
    (index: number) => (e: MouseEvent<HTMLElement>) => {
      if (e.currentTarget.nodeName === 'A') {
        return;
      }

      if (openIndexes.includes(index)) {
        setOpenIndexes(openIndexes.filter((i) => i !== index));
        return;
      }

      if (toggleMode) {
        setOpenIndexes([index]);
        return;
      }

      setOpenIndexes([...openIndexes, index]);
    },
    [openIndexes]
  );

  if (!items.length) return null;

  return (
    <Container>
      {title && <GroupTitle>{title}</GroupTitle>}
      <Items>
        {items.map((item, i) => (
          <FaqCollapseItem
            key={i}
            {...item}
            open={openIndexes.includes(i)}
            onClick={toggle(i)}
          />
        ))}
      </Items>
    </Container>
  );
}

const GroupTitle = styled.div`
  ${({ theme }) => theme.font.captionSmallUppercase};
  margin-bottom: 16px;
  text-align: left;
`;

const Container = styled.div`
  :not(:last-child) {
    margin-bottom: 40px;
  }
`;

const Items = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: 8px;
`;
