import styled from 'styled-components';
import titleBg from './assets/title-bg.png';
import titleBgWithShadow from './assets/title-bg-with-shadow.png';

export type XmasHeadingProps = {
  withShadow?: boolean;
};

export function XmasHeading({ withShadow = false }: XmasHeadingProps) {
  return (
    <Container>
      <Title $withShadow={withShadow}>Christmas Special</Title>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 30px;
`;

const Title = styled.h1<{ $withShadow: boolean }>`
  display: block;
  position: relative;
  z-index: 0;

  margin: 0;
  padding-bottom: 26px;
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};

  &::before {
    content: '';
    background-image: url(${({ $withShadow }) =>
      $withShadow ? titleBgWithShadow.src : titleBg.src});
    background-size: 100%;
    width: 422px;
    height: 100px;

    position: absolute;
    font-size: 0;
    line-height: 0;
    top: 50%;
    left: 50%;
    z-index: -1;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
  }
`;
