import { useQuery } from '@tanstack/react-query';
import { useUser } from '@modules/_app';
import {
  GET_COLLABORATORS_USAGE_PATH,
  GetCollaboratorsUsageResponse
} from '@elfsight-universe/service-core-contracts/iam';
import { client } from '../client';

export function useCollaboratorsUsageQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetCollaboratorsUsageResponse>(
    [GET_COLLABORATORS_USAGE_PATH],
    () =>
      client
        .get(GET_COLLABORATORS_USAGE_PATH)
        .json<GetCollaboratorsUsageResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
