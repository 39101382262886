import { Exclude, Expose } from 'class-transformer';

export const GET_INDUSTRIES_PATH = 'identity-and-access/get-industries';

@Exclude()
export class GetIndustriesResponse {
  @Expose()
  payload: GetIndustriesItem[];
}

@Exclude()
export class GetIndustriesItem {
  @Expose()
  pid: string;

  @Expose()
  name: string;
}
