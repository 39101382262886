import { useMutation } from '@tanstack/react-query';
import {
  UPDATE_EMAIL_PATH,
  UpdateEmailRequest,
  UpdateEmailResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/update-email';
import { client } from '../client';
import { useMeQuery } from '../queries';

export function useEmailChangeMutation(
  onSuccess: () => void,
  onError: (error: UpdateEmailResponseError) => void
) {
  const { refetch: refetchMe } = useMeQuery();

  return useMutation<unknown, UpdateEmailResponseError, UpdateEmailRequest>(
    (data) => client.post(UPDATE_EMAIL_PATH, { json: data }),
    {
      onSuccess: () => {
        refetchMe();
        onSuccess();
      },
      onError
    }
  );
}
