import { useQuery } from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import {
  GET_ALL_WIDGETS_PATH,
  GetAllWidgetsRequest,
  GetAllWidgetsResponse
} from '@elfsight-universe/service-core-contracts/widget/get-all-widgets';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useAllWidgetsQuery(
  dto: GetAllWidgetsRequest,
  onSuccess?: (data: GetAllWidgetsResponse) => void
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetAllWidgetsResponse>(
    [GET_ALL_WIDGETS_PATH, ...Object.values(dto)],
    () =>
      client
        .get(GET_ALL_WIDGETS_PATH, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json<GetAllWidgetsResponse>(),
    {
      onSuccess,
      keepPreviousData: false,
      enabled: isAuthenticated
    }
  );
}
