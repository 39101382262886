import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_WISHLIST_TOPICS_PATH,
  GetWishlistTopicsResponse,
  GetWishlistTopicsResponseError
} from '@elfsight-universe/service-core-contracts/community-integration';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useWishlistTopicsQuery(
  appAlias: string | undefined,
  {
    enabled,
    ...options
  }: UseQueryOptions<
    GetWishlistTopicsResponse,
    GetWishlistTopicsResponseError
  > = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetWishlistTopicsResponse, GetWishlistTopicsResponseError>(
    [GET_WISHLIST_TOPICS_PATH, appAlias],
    () =>
      client
        .get(GET_WISHLIST_TOPICS_PATH, {
          searchParams: { appAlias: appAlias as string }
        })
        .json<GetWishlistTopicsResponse>(),
    {
      enabled:
        enabled !== undefined
          ? enabled && Boolean(appAlias) && isAuthenticated
          : Boolean(appAlias) && isAuthenticated,
      ...options
    }
  );
}
