import styled from 'styled-components';
import {
  getColorWithAlpha,
  Tooltip,
  TooltipProps
} from '@elfsight-universe/ui-common';
import { PricingTableColorTheme } from '@modules/upgrade/pricing-table';

export type FeatureTooltipProps = TooltipProps & {
  pricingTableColorTheme?: PricingTableColorTheme;
};

export function FeatureTooltip({
  children,
  content,
  pricingTableColorTheme = PricingTableColorTheme.WHITE,
  ...forwardingProps
}: FeatureTooltipProps) {
  return (
    <Tooltip
      openDelay={0}
      width={300}
      content={content}
      withSafePolygon
      {...forwardingProps}
    >
      <FeatureTooltipInner _colorTheme={pricingTableColorTheme}>
        {children}
      </FeatureTooltipInner>
    </Tooltip>
  );
}

export const FeatureTooltipInner = styled.span<{
  _colorTheme: PricingTableColorTheme;
}>`
  position: relative;
  display: inline;
  box-sizing: border-box;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed;
    border-color: ${({ theme: { colors }, _colorTheme }) =>
      ({
        white: colors.gray30,
        black: getColorWithAlpha(colors.white, 0.5)
      })[_colorTheme]};
    transition: border-color 0.2s;
  }

  &:hover {
    &::after {
      border-color: ${({ theme: { colors }, _colorTheme }) =>
        ({
          white: colors.black,
          black: colors.white
        })[_colorTheme]};
    }
  }
`;
