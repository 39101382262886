import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { CredentialResponse } from 'google-one-tap';
import {
  SIGN_IN_BY_GOOGLE_ID_TOKEN,
  SignInByGoogleIDTokenResponse,
  SignInByGoogleIDTokenResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/sign-in-by-external-account';
import { client } from '../client';

export function useSignInByGoogleIdToken(
  options: Pick<
    UseMutationOptions<
      SignInByGoogleIDTokenResponse,
      SignInByGoogleIDTokenResponseError,
      CredentialResponse
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    SignInByGoogleIDTokenResponse,
    SignInByGoogleIDTokenResponseError,
    CredentialResponse
  >(
    async (credentialResponse) =>
      (
        await client.post(SIGN_IN_BY_GOOGLE_ID_TOKEN, {
          json: credentialResponse
        })
      ).json(),
    options
  );
}
