import { Exclude, Expose, Type } from 'class-transformer';
import { IsString } from 'class-validator';
import type { JsonObject } from '../json.type';

export const GET_CONFIGURATOR_TEMPLATES_PATH =
  'widget/get-configurator-templates';

export class GetConfiguratorTemplatesRequest {
  @IsString()
  appAlias: string;
}

@Exclude()
export class GetConfiguratorTemplatesResponse {
  @Expose()
  @Type(() => GetConfiguratorTemplatesResponseTemplate)
  payload: GetConfiguratorTemplatesResponseTemplate[];
}

@Exclude()
export class GetConfiguratorTemplatesResponseTemplate {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  image: string;

  @Expose()
  category: string;

  @Expose()
  settings: JsonObject;
}
