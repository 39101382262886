import { CreateFeatureOptions } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';

export const supportFeatureResolver = ({
  isLitePlan,
  isProPlan,
  isPremiumPlan,
  isEnterprisePlan
}: CreateFeatureOptions) => {
  if (isEnterprisePlan) {
    return {
      text: 'Account manager'
    };
  }

  if (isLitePlan) {
    return {
      text: 'Only bug fixes'
    };
  }

  if (isProPlan) {
    return {
      text: 'Priority support'
    };
  }

  if (isPremiumPlan) {
    return {
      text: 'Priority support & live chat'
    };
  }

  return {
    text: 'Basic Support'
  };
};
