import { TypeHelpOptions } from 'class-transformer/types/interfaces';
import { PlanType } from '../enums';
import { LitePlanProperties, StandardPaidPlanProperties } from '../classes';

export const getTransformClassForPlanProps = (
  type?: TypeHelpOptions | undefined
) => {
  if (type?.object.type === PlanType.LITE) {
    return LitePlanProperties;
  }

  return StandardPaidPlanProperties;
};
