import { useQuery } from '@tanstack/react-query';
import {
  GET_RECOMMENDED_APPS_PATH,
  GetRecommendedAppsResponse
} from '@elfsight-universe/service-core-contracts/widget/get-recommended-apps';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useRecommendedAppsQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetRecommendedAppsResponse>(
    [GET_RECOMMENDED_APPS_PATH],
    () =>
      client.get(GET_RECOMMENDED_APPS_PATH).json<GetRecommendedAppsResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
