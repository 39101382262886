import { InputHTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ThemeColor } from '../../theme';

export type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  uncheckedColor?: ThemeColor;
  checkedColor?: ThemeColor;
  label?: ReactNode;
  caption?: ReactNode;
};

export function Radio({
  uncheckedColor,
  checkedColor,
  label,
  caption,
  ...forwardingProps
}: RadioProps) {
  return (
    <Container>
      <RadioContainer>
        <Input
          {...forwardingProps}
          _uncheckedColor={uncheckedColor}
          _checkedColor={checkedColor}
          type="radio"
        />
        <Handle />
      </RadioContainer>

      <Content>
        {label && <Label>{label}</Label>}
        {caption && <Caption>{caption}</Caption>}
      </Content>
    </Container>
  );
}

const Handle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.2s;

  ::after {
    border-radius: 50%;
    content: '';
    top: 11px;
    left: 11px;
    width: 8px;
    height: 8px;
  }
`;

const Content = styled.div`
  margin-left: 8px;
`;

const Caption = styled.span`
  display: inline-block;
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.gray70};
`;

const Label = styled.span``;

const Container = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  :hover ${Handle} {
    background-color: ${({ theme: { colors } }) => colors.gray5};
  }

  &:has(> ${Content}):has(${Label} + ${Caption}) {
    align-items: flex-start;

    ${Label} {
      ${({ theme }) => theme.font.title4Semibold};
    }
  }
`;

const RadioContainer = styled.span`
  display: inline-block;
  position: relative;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  overflow: hidden;
`;

const Input = styled.input<{
  checked?: boolean;
  _uncheckedColor?: ThemeColor;
  _checkedColor?: ThemeColor;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;

  ${({
    theme: { colors },
    checked,
    _uncheckedColor = 'white',
    _checkedColor = 'black'
  }) =>
    checked
      ? css`
          & + ${Handle} {
            background-color: ${colors[_checkedColor]} !important;
            ::after {
              background-color: ${colors.white};
            }
          }
        `
      : css`
          & + ${Handle} {
            background-color: ${colors[_uncheckedColor]};
            box-shadow: inset 0 0 0 1px ${colors.gray30};
            ::after {
              background-color: ${colors.transparent};
            }
        `}
`;
