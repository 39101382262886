import { MouseEvent } from 'react';
import {
  FAQ,
  FAQItem,
  FAQProps,
  H1,
  Link,
  P
} from '@elfsight-universe/ui-common';
import { useCountPublicAppsQuery } from '@api';
import { beacon } from '@modules/beacon';
import { usePageContext } from '@modules/_app';
import { LayoutInner, LayoutSection } from '@components';
import { helpscoutArticles } from '@constants';

export type FAQSectionProps = Omit<FAQProps, 'items'> & {
  withExtraPadding?: boolean;
};

export function FAQSection({ withExtraPadding, ...props }: FAQSectionProps) {
  const { override } = usePageContext();
  const { data: publicAppsCountData = { count: 0 } } =
    useCountPublicAppsQuery();

  const openBeacon = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    override({ beaconHidden: false });
    beacon.openChat();
  };

  const openBeaconArticle =
    (articleId: string) => (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      override({ beaconHidden: false });
      beacon.article(articleId);
    };

  const FAQItems = getPricingFAQ(
    openBeacon,
    openBeaconArticle,
    publicAppsCountData.count
  );

  return (
    <LayoutSection
      _padding={withExtraPadding ? [60, 60] : undefined}
      _paddingMobile={withExtraPadding ? [40, 60] : undefined}
    >
      <LayoutInner _width={1000}>
        <H1 gb alignCenter>
          Frequently Asked Questions
        </H1>
        <FAQ items={FAQItems} {...props} />
      </LayoutInner>
    </LayoutSection>
  );
}

const getPricingFAQ = (
  openBeacon: (e: MouseEvent<HTMLElement>) => void,
  openBeaconArticle: (
    articleId: string
  ) => (e: MouseEvent<HTMLElement>) => void,
  publicAppsCount: number
): FAQItem[] => [
  {
    question: 'If I change my mind, can I get a refund?',
    answer: (
      <>
        Yes, you can receive a full refund for any reason within 14 days. For
        refunds after the 14-day period, check out our{' '}
        <Link href="https://elfsight.com/refund-policy/" target="_blank">
          Refund Policy
        </Link>
        .
      </>
    )
  },
  {
    question: 'Can I cancel my subscription at any time?',
    answer:
      'Yes, you can cancel your subscription at any time on your own in the Billing section.'
  },
  {
    question: 'Do you offer any discounts?',
    answer: (
      <>
        Yes, we offer numerous ways to save: welcome coupon, pack plans,
        discount for the second app, and more. You can see all of these offers
        in the{' '}
        <Link href="/deals" target="_blank">
          Deals section
        </Link>
        .
      </>
    )
  },
  {
    question: 'Can you help me with the widget installation on my site?',
    answer:
      'Yes, with paid plans, a free widget installation assistance option is available. Create a widget, subscribe to a paid plan, and in the Install window, click on the Request Installation tab, fill out the form and submit it. We will try to help as quickly and effectively as possible with the installation process.'
  },
  {
    question:
      'Is it more advantageous to subscribe to a monthly or annual plan?',
    answer: (
      <>
        It&apos;s most beneficial to subscribe to the annual plan as it allows
        you to <b>save up to 29%!</b>
      </>
    )
  },
  {
    question: "What's the advantage of the All Apps Pack subscription?",
    answer: (
      <>
        The Pack subscription allows you to access {publicAppsCount || '90+'}{' '}
        apps for the <b>price of just three!</b> This is a very profitable
        offer. With these apps, you can endlessly upgrade your site and maximize
        its returns.
      </>
    )
  },
  {
    question: 'How many views do I need?',
    answer: (
      <>
        The number of views equals the number of times the widget loads on your
        website. In order to select the proper plan, you need to sum up the
        views of pages where your widget will be installed. It is recommended to
        have some reserve of views when choosing a plan to account for any
        unexpected increase in traffic. This ensures that you have flexibility
        and can accommodate traffic spikes without exceeding your plan&apos;s
        limits. <b>The Pro plan</b> is an excellent option in this regard.{' '}
        <Link onClick={openBeaconArticle(helpscoutArticles.views.id)}>
          Learn More
        </Link>
        .
      </>
    )
  },
  {
    question:
      "I'm interested in the Enterprise plan, but I have a couple of questions",
    answer: (
      <>
        Thank you for your interest in our Enterprise plans. We would be happy
        to answer any questions you have. Please{' '}
        <Link href="https://elfsig.ht/enterprise-meeting" target="_blank">
          book a call
        </Link>{' '}
        with us, and our team will provide you with all the information you
        need.
      </>
    )
  },
  {
    question: 'Do you offer support?',
    answer: (
      <>
        <P>
          Yes! You can always contact our{' '}
          <Link onClick={openBeacon}>Support Team</Link> if any assistance
          required or if any questions come up. We can help you with:
        </P>
        <ul>
          <li>installation on your website;</li>
          <li>
            customization by means of widget&apos;s settings and CSS codes;
          </li>
          <li>bug fixing and troubleshooting;</li>
          <li>
            consultations on any questions related to our service and widgets.
          </li>
        </ul>
      </>
    )
  },
  {
    question: 'How to remove Elfsight branding from the widget?',
    answer:
      'To remove the Elfsight branding from the widget, simply upgrade to any paid plan.'
  }
];
