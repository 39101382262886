import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_CHANGELOG_TOPICS_PATH,
  GetChangelogTopicsResponse,
  GetChangelogTopicsResponseError
} from '@elfsight-universe/service-core-contracts/community-integration';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useChangelogTopicsQuery(
  appAlias: string | undefined,
  {
    enabled,
    ...options
  }: UseQueryOptions<
    GetChangelogTopicsResponse,
    GetChangelogTopicsResponseError
  > = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetChangelogTopicsResponse, GetChangelogTopicsResponseError>(
    [GET_CHANGELOG_TOPICS_PATH, appAlias],
    () =>
      client
        .get(GET_CHANGELOG_TOPICS_PATH, {
          searchParams: { appAlias: appAlias as string }
        })
        .json<GetChangelogTopicsResponse>(),
    {
      enabled:
        enabled !== undefined
          ? enabled && Boolean(appAlias) && isAuthenticated
          : Boolean(appAlias) && isAuthenticated,
      ...options
    }
  );
}
