import { Exclude, Expose } from 'class-transformer';
import { IsUUID } from 'class-validator';

export const GET_CONNECTED_WIDGET_PATH = 'wix-integration/get-connected-widget';

@Exclude()
export class GetConnectedWidgetRequest {
  @Expose()
  wixWidgetId: string;
}

@Exclude()
export class GetConnectedWidgetResponse {
  @Expose()
  @IsUUID()
  widgetPid: string | null;
}
