import { useMutation } from '@tanstack/react-query';
import {
  UNINSTALL_APP_PATH,
  UninstallAppResponseError
} from '@elfsight-universe/service-core-contracts/widget/uninstall-app';
import { client } from '../client';

export function useUninstallAppMutation(
  appAlias: string,
  onSuccess?: () => void
) {
  return useMutation<Response, UninstallAppResponseError>(
    () => client.post(UNINSTALL_APP_PATH, { json: { appAlias } }),
    {
      onSuccess
    }
  );
}
