import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  REQUEST_USER_VERIFICATION_PATH,
  RequestUserVerificationResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/request-user-verification';
import { client } from '../client';

export function useEmailResendConfirmMutation(
  options: UseMutationOptions<
    unknown,
    RequestUserVerificationResponseError
  > = {}
) {
  return useMutation<unknown, RequestUserVerificationResponseError>(
    (data) => client.post(REQUEST_USER_VERIFICATION_PATH, { json: data }),
    options
  );
}
