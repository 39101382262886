import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumber, IsOptional, IsString, Min } from 'class-validator';
import { BillingError, WidgetError } from '../errors';
import { ViewsCountingPeriod } from './classes';

export const GET_APP_USAGE_STATISTICS_PATH = 'billing/get-app-usage-statistics';

export class GetAppUsageStatisticsRequest {
  @IsString()
  appAlias: string;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(0)
  minusMonths: number;
}

@Exclude()
export class GetAppUsageStatisticsResponse {
  @Expose()
  @Type(() => GetAppUsageStatisticsResponseApp)
  app: {
    name: string;
    icon: string;
    alias: string;
    category: {
      color: string;
    };
  };

  @Expose()
  @Type(() => ViewsCountingPeriod)
  viewsCountingPeriod: {
    beginsAt: Date;
    endsAt: Date;
  };

  @Expose()
  @Type(() => GetAppUsageStatisticsResponseForecast)
  forecast:
    | {
        daysBeforeBlocked: number;
        accumulatedViewsBlocked: number;
        dailyViewsBlocked: number;
        date: Date;
      }
    | undefined;

  @Expose()
  @Type(() => GetAppUsageStatisticsResponseViewsDetailCount)
  viewsDetailCount: GetAppUsageStatisticsResponseViewsDetailCount[];

  @Expose()
  subscriptionName: string;

  @Expose()
  isBlocked: boolean;

  @Expose()
  isWarning: boolean;

  @Expose()
  countDays: number;

  @Expose()
  isEndCountingPeriod: boolean;

  @Expose()
  viewsCount: number;

  @Expose()
  viewsLimit: number | null;

  @Expose()
  daysToReset: number;
}

@Exclude()
export class GetAppUsageStatisticsResponseApp {
  @Expose()
  @Type(() => GetAppUsageStatisticsResponseCategory)
  category: {
    color: string;
  };

  @Expose()
  name: string;

  @Expose()
  icon: string;

  @Expose()
  alias: string;
}

@Exclude()
export class GetAppUsageStatisticsResponseCategory {
  @Expose()
  color: string;
}

@Exclude()
export class GetAppUsageStatisticsResponseViewsDetailCount {
  @Expose()
  date: Date;

  @Expose()
  dailyViews: number;

  @Expose()
  accumulatedViews: number;
}

@Exclude()
export class GetAppUsageStatisticsResponseForecast {
  @Expose()
  daysBeforeBlocked: number;

  @Expose()
  accumulatedViewsBlocked: number;

  @Expose()
  dailyViewsBlocked: number;

  @Expose()
  date: Date;
}

export type GetAppUsageStatisticsResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | WidgetError.APP_DOES_NOT_EXIST;
