import { useState } from 'react';
import { useRouterQueryUpdate } from '@elfsight-universe/ui-common';
import { ENTERPRISE_PARAM } from '../constants';

export function useEnterprisePlans(): [boolean, (value: boolean) => void] {
  const [isEnterprise, setEnterprise] = useState<boolean>(false);

  const updateEnterpriseQuery = useRouterQueryUpdate(
    ENTERPRISE_PARAM,
    (value) => {
      if (value) {
        setEnterprise(value === 'true');
      }
    }
  );

  const handleSetEnterprise = (value: boolean) => {
    setEnterprise(value);
    updateEnterpriseQuery(value ? 'true' : 'false');
  };

  return [isEnterprise, handleSetEnterprise];
}
