import { ReactNode } from 'react';
import { Normalize } from 'styled-normalize';
import {
  ThemeProvider as BaseThemeProvider,
  createGlobalStyle
} from 'styled-components';
import { theme } from './theme';
import { PropsWithTheme } from './theme-types';

const GlobalStyle = createGlobalStyle<PropsWithTheme>`
  body {
    min-width: 360px;
    visibility: visible !important;
    background: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.font.base};
    color: ${({ theme }) => theme.colors.black};
    -webkit-font-smoothing: antialiased;
  }
`;

export function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <BaseThemeProvider theme={theme}>
      <Normalize />
      <GlobalStyle />

      {children}
    </BaseThemeProvider>
  );
}
