import styled from 'styled-components';

export const HeaderDivider = styled.hr`
  height: 24px;
  border: 0;
  border-radius: 0.5px;
  background-color: ${({ theme }) => theme.colors.gray10};
  flex: 0 0 1px;
  margin: 0;
`;
