import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsString
} from 'class-validator';
import { Type } from 'class-transformer';

export const UPDATE_ANNOUNCEMENT_PATH = 'announcement/update-announcement';

export class UpdateAnnouncementRequest {
  @Type(() => Number)
  @IsNumber()
  id: number;

  @IsString()
  @IsNotEmpty({ message: 'Title is required.' })
  title: string;

  @IsString()
  body: string;

  @IsString()
  ctaButtonText: string;

  @IsString()
  ctaButtonLink: string;

  @Type(() => Boolean)
  @IsBoolean()
  ctaButtonLinkSSOEnabled: boolean;

  @IsArray()
  @Type(() => Number)
  @IsNumber(undefined, { each: true })
  tagIds: number[];
}

export type UpdateAnnouncementResponseError = 'ANNOUNCEMENT_DOES_NOT_EXISTS';
