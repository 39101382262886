import { IsEmail, IsNotEmpty } from 'class-validator';
import { IdentityAndAccessError } from '../errors';

export const UPDATE_EMAIL_PATH = 'identity-and-access/update-email';

export class UpdateEmailRequest {
  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail(undefined, { message: 'Email is invalid.' })
  email: string;
}

export type UpdateEmailResponseError =
  IdentityAndAccessError.USER_ALREADY_EXISTS;
