import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  REACTIVATE_SUBSCRIPTION_PATH,
  ReactivateSubscriptionRequest,
  ReactivateSubscriptionResponseError
} from '@elfsight-universe/service-core-contracts/billing/reactivate-subscription';
import { client } from '../client';

export function useReactivateSubscriptionMutation(
  options: Pick<
    UseMutationOptions<
      Response,
      ReactivateSubscriptionResponseError,
      ReactivateSubscriptionRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    Response,
    ReactivateSubscriptionResponseError,
    ReactivateSubscriptionRequest
  >(
    (data) => client.post(REACTIVATE_SUBSCRIPTION_PATH, { json: data }).json(),
    options
  );
}
