import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import addDays from 'date-fns/addDays';
import { SIGN_UP_LANDING_PAGE_META_COOKIE_NAME } from '@elfsight-universe/service-core-contracts/analytics/sign-up-landing-page-meta';

const { NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN } = process.env;

export function useCatchLandingPage() {
  const [cookies, setCookie] = useCookies();

  const signUpLandingPageCookie =
    cookies[SIGN_UP_LANDING_PAGE_META_COOKIE_NAME];
  const url = typeof window !== 'undefined' ? window.location.href : undefined;

  useEffect(() => {
    if (!signUpLandingPageCookie && url) {
      setCookie(SIGN_UP_LANDING_PAGE_META_COOKIE_NAME, url, {
        domain: NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN,
        path: '/',
        secure: true,
        sameSite: 'none',
        expires: addDays(new Date(), 30)
      });
    }
  }, [url, signUpLandingPageCookie]);
}
