import styled, { css } from 'styled-components';
import { DESKTOP, MOBILE } from '@elfsight-universe/ui-common';

export const LayoutSection = styled.section<{
  _withBackground?: boolean;
  _minHeight?: number;
  _padding?: number | [number, number];
  _paddingMobile?: number | [number, number];
}>`
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  ${({ _padding = [40, 60], _paddingMobile }) => {
    const [pt, pb] = Array.isArray(_padding) ? _padding : [_padding, _padding];

    let ptMobile: number;
    let pbMobile: number;

    if (_paddingMobile === undefined) {
      ptMobile = pt / 2;
      pbMobile = pb;
    } else {
      [ptMobile, pbMobile] = Array.isArray(_paddingMobile)
        ? _paddingMobile
        : [_paddingMobile, _paddingMobile];
    }

    return css`
      ${DESKTOP} {
        padding-top: ${pt}px;
        padding-bottom: ${pb}px;
      }
      ${MOBILE} {
        padding-top: ${ptMobile}px;
        padding-bottom: ${pbMobile}px;
      }
    `;
  }}
  background: ${({ theme, _withBackground }) => {
    if (_withBackground === undefined) {
      return 'transparent';
    }

    return _withBackground ? theme.colors.background : theme.colors.white;
  }};

  ${({ _minHeight }) =>
    _minHeight &&
    css`
      min-height: ${_minHeight}px;
    `};

  :only-child {
    flex-grow: 1;
  }
`;
