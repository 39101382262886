import { Exclude, Expose } from 'class-transformer';

export const HAS_ACTIVE_PAID_SUBSCRIPTION_PATH =
  'billing/has-active-paid-subscription';

@Exclude()
export class HasActivePaidSubscriptionResponse {
  @Expose()
  hasActivePaidSubscription: boolean;
}
