import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  REMOVE_ACCOUNT_MEMBER_PATH,
  RemoveAccountMemberRequest,
  RemoveAccountMemberResponseError
} from '@elfsight-universe/service-core-contracts/iam';
import { client } from '../client';

export function useRemoveAccountMemberMutation(
  options: UseMutationOptions<
    undefined,
    RemoveAccountMemberResponseError,
    RemoveAccountMemberRequest
  > = {}
) {
  return useMutation<
    undefined,
    RemoveAccountMemberResponseError,
    RemoveAccountMemberRequest
  >(
    ({ accountMemberPid }) =>
      client
        .post(REMOVE_ACCOUNT_MEMBER_PATH, {
          json: { accountMemberPid }
        })
        .json<undefined>(),
    {
      retry: false,
      ...options
    }
  );
}
