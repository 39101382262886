import { beacon } from '../../beacon';
import { useDoneOnboardingActionMutation, useOnboardingQuery } from '@api';
import { OnboardingActionType } from '@elfsight-universe/service-core-contracts/onboarding/onboarding-action-type.enum';

export function useWelcomeMessage(
  messageKey: 'welcomeMessage' | 'welcomeMessageFromDemo'
) {
  useOnboardingQuery({
    onSuccess: (data) => {
      if (data?.welcomeMessageReviewed) {
        return;
      }

      if (messageKey !== 'welcomeMessageFromDemo') {
        beacon.showMessage(messageKey, true);
      }

      done({ type: OnboardingActionType.REVIEW_WELCOME_MESSAGE });
    }
  });
  const { mutate: done } = useDoneOnboardingActionMutation();
}
