import {
  useInfiniteQuery,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import {
  GET_PUBLISHED_ANNOUNCEMENTS_PATH,
  GetPublishedAnnouncementsRequest,
  GetPublishedAnnouncementsResponse
} from '@elfsight-universe/service-core-contracts/announcement';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function usePublishedAnnouncementsInfiniteQuery(
  dto: Omit<Partial<GetPublishedAnnouncementsRequest>, 'page'>,
  options?: UseInfiniteQueryOptions<GetPublishedAnnouncementsResponse>
) {
  const { isAuthenticated } = useUser();
  return useInfiniteQuery<GetPublishedAnnouncementsResponse>(
    [GET_PUBLISHED_ANNOUNCEMENTS_PATH, ...Object.values(dto)],
    ({ pageParam }) =>
      client
        .get(GET_PUBLISHED_ANNOUNCEMENTS_PATH, {
          searchParams: pickBy(
            { ...dto, page: pageParam },
            (v) => v !== undefined
          )
        })
        .json<GetPublishedAnnouncementsResponse>(),
    {
      getNextPageParam: (data) =>
        data.meta.lastPage > data.meta.currentPage
          ? data.meta.currentPage + 1
          : undefined,
      enabled: isAuthenticated,
      ...options
    }
  );
}
