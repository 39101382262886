import { useQuery } from '@tanstack/react-query';
import {
  GET_INSTALLED_APPS_WITH_VIEWS_INDICATORS_PATH,
  GetInstalledAppsWithViewsIndicatorsResponse
} from '@elfsight-universe/service-core-contracts/widget/get-installed-apps-with-views-indicators';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useInstalledAppsWithViewsIndicatorsQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetInstalledAppsWithViewsIndicatorsResponse>(
    [GET_INSTALLED_APPS_WITH_VIEWS_INDICATORS_PATH],
    () => client.get(GET_INSTALLED_APPS_WITH_VIEWS_INDICATORS_PATH).json(),
    {
      enabled: isAuthenticated
    }
  );
}
