import styled from 'styled-components';
import { Button, Hint } from '@elfsight-universe/ui-common';
import { sendAnalyticsEvent } from '@api';
import { UPGRADE_PLAN_CLICK_EVENT_NAME } from '@modules/upgrade';

interface AppUsageBlockedProps {
  resetDate: string;
  upgradeURL: string;
}

export function AppUsageBlocked({
  upgradeURL,
  resetDate
}: AppUsageBlockedProps) {
  return (
    <BlockedContainer>
      <Blocked>
        Deactivated due to view limit reached&nbsp;
        <Hint
          width={300}
          content={`Your widgets no longer work on your website until the next views reset on ${resetDate}.`}
          iconColor="alert"
        />
      </Blocked>

      <Button
        href={upgradeURL}
        variation="promoPrimary"
        fullWidth
        center
        small
        onClick={() => {
          sendAnalyticsEvent({
            name: UPGRADE_PLAN_CLICK_EVENT_NAME,
            params: {
              button_name: 'Upgrade Now',
              button_place: 'Sidebar'
            }
          });
        }}
      >
        Upgrade Now
      </Button>
    </BlockedContainer>
  );
}

const BlockedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
  z-index: ${({ theme }) => theme.zIndex.appUsageBlocked};
`;

const Blocked = styled.div`
  margin-top: 2px;
  ${({ theme }) => theme.font.caption};
  color: ${({ theme }) => theme.colors.alert};
`;
