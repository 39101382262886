import { Exclude, Expose, Type } from 'class-transformer';

export const GET_PROMOTED_APPS_PATH = 'deals/get-promoted-apps';

@Exclude()
export class GetPromotedAppsResponse {
  @Expose()
  @Type(() => PromotedApp)
  payload: PromotedApp[];
}

@Exclude()
class PromotedApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  basePrice: number;

  @Expose()
  discountPrice: number;
}
