import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { getDealURL } from '@modules/deals/utils';

type DealWithCustomURLDetailTemplateProps = AccountDeal;

export function DealWithCustomURLDetailTemplate(
  accountDeal: DealWithCustomURLDetailTemplateProps
) {
  const router = useRouter();

  const url = getDealURL(accountDeal);

  useEffect(() => {
    router.replace(url);
  }, [url]);

  return null;
}
