import { ReactNode } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import {
  formatAbbrNumber,
  MOBILE,
  MOBILE_WIDTH
} from '@elfsight-universe/ui-common';

export type CommunityCardProps = {
  children: ReactNode;
  replies: number;
  views: number;
  date?: string;
  profilePictures: string[];
};

export function CommunityCard({
  replies,
  views,
  date,
  profilePictures,
  children,
  ...forwardingProps
}: CommunityCardProps) {
  const isMobileQuery = useMediaQuery({ maxWidth: MOBILE_WIDTH });

  return (
    <Container {...forwardingProps}>
      <Content>{children}</Content>

      <UserAvatars>
        {profilePictures.map((pic) => (
          <UserAvatar key={pic} src={pic} width={24} height={24} />
        ))}
      </UserAvatars>

      <Activity>
        <Replies>
          {`${formatAbbrNumber(replies)} ${
            replies === 1 ? 'reply' : 'replies'
          }`}
        </Replies>

        <Views>
          {`${formatAbbrNumber(views)} ${views === 1 ? 'view' : 'views'}`}
        </Views>

        {date && isMobileQuery && <TopicDate>{date}</TopicDate>}
      </Activity>
    </Container>
  );
}

const Container = styled.li`
  position: relative;

  display: grid;
  grid-template-columns: 4fr 80px minmax(160px, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${MOBILE} {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
  }

  @supports (grid-template-columns: subgrid) {
    & {
      grid-column-gap: initial;
      grid-column: span 4;
      grid-template-columns: subgrid;
      grid-template-rows: 1fr;

      ${MOBILE} {
        grid-column: span 3;
      }
    }
  }
`;

const Content = styled.div`
  @supports (grid-template-columns: subgrid) {
    ${MOBILE} {
      grid-column: span 3;
    }
  }
`;

const UserAvatars = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: 4px;

  @supports (grid-template-columns: subgrid) {
    ${MOBILE} {
      grid-column: span 3;
    }
  }
`;

const UserAvatar = styled.img`
  border-radius: 50%;
`;

const Activity = styled.div`
  ${({ theme }) => theme.font.title4};

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 12px;
  color: ${({ theme }) => theme.colors.gray50};

  ${MOBILE} {
    display: grid;
    grid-template-columns: 1fr 1fr 1.2fr;
    grid-template-rows: 1fr;
    justify-content: start;
    align-items: end;
  }

  @supports (grid-template-columns: subgrid) {
    display: grid;
    grid-column: span 2;
    grid-template-columns: subgrid;
    gap: 20px;
    justify-items: end;

    ${MOBILE} {
      grid-column: span 3;
      gap: 4px;
      justify-items: start;
    }
  }
`;

const Replies = styled.span``;

const Views = styled.span``;

const TopicDate = styled.span``;
