import { Type } from 'class-transformer';
import { IsNumber } from 'class-validator';

export const UNPUBLISH_ANNOUNCEMENT_PATH =
  'announcement/unpublish-announcement';

export class UnpublishAnnouncementRequest {
  @Type(() => Number)
  @IsNumber()
  id: number;
}

export type UnpublishAnnouncementResponseError = 'ANNOUNCEMENT_DOES_NOT_EXISTS';
