import {
  FeatureTooltip,
  FeatureTooltipProps
} from '../../../components/feature-tooltip';

export function CollaboratorsLimitTooltip({
  children,
  ...forwardingProps
}: Omit<FeatureTooltipProps, 'content'>) {
  return (
    <FeatureTooltip
      content={<>Invite members to collaborate on widgets together.</>}
      {...forwardingProps}
    >
      {children}
    </FeatureTooltip>
  );
}
