import {
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
  KeyboardEvent
} from 'react';
import { useRect } from 'react-use-rect';
import { Popover, PopoverPosition, PopoverProps } from 'react-tiny-popover';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { isKey } from '@elfsight-universe/ui-common/src/utils';
import { useTheme } from '@elfsight-universe/ui-common';
import { SelectOption } from './select-option';
import { SearchInput } from '../search-input';
import { SelectPopoverItem } from './select-option';
import { slideInTranslateMixin } from '../../popovers/slide-in-translate-mixin';
import { customScrollbarMixin } from '../../custom-scrollbar-mixin';

export type SelectChangeEvent<T extends string = string> = {
  target: {
    value: T;
  };
};

export type SelectPopoverLegacyProps<T extends string = string> = Omit<
  PopoverProps,
  'content'
> &
  PropsWithChildren<{
    value?: T;
    onChange: (event: SelectChangeEvent<T>) => void;
    options: SelectOption[];
    withSearch?: boolean;
    width?: number;
    maxHeight?: number;
    maxWidth?: number;
    autoFocus?: boolean;
    onClose: () => void;
    withPositionFixedParent?: boolean;
  }>;

export function SelectPopoverLegacy({
  children,
  value,
  isOpen,
  onClickOutside,
  options,
  onChange,
  withSearch,
  width,
  maxHeight,
  maxWidth,
  autoFocus = true,
  onClose,
  withPositionFixedParent,
  ...forwardingProps
}: SelectPopoverLegacyProps) {
  const { zIndex: themeZIndex } = useTheme();
  const contentRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerRef] = useRect((rect) => setContainerWidth(rect.width), {
    resize: true
  });

  const [filter, setFilter] = useState('');

  const filteredOptions = options.filter(({ value, label }) => {
    const lowerFilter = filter.toLowerCase();
    return (
      value.toLowerCase().includes(lowerFilter) ||
      label.toLowerCase().includes(lowerFilter)
    );
  });

  const currentFilteredOption = filteredOptions.find(
    ({ value: optionValue }) => optionValue === value
  );

  const [focusedOption, setFocusedOption] = useState<SelectOption | undefined>(
    currentFilteredOption
  );

  /**
   * Set focus on option when use search field
   */
  useEffect(() => {
    const focusedOptionInList = filteredOptions.some(
      (option: SelectOption) => option.value === focusedOption?.value
    );
    if (!focusedOptionInList) {
      setFocusedOption(filteredOptions[0]);
    }
  }, [filteredOptions]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (!isOpen) return;

      if (autoFocus && withSearch && searchInputRef.current) {
        searchInputRef.current.focus();
      }

      if (autoFocus && !withSearch && contentRef.current) {
        contentRef.current.focus();
      }

      setFocusedOption(currentFilteredOption);
    });
  }, [isOpen, autoFocus, withSearch, currentFilteredOption]);

  const handleOptionMouseEnter = (option: SelectOption) => {
    setFocusedOption(option);
  };

  const controlByKeys = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isKey('ArrowUp', event)) {
      return handleArrowsPress(event, false);
    }

    if (isKey('ArrowDown', event)) {
      return handleArrowsPress(event, true);
    }

    if (isKey('Enter', event)) {
      return handleEnterPress(event);
    }

    if (isKey('Tab', event)) {
      return handleTabPress(event);
    }

    if (isKey('Escape', event)) {
      return closePopover();
    }
  };

  const handleArrowsPress = (
    event: KeyboardEvent<HTMLInputElement>,
    isDown?: boolean
  ) => {
    const lastIndex = filteredOptions.length - 1;
    const focusedOptionIndex = filteredOptions.findIndex(
      (item) => item.value === focusedOption?.value
    );

    const getNextOptionIndex = (currentIndex: number) => {
      if (currentIndex === lastIndex && isDown) {
        return 0;
      }

      if ((currentIndex === 0 || currentIndex === -1) && !isDown) {
        return lastIndex;
      }

      return currentIndex + (isDown ? 1 : -1);
    };

    const getNextEnabledOption = (currentItemIndex: number) => {
      const nextIndex = getNextOptionIndex(currentItemIndex);
      return filteredOptions[nextIndex];
    };

    event.preventDefault();

    const nextOption = getNextEnabledOption(focusedOptionIndex);
    setFocusedOption(nextOption);
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (filteredOptions.length <= 0) {
      return;
    }

    if (isOpen && (focusedOption?.value || focusedOption?.value === '')) {
      event.preventDefault();

      handleOptionSelect(focusedOption.value);
      setFilter('');
    }
  };

  const handleTabPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (filter.length <= 0) {
      closePopover?.();
      event.preventDefault();
    }
  };

  const handleOptionSelect = (value: string) => {
    onChange({ target: { value } });
  };

  const closePopover = () => {
    onClose();
  };

  const handleSearchReset = () => {
    setFilter('');
    searchInputRef.current?.focus();
  };

  return (
    <Popover
      {...forwardingProps}
      isOpen={isOpen}
      ref={containerRef}
      containerStyle={{
        zIndex: themeZIndex.popoverDefault.toString(),
        minWidth: `${containerWidth}px`,
        maxWidth: maxWidth ? `${maxWidth}px` : undefined,
        width: width ? `${width}px` : undefined
      }}
      positions={['bottom', 'top']}
      align="center"
      padding={4}
      boundaryInset={12}
      onClickOutside={onClickOutside}
      content={({ parentRect, popoverRect, boundaryInset, position }) => {
        const calculatedMaxHeight = withPositionFixedParent
          ? getFixedParentRelatedMaxHeight(
              popoverRect,
              position as 'top' | 'bottom',
              boundaryInset
            )
          : parentRect.height +
            parentRect.top -
            popoverRect.top -
            boundaryInset;

        const finalMaxHeight =
          maxHeight && maxHeight < calculatedMaxHeight
            ? maxHeight
            : calculatedMaxHeight;

        return (
          <CSSTransition
            nodeRef={contentRef}
            classNames="slide-in"
            timeout={0}
            in
            appear
          >
            <Content
              tabIndex={!withSearch ? 0 : undefined}
              ref={contentRef}
              _position={position}
              style={{
                maxHeight: finalMaxHeight
              }}
              onKeyDown={controlByKeys}
            >
              {withSearch && (
                <SearchContainer>
                  <SearchInput
                    ref={searchInputRef}
                    value={filter}
                    onChange={({ target: { value } }) => setFilter(value)}
                    onReset={handleSearchReset}
                  />
                </SearchContainer>
              )}

              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <SelectPopoverItem
                    onMouseEnter={() => handleOptionMouseEnter(option)}
                    focused={option.value === focusedOption?.value}
                    key={option.value}
                    selected={option.value === value}
                    onChange={(event) => handleOptionSelect(event.target.value)}
                    {...option}
                  />
                ))
              ) : (
                <Empty>No results found</Empty>
              )}
            </Content>
          </CSSTransition>
        );
      }}
    >
      {children}
    </Popover>
  );
}

function getFixedParentRelatedMaxHeight(
  popoverRect: ClientRect,
  position: 'top' | 'bottom',
  boundaryInset: number
) {
  if (position === 'top') {
    return popoverRect.bottom - boundaryInset;
  }

  return window.innerHeight - popoverRect.top - boundaryInset;
}

const Empty = styled.div`
  color: ${({ theme }) => theme.colors.gray50};
  padding: 0 16px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchContainer = styled.div`
  padding: 8px 16px 16px;
`;

const Content = styled.div<{ _position?: PopoverPosition }>`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px 0;
  overflow-y: auto;
  outline: none;

  ${slideInTranslateMixin(8)}
  ${customScrollbarMixin}
`;
