import { IsNotEmpty, IsUUID } from 'class-validator';
import { BillingError, PaddleError } from '../errors';

export const REACTIVATE_SUBSCRIPTION_PATH = 'billing/reactivate-subscription';

export class ReactivateSubscriptionRequest {
  @IsUUID()
  pid: string;

  @IsNotEmpty()
  checkoutId: string;
}

export type ReactivateSubscriptionResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANNOT_BE_ACTIVATED
  | PaddleError.ORDER_DOES_NOT_EXIST;
