import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { Link, P } from '@elfsight-universe/ui-common';
import {
  Coupon,
  DefaultContainer,
  HowToApply,
  HowToApplyListItem
} from '@modules/deals/ui';
import { beacon } from '@modules/beacon';
import { helpscoutArticles } from '@constants';

const howToUseCouponLink = (
  <Link
    color="accent"
    onClick={() => beacon.article(helpscoutArticles.coupon.id)}
  >
    How to use coupon
  </Link>
);

type WelcomeDetailTemplateProps = AccountDeal;

export function WelcomeDetailTemplate({
  deal,
  coupon
}: WelcomeDetailTemplateProps) {
  const { title, caption, textImage, alias } = deal;

  return (
    <DefaultContainer title={title} textImage={textImage} alias={alias}>
      <P big>{caption}</P>

      <HowToApply>
        <HowToApplyListItem>
          <span>1. Copy 20% discount coupon code below:</span>
          <div>
            <Coupon coupon={coupon?.code as string} />
          </div>
        </HowToApplyListItem>
        <HowToApplyListItem>
          <span>
            2. Select any paid plan and apply the coupon during checkout.
          </span>
        </HowToApplyListItem>
        <HowToApplyListItem>
          <span>{howToUseCouponLink}</span>
        </HowToApplyListItem>
      </HowToApply>
    </DefaultContainer>
  );
}
