import { useEffect, useState } from 'react';
import { isInternalEmail } from '@elfsight-universe/shared';
import { useUser } from '@modules/_app';
import { parseBool } from '@utils';
import { GoogleTagManager } from './google-tag-manager';
import { FacebookPixel } from './facebook-pixel';
import { TwitterPixel } from './twitter-pixel';
import { ProfitWellEngagement } from './profitwell';

const { NEXT_PUBLIC_ANALYTICS_ENABLED } = process.env;

export function Analytics() {
  const { user, isLoading: isUserLoading } = useUser();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (isUserLoading) return;
    const internalUser = isInternalEmail(user?.email);
    setEnabled(parseBool(NEXT_PUBLIC_ANALYTICS_ENABLED) && !internalUser);
  }, [user?.email, isUserLoading]);

  if (!enabled) {
    return null;
  }

  return (
    <>
      <GoogleTagManager />
      <FacebookPixel />
      <TwitterPixel />
      <ProfitWellEngagement />
    </>
  );
}
