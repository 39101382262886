import { useQueryClient } from '@tanstack/react-query';
import {
  GET_COLLABORATORS_PATH,
  GET_COLLABORATORS_USAGE_PATH,
  GET_COLLABORATORS_FEATURE_SUMMARY_PATH
} from '@elfsight-universe/service-core-contracts/iam';

export function useInvalidateCollaboratorsRelatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        [
          GET_COLLABORATORS_PATH,
          GET_COLLABORATORS_USAGE_PATH,
          GET_COLLABORATORS_FEATURE_SUMMARY_PATH
        ].includes(queryKey[0] as string)
    });
  };
}
