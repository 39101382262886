import { IsNotEmpty, IsString, MaxLength } from 'class-validator';
import { Exclude } from 'class-transformer';
import { Project } from './classes';

export const CREATE_PROJECT_PATH = 'project/create-project';

export class CreateProjectRequest {
  @IsString()
  @IsNotEmpty({ message: 'Please provide a name for your project.' })
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  name: string;
}

@Exclude()
export class CreateProjectResponse extends Project {}

export type CreateProjectResponseError = 'PROJECTS_LIMIT_REACHED';
