import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class Project {
  @Expose()
  pid: string;

  @Expose()
  createdAt: Date;

  @Expose()
  updatedAt: Date;

  @Expose()
  name: string;

  @Expose()
  active: boolean;
}
