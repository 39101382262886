import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumber, IsOptional, IsUUID, Max, Min } from 'class-validator';
import type { JsonObject } from '../json.type';

export const GET_APP_TEMPLATES_PATH = 'widget/get-app-templates';

export class GetAppTemplatesRequest {
  @IsUUID()
  templateCategoryPid: string;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  page = 1;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  @Max(100)
  itemsPerPage = 20;
}

@Exclude()
export class GetAppTemplatesResponse {
  @Expose()
  @Type(() => GetAppTemplatesMeta)
  meta: {
    totalItems: number;
    lastPage: number;
    currentPage: number;
  };

  @Expose()
  @Type(() => GetAppTemplatesResponseTemplate)
  payload: GetAppTemplatesResponseTemplate[];
}

@Exclude()
export class GetAppTemplatesMeta {
  @Expose()
  totalItems: number;

  @Expose()
  lastPage: number;

  @Expose()
  currentPage: number;
}

@Exclude()
export class GetAppTemplatesResponseTemplate {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  thumbnailURL: string;

  @Expose()
  settings: JsonObject;
}
