import { useQuery } from '@tanstack/react-query';
import {
  GET_USER_ACCOUNTS_PATH,
  GetUserAccountsResponse
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useUserAccountsQuery() {
  const { isAuthenticated } = useUser();

  return useQuery<GetUserAccountsResponse>(
    [GET_USER_ACCOUNTS_PATH],
    () => client.get(GET_USER_ACCOUNTS_PATH).json<GetUserAccountsResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
