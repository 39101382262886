import { IsEmail, IsNotEmpty } from 'class-validator';
import { IdentityAndAccessError } from '../errors';

export const REQUEST_PASSWORD_RESET_PATH =
  'identity-and-access/request-password-reset';

export class RequestPasswordResetRequest {
  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail(undefined, { message: 'Email is invalid.' })
  email: string;
}

export type RequestPasswordResetResponseError =
  | IdentityAndAccessError.USER_DOES_NOT_EXISTS
  | IdentityAndAccessError.USER_DELETED;
