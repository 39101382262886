export enum AccountSidebarURL {
  PROFILE = '/account/profile',
  NOTIFICATIONS = '/account/notifications',
  SECURITY = '/account/security',
  BILLING = '/billing',
  MEMBERS = '/members'
}

export type AccountSidebarMenuGroup = {
  groupName: string;
  items: AccountSidebarMenuItem[];
};

export interface AccountSidebarMenuItem {
  label: string;
  url: AccountSidebarURL;
}

export class AccountSidebarMenuBuilder {
  groups: Map<string, AccountSidebarMenuItem[]>;

  constructor() {
    this.groups = new Map();
  }

  static create() {
    return new this();
  }

  addGroup(name: string) {
    this.groups.set(name, []);

    return this;
  }

  addItem(item: AccountSidebarMenuItem, groupName: string) {
    const currentItems = this.groups.get(groupName) || [];
    this.groups.set(groupName, [...currentItems, item]);

    return this;
  }

  build(): AccountSidebarMenuGroup[] {
    return Array.from(this.groups).map(([group, items]) => ({
      groupName: group,
      items
    }));
  }
}

export interface BuildAccuontSidebarMenuOptions {
  withNotifications?: boolean;
  withCollaborators?: boolean;
}

export function buildBaseAccountSidebaMenuItems(
  options?: BuildAccuontSidebarMenuOptions
): AccountSidebarMenuGroup[] {
  const builder = AccountSidebarMenuBuilder.create();
  const accountGroup = 'account';

  builder.addGroup(accountGroup).addItem(
    {
      label: 'Profile',
      url: AccountSidebarURL.PROFILE
    },
    accountGroup
  );

  if (options?.withNotifications) {
    builder.addItem(
      {
        label: 'Notifications',
        url: AccountSidebarURL.NOTIFICATIONS
      },
      accountGroup
    );
  }

  builder.addItem(
    {
      label: 'Account & Security',
      url: AccountSidebarURL.SECURITY
    },
    accountGroup
  );

  if (!options?.withCollaborators) {
    builder.addItem(
      {
        label: 'Billing',
        url: AccountSidebarURL.BILLING
      },
      accountGroup
    );
  }

  if (!options?.withCollaborators) {
    return builder.build();
  }

  const collaboratorsGroup = 'workspace';

  builder.addGroup(collaboratorsGroup);
  builder.addItem(
    {
      label: 'Members',
      url: AccountSidebarURL.MEMBERS
    },
    collaboratorsGroup
  );
  builder.addItem(
    {
      label: 'Billing',
      url: AccountSidebarURL.BILLING
    },
    collaboratorsGroup
  );

  return builder.build();
}
