import Router from 'next/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FINISH_IMPERSONATE_CLIENT_PATH } from '@elfsight-universe/service-core-contracts/identity-and-access/finish-impersonate-client';
import { beacon } from '@modules/beacon';
import { client } from '@api';

export function useFinishImpersonateClientMutation() {
  const queryClient = useQueryClient();

  return useMutation(() => client.post(FINISH_IMPERSONATE_CLIENT_PATH), {
    onSuccess: () => {
      queryClient.clear();
      Router.replace('/');
      beacon.logout();
      window.close();
    }
  });
}
