export function isInternalEmail(email?: string) {
  const INTERNAL_DOMAINS = [
    'elfsight.com',
    'elfsightdev.com',
    'tempmail.elfsight.dev'
  ];
  const domainsString = INTERNAL_DOMAINS.join('|').replace('.', '.');
  const regExp = new RegExp(`^.*@(${domainsString})$`, 'i');

  return email ? regExp.test(email) : false;
}
