import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class PaginationResponse {
  @Expose()
  totalItems: number;

  @Expose()
  lastPage: number;
}
