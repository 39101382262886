import {
  CreateFeatureOptions,
  FeatureResolver
} from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';

export const appsCountFeatureResolver: FeatureResolver = ({
  appsCount = 1
}: CreateFeatureOptions) => {
  if (appsCount > 1) {
    return {
      highlight: true,
      text: (
        <>
          All {appsCount} Elfsight apps
          <br />+ upcoming apps
        </>
      )
    };
  }
};
