import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  REQUEST_REACTIVATE_SUBSCRIPTION_PATH,
  RequestReactivateSubscriptionRequest,
  RequestReactivateSubscriptionResponse,
  RequestReactivateSubscriptionResponseError
} from '@elfsight-universe/service-core-contracts/billing';
import { client } from '../client';

export function useRequestReactivateSubscriptionMutation(
  options: Pick<
    UseMutationOptions<
      RequestReactivateSubscriptionResponse,
      RequestReactivateSubscriptionResponseError,
      RequestReactivateSubscriptionRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    RequestReactivateSubscriptionResponse,
    RequestReactivateSubscriptionResponseError,
    RequestReactivateSubscriptionRequest
  >(
    (data) =>
      client.post(REQUEST_REACTIVATE_SUBSCRIPTION_PATH, { json: data }).json(),
    options
  );
}
