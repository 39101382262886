import { useState } from 'react';
import styled from 'styled-components';
import { useRect } from 'react-use-rect';
import { Link, MOBILE } from '@elfsight-universe/ui-common';
import { RegularEnterpriseArrowSwitch } from './components/regular-enterprise-arrow-switch';
import { RegularEnterpriseBannerSwitch } from './components/regular-enterprise-banner-switch';
import { PricingTableEnterpriseEmpty } from './pricing-table-enterprise-empty';
import { PricingTableProps } from './pricing-table-types';
import { PricingTableMobileView } from './pricing-table-mobile-view';
import { PricingTableRegularView } from './pricing-table-regular-view';
import {
  PricingFeaturesSingleApp,
  PricingFeaturesAllAppsPack
} from '../pricing-app-features';

const COLUMN_MIN_WIDTH = 230;

export interface PrimaryPricingTableProps extends PricingTableProps {
  onRegularEnterpriseSwitchRequest: (toEnterprise: boolean) => void;
  isPack?: boolean;
}

export function PricingTable({
  plans,
  billingInterval,
  isEnterprise,
  reverseOrder,
  alwaysMobile,
  appName,
  onRegularEnterpriseSwitchRequest,
  isPack,
  ...forwardingProps
}: PrimaryPricingTableProps) {
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerRef] = useRect((rect) => setContainerWidth(rect.width), {
    resize: true
  });
  const thisPeriodPlans = plans.filter(
    (plan) =>
      !plan.properties.hidden &&
      plan.billingInterval === billingInterval &&
      plan.isEnterprisePlan === isEnterprise
  );

  const isMobileView =
    alwaysMobile || containerWidth / COLUMN_MIN_WIDTH < thisPeriodPlans.length;

  if (reverseOrder) {
    thisPeriodPlans.reverse();
  }

  const shouldDisplayEnterpriseEmpty =
    isEnterprise && thisPeriodPlans.length === 0;
  const shouldDisplayRegularView =
    !shouldDisplayEnterpriseEmpty && !isMobileView;
  const shouldDisplayMobileView = !shouldDisplayEnterpriseEmpty && isMobileView;
  const shouldDisplayArrowSwitch = !isMobileView;
  const shouldDisplayBannerSwitch = isMobileView;

  return (
    <Container ref={containerRef}>
      <TableWrapper $center={shouldDisplayRegularView}>
        {shouldDisplayEnterpriseEmpty && (
          <PricingTableEnterpriseEmpty appName={appName} />
        )}
        {shouldDisplayRegularView && (
          <PricingTableRegularView
            plans={thisPeriodPlans}
            billingInterval={billingInterval}
            {...forwardingProps}
          />
        )}
        {shouldDisplayMobileView && (
          <PricingTableMobileView
            plans={thisPeriodPlans}
            billingInterval={billingInterval}
            {...forwardingProps}
          />
        )}
        {shouldDisplayArrowSwitch && (
          <RegularEnterpriseArrowSwitch
            isEnterprise={isEnterprise}
            onSwitchRequest={onRegularEnterpriseSwitchRequest}
          />
        )}
      </TableWrapper>

      {isPack ? (
        <PricingFeaturesAllAppsPack isEnterprise={isEnterprise} />
      ) : (
        <PricingFeaturesSingleApp isEnterprise={isEnterprise} />
      )}

      {isEnterprise && (
        <Booking>
          Looking to discuss your options for Enterprise plans?{' '}
          <Link color="accent" href="https://elfsig.ht/enterprise-meeting">
            Book a meeting now!
          </Link>
        </Booking>
      )}
      {shouldDisplayBannerSwitch && (
        <RegularEnterpriseBannerSwitch
          isEnterprise={isEnterprise}
          onSwitchRequest={onRegularEnterpriseSwitchRequest}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableWrapper = styled.div<{ $center: boolean }>`
  position: relative;
  align-self: ${({ $center }) => ($center ? 'center' : 'stretch')};
`;

const Booking = styled.div`
  margin-top: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  ${MOBILE} {
    margin-top: 32px;
  }
`;
