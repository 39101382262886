import styled, { css } from 'styled-components';
import { DESKTOP, MOBILE } from '@elfsight-universe/ui-common';

export const LayoutInner = styled.div<{
  _width?: number;
  _fullHeight?: boolean;
  _textCenter?: boolean;
  _flexVertical?: boolean;
}>`
  position: relative;
  ${({ _flexVertical = false }) =>
    _flexVertical && 'display: flex; flex-direction: column;'}
  ${({ _fullHeight = true }) => _fullHeight && 'flex-grow: 1;'}
  ${({ _textCenter = false }) => _textCenter && 'text-align: center;'}
  box-sizing: content-box;
  ${({ _width = 800 }) =>
    _width &&
    css`
      max-width: ${_width}px;
    `}
  ${DESKTOP} {
    width: calc(100% - 80px);
    padding: 0 40px;
  }
  ${MOBILE} {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;
