import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  ASSIGN_WIDGET_TO_PROJECT_PATH,
  AssignWidgetToProjectRequest,
  AssignWidgetToProjectResponseError
} from '@elfsight-universe/service-core-contracts/widget';
import { client } from '../client';

export function useAssignWidgetToProjectMutation(
  options: UseMutationOptions<
    Response,
    AssignWidgetToProjectResponseError,
    AssignWidgetToProjectRequest
  > = {}
) {
  return useMutation<
    Response,
    AssignWidgetToProjectResponseError,
    AssignWidgetToProjectRequest
  >(
    (data) =>
      client.post(ASSIGN_WIDGET_TO_PROJECT_PATH, {
        json: data
      }),
    {
      retry: false,
      ...options
    }
  );
}
