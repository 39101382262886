import { Transform } from 'class-transformer';
import { IsString } from 'class-validator';
import { BillingError } from '../../errors';
import { PaddleSubscriptionStatus } from '../enums/paddle-subscription-status.enum';
import { PaddleAlertName } from '../enums/paddle-alert-name';
import type { PaddlePassthrough } from '../interfaces/paddle-passthrough';
import { toJSON } from '../../utils/to-json';
import { JsonObject, JsonValue } from '../../json.type';

export class SubscriptionCreatedRequest implements JsonObject {
  [K: string]: JsonValue;

  @IsString()
  alert_id: string;

  @IsString()
  alert_name: PaddleAlertName;

  @IsString()
  cancel_url: string;

  @IsString()
  checkout_id: string;

  @IsString()
  currency: string;

  @IsString()
  email: string;

  @IsString()
  event_time: string;

  @IsString()
  linked_subscriptions: string;

  @IsString()
  marketing_consent: string;

  @IsString()
  next_bill_date: string;

  @Transform(({ value }) => toJSON(value))
  passthrough: PaddlePassthrough;

  @IsString()
  quantity: string;

  @IsString()
  source: string;

  @IsString()
  status: PaddleSubscriptionStatus;

  @IsString()
  subscription_id: string;

  @IsString()
  subscription_plan_id: string;

  @IsString()
  unit_price: string;

  @IsString()
  update_url: string;

  @IsString()
  user_id: string;
}

export type SubscriptionCreatedResponseError =
  BillingError.SUBSCRIPTION_DOES_NOT_EXIST;
