import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_LAST_USED_INSTALLED_APP_PATH,
  GetLastUsedInstalledAppResponse
} from '@elfsight-universe/service-core-contracts/widget/get-last-used-installed-app';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useLastUsedInstalledAppQuery({
  enabled = true,
  ...options
}: UseQueryOptions<GetLastUsedInstalledAppResponse> = {}) {
  const { isAuthenticated } = useUser();
  return useQuery<GetLastUsedInstalledAppResponse>(
    [GET_LAST_USED_INSTALLED_APP_PATH],
    () =>
      client
        .get(GET_LAST_USED_INSTALLED_APP_PATH)
        .json<GetLastUsedInstalledAppResponse>(),
    {
      enabled: enabled && isAuthenticated,
      ...options
    }
  );
}
