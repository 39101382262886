import styled from 'styled-components';

type ProgressBarProps = {
  progress?: number;
};

export function ProgressBar({ progress = 0 }: ProgressBarProps) {
  return (
    <Container>
      <InnerBar _progress={progress} />
    </Container>
  );
}

const Container = styled.div`
  height: 6px;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray10};
`;

const InnerBar = styled.div<{ _progress: number }>`
  height: 6px;
  width: ${({ _progress }) => _progress}%;
  border-radius: 12px;
  background: linear-gradient(270deg, #11c031 0%, #33de3a 100%);
  transition: width 0.4s;
`;
