import styled, { css } from 'styled-components';
import { MOBILE } from '@elfsight-universe/ui-common';
import titleRectangle from './assets/title-rectangle.svg?url';
import limitRectangle from './assets/limit-rectangle.svg?url';

type HeadingLocation = 'page' | 'modal';

type BlackFridayHeadingProps = {
  headingLocation?: HeadingLocation;
};

export function BlackFridayHeading({
  headingLocation = 'page'
}: BlackFridayHeadingProps) {
  return (
    <Container>
      <Limit>Only 24 hours</Limit>
      <Title _headingLocation={headingLocation}>Black Friday Sale</Title>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Limit = styled.div`
  margin: 0 0 16px;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;

  &::before {
    content: url(${limitRectangle.src});
    position: absolute;
    font-size: 0;
    line-height: 0;
    top: 50%;
    left: 50%;
    z-index: -1;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Title = styled.h1<{ _headingLocation: HeadingLocation }>`
  margin: 0;
  display: block;
  position: relative;
  z-index: 0;
  text-align: center;
  font-size: 28px;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};

  &::before {
    content: url(${titleRectangle.src});
    position: absolute;
    font-size: 0;
    line-height: 0;
    top: 50%;
    left: 50%;
    z-index: -1;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
  }

  ${MOBILE} {
    ${({ _headingLocation }) =>
      _headingLocation === 'modal' &&
      css`
        font-size: 24px;
        &::before {
          transform: translateX(-50%) translateY(-50%) scale(0.9);
        }
    `}
  }
`;
