import { Hint, ThemeColor } from '@elfsight-universe/ui-common';
import { CreateFeatureOptions, FeatureResolver } from '../../feature-resolver';
import { mapAdditionalFeatures } from './map-additional-features';
import { AdditionalFeature, PACKS_FEATURES } from './additional-features';

export const additionalFeaturesFeatureResolver: FeatureResolver = ({
  isPackPlan,
  isEnterprisePlan,
  appAlias
}: CreateFeatureOptions) => {
  if (!isEnterprisePlan) {
    return;
  }

  if (isPackPlan) {
    return PACKS_FEATURES.map((additionalFeature) => ({
      text: createTextForAdditionalFeature(additionalFeature, 'white')
    }));
  }

  if (appAlias) {
    const additionalFeatures = mapAdditionalFeatures(appAlias);

    if (!additionalFeatures) {
      return;
    }

    return additionalFeatures.map((additionalFeature) => ({
      text: createTextForAdditionalFeature(additionalFeature)
    }));
  }

  return;
};

const createTextForAdditionalFeature = (
  { feature, tooltip }: AdditionalFeature,
  iconColor?: ThemeColor
) => (
  <>
    {feature}&nbsp;
    <Hint width={300} content={tooltip} iconColor={iconColor} />
  </>
);
