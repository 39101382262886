import { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import TextareaAutosize, {
  TextareaAutosizeProps
} from 'react-textarea-autosize';
import { Control } from './control';
import { ControlProps, ControlSize } from './control-types';
import { useFocused } from './use-focused';

export type AutosizeTextareaProps = InputHTMLAttributes<HTMLTextAreaElement> &
  ControlProps &
  TextareaAutosizeProps;

export const AutosizeTextarea = forwardRef<
  HTMLTextAreaElement,
  AutosizeTextareaProps
>(function _Textarea(
  {
    label,
    large,
    captionTop,
    captionBottom,
    fullWidth,
    maxWidth,
    className,
    error,
    onFocus,
    onBlur,
    minRows = 3,
    ...forwardingProps
  },
  forwardingRef
) {
  const [focused, focusHandlers] = useFocused(onFocus, onBlur);
  const size: ControlSize = large ? 'large' : 'medium';
  const fontSize = { large: 16, medium: 14 }[size];

  return (
    <Control
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fontSize={fontSize}
      focused={focused}
      label={label}
      captionTop={captionTop}
      captionBottom={captionBottom}
      error={error}
      className={className}
    >
      <StyledTextareaAutosize
        ref={forwardingRef}
        minRows={minRows}
        {...focusHandlers}
        {...forwardingProps}
      />
    </Control>
  );
});

const StyledTextareaAutosize = styled(TextareaAutosize)`
  ${({ theme }) => theme.font.text};
  padding: 8px 12px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  resize: none;
  outline: none;
  border: none;
  border-radius: 4px;
  -webkit-tap-highlight-color: transparent;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray30};
  }
`;
