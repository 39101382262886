import styled from 'styled-components';
import { Check } from '@elfsight/icons';
import { H2, H3, H4, Icon, P } from '@elfsight-universe/ui-common';

export function RequestInstallationSuccess() {
  return (
    <Container>
      <Header>
        <CenteredIcon component={Check} size={24} fill="success" />
        <H2 mb={8}>Thank You for Your Request!</H2>
        <P>Your widget installation request has been successfully received.</P>
      </Header>

      <Body>
        <H3 mb={12}>What Happens Next?</H3>
        <List>
          <ListItem>
            <ListItemCaption>Installation Process</ListItemCaption>
            <P>
              Normally, we process installation requests within 24 hours on
              weekdays after receiving the request. During this time, we may
              contact you via the provided email if additional information is
              needed or if we encounter any issues.
            </P>
          </ListItem>
          <ListItem>
            <ListItemCaption> Completion Confirmation</ListItemCaption>
            <P>
              Once the widget is successfully installed, you will receive a
              confirmation email with details about the installation and any
              necessary post-installation instructions.
            </P>
          </ListItem>
          <ListItem>
            <ListItemCaption> Stay Updated</ListItemCaption>
            <P>
              Please keep an eye on your email for any updates or requests for
              additional information.
            </P>
          </ListItem>
        </List>
      </Body>

      <Footer>
        <Separator />
        <H4 mb={4}>Thank You for Choosing Elfsight!</H4>
        <P>
          We appreciate your trust in us and look forward to enhancing your
          website with our widget.
        </P>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 555px;
  width: 100%;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = styled.div`
  text-align: start;
  margin: 40px 0;
`;

const Footer = styled.div`
  text-align: start;
`;

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 24px;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ListItemCaption = styled(H4)`
  margin-bottom: 4px;
`;

const CenteredIcon = styled(Icon)`
  margin: 0 auto 20px;
`;

const Separator = styled.div`
  height: 1px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.gray10};
  width: 100%;
`;
