import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_PROFILE_INFO_PATH,
  GetProfileInfoResponse,
  GetProfileInfoResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/get-profile-info';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useProfileQuery<T extends GetProfileInfoResponse>(
  options: UseQueryOptions<T, GetProfileInfoResponseError> = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<T, GetProfileInfoResponseError>(
    [GET_PROFILE_INFO_PATH],
    () => client.get(GET_PROFILE_INFO_PATH).json(),
    {
      enabled: isAuthenticated,
      ...options
    }
  );
}
