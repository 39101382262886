import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { AngleLeft16, AngleRight16 } from '@elfsight/icons';
import { ButtonPrimitive } from '../buttons';
import { Icon } from '../icons';
import { PaginationItemParams, PaginationItemType } from './pagination-types';

export type PaginationItemProps = HTMLAttributes<HTMLButtonElement> &
  PaginationItemParams;

export function PaginationItem({
  type,
  selected,
  page,
  disabled,
  onClick,
  ...forwardingProps
}: PaginationItemProps) {
  const IconComponent = {
    [PaginationItemType.PREVIOUS]: AngleLeft16,
    [PaginationItemType.NEXT]: AngleRight16,
    [PaginationItemType.FIRST]: AngleLeft16,
    [PaginationItemType.LAST]: AngleRight16,
    [PaginationItemType.END_ELLIPSIS]: null,
    [PaginationItemType.START_ELLIPSIS]: null,
    [PaginationItemType.PAGE]: null
  }[type];

  if (
    type === PaginationItemType.START_ELLIPSIS ||
    type === PaginationItemType.END_ELLIPSIS
  ) {
    return (
      <Item disabled {...forwardingProps}>
        …
      </Item>
    );
  }

  if (type !== PaginationItemType.PAGE && disabled) {
    return null;
  }

  return (
    <Item
      _selected={selected}
      disabled={disabled}
      onClick={onClick}
      {...forwardingProps}
    >
      {type === PaginationItemType.PAGE && page}
      {type === PaginationItemType.LAST && 'Last'}
      {type === PaginationItemType.NEXT && 'Next'}

      {IconComponent ? (
        <Icon component={IconComponent} size={16} fill="currentColor" />
      ) : null}

      {type === PaginationItemType.FIRST && 'First'}
      {type === PaginationItemType.PREVIOUS && 'Back'}
    </Item>
  );
}

const Item = styled(ButtonPrimitive)<{ _selected?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 8px;
  background: none;
  border: none;
  outline: none;
  transition: background 0.2s, box-shadow 0.2s;

  ${({ disabled, _selected, theme }) => {
    if (disabled) {
      return css`
        cursor: default;
      `;
    }

    return _selected
      ? css`
          cursor: default;
          color: ${theme.colors.black};
          box-shadow: inset 0 0 0 1px ${theme.colors.gray30};
        `
      : css`
          cursor: pointer;
          color: ${theme.colors.gray50};
          :active,
          :hover {
            box-shadow: none;
            color: ${theme.colors.black};
            background: ${theme.colors.gray5};
          }
        `;
  }}
`;
