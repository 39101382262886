import { Component, ErrorInfo, ReactNode } from 'react';
import { captureException } from '@sentry/nextjs';
import { ErrorPage } from './error-page';

type ErrorBoundaryState = { error?: Error };

const initialState: ErrorBoundaryState = { error: undefined };

export class ErrorBaseBoundary extends Component<
  { children: ReactNode },
  ErrorBoundaryState
> {
  state = initialState;

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('errorInfo', errorInfo);
    captureException(error);
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <ErrorPage statusCode={500} />;
    }

    return this.props.children;
  }
}
