import { Exclude, Expose } from 'class-transformer';
import { IsUUID } from 'class-validator';

export const GET_PERMISSION_TO_ACCESS_WIDGET_PATH =
  'widget/get-permission-to-access-widget';

export class GetPermissionToAccessWidgetRequest {
  @IsUUID(undefined, {
    each: true
  })
  widgetPids: string[];
}

@Exclude()
export class GetPermissionToAccessWidgetResponse {
  @Expose()
  accessWidgetPids: string[];
}
