import styled, { useTheme } from 'styled-components';
import { IconInject } from './icon-inject';

export type AppIconProps = {
  icon: string | null | undefined;
  fill?: string;
  size?: number;
};

export function AppIcon({
  icon,
  size = 18,
  fill,
  ...forwardingProps
}: AppIconProps) {
  const theme = useTheme();
  const finalFill = fill || theme.colors.brand;
  if (!icon) return null;

  return (
    <StyledIconInject
      src={icon}
      size={size}
      fill={finalFill}
      {...forwardingProps}
    />
  );
}

const StyledIconInject = styled(IconInject)`
  pointer-events: none;
`;
