import { useDealByAliasQuery, usePaidSubscriptionsQuery } from '@api';
import { PlanType } from '@elfsight-universe/service-core-contracts/billing';
import { SECOND_APP_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';
import { PricingTablePlan } from '@modules/upgrade/pricing-table';

export function useIsSecondAppDealPromising(plan: PricingTablePlan): boolean {
  const { data: paidSubscriptions } = usePaidSubscriptionsQuery();
  const { data: secondAppDealData } = useDealByAliasQuery({
    alias: SECOND_APP_DEAL_ALIAS
  });

  return (
    paidSubscriptions?.payload.length === 0 &&
    plan.type === PlanType.SINGLE_APP &&
    !secondAppDealData?.accountDeal
  );
}
