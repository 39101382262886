import { Exclude, Expose, Type } from 'class-transformer';
import { WidgetError } from '../errors';
import { IsOptional, IsString } from 'class-validator';

export const GET_LAST_CREATED_WIDGET_PATH = 'widget/get-last-created-widget';

export class GetLastCreatedWidgetRequest {
  @IsString()
  @IsOptional()
  appAlias?: string;
}

@Exclude()
export class GetLastCreatedWidgetResponse {
  @Expose()
  pid: string;

  @Expose()
  @Type(() => GetLastCreatedWidgetResponseApp)
  app: {
    name: string;
    alias: string;
    icon: string;
    category: {
      color: string;
    };
  };
}

@Exclude()
export class GetLastCreatedWidgetResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  icon: string;

  @Expose()
  @Type(() => GetLastCreatedWidgetResponseAppCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetLastCreatedWidgetResponseAppCategory {
  @Expose()
  color: string;
}

export type GetLastCreatedWidgetResponseError =
  WidgetError.WIDGET_DOES_NOT_EXIST;
