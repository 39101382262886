import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class PlanPolicyModifier {
  @Expose()
  viewsLimit?: number;

  @Expose()
  widgetsLimit?: number;

  @Expose()
  collaboratorsLimit?: number;
}
