import {
  cloneElement,
  forwardRef,
  CSSProperties,
  ReactElement,
  ReactNode,
  useState,
  useEffect
} from 'react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles
} from '@floating-ui/react';
import styled from 'styled-components';
import { createDropdownSlideInAnimation } from './create-dropdown-slide-in-animation';
import { useTheme } from '../../theme';

export type TooltipProps = {
  content: ReactNode;
  children: ReactElement;
  preventOpen?: boolean;
  placement?: Placement;
  containerStyle?: Partial<CSSProperties>;
  width?: number;
  alignCenter?: boolean;
  colorTheme?: 'white' | 'black';
  openDelay?: number;
  closeDelay?: number;
  withSafePolygon?: boolean;
  safePolygonBuffer?: number;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(function Tooltip(
  {
    children,
    containerStyle,
    content,
    width,
    alignCenter,
    placement = 'top',
    openDelay = 400,
    closeDelay = 0,
    colorTheme = 'black',
    preventOpen = false,
    withSafePolygon = false,
    safePolygonBuffer = 3,
    isOpen: controlledOpen,
    onOpenChange: setControlledOpen,
    ...forwardingProps
  }: TooltipProps,
  forwardingRef
) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const { colors: themeColors, zIndex: themeZIndex } = useTheme();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  useEffect(() => {
    if (preventOpen && controlledOpen == null) {
      setUncontrolledOpen(false);
    }
  }, [preventOpen]);

  const {
    context: floatingContext,
    refs: floatingRefs,
    floatingStyles
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(4),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 8
      }),
      shift({ padding: 5 })
    ]
  });

  const hover = useHover(floatingContext, {
    move: false,
    delay: {
      open: openDelay,
      close: closeDelay
    },
    enabled: !preventOpen && controlledOpen == null,
    handleClose: withSafePolygon
      ? safePolygon({
          buffer: safePolygonBuffer,
          requireIntent: false
        })
      : null
  });

  const dismiss = useDismiss(floatingContext);
  const role = useRole(floatingContext, { role: 'tooltip' });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    hover,
    dismiss,
    role
  ]);

  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([
    floatingRefs.setReference,
    forwardingRef,
    childrenRef
  ]);

  const trigger = cloneElement(
    children,
    getReferenceProps({
      ref,
      ...children.props
    })
  );

  const { isMounted, styles: transitionStyles } = useTransitionStyles(
    floatingContext,
    createDropdownSlideInAnimation()
  );

  const colors = {
    black: {
      _backgroundColor: themeColors.tooltip,
      _textColor: themeColors.white
    },
    white: {
      _backgroundColor: themeColors.white,
      _textColor: themeColors.black
    }
  }[colorTheme];

  if (!content) {
    return children;
  }

  return (
    <>
      {trigger}

      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager
            initialFocus={-1}
            context={floatingContext}
            modal={false}
          >
            <Content
              _width={width}
              _alignCenter={alignCenter}
              _withSafePolygon={withSafePolygon}
              ref={floatingRefs.setFloating}
              style={{
                ...floatingStyles,
                ...transitionStyles,
                zIndex: themeZIndex.tooltip.toString(),
                ...containerStyle
              }}
              {...getFloatingProps()}
              {...colors}
              {...forwardingProps}
            >
              {content}
            </Content>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
});

const Content = styled.div<{
  _alignCenter?: boolean;
  _width?: number;
  _backgroundColor: string;
  _textColor: string;
  _withSafePolygon?: boolean;
}>`
  position: relative;
  padding: 8px 12px;
  border-radius: 4px;
  max-width: ${({ _width }) => (_width ? `${_width}px` : 'inherit')};
  text-align: ${({ _alignCenter }) => (_alignCenter ? 'center' : 'left')};
  background: ${({ _backgroundColor }) => _backgroundColor};
  color: ${({ _textColor }) => _textColor};
  ${({ theme }) => theme.font.caption};
  box-shadow: 0 3px 6px ${({ theme }) => theme.colors.gray10};
  pointer-events: ${({ _withSafePolygon }) => (_withSafePolygon ? 'auto' : 'none')};
`;
