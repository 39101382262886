import ky from 'ky';
import { useInjectCSRFToken } from '@elfsight-universe/ky-csrf-protection';
import {
  CSRF_HEADER_NAME,
  CSRF_PATH
} from '@elfsight-universe/service-core-contracts/csrf';
import { ACCOUNT_PID_SESSION_OPTION } from '@constants';

const { NEXT_PUBLIC_SERVICE_CORE__BASE_URL } = process.env;

export const client = ky.create({
  prefixUrl: `/core/`,
  credentials: 'include',
  timeout: 60000,
  retry: {
    limit: 3,
    statusCodes: [408, 417],
    backoffLimit: 3000
  },
  hooks: {
    beforeRequest: [
      (request: Request) => {
        const accountPid = global.sessionStorage
          ? global.sessionStorage.getItem(ACCOUNT_PID_SESSION_OPTION)
          : undefined;

        if (accountPid) {
          request.headers.set('X-Account-PID', JSON.parse(accountPid));
        }
      },
      useInjectCSRFToken(
        `${NEXT_PUBLIC_SERVICE_CORE__BASE_URL}${CSRF_PATH}`,
        CSRF_HEADER_NAME
      )
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          const data = await response.json();
          console.error(
            `HTTP Client Error with status code: ${response.status}`,
            {
              url: request.url,
              method: request.method,
              body: options.body,
              responseData: data
            }
          );
          throw data?.message || data;
        }

        return response;
      }
    ]
  }
});
