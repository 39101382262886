import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  DONE_ONBOARDING_ACTION_PATH,
  DoneOnboardingActionRequest
} from '@elfsight-universe/service-core-contracts/onboarding';
import { client } from '../client';
import { useOnboardingQuery } from '../queries';

export function useDoneOnboardingActionMutation({
  onSuccess,
  ...options
}: UseMutationOptions<Response, unknown, DoneOnboardingActionRequest> = {}) {
  const { refetch: refetchOnboarding } = useOnboardingQuery();

  return useMutation<Response, unknown, DoneOnboardingActionRequest>(
    (data) =>
      client.post(DONE_ONBOARDING_ACTION_PATH, {
        json: data
      }),
    {
      onSuccess: (...args) => {
        refetchOnboarding();
        onSuccess && onSuccess(...args);
      },
      ...options
    }
  );
}
