import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export function useRouterRouteChange() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url: string, { shallow }: { shallow: boolean }) => {
      if (shallow || clearURL(url) === clearURL(router.asPath)) {
        return;
      }
      setLoading(true);
    };
    const handleComplete = () => {
      setLoading(false);
    };
    const handleError = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
    };
  }, []);

  return loading;
}

const clearURL = (url: string) => url.replace(/^(\/[^\/?]*)?(.*)/, '$1');
