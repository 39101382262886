import styled from 'styled-components';
import { Divider, MenuButton } from '@elfsight-universe/ui-common';
import { customScrollbarMixin } from '@elfsight-universe/ui-common/src/components/custom-scrollbar-mixin';
import { useCollaboratorsFeatureSummaryQuery } from '@api';
import { LayoutSidebar } from '@components';
import { useUser } from '@modules/_app';
import {
  AccountSidebarURL,
  buildBaseAccountSidebaMenuItems
} from './account-sidebar-menu-builder';

export type AccountSidebarProps = {
  activeItemURL: AccountSidebarURL;
};

export function AccountSidebar({ activeItemURL }: AccountSidebarProps) {
  const { data: collaboratorsFeatureSummaryData } =
    useCollaboratorsFeatureSummaryQuery();
  const { user } = useUser();
  const sidebarItems = buildBaseAccountSidebaMenuItems({
    withCollaborators: collaboratorsFeatureSummaryData?.available,
    withNotifications:
      user && user.owningAccountPid === user.effectiveAccount?.pid
  });

  return (
    <LayoutSidebar width={260} padding={0}>
      <InnerWrapper>
        <Sections>
          {collaboratorsFeatureSummaryData &&
            sidebarItems.map(({ groupName, items }, index, array) => {
              const showDivider = index !== array.length - 1;

              return (
                <SidebarSection key={groupName}>
                  <Title>{groupName}</Title>
                  {items.map(({ url, label }) => (
                    <MenuButton
                      key={url}
                      padding={12}
                      iconSize={20}
                      selectedBackgroundColor="gray5"
                      selected={url === activeItemURL}
                      href={url}
                    >
                      {label}
                    </MenuButton>
                  ))}
                  {showDivider && <Divider _marginY={12} />}
                </SidebarSection>
              );
            })}
        </Sections>
      </InnerWrapper>
    </LayoutSidebar>
  );
}

const Sections = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InnerWrapper = styled.div`
  box-sizing: border-box;
  padding: 24px;
  position: fixed;
  background: #f8f8f8;
  width: 260px;
  overflow-y: auto;
  height: calc(100vh - var(--layout-header-height));

  ${customScrollbarMixin}
`;

const Title = styled.span`
  display: block;
  padding: 8px 0;
  ${({ theme }) => theme.font.captionSmallUppercase};
  color: ${({ theme }) => theme.colors.gray50};
`;

const SidebarSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:first-child {
    > ${Title} {
      padding-top: 0;
    }
  }
`;
