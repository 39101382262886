import { ReactNode } from 'react';
import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import {
  BIRTHDAY_DEAL_ALIAS,
  BLACK_FRIDAY_DEAL_ALIAS,
  REFER_A_FRIEND_DEAL_ALIAS,
  SECOND_APP_DEAL_ALIAS,
  SWITCH_TO_ANNUAL_DEAL_ALIAS,
  SWITCH_TO_PACK_DEAL_ALIAS,
  WELCOME_DEAL_ALIAS,
  XMAS_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';
import {
  DealWithCustomURLDetailTemplate,
  SecondAppDetailTemplate,
  SwitchToAnnualDetailTemplate
} from '@modules/deals/templates';

export const getDealTemplate = (accountDeal: AccountDeal): ReactNode => {
  const dealTemplatesMapping = {
    [WELCOME_DEAL_ALIAS]: <DealWithCustomURLDetailTemplate {...accountDeal} />,
    [SECOND_APP_DEAL_ALIAS]: <SecondAppDetailTemplate {...accountDeal} />,
    [SWITCH_TO_PACK_DEAL_ALIAS]: (
      <DealWithCustomURLDetailTemplate {...accountDeal} />
    ),
    [SWITCH_TO_ANNUAL_DEAL_ALIAS]: (
      <SwitchToAnnualDetailTemplate {...accountDeal} />
    ),
    [REFER_A_FRIEND_DEAL_ALIAS]: (
      <DealWithCustomURLDetailTemplate {...accountDeal} />
    ),
    [BLACK_FRIDAY_DEAL_ALIAS]: (
      <DealWithCustomURLDetailTemplate {...accountDeal} />
    ),
    [XMAS_DEAL_ALIAS]: <DealWithCustomURLDetailTemplate {...accountDeal} />,
    [BIRTHDAY_DEAL_ALIAS]: <DealWithCustomURLDetailTemplate {...accountDeal} />
  };

  return dealTemplatesMapping[
    accountDeal.deal.alias as keyof typeof dealTemplatesMapping
  ];
};
