import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import {
  GET_PROJECTS_LIST_PATH,
  GetProjectsListRequest,
  GetProjectsListResponse
} from '@elfsight-universe/service-core-contracts/project';
import { useUser } from '@modules/_app';
import { useEvents } from '@modules/_event-bus';
import { client } from '../client';

export function useProjectsListQuery(
  dto: GetProjectsListRequest,
  { enabled = true, ...options }: UseQueryOptions<GetProjectsListResponse> = {}
) {
  const { isAuthenticated } = useUser();

  const query = useQuery<GetProjectsListResponse>(
    [GET_PROJECTS_LIST_PATH, ...Object.values(dto)],
    () =>
      client
        .get(GET_PROJECTS_LIST_PATH, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json<GetProjectsListResponse>(),
    {
      keepPreviousData: true,
      enabled: enabled && isAuthenticated,
      ...options
    }
  );

  useEvents(['ProjectThumbnailCreated'], () => query.refetch());

  return query;
}
