import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import isString from 'lodash/isString';
import addDays from 'date-fns/addDays';
import {
  SIGN_UP_UTM_META_COOKIE_NAME,
  SignUpUTMMeta
} from '@elfsight-universe/service-core-contracts/analytics/sign-up-utm-meta';

const { NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN } = process.env;

const UTM_SOURCE_PARAM = 'utm_source';
const UTM_MEDIUM_PARAM = 'utm_medium';
const UTM_CAMPAIGN_PARAM = 'utm_campaign';

export function useCatchUTMParams() {
  const { query } = useRouter();
  const [cookies, setCookie] = useCookies();

  const utmSource = query?.[UTM_SOURCE_PARAM];
  const utmMedium = query?.[UTM_MEDIUM_PARAM];
  const utmCampaign = query?.[UTM_CAMPAIGN_PARAM];

  const signUpUTMMetaCookie = cookies[SIGN_UP_UTM_META_COOKIE_NAME];

  useEffect(() => {
    if (
      !signUpUTMMetaCookie &&
      isString(utmSource) &&
      isString(utmMedium) &&
      isString(utmCampaign)
    ) {
      const signUpUTMMeta: SignUpUTMMeta = {
        utmSource,
        utmMedium,
        utmCampaign
      };

      setCookie(SIGN_UP_UTM_META_COOKIE_NAME, signUpUTMMeta, {
        domain: NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN,
        path: '/',
        secure: true,
        sameSite: 'none',
        expires: addDays(new Date(), 30)
      });
    }
  }, [utmSource, utmMedium, utmCampaign, signUpUTMMetaCookie]);
}
