import { Exclude, Expose } from 'class-transformer';
import { OnboardingActionType } from './onboarding-action-type.enum';

export const GET_ONBOARDING_PATH = 'onboarding/get-onboarding';

@Exclude()
export class GetOnboardingResponse {
  @Expose()
  actions: OnboardingActionType[];

  @Expose()
  done: boolean;

  @Expose()
  finish: boolean;

  @Expose()
  welcomeMessageReviewed: boolean;

  @Expose()
  occupationChosen: boolean;
}
