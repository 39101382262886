import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState
} from 'react';
import {
  useWebStorage,
  UseWebStorageOnReady
} from '@elfsight-universe/react-web-storage';
import addDays from 'date-fns/addDays';
import { usePageContext } from '@modules/_app';
import { useOnboardingQuery } from '@api';

export type IHeaderContext = {
  activePage?: string;
  isMobileNavOpen: boolean;
  toggleMobileNav: (state?: boolean) => void;
  isOnboardingOpen: boolean;
  toggleOnboardingOpen: (state?: boolean) => void;
};

export const HeaderContext = createContext<IHeaderContext | null>(null);

export type HeaderProviderProps = PropsWithChildren<{ activePage?: string }>;

export function HeaderProvider({ children, activePage }: HeaderProviderProps) {
  const { onboardingModalHidden } = usePageContext();
  const { data: onboardingData } = useOnboardingQuery();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isOnboardingOpen, setOnboardingOpen] = useState(false);
  const toggleMobileNav = (state?: boolean) => {
    setMobileNavOpen(state !== undefined ? state : !isMobileNavOpen);
  };

  const toggleOnboardingOpen = (state?: boolean) => {
    const value = state !== undefined ? state : !isOnboardingOpen;

    setOnboardingOpen(value);
    if (!value) {
      storeOnboardingClosed(new Date());
    }
  };

  const onOnboardingStoreReady: UseWebStorageOnReady<Date> = useCallback(
    (timestamp) => {
      if (onboardingData && !onboardingData.occupationChosen) {
        const isExpired =
          !!timestamp && new Date() > addDays(new Date(timestamp), 1);

        if (!timestamp || isExpired) {
          setOnboardingOpen(true);
        }
      }
    },
    [onboardingModalHidden, onboardingData]
  );

  const { storeValue: storeOnboardingClosed } = useWebStorage<Date>({
    key: `onboarding-closed-timestamp`,
    onReady: onOnboardingStoreReady
  });

  return (
    <HeaderContext.Provider
      value={{
        isMobileNavOpen,
        toggleMobileNav,
        activePage,
        isOnboardingOpen,
        toggleOnboardingOpen
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}

export function useHeader() {
  const context = useContext(HeaderContext);

  if (context === null) {
    throw new Error('`useHeader` must be nested inside an `HeaderProvider`.');
  }

  return context;
}
