import { Exclude, Expose, Type } from 'class-transformer';
import { IsString, ValidateNested } from 'class-validator';
import { WidgetError } from '../errors';
import { ChangelogTopicType } from './enums';

export const GET_CHANGELOG_TOPICS_PATH =
  'community-integration/get-changelog-topics';

export class GetChangelogTopicsRequest {
  @IsString()
  appAlias: string;
}

@Exclude()
export class GetChangelogTopicsResponse {
  @Expose()
  @Type(() => GetChangelogTopicsResponseChangelogTopic)
  payload: GetChangelogTopicsResponseChangelogTopic[];

  @Expose()
  @Type(() => GetChangelogTopicsResponseMeta)
  meta: {
    hasNewChangelogTopics: boolean;
    seeAllUpdatesLink: string;
    changelogHomeLink: string;
  };
}

export type GetChangelogTopicsResponseError = WidgetError.APP_DOES_NOT_EXIST;

@Exclude()
export class GetChangelogTopicsResponseMeta {
  @Expose()
  hasNewChangelogTopics: boolean;

  @Expose()
  seeAllUpdatesLink: string;

  @Expose()
  changelogHomeLink: string;
}

@Exclude()
export class GetChangelogTopicsResponseChangelogTopic {
  @Expose()
  pid: string;

  @Expose()
  createdAt: Date;

  @Expose()
  title: string;

  @Expose()
  type: ChangelogTopicType;

  @Expose()
  views: number;

  @Expose()
  replies: number;

  @Expose()
  link: string;

  @Expose()
  @Type(() => GetChangelogTopicsResponseChangelogTopicPoster)
  posters: GetChangelogTopicsResponseChangelogTopicPoster[];
}

@Exclude()
export class GetChangelogTopicsResponseChangelogTopicPoster {
  @Expose()
  @Type(() => GetChangelogTopicsResponseChangelogTopicPosterUser)
  user: {
    profilePicture: string;
  };
}

@Exclude()
export class GetChangelogTopicsResponseChangelogTopicPosterUser {
  @Expose()
  profilePicture: string;
}
