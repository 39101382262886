import { DEFAULT_SERVICE_CORE__BASE_URL } from './constants';
import { generateWidgetShortlink } from './generate-widget-shortlink';

export const generateWidgetLink = (
  widgetPid: string,
  serviceCorePlatformURL = DEFAULT_SERVICE_CORE__BASE_URL
) => {
  const isCustomPlatform =
    serviceCorePlatformURL !== DEFAULT_SERVICE_CORE__BASE_URL;
  let shortlink = generateWidgetShortlink(widgetPid);
  if (isCustomPlatform) {
    shortlink += `?platformURL=${serviceCorePlatformURL}`;
  }
  return shortlink;
};
