import {
  DEFAULT_SERVICE_CORE__BASE_URL,
  PLATFORM_SCRIPT_URL
} from './constants';

// @TODO add .spec

export const generateWidgetCode = (
  widgetPid: string,
  serviceCorePlatformURL = DEFAULT_SERVICE_CORE__BASE_URL
) => {
  const isDefaultPlatform =
    serviceCorePlatformURL === DEFAULT_SERVICE_CORE__BASE_URL;

  if (isDefaultPlatform) {
    return `<script src="${PLATFORM_SCRIPT_URL}" async></script>\n<div class="elfsight-app-${widgetPid}" data-elfsight-app-lazy></div>`;
  }

  const customPlatformUrlScript = `<script>window.eappsCustomPlatformUrl = "${serviceCorePlatformURL}";</script>`;

  return `${customPlatformUrlScript}\n<script src="${PLATFORM_SCRIPT_URL}" async></script>\n<div class="elfsight-app-${widgetPid}" data-elfsight-app-lazy></div>`;
};
