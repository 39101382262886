import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  SWITCH_ACCOUNT_PATH,
  SwitchAccountRequest,
  SwitchAccountResponse,
  SwitchAccountResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useSwitchAccountMutation(
  options: Pick<
    UseMutationOptions<
      SwitchAccountResponse,
      SwitchAccountResponseError,
      SwitchAccountRequest
    >,
    'onError' | 'onSuccess'
  > = {}
) {
  return useMutation<
    SwitchAccountResponse,
    SwitchAccountResponseError,
    SwitchAccountRequest
  >(
    (data) =>
      client
        .post(SWITCH_ACCOUNT_PATH, {
          json: data
        })
        .json(),
    options
  );
}
