import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  CONNECT_WIDGET_PATH,
  ConnectWidgetRequest,
  ConnectWidgetResponseError
} from '@elfsight-universe/service-core-contracts/wix-integration';
import { client } from '../client';

export function useConnectWidgetMutation(
  options?: UseMutationOptions<
    undefined,
    ConnectWidgetResponseError,
    ConnectWidgetRequest
  >
) {
  return useMutation<
    undefined,
    ConnectWidgetResponseError,
    ConnectWidgetRequest
  >(async (data) => {
    await client.post(CONNECT_WIDGET_PATH, { json: data });
    return undefined;
  }, options);
}
