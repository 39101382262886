export enum AdminError {
  ALIAS_ALREADY_EXIST = 'ALIAS_ALREADY_EXIST',
  EMAIL_ALREADY_EXIST = 'EMAIL_ALREADY_EXIST',
  INVALID_BIND = 'INVALID_BIND',
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  PICTURE_DOES_NOT_MEET_REQUIREMENTS = 'PICTURE_DOES_NOT_MEET_REQUIREMENTS',
  FEATURE_ALREADY_EXIST = 'FEATURE_ALREADY_EXIST',
  SUBSCRIPTION_DOES_NOT_EXIST = 'SUBSCRIPTION_DOES_NOT_EXIST',
  SUBSCRIPTION_ALREADY_EXIST = 'SUBSCRIPTION_ALREADY_EXIST',
  CANNOT_GET_PADDLE_SUBSCRIPTION = 'CANNOT_GET_PADDLE_SUBSCRIPTION',
  PLAN_DOES_NOT_EXIST = 'PLAN_DOES_NOT_EXIST',
  CANNOT_GET_PADDLE_PAYMENT = 'CANNOT_GET_PADDLE_PAYMENT',
  SUBSCRIPTION_CANNOT_BE_UPDATED = 'SUBSCRIPTION_CANNOT_BE_UPDATED'
}
