import { PropsWithChildren, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { catchUnhandledError } from '@modules/_error';

const RETRY_LIMIT = 3;

export function AppQueryClientProvider({ children }: PropsWithChildren) {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount, error) => {
              if (error === 'NOT_AUTHORIZED') {
                return false;
              }

              return failureCount < RETRY_LIMIT;
            },
            onError: (err) => {
              catchUnhandledError(err);
            }
          },
          mutations: {
            retry: (failureCount, error) => {
              if (error === 'NOT_AUTHORIZED') {
                return false;
              }

              return failureCount < RETRY_LIMIT;
            },
            onError: (err) => {
              catchUnhandledError(err);
            }
          }
        }
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}

      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
