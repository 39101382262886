import { IsNotEmpty, IsString, IsUUID, MaxLength } from 'class-validator';

export const UPDATE_PROJECT_NAME_PATH = 'project/update-project-name';

export class UpdateProjectNameRequest {
  @IsUUID()
  pid: string;

  @IsString()
  @IsNotEmpty({ message: 'Please provide a name for your project.' })
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  name: string;
}

export type UpdateProjectNameResponseError = 'PROJECT_DOES_NOT_EXIST';
