import { ReactNode, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import {
  GetConnectedWidgetResponse,
  GET_CONNECTED_WIDGET_PATH
} from '@elfsight-universe/service-core-contracts/wix-integration';
import { useConnectWidgetMutation, useConnectedWidgetQuery } from '@api';
import { WixIntegration } from './wix-integration';
import { WixIntegrationContext } from './wix-integration-context';

export interface WixIntegrationProviderProps {
  children: ReactNode;
}

export function WixIntegrationProvider({
  children
}: WixIntegrationProviderProps) {
  // extract wix widget id from query params
  const wixWidgetId = useExtractWixWidgetId();
  const isWixSession = !!wixWidgetId;
  // check if a wix widget is connected
  const { isFetched, data: connectedWidgetData } = useConnectedWidgetQuery(
    wixWidgetId ?? '',
    { enabled: isWixSession }
  );
  const isReady = !isWixSession || isFetched;
  const connectedWidgetPid = connectedWidgetData?.widgetPid ?? null;
  // prepare connect mutation
  const queryClient = useQueryClient();
  const connectedWidgetCacheKey = [GET_CONNECTED_WIDGET_PATH, wixWidgetId];
  const { mutate: executeConnectWidgetMutation, isLoading: isConnecting } =
    useConnectWidgetMutation({
      // employ optimistic update
      onMutate: async (input) => {
        await queryClient.cancelQueries(connectedWidgetCacheKey);
        queryClient.setQueryData<GetConnectedWidgetResponse>(
          connectedWidgetCacheKey,
          { widgetPid: input.widgetPid }
        );
      },
      onError: () => {
        queryClient.setQueryData<GetConnectedWidgetResponse>(
          connectedWidgetCacheKey,
          { widgetPid: connectedWidgetPid }
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(connectedWidgetCacheKey);
      }
    });

  // build WixIntegration instance
  const wixIntegration: WixIntegration = useMemo(() => {
    if (!isWixSession) {
      return { isWixSession: false };
    }
    const [wixInstanceId] = wixWidgetId.split('.');
    const isWixWidgetConnected = !!connectedWidgetPid;
    function isWixWidgetConnectedTo(widgetPid: string) {
      return widgetPid === connectedWidgetPid;
    }
    function connectWixWidget(widgetPid: string, onSuccess?: () => void) {
      if (!isWixSession) {
        throw new Error('Wix widget could only be connected in Wix session');
      }
      executeConnectWidgetMutation({ widgetPid, wixWidgetId }, { onSuccess });
    }
    return {
      isWixSession,
      isReady,
      isWixWidgetConnected,
      isConnecting,
      wixWidgetId,
      wixInstanceId,
      isWixWidgetConnectedTo,
      connectWixWidget
    };
  }, [isWixSession, isReady, isConnecting, wixWidgetId, connectedWidgetPid]);

  return (
    <WixIntegrationContext.Provider value={wixIntegration}>
      {children}
    </WixIntegrationContext.Provider>
  );
}

function useExtractWixWidgetId() {
  const router = useRouter();
  const { wixWidgetId: wixWidgetIdParam } = router.query;
  const [wixWidgetId, setWixWidgetId] = useState<string | undefined>();
  useEffect(() => {
    if (wixWidgetIdParam && typeof wixWidgetIdParam === 'string') {
      setWixWidgetId(wixWidgetIdParam);
    }
  }, [wixWidgetIdParam]);
  return wixWidgetId;
}
