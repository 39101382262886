import { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DESKTOP, MOBILE } from '@elfsight-universe/ui-common';
import { useIsMobile } from '@modules/_app';

export type HeaderLayoutProps = {
  leftSlot?: ReactNode;
  centerSlot?: ReactNode;
  rightSlot?: ReactNode;
  topSlot?: ReactNode;
  centerSlotPosition?: 'top' | 'bottom';
  withoutShadow?: boolean;
  slotGap?: number;
  slotGapMobile?: number;
  style?: {
    leftColumn?: CSSProperties;
  };
};

export function HeaderLayout({
  leftSlot,
  centerSlot,
  rightSlot,
  topSlot,
  centerSlotPosition,
  withoutShadow = false,
  slotGap = 20,
  slotGapMobile = 12,
  style = {}
}: HeaderLayoutProps) {
  const isMobile = useIsMobile();

  const finalCenterSlotPosition =
    centerSlotPosition ??
    (isMobile && Boolean(leftSlot) && Boolean(rightSlot) ? 'bottom' : 'top');
  const displayCenterColumn =
    finalCenterSlotPosition === 'top' && Boolean(centerSlot);
  const displayTopInner = leftSlot || rightSlot || displayCenterColumn;
  const displayBottomInner =
    finalCenterSlotPosition === 'bottom' && Boolean(centerSlot);

  return (
    <Container
      _withoutShadow={withoutShadow}
      _slotGap={slotGap}
      _slotGapMobile={slotGapMobile}
    >
      {topSlot}

      {displayTopInner && (
        <Inner _withCenterColumn={displayCenterColumn}>
          <HeaderLeftColumn style={style.leftColumn}>
            {leftSlot}
          </HeaderLeftColumn>

          {displayCenterColumn && (
            <HeaderCenterColumn>{centerSlot}</HeaderCenterColumn>
          )}

          <HeaderRightColumn>{rightSlot}</HeaderRightColumn>
        </Inner>
      )}

      {displayBottomInner && <Inner _secondary>{centerSlot}</Inner>}
    </Container>
  );
}

const columnBasis = css`
  height: 100%;
  min-width: 0;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
`;

export const HeaderLeftColumn = styled.div`
  justify-content: flex-start;
  ${columnBasis}
`;

export const HeaderCenterColumn = styled.div`
  justify-content: center;
  ${columnBasis}
`;

export const HeaderRightColumn = styled.div`
  justify-content: flex-end;
  ${columnBasis}
`;

const Inner = styled.div<{
  _secondary?: boolean;
  _center?: boolean;
  _withCenterColumn?: boolean;
}>`
  height: ${({ _secondary }) => (_secondary ? 36 : 60)}px;

  ${({ _withCenterColumn }) =>
    _withCenterColumn &&
    css`
      ${HeaderLeftColumn},
      ${HeaderRightColumn} {
        flex-basis: 50%;
        flex-shrink: 1;
      }
    `}

  ${DESKTOP} {
    gap: 0 40px;
  }
  ${MOBILE} {
    gap: 0 20px;
  }
  display: flex;
  align-items: center;
  justify-content: ${({ _center }) => (_center ? 'center' : 'space-between')};
  box-sizing: border-box;
  padding: 0 12px;
`;

const Container = styled.header<{
  _withoutShadow: boolean;
  _slotGap: number;
  _slotGapMobile: number;
}>`
  width: 100%;
  ${({ _withoutShadow, theme }) =>
    !_withoutShadow &&
    css`
      box-shadow: 0 2px 4px 0 ${theme.colors.gray10};
    `}

  ${/* sc-selector */ HeaderLeftColumn},
  ${/* sc-selector */ HeaderCenterColumn},
  ${/* sc-selector */ HeaderRightColumn} {
    ${DESKTOP} {
      gap: ${({ _slotGap }) => `0 ${_slotGap}px`};
    }
    ${MOBILE} {
      gap: ${({ _slotGapMobile }) => `0 ${_slotGapMobile}px`};
    }
  }
`;
