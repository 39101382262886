import { useQuery } from '@tanstack/react-query';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useNotificationsUnreadCountQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<number>(
    ['_mock/get-notifications-unread-count'],
    () => client.get('_mock/get-notifications-unread-count').json(),
    {
      enabled: isAuthenticated
    }
  );
}
