import { IsString } from 'class-validator';
import { IdentityAndAccessError, SecureLinkError } from '../errors';

export const SIGN_IN_BY_AUTH_TICKET_PATH =
  'identity-and-access/sign-in-by-auth-ticket';

export class SignInByAuthTicketRequest {
  @IsString()
  ticket: string;
}

export type SignInByAuthTicketResponseError =
  | SecureLinkError.SECURE_LINK_DOES_NOT_EXIST
  | IdentityAndAccessError.AUTH_IDENTITY_DOES_NOT_EXIST
  | IdentityAndAccessError.USER_DELETED
  | IdentityAndAccessError.ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT
  | IdentityAndAccessError.USER_CANNOT_LOG_IN_USING_THIS_METHOD;
