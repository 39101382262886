import { createContext, ReactNode, useContext } from 'react';
import { filter, Observable, Subject } from 'rxjs';
import { EventType } from '@modules/_event-bus/event.type';

const eventBusSubject = new Subject<EventType>();

export type IEventBusContext = {
  emit: (event: EventType) => void;
  getEvents: (messages: string[]) => Observable<EventType>;
};

export const EventBusContext = createContext<IEventBusContext | null>(null);

type EventBusProviderProps = {
  children: ReactNode;
};

export function EventBusProvider({ children }: EventBusProviderProps) {
  const emit = (event: EventType) => eventBusSubject.next(event);

  const getEvents = (messages: string[]) =>
    eventBusSubject
      .asObservable()
      .pipe(filter((event: EventType) => messages.includes(event.type)));

  return (
    <EventBusContext.Provider value={{ emit, getEvents }}>
      {children}
    </EventBusContext.Provider>
  );
}

export function useEventBus() {
  const context = useContext(EventBusContext);

  if (context === null) {
    throw new Error(
      '`useEventBus` must be nested inside an `EventBusProvider`.'
    );
  }

  return context;
}
