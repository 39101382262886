import { IsString } from 'class-validator';
import { IdentityAndAccessError } from '../errors';

export const SIGN_IN_BY_MINDBOX_TICKET_PATH =
  'identity-and-access/sign-in-by-mindbox-ticket';

export class SignInByMindboxTicketRequest {
  @IsString()
  ticket: string;
}

export type SignInByMindboxTicketResponseError =
  | IdentityAndAccessError.USER_DOES_NOT_EXISTS
  | IdentityAndAccessError.MINDBOX_TICKET_IS_INVALID
  | IdentityAndAccessError.USER_DELETED
  | IdentityAndAccessError.ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT
  | IdentityAndAccessError.USER_CANNOT_LOG_IN_USING_THIS_METHOD;
