import styled from 'styled-components';
import { Check16 } from '@elfsight/icons';
import {
  P,
  H2,
  Icon,
  Link,
  Divider,
  MOBILE
} from '@elfsight-universe/ui-common';
import { useUpgradeContext } from '@modules/upgrade/upgrade-context';
import { getAllAppsPackFeatures } from './get-all-apps-pack-features';
import { useCountPublicAppsQuery } from '@api';

export type PricingFeaturesAllAppsPackProps = {
  isEnterprise: boolean;
};

export function PricingFeaturesAllAppsPack({
  isEnterprise
}: PricingFeaturesAllAppsPackProps) {
  const { setOpenAllAppsPackModal } = useUpgradeContext();
  const { data: publicAppsCountData = { count: 0 } } =
    useCountPublicAppsQuery();

  const moreAppsButton = (
    <TextButton
      forwardedAs="button"
      color="accent"
      onClick={() => setOpenAllAppsPackModal(true)}
    >
      {publicAppsCountData.count || '90+'} more powerful apps
    </TextButton>
  );

  const features = getAllAppsPackFeatures(moreAppsButton);

  return (
    <Container>
      <H2 mb={20}>Unlock the power of All Apps Pack</H2>

      <ItemList>
        {features.map(({ id, feature }) => (
          <Item key={id}>
            <CheckIcon size={16} component={Check16} fill="success" />
            <P>{feature}</P>
          </Item>
        ))}
      </ItemList>
      {isEnterprise && <StyledDivider _marginY={0} />}
    </Container>
  );
}

const Container = styled.div`
  padding-top: 48px;
  margin: 0 auto;
  width: 100%;
  max-width: 750px;

  ${MOBILE} {
    padding-top: 32px;
  }
`;

const ItemList = styled.ul`
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
`;

const Item = styled.li`
  list-style: none;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const CheckIcon = styled(Icon)`
  margin-right: 8px;
`;

const TextButton = styled(Link)`
  padding: 0;
  border: none;
  background-color: transparent;
`;

const StyledDivider = styled(Divider)`
  margin-top: 40px;

  ${MOBILE} {
    margin-top: 32px;
  }
`;
