import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { ElfsightWidget } from 'next-elfsight-widget';
import { LayoutInner, LayoutPage, LayoutSection } from '@components';
import { UpgradeContext, UpgradeProvider } from './upgrade-context';
import { PricingHeader } from './pricing-header';
import { CTASection } from './cta';
import { PlansTabs } from './plans';
import { FAQSection } from './faq';
import { HEADER_CLOSE_URL_PARAM } from './constants';
import { useHistory } from '../_app';
import { PricingTestimonials } from './pricing-testimonials';
import { PricingTopBar } from '@modules/upgrade/pricing-top-bar';
import { BirthdayTopBar } from '@modules/instant-extension-campaign/birthday/birthday-top-bar/birthday-top-bar';
import { useInstantExtensionCampaignContext } from '@modules/instant-extension-campaign/context/instant-extension-campaign-context';
import {
  BIRTHDAY_DEAL_ALIAS,
  BLACK_FRIDAY_DEAL_ALIAS,
  XMAS_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';
import { BlackFridayTopBar } from '@modules/instant-extension-campaign/black-friday/black-friday-top-bar/black-friday-top-bar';
import { XmasTopBar } from '@modules/instant-extension-campaign/xmas/xmas-top-bar/xmas-top-bar';

export const UpgradePage: NextPage = () => {
  const { back } = useHistory();
  const { query, push } = useRouter();

  const { alias } = useInstantExtensionCampaignContext();

  const topBar = {
    [BIRTHDAY_DEAL_ALIAS]: <BirthdayTopBar />,
    [BLACK_FRIDAY_DEAL_ALIAS]: <BlackFridayTopBar />,
    [XMAS_DEAL_ALIAS]: <XmasTopBar />,
    default: null
  }[alias || 'default'];

  const handleCloseRequest = query[HEADER_CLOSE_URL_PARAM]
    ? () => push(`${query[HEADER_CLOSE_URL_PARAM]}`)
    : () => back('/');

  return (
    <LayoutPage>
      <UpgradeProvider>
        <UpgradeContext.Consumer>
          {(context) => {
            const finalTopBar =
              topBar || (context?.isWelcomeDeal && <PricingTopBar />);

            return (
              <>
                {finalTopBar}
                <PricingHeader onCloseRequest={handleCloseRequest} />
                <CTASection />
                <PlansTabs />
                <LayoutSection _padding={[60, 40]}>
                  <LayoutInner _width={1000}>
                    <PricingTestimonials />
                    <ElfsightWidget
                      widgetId="ec8f8062-2d58-4e32-92b8-07ef973dc830"
                      lazy
                    />
                  </LayoutInner>
                </LayoutSection>
                <FAQSection />
              </>
            );
          }}
        </UpgradeContext.Consumer>
      </UpgradeProvider>
    </LayoutPage>
  );
};
