import { IsNotEmpty } from 'class-validator';
import { BillingError } from '../errors';
import { UpdateSubscriptionError } from './interfaces';

export const SWITCH_SUBSCRIPTION_PLAN_PATH = 'billing/switch-subscription-plan';

export class SwitchSubscriptionPlanRequest {
  @IsNotEmpty()
  subscriptionPid: string;

  @IsNotEmpty()
  planPid: string;
}

export type SwitchSubscriptionPlanResponseErrorReason =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANNOT_BE_UPDATED
  | BillingError.PLAN_DOES_NOT_EXIST;

export type SwitchSubscriptionPlanResponseError =
  | UpdateSubscriptionError
  | SwitchSubscriptionPlanResponseErrorReason;
