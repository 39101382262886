import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { Button, P } from '@elfsight-universe/ui-common';
import { DefaultContainer } from '@modules/deals/ui';
import { getDealCaption } from '@modules/deals/utils';

type BlackFridayDetailTemplateProps = AccountDeal;

export function BlackFridayDetailTemplate(
  accountDeal: BlackFridayDetailTemplateProps
) {
  const { title, textImage, alias } = accountDeal.deal;

  return (
    <DefaultContainer title={title} textImage={textImage} alias={alias}>
      <P big>{getDealCaption(accountDeal)}</P>

      <Button backgroundColor="brand" href="/birthday-sale">
        Explore Offer
      </Button>
    </DefaultContainer>
  );
}
