import Image from 'next/image';
import styled from 'styled-components';
import { H3, P, Button, Link } from '@elfsight-universe/ui-common';
import onboardingCongratulations from '@public/assets/onboarding-congratulations.png';
import { useCountPublicAppsQuery, useDoneOnboardingActionMutation } from '@api';
import { OnboardingActionType } from '@elfsight-universe/service-core-contracts/onboarding/onboarding-action-type.enum';
import { useIsMobile } from '@modules/_app';

export function OnboardingCongratulations() {
  const isMobile = useIsMobile();
  const { mutate: done } = useDoneOnboardingActionMutation();
  const { data: publicAppsCountData = { count: 0 } } =
    useCountPublicAppsQuery();

  return (
    <Container>
      <Image
        src={onboardingCongratulations}
        height={100}
        width={248}
        style={{
          objectFit: 'contain'
        }}
        alt=""
      />
      <H3 mt={20} mb={12}>
        Congratulations!
      </H3>
      <Text mb={20}>
        You’ve successfully completed the onboarding process. Now you can
        continue exploring{' '}
        <Link href="/catalog" color="accent">
          {publicAppsCountData.count || '90+'} Elfsight apps
        </Link>{' '}
        and make your website even better.
      </Text>
      <FinishButton
        onClick={() => done({ type: OnboardingActionType.FINISH })}
        variation="accentPrimary"
        fullWidth={isMobile}
        center
      >
        OK
      </FinishButton>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled(P)`
  color: ${({ theme }) => theme.colors.gray70};
  text-align: center;
`;

const FinishButton = styled(Button)`
  min-width: 80px;
`;
