import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class PlanPolicy {
  @Expose()
  viewsLimit: number | null;

  @Expose()
  widgetsLimit: number;

  @Expose()
  projectsLimit: number;

  @Expose()
  collaboratorsLimit: number;
}
