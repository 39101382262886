import { ReactNode, useState } from 'react';
import { toast } from 'react-hot-toast';
import { createGlobalStyle } from 'styled-components';
import Script from 'next/script';
import { ContactSupport } from '@modules/_error';
import { useOffer } from '@modules/offer';
import { PaddleContext } from './paddle-context';
import { PaddleEvent } from './paddle-event.enum';
import { PaddleCheckoutOptions } from './paddle-types';

const LOAD_ERROR_TOAST_ID = 'PADDLE_COULD_NOT_LOAD';

const PADDLE_VENDOR_URL = 'https://cdn.paddle.com/paddle/paddle.js';
const { NEXT_PUBLIC_SERVICE_CORE__PADDLE_VENDOR_ID } = process.env;

type PaddleProviderProps = { children: ReactNode };

export function PaddleProvider({ children }: PaddleProviderProps) {
  const [isLoading, setLoading] = useState(false);
  const { offer, toggleSlideInOpen } = useOffer();

  const handlePaddleInit = function () {
    if (!NEXT_PUBLIC_SERVICE_CORE__PADDLE_VENDOR_ID) {
      throw new Error('PADDLE_VENDOR_ID is Required');
    }

    window.Paddle.Setup({
      vendor: Number(NEXT_PUBLIC_SERVICE_CORE__PADDLE_VENDOR_ID),
      eventCallback: (data) => {
        console.debug('PaddleJS Event', data);

        if (
          data.event === PaddleEvent.CheckoutError &&
          data.eventData.error &&
          data.eventData.error_type === 'payment_failure'
        ) {
          setLoading(false);
          console.error(data.eventData.error);
          toast.error(data.eventData.error);
        }
      }
    });
  };

  const openCheckoutURL = (
    options: PaddleCheckoutOptions & { override: string }
  ) => {
    if (!window.Paddle) {
      setLoading(false);
      toast.dismiss(LOAD_ERROR_TOAST_ID);
      return toast.error(
        <>
          A checkout provider could not loaded.{' '}
          <ContactSupport underline>Contact our Support Team</ContactSupport> if
          something doesn’t work.
        </>,
        { duration: Infinity, id: LOAD_ERROR_TOAST_ID }
      );
    }

    window.Paddle.Checkout.open({
      loadCallback: () => {
        if (offer?.deal?.couponCode) {
          toggleSlideInOpen(true);
        }
      },
      closeCallback: () => {
        setLoading(false);
        if (offer?.deal?.couponCode) {
          toggleSlideInOpen(false);
        }
      },
      ...options
    });
  };

  const openDetailsPopup = (checkoutID: string) => {
    window.Paddle.Order.DetailsPopup(checkoutID);
  };

  return (
    <PaddleContext.Provider
      value={{ openCheckoutURL, setLoading, isLoading, openDetailsPopup }}
    >
      {children}

      <GlobalStyle />

      <Script
        src={PADDLE_VENDOR_URL}
        strategy="afterInteractive"
        onLoad={handlePaddleInit}
      />
    </PaddleContext.Provider>
  );
}

const GlobalStyle = createGlobalStyle`
  .paddle-loader {
    display: none !important;
  }
  .paddle-frame {
    z-index: ${({ theme }) => theme.zIndex.paddleFrame} !important;
  }
`;
