import { IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { SearchQueryType } from './enums';

export const SAVE_SEARCH_QUERY_PATH = 'analytics/save-search-query';

export class SaveSearchQueryRequest {
  @IsEnum(SearchQueryType)
  type: SearchQueryType;

  @IsString()
  @IsNotEmpty()
  search: string;
}
