import styled from 'styled-components';
import { formatAbbrNumber, ThemeColor } from '@elfsight-universe/ui-common';
import { IAppUsage } from './app-usage-types';

export type ViewsCounterProps = Pick<IAppUsage, 'appUsage'>;

export function AppUsageCounter({
  appUsage,
  ...forwardingProps
}: ViewsCounterProps) {
  const { viewsLimit, viewsCount, viewsUsage } = appUsage;

  const color = (() => {
    if (viewsUsage > 0.9) return 'alert';
    if (viewsUsage > 0.5) return 'warning';
    return 'success';
  })();

  const clampedViewsCount =
    viewsCount > viewsLimit && viewsLimit !== null ? viewsLimit : viewsCount;

  return (
    <Container {...forwardingProps}>
      <Counter>
        <Value>
          {formatAbbrNumber(clampedViewsCount)} /{' '}
          {viewsLimit !== null ? formatAbbrNumber(viewsLimit) : 'Unlimited'}
        </Value>
      </Counter>

      <ThumbBar>
        <Thumb _color={color} style={{ width: `${viewsUsage * 100}%` }} />
      </ThumbBar>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 33%;
  align-items: flex-end;
`;

const Counter = styled.div`
  display: flex;
  align-items: center;
`;

const Value = styled.span`
  ${({ theme }) => theme.font.captionSmallUppercase};
  color: ${({ theme }) => theme.colors.black};
`;

const ThumbBar = styled.div`
  margin-top: 4px;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.gray10};
`;

const Thumb = styled.div<{ _color: ThemeColor }>`
  height: 100%;
  min-width: 0;
  border-radius: 4px;
  background: ${({ theme, _color }) => theme.colors[_color]};
`;
