import { useMutation } from '@tanstack/react-query';
import {
  SET_USER_PREFERENCES_PATH,
  SetUserPreferencesRequest,
  SetUserPreferencesResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/set-user-preferences';
import { client } from '../client';
import { useUserPreferencesQuery } from '../queries';

export function useSetUserPreferenceMutation() {
  const { refetch: refetchPreferences } = useUserPreferencesQuery();

  return useMutation<
    Response,
    SetUserPreferencesResponseError,
    SetUserPreferencesRequest
  >(
    (data) =>
      client.post(SET_USER_PREFERENCES_PATH, {
        json: data
      }),
    {
      onSuccess: () => {
        refetchPreferences();
      }
    }
  );
}
