import { ReactNode } from 'react';

export const getAllAppsPackFeatures = (moreAppsButton: ReactNode) => [
  {
    id: '1',
    feature: "The world's finest tools for website enhancement"
  },

  {
    id: '2',
    feature: 'Gain access to the full catalog of top-rated website widgets'
  },

  {
    id: '3',
    feature:
      'Take your website to the next level with your own suite of customizable widgets'
  },

  {
    id: '4',
    feature:
      "Perfect for agencies - enhance your clients' websites more effectively with All Apps Pack"
  },

  {
    id: '5',
    feature: (
      <>
        Google Reviews, Instagram Feed, WhatsApp Chat, Event Calendar, and{' '}
        {moreAppsButton}
      </>
    )
  },

  {
    id: '6',
    feature:
      'An unrivaled pack of widgets to display reviews, social content, forms, maps, calendars, pricing and etc.'
  },
  {
    id: '7',
    feature:
      'One subscription for all apps - an easy and must-grab deal that saves you $1000s'
  }
];
