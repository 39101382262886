import { FC, useMemo } from 'react';
import { useOnboardingQuery } from '@api';
import { OnboardingActionType } from '@elfsight-universe/service-core-contracts/onboarding/onboarding-action-type.enum';
import {
  PredefinedOnboardingItemProps,
  ITEMS
} from '@modules/onboarding/onboarding-items';
import { HeaderMobileOnboarding } from './header-mobile-onboarding';
import { HeaderOnboarding } from './header-onboarding';

export type OnboardingItemsWithDone = {
  action: OnboardingActionType;
  component: FC<PredefinedOnboardingItemProps>;
  done: boolean;
};

export type HeaderOnboardingContainerProps = {
  isMobile?: boolean;
};

export function HeaderOnboardingContainer({
  isMobile
}: HeaderOnboardingContainerProps) {
  const { data, isLoading } = useOnboardingQuery();

  const done = !!data?.done;
  const finish = !!data?.finish;
  const completedActions = data?.actions || [];

  const items: OnboardingItemsWithDone[] = useMemo(
    () =>
      ITEMS.map((item) => ({
        ...item,
        done: completedActions.includes(item.action)
      })).sort((a, b) => Number(b.done) - Number(a.done)),
    [completedActions]
  );

  const itemsCount = items.length;
  const doneCount = items.filter(({ done }) => done).length;
  const progress = Math.floor((doneCount / itemsCount) * 100);
  const progressNote = `${doneCount} of ${itemsCount} completed (${progress}%)`;

  if (finish || isLoading) {
    return null;
  }

  return isMobile ? (
    <HeaderMobileOnboarding
      done={done}
      progress={progress}
      progressNote={progressNote}
      items={items}
    />
  ) : (
    <HeaderOnboarding
      done={done}
      progress={progress}
      progressNote={progressNote}
      items={items}
    />
  );
}
