import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

export function AnnouncementsCardSkeleton({ ...forwardingProps }) {
  return (
    <Container>
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 312 322"
        backgroundColor="#f0f0f0"
        foregroundColor="#dedede"
        preserveAspectRatio="xMidYMin meet"
        {...forwardingProps}
      >
        <rect x="0" y="0" rx="8" ry="8" width="46" height="13" />
        <rect x="0" y="23" rx="8" ry="8" width="312" height="19" />
        <rect x="0" y="58" rx="8" ry="8" width="312" height="195" />

        <rect x="0" y="267" rx="8" ry="8" width="312" height="13" />
        <rect x="0" y="288" rx="8" ry="8" width="312" height="13" />
        <rect x="0" y="309" rx="8" ry="8" width="72" height="13" />
      </ContentLoader>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  overflow: hidden;
`;
