import styled from 'styled-components';

export const Divider = styled.hr<{ _marginY?: number }>`
  border: none;
  border-radius: 1px;
  margin: ${({ _marginY = 16 }) => _marginY}px auto;
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.gray10};
`;
