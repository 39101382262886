import { MouseEvent, forwardRef, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

export type ButtonPrimitiveProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  stayFocused?: boolean;
};

export const ButtonPrimitive = forwardRef<
  HTMLButtonElement,
  ButtonPrimitiveProps
>(function $ButtonPrimitive(
  {
    children,
    onClick,
    stayFocused = false,
    disabled = false,
    ...forwardingProps
  },
  forwardingRef
) {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (event.detail > 0 && !stayFocused) {
      event.currentTarget.blur();
    }

    if (onClick && !disabled) {
      onClick(event);
    }
  };

  return (
    <Button
      {...forwardingProps}
      ref={forwardingRef}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
});

const Button = styled.button<{ disabled: boolean }>`
  padding: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: none;
  -webkit-tap-highlight-color: transparent;
`;
