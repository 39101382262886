import { useState } from 'react';
import { useRouterQueryReady } from './use-router-query-ready';
import {
  ParsedUrlQueryInputValue,
  useRouterSetQuery
} from './use-router-set-query';

export function useRouterQueryState<D = undefined>(
  queryParam: string,
  defaultValue?: D,
  formatQueryValue?: (value: string | string[]) => D
): [D | undefined, (value: D | undefined) => void] {
  const [state, setState] = useState<D | undefined>(defaultValue);
  const setQuery = useRouterSetQuery(queryParam);

  useRouterQueryReady(queryParam, (nextValue) =>
    setState(() => {
      if (formatQueryValue) {
        return formatQueryValue(nextValue);
      }

      return nextValue as D;
    })
  );

  const changeState = (value: D | undefined) => {
    setState(value);
    setQuery(value as ParsedUrlQueryInputValue);
  };

  return [state, changeState];
}
