import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  AppIcon,
  Chip,
  FontType,
  getColorWithAlpha,
  NextLinkComposed,
  NextLinkComposedProps,
  P,
  Typography
} from '@elfsight-universe/ui-common';

export type AppCardProps = NextLinkComposedProps & {
  alias: string;
  name: string;
  caption: string;
  icon: string;
  badge?: {
    title: string;
    color?: string;
  } | null;
  category: {
    color: string;
  };
  disabled?: boolean;
  opaque?: boolean;
  wishlistLink?: string;
  toWishlist?: boolean;
  titleVariant?: FontType;
  projectPid?: string;
  projectName?: string;
};

export const AppCard = forwardRef<HTMLAnchorElement, AppCardProps>(
  function _AppCard(
    {
      alias,
      name,
      caption,
      icon,
      badge,
      disabled = false,
      opaque = false,
      titleVariant = 'title2',
      wishlistLink,
      toWishlist = false,
      projectPid,
      projectName,
      className
    },
    forwardingRef
  ) {
    const searchParams = new URLSearchParams();
    if (projectPid) {
      searchParams.append('projectPid', projectPid);
    }
    if (projectName) {
      searchParams.append('projectName', projectName);
    }

    const linkProps = toWishlist
      ? wishlistLink
        ? { href: wishlistLink, target: '_blank' }
        : { $disabled: true }
      : { href: `/widget/create/${alias}?${searchParams}` };

    return (
      <Container
        $disabled={disabled}
        {...linkProps}
        className={className}
        ref={forwardingRef}
      >
        {icon ? <AppIcon icon={icon} size={38} /> : <AppIconPlaceholder />}

        <Content>
          <Typography
            variant={titleVariant}
            mb={4}
            color={opaque ? 'gray50' : 'black'}
          >
            {name}&nbsp;
            {badge && (
              <Chip
                textColor={badge?.color || 'brand'}
                backgroundColor="transparent"
                borderColor={badge.color || 'brand'}
                yOffset={-2}
              >
                {badge.title}
              </Chip>
            )}
          </Typography>
          <P color={opaque ? 'gray30' : 'gray70'}>{caption}</P>
        </Content>
      </Container>
    );
  }
);

const Container = styled(NextLinkComposed)<{
  $disabled: boolean;
}>`
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: flex;
  gap: 12px;
  padding: 16px;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
  transition: background 0.2s;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      user-select: none;
    `}

  background: ${({ theme: { colors } }) => colors.white};
  :hover,
  :focus {
    background: ${({ theme }) => theme.colors.gray5};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppIconPlaceholder = styled.div`
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  background-color: ${({ theme }) => getColorWithAlpha(theme.colors.brand, 0.1)};
  border-radius: 12px;
`;
