import { useState } from 'react';
import { useWebStorage } from '@elfsight-universe/react-web-storage';

export const ACCOUNT_PID_SESSION_OPTION = 'accountPid';

export function useCurrentAccountPid() {
  const {
    storeValue: storeAccountPid,
    storedValue: storedAccountPid,
    clearStored: clearStoredAccountPid
  } = useWebStorage<string>({
    key: ACCOUNT_PID_SESSION_OPTION,
    type: 'sessionStorage'
  });
  const [accountPid, setAccountPid] = useState<string | undefined>(
    storedAccountPid
  );
  const clearStored = () => {
    clearStoredAccountPid();
    setAccountPid(undefined);
  };

  return {
    store: storeAccountPid,
    clear: clearStored,
    value: accountPid
  };
}
