import { useState, useEffect, useRef } from 'react';

export function useAvatarLoad(
  src?: string,
  onLoad?: () => void,
  onError?: () => void
): State {
  const onLoadRef = useRef(onLoad);
  const onErrorRef = useRef(onError);
  const [state, setState] = useState<State>('pending');

  useEffect(() => {
    if (!src) return;

    const image = new Image();
    image.src = src;

    setState('pending');

    const handleLoad = () => {
      setState('loaded');
      onLoadRef.current && onLoadRef.current();
    };

    image.addEventListener('load', handleLoad);

    const handleError = () => {
      setState('failed');
      onErrorRef.current && onErrorRef.current();
    };

    image.addEventListener('error', handleError);

    return () => {
      image.removeEventListener('load', handleLoad);
      image.removeEventListener('error', handleError);
    };
  }, [src]);

  return state;
}

type State = 'loaded' | 'pending' | 'failed';
