import { useMutation } from '@tanstack/react-query';
import {
  CONFIRM_LITE_SUBSCRIPTION_PATH,
  ConfirmLiteSubscriptionRequest
} from '@elfsight-universe/service-core-contracts/billing/confirm-lite-subscription';
import { client } from '../client';

export function useConfirmLiteSubscriptionMutation(onSuccess?: () => void) {
  return useMutation<Response, unknown, ConfirmLiteSubscriptionRequest>(
    (data) =>
      client.post(CONFIRM_LITE_SUBSCRIPTION_PATH, {
        json: data
      }),
    {
      onSuccess
    }
  );
}
