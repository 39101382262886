import { css } from 'styled-components';
import { getColorWithAlpha } from '../utils';

export const customScrollbarMixin = css`
  &::-webkit-scrollbar {
    background-color: ${({ theme }) => getColorWithAlpha(theme.colors.white, 0)};
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray10};
    border-radius: 6px;

    :hover {
      background-color: ${({ theme }) => theme.colors.gray30};
    }
  }
`;
