import { Exclude, Expose } from 'class-transformer';

export const GET_COLLABORATORS_FEATURE_SUMMARY_PATH =
  'iam/get-collaborators-feature-summary';

@Exclude()
export class GetCollaboratorsFeatureSummaryResponse {
  @Expose()
  available: boolean;

  @Expose()
  maxCollaboratorsLimit: number;
}
