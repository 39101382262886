import { Type } from 'class-transformer';
import { IsNumber } from 'class-validator';

export const PUBLISH_ANNOUNCEMENT_PATH = 'announcement/publish-announcement';

export class PublishAnnouncementRequest {
  @Type(() => Number)
  @IsNumber()
  id: number;
}

export type PublishAnnouncementResponseError = 'ANNOUNCEMENT_DOES_NOT_EXISTS';
