import { Exclude, Expose, Type } from 'class-transformer';
import { IsString } from 'class-validator';

export const GET_APP_FEATURES_PATH = 'widget/get-app-features';

export class GetAppFeaturesRequest {
  @IsString()
  appAlias: string;
}

@Exclude()
export class GetAppFeaturesResponse {
  @Expose()
  @Type(() => GetAppFeaturesResponseAppFeature)
  payload: GetAppFeaturesResponseAppFeature[];
}

@Exclude()
export class GetAppFeaturesResponseAppFeature {
  @Expose()
  pid: string;

  @Expose()
  showSoonBadge: boolean;

  @Expose()
  value: string;
}
