import React from 'react';
import { Loader } from '@elfsight-universe/ui-common';
import {
  PricingTable,
  PricingTablePeriodToggle,
  PricingTableProps
} from '../pricing-table';
import { useUpgradeContext } from '../upgrade-context';
import { BillingInterval } from '@elfsight-universe/service-core-contracts/billing';

// @TODO calculate actual sale
export const BASIC_ANNUAL_SALE = 17;
export const ENTERPRISE_ANNUAL_SALE = 29;

export type PlansProps = Omit<
  PricingTableProps,
  'billingInterval' | 'isEnterprise'
> & {
  isLoading: boolean;
  isPack?: boolean;
};

export function Plans({
  isLoading,
  isPack = false,
  plans,
  ...forwardingProps
}: PlansProps) {
  const {
    billingInterval,
    setBillingInterval,
    data,
    isEnterprise,
    setEnterprise,
    isReadyUpgradeContext
  } = useUpgradeContext();
  const displayBillingIntervalToggle = data?.displayBillingIntervalToggle;

  const periodToggleDiscount = plans.find(
    (plan) =>
      !plan.isLitePlan &&
      plan.billingInterval === BillingInterval.YEARLY &&
      plan.isEnterprisePlan === isEnterprise
  )?.discountPercentage;

  if (isLoading || !isReadyUpgradeContext) {
    return <Loader />;
  }

  const periodToggle = (
    <PricingTablePeriodToggle
      annualSale={periodToggleDiscount}
      billingInterval={billingInterval}
      onChange={setBillingInterval}
    />
  );

  return (
    <>
      {displayBillingIntervalToggle && isReadyUpgradeContext && periodToggle}

      <PricingTable
        billingInterval={billingInterval}
        isEnterprise={isEnterprise}
        onRegularEnterpriseSwitchRequest={setEnterprise}
        isPack={isPack}
        plans={plans}
        {...forwardingProps}
      />
    </>
  );
}
