import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  CREATE_WIDGET_PATH,
  CreateWidgetRequest,
  CreateWidgetResponse,
  CreateWidgetResponseError
} from '@elfsight-universe/service-core-contracts/widget';
import { client } from '../client';

export function useCreateWidgetMutation(
  dto: CreateWidgetRequest,
  options: UseMutationOptions<
    CreateWidgetResponse,
    CreateWidgetResponseError
  > = {}
) {
  return useMutation<CreateWidgetResponse, CreateWidgetResponseError>(
    () =>
      client
        .post(CREATE_WIDGET_PATH, {
          json: dto
        })
        .json<CreateWidgetResponse>(),
    {
      retry: false,
      ...options
    }
  );
}
