import { useQuery } from '@tanstack/react-query';
import {
  GET_CATALOG_APPS_PATH,
  GetCatalogAppsRequest,
  GetCatalogAppsResponse
} from '@elfsight-universe/service-core-contracts/widget/get-catalog-apps';
import pickBy from 'lodash/pickBy';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useCatalogAppsQuery(dto: GetCatalogAppsRequest) {
  const { isAuthenticated } = useUser(); // @TODO remove for guest catalog
  return useQuery<GetCatalogAppsResponse>(
    [GET_CATALOG_APPS_PATH],
    () =>
      client
        .get(GET_CATALOG_APPS_PATH, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json<GetCatalogAppsResponse>(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isAuthenticated
    }
  );
}
