import styled from 'styled-components';
import { Masonry } from 'masonic';
import {
  H1,
  H3,
  Icon,
  Loader,
  MOBILE,
  Modal,
  ModalProps,
  Typography
} from '@elfsight-universe/ui-common';
import { usePackAppsListWithCategoriesQuery } from '@api';
import { ElfsightGlyph, Check16 } from '@elfsight/icons';

const PRETTY_CATEGORIES_ORDER_ALIASES = [
  'social',
  'reviews',
  'chats',
  'tools',
  'forms',
  'video',
  'e-commerce',
  'audio'
];

type UpgradeAllAppsPackModalProps = Omit<ModalProps, 'title' | 'actions'>;

export function UpgradeAllAppsPackModal({
  isOpen,
  ...forwardingProps
}: UpgradeAllAppsPackModalProps) {
  const { data: appsWithCategories = { payload: [] }, isLoading } =
    usePackAppsListWithCategoriesQuery();

  const prettifiedOrderApps = appsWithCategories.payload
    .slice()
    .sort(
      (a, b) =>
        PRETTY_CATEGORIES_ORDER_ALIASES.indexOf(a.category.alias) -
        PRETTY_CATEGORIES_ORDER_ALIASES.indexOf(b.category.alias)
    );

  return (
    <Modal
      maxWidth={1000}
      padding={[0, 0]}
      isOpen={isOpen}
      withClose
      {...forwardingProps}
    >
      <Wrapper>
        <Container>
          <Title>
            <ElfsightLogoWrapper>
              <Icon component={ElfsightGlyph} size={18} fill="white" />
            </ElfsightLogoWrapper>
            <Caption>All Apps Pack</Caption>
          </Title>

          {isLoading ? (
            <Loader />
          ) : (
            <Body>
              <Content>
                <Masonry
                  overscanBy={10}
                  items={prettifiedOrderApps}
                  columnGutter={24}
                  rowGutter={32}
                  columnWidth={135}
                  maxColumnCount={4}
                  render={({ data: { apps, category } }) => (
                    <AppsColumn key={category.alias}>
                      <CategoryCaption>{category.name}</CategoryCaption>

                      <ListColumn>
                        {apps.map((app) => (
                          <ListColumnItem key={app.alias}>
                            <CheckIcon
                              size={16}
                              component={Check16}
                              fill="success"
                            />
                            {app.name}
                          </ListColumnItem>
                        ))}
                      </ListColumn>
                    </AppsColumn>
                  )}
                />

                <CenteredText variant="text" alignCenter>
                  + all upcoming apps
                </CenteredText>
              </Content>
            </Body>
          )}
        </Container>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  padding: 40px 40px 0;

  ${MOBILE} {
    padding: 20px 20px 0;
  }
`;

const ElfsightLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #fa527a 0%, #f80741 172.5%);
  border-radius: 12px;
  width: 32px;
  height: 32px;
`;

const Caption = styled(H1)`
  ${MOBILE} {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
  }
`;

const Body = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  padding: 0 40px 40px;
  max-height: 100%;
  overflow-y: auto;

  ${MOBILE} {
    padding: 0 20px 20px;
  }
`;

const AppsColumn = styled.div``;

const CategoryCaption = styled(H3)`
  margin-bottom: 12px;
`;

const ListColumn = styled.ul`
  margin: 0;
  padding: 0;
`;

const ListColumnItem = styled.li`
  list-style: none;
  display: flex;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

const CenteredText = styled(Typography)`
  ${({ theme }) => theme.font.title3Semibold};
`;

const CheckIcon = styled(Icon)`
  margin-right: 8px;
`;
