import {
  BillingInterval,
  PlanGrade,
  PlanPolicy,
  PlanType
} from '@elfsight-universe/service-core-contracts/billing';
import { Feature } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';

export enum PricingTableColorTheme {
  WHITE = 'white',
  BLACK = 'black'
}

export type PricingTablePlan = {
  pid: string;
  paddleId: number | undefined;
  name: string;
  caption?: string;
  billingInterval: string;
  price: number;
  pricePerMonth: number;
  pricePerWidget: number;
  pricePerMonthDiscounted?: number;
  discountTotal?: number;
  discountPercentage?: number;
  features: Feature[];
  current?: boolean;
  isLitePlan?: boolean;
  isPackPlan: boolean;
  highlight?: boolean;
  isEnterprisePlan: boolean;
  grade?: PlanGrade;
  policy: PlanPolicy;
  properties: {
    appId?: number;
    grade?: PlanGrade;
    billingInterval: BillingInterval;
    price?: number;
    paddleId?: number;
    hidden?: boolean;
  };
  type: PlanType;
};

export type PricingTableProps = {
  plans: PricingTablePlan[];
  billingInterval: BillingInterval;
  isEnterprise: boolean;
  reverseOrder?: boolean;
  alwaysMobile?: boolean;
  appName?: string;
  colorTheme?: PricingTableColorTheme;
};
