import { Transform } from 'class-transformer';

export const TransformBool = () =>
  Transform(({ value }) =>
    new Map([
      ['undefined', undefined],
      ['true', true],
      ['false', false]
    ]).get(value)
  );
