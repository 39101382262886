import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  CREATE_PROJECT_PATH,
  CreateProjectRequest,
  CreateProjectResponse,
  CreateProjectResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useCreateProjectMutation(
  options: UseMutationOptions<
    CreateProjectResponse,
    CreateProjectResponseError,
    CreateProjectRequest
  > = {}
) {
  return useMutation<
    CreateProjectResponse,
    CreateProjectResponseError,
    CreateProjectRequest
  >(
    ({ name }) =>
      client
        .post(CREATE_PROJECT_PATH, {
          json: { name }
        })
        .json<CreateProjectResponse>(),
    {
      retry: false,
      ...options
    }
  );
}
