import { useQuery } from '@tanstack/react-query';
import {
  GET_PAYMENT_HISTORY_PATH,
  GetPaymentHistoryResponse
} from '@elfsight-universe/service-core-contracts/billing/get-payment-history';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function usePaymentHistoryQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetPaymentHistoryResponse>(
    [GET_PAYMENT_HISTORY_PATH],
    () => client.get(GET_PAYMENT_HISTORY_PATH).json(),
    {
      enabled: isAuthenticated
    }
  );
}
