import { Length } from 'class-validator';
import { Exclude, Expose } from 'class-transformer';
import { IdentityAndAccessError, SecureLinkError } from '../errors';

export const GET_PASSWORD_RESET_PATH =
  'identity-and-access/get-password-reset-info';

export class GetPasswordResetInfoRequest {
  @Length(128)
  token: string;
}

@Exclude()
export class GetPasswordResetInfoResponse {
  @Expose()
  email: string;
}

export type GetPasswordResetInfoResponseError =
  | SecureLinkError.SECURE_LINK_DOES_NOT_EXIST
  | IdentityAndAccessError.USER_DOES_NOT_EXISTS;
