import { useEffect, useState } from 'react';

export function useWindowHeight() {
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleHeightMeasure = () => {
      setHeight(window.innerHeight);
    };

    handleHeightMeasure();

    window.addEventListener('resize', handleHeightMeasure);
    window.visualViewport?.addEventListener('resize', handleHeightMeasure);
    return () => {
      window.removeEventListener('resize', handleHeightMeasure);
      window.visualViewport?.removeEventListener('resize', handleHeightMeasure);
    };
  }, []);

  return height;
}
