import { useQuery } from '@tanstack/react-query';
import {
  GET_SWITCH_SUBSCRIPTION_APP_ESTIMATE_PATH,
  GetSwitchSubscriptionAppEstimateResponse,
  GetSwitchSubscriptionAppEstimateResponseError
} from '@elfsight-universe/service-core-contracts/billing/get-switch-subscription-app-estimate';
import { client } from '../client';
import { useUser } from '@modules/_app';

export function useSwitchSubscriptionAppEstimateQuery(
  newAppAlias: string,
  currentAppAlias: string
) {
  const { isAuthenticated } = useUser();
  return useQuery<
    GetSwitchSubscriptionAppEstimateResponse,
    GetSwitchSubscriptionAppEstimateResponseError
  >(
    [GET_SWITCH_SUBSCRIPTION_APP_ESTIMATE_PATH, newAppAlias],
    () =>
      client
        .get(GET_SWITCH_SUBSCRIPTION_APP_ESTIMATE_PATH, {
          searchParams: {
            newAppAlias,
            currentAppAlias
          },
          retry: {
            limit: 0
          }
        })
        .json<GetSwitchSubscriptionAppEstimateResponse>(),
    {
      enabled: Boolean(newAppAlias && currentAppAlias) && isAuthenticated
    }
  );
}
