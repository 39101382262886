import { Exclude, Expose, Type } from 'class-transformer';
import { Project } from './classes';

export const GET_ALL_PROJECTS_PATH = 'project/get-all-projects';

@Exclude()
export class GetAllProjectsResponse {
  @Expose()
  @Type(() => GetAllProjectsResponseMeta)
  meta: {
    lastUsedProjectPid?: string;
  };

  @Expose()
  @Type(() => GetAllProjectsResponseProject)
  payload: GetAllProjectsResponseProject[];
}

@Exclude()
export class GetAllProjectsResponseMeta {
  @Expose()
  lastUsedProjectPid?: string;
}

@Exclude()
export class GetAllProjectsResponseProject extends Project {}
