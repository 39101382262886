import { Exclude, Expose, Type } from 'class-transformer';

export const GET_INSTALLED_APPS_WITH_VIEWS_INDICATORS_PATH =
  'widget/get-installed-apps-with-views-indicators';

@Exclude()
export class GetInstalledAppsWithViewsIndicatorsResponse {
  @Expose()
  @Type(() => GetInstalledAppsWithViewsIndicatorsResponseApp)
  payload: GetInstalledAppsWithViewsIndicatorsResponseApp[];
}

@Exclude()
export class GetInstalledAppsWithViewsIndicatorsResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  icon: string;

  @Expose()
  isLastActive: boolean;

  @Expose()
  isOrangeWarning: boolean;

  @Expose()
  isRedWarning: boolean;

  @Expose()
  isBlocked: boolean;

  @Expose()
  @Type(() => GetInstalledAppsWithViewsIndicatorsResponseCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetInstalledAppsWithViewsIndicatorsResponseCategory {
  @Expose()
  color: string;
}
