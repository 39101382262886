import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { toast, useToasterStore } from 'react-hot-toast';
import { ToastOnlineStatus } from '@modules/_app/app-toaster/toast-online-status';
import { useOnlineSocketConnection } from '@modules/_app/utils/use-online-socket-connection';

export const TOAST_ONLINE_ID = 'ONLINE_ID';
export const TOAST_OFFLINE_ID = 'OFFLINE_ID';
export const TOAST_OFFLINE_DURATION = Infinity;

export const AppOnlineProviderContext = createContext<null>(null);

export function AppOnlineProvider({ children }: PropsWithChildren) {
  const { toasts } = useToasterStore();

  const { isConnected } = useOnlineSocketConnection();

  useEffect(() => {
    if (isConnected) {
      const isOfflineToastPresent = toasts.some(
        (t) => t.id === TOAST_OFFLINE_ID
      );

      if (isOfflineToastPresent) {
        showOnlineToast();
      }
    }

    if (!isConnected) {
      showOfflineToast();
    }
  }, [isConnected]);

  /**
   * additional using window.navigator.onLine API to detect offline status
   */
  useEffect(() => {
    window.addEventListener('online', showOnlineToast);
    window.addEventListener('offline', showOfflineToast);

    return () => {
      window.removeEventListener('online', showOnlineToast);
      window.removeEventListener('offline', showOfflineToast);
    };
  }, []);

  const showOnlineToast = () => {
    toast.dismiss(TOAST_OFFLINE_ID);
    toast(
      <ToastOnlineStatus
        isOnline
        onClose={() => toast.dismiss(TOAST_ONLINE_ID)}
      />,
      {
        id: TOAST_ONLINE_ID
      }
    );
  };

  const showOfflineToast = () => {
    toast.dismiss(TOAST_ONLINE_ID);
    toast(
      <ToastOnlineStatus
        isOnline={false}
        onClose={() => toast.dismiss(TOAST_OFFLINE_ID)}
      />,
      {
        id: TOAST_OFFLINE_ID,
        duration: TOAST_OFFLINE_DURATION
      }
    );
  };

  return (
    <AppOnlineProviderContext.Provider value={null}>
      {children}
    </AppOnlineProviderContext.Provider>
  );
}

export function useAppOnlineProviderContext() {
  const context = useContext(AppOnlineProviderContext);

  if (context === null) {
    throw new Error(
      '`useAppOnlineProviderContext` must be nested inside an `AppOnlineProvider`.'
    );
  }

  return context;
}
