import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { BackToTop } from '@elfsight/icons';
import { Button as BaseButton, ButtonProps } from './button';
import { Icon } from '../icons';

type BackToTopButtonProps = ButtonProps & {
  display?: boolean;
};

export function BackToTopButton({
  display,
  ...forwardingProps
}: BackToTopButtonProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  return (
    <CSSTransition
      nodeRef={containerRef}
      classNames="slide-in"
      timeout={300}
      in={display}
      appear
    >
      <Container ref={containerRef}>
        <Button
          large
          variation="accentPrimary"
          onClick={handleClick}
          padding={16}
          {...forwardingProps}
        >
          <Icon component={BackToTop} size={20} />
        </Button>
      </Container>
    </CSSTransition>
  );
}

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 60px;
  transform: translateX(-100px);
  opacity: 0;
  transition: all 0.2s;

  &.slide-in-enter-done {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Button = styled(BaseButton)`
  border-radius: 0 30px 30px 0;
`;
