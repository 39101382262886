import { useQueryClient } from '@tanstack/react-query';
import {
  GET_INSTANT_EXTENSION_CANDIDATES_PATH,
  GET_PACK_PLAN_UPGRADE_ELIGIBILITY
} from '@elfsight-universe/service-core-contracts/billing';
import { GET_PROMOTED_APPS_PATH } from '@elfsight-universe/service-core-contracts/deals';

export function useResetBlackFridayRelatedQueries() {
  const queryClient = useQueryClient();

  return () =>
    queryClient.resetQueries({
      predicate: ({ queryKey }) =>
        [
          GET_INSTANT_EXTENSION_CANDIDATES_PATH,
          GET_PACK_PLAN_UPGRADE_ELIGIBILITY,
          GET_PROMOTED_APPS_PATH
        ].includes(queryKey[0] as string)
    });
}
