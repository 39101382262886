import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  REPORT_PROJECT_USAGE_PATH,
  ReportProjectUsageRequest,
  ReportProjectUsageResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useReportProjectsUsageMutation(
  options: UseMutationOptions<
    Response,
    ReportProjectUsageResponseError,
    ReportProjectUsageRequest
  > = {}
) {
  return useMutation<
    Response,
    ReportProjectUsageResponseError,
    ReportProjectUsageRequest
  >((data) => client.post(REPORT_PROJECT_USAGE_PATH, { json: data }), {
    retry: false,
    ...options
  });
}
