import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  DUPLICATE_WIDGET_PATH,
  DuplicateWidgetRequest,
  DuplicateWidgetResponseError
} from '@elfsight-universe/service-core-contracts/widget/duplicate-widget';
import { client } from '../client';

export function useDuplicateWidgetMutation(
  dto: DuplicateWidgetRequest,
  options: Pick<
    UseMutationOptions<Response, DuplicateWidgetResponseError>,
    'onError' | 'onSuccess' | 'onMutate' | 'onSettled'
  >
) {
  return useMutation<Response, DuplicateWidgetResponseError>(
    () => client.post(DUPLICATE_WIDGET_PATH, { json: dto }),
    options
  );
}
