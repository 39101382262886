import { useQueryClient } from '@tanstack/react-query';
import {
  GET_APP_USAGE_STATISTICS_BY_WIDGETS_PATH,
  GET_APP_USAGE_STATISTICS_PATH,
  GET_APPS_USAGE_PATH,
  GET_PACK_PLANS_PATH,
  GET_SINGLE_APP_PLANS_PATH,
  GET_UPGRADE_CONTEXT_PATH,
  GET_SUBSCRIPTION_APP_INFO_PATH,
  GET_PAID_SUBSCRIPTIONS_PATH,
  GET_INSTANT_EXTENSION_CANDIDATES_PATH,
  GET_PACK_PLAN_UPGRADE_ELIGIBILITY
} from '@elfsight-universe/service-core-contracts/billing';
import {
  GET_ACCOUNT_DEAL_BY_ALIAS_PATH,
  GET_ACCOUNT_DEALS_PATH,
  GET_FEATURED_OFFER_PATH,
  GET_PROMOTED_APPS_PATH
} from '@elfsight-universe/service-core-contracts/deals';
import {
  GET_ALL_WIDGETS_PATH,
  GET_WIDGET_PATH,
  GET_INSTALLED_APPS_WITH_VIEWS_INDICATORS_PATH,
  GET_WIDGETS_LIST_PATH,
  GET_LAST_USED_INSTALLED_APP_PATH
} from '@elfsight-universe/service-core-contracts/widget';
import { GET_USER_PREFERENCES_PATH } from '@elfsight-universe/service-core-contracts/identity-and-access';
import { GET_BEACON_IDENTITY_PATH } from '@elfsight-universe/service-core-contracts/help-scout';
import { HAS_ACTIVE_PAID_SUBSCRIPTION_PATH } from '@elfsight-universe/service-core-contracts/billing/has-active-paid-subscription';
import { GET_PROJECTS_FEATURE_SUMMARY_PATH } from '@elfsight-universe/service-core-contracts/project';
import {
  GET_COLLABORATORS_PATH,
  GET_COLLABORATORS_USAGE_PATH,
  GET_COLLABORATORS_FEATURE_SUMMARY_PATH
} from '@elfsight-universe/service-core-contracts/iam';

export function useInvalidateUpgradeRelatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        [
          GET_UPGRADE_CONTEXT_PATH,
          GET_APPS_USAGE_PATH,
          GET_APP_USAGE_STATISTICS_PATH,
          GET_APP_USAGE_STATISTICS_BY_WIDGETS_PATH,
          GET_WIDGET_PATH,
          GET_ALL_WIDGETS_PATH,
          GET_ACCOUNT_DEAL_BY_ALIAS_PATH,
          GET_ACCOUNT_DEALS_PATH,
          GET_FEATURED_OFFER_PATH,
          GET_SINGLE_APP_PLANS_PATH,
          GET_PACK_PLANS_PATH,
          GET_INSTALLED_APPS_WITH_VIEWS_INDICATORS_PATH,
          GET_WIDGETS_LIST_PATH,
          GET_SUBSCRIPTION_APP_INFO_PATH,
          GET_USER_PREFERENCES_PATH,
          GET_PAID_SUBSCRIPTIONS_PATH,
          GET_LAST_USED_INSTALLED_APP_PATH,
          GET_INSTANT_EXTENSION_CANDIDATES_PATH,
          GET_PROMOTED_APPS_PATH,
          GET_PACK_PLAN_UPGRADE_ELIGIBILITY,
          GET_BEACON_IDENTITY_PATH,
          HAS_ACTIVE_PAID_SUBSCRIPTION_PATH,
          GET_PROJECTS_FEATURE_SUMMARY_PATH,
          GET_COLLABORATORS_USAGE_PATH,
          GET_COLLABORATORS_PATH,
          GET_COLLABORATORS_FEATURE_SUMMARY_PATH
        ].includes(queryKey[0] as string)
    });

    queryClient.resetQueries({
      predicate: ({ queryKey }) =>
        [
          GET_UPGRADE_CONTEXT_PATH,
          GET_SINGLE_APP_PLANS_PATH,
          GET_PACK_PLANS_PATH
        ].includes(queryKey[0] as string)
    });
  };
}
