import BaseCountdown from 'react-countdown';
import styled from 'styled-components';
import { ThemeColor } from '../theme';

interface DealCountdownProps {
  expiredAt: Date | string;
  isUsed: boolean;
}

export function DealCountdown({ expiredAt, isUsed }: DealCountdownProps) {
  if (isUsed) {
    return <Container _color="success">ALREADY USED</Container>;
  }

  return (
    <BaseCountdown
      date={expiredAt}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        return completed ? (
          <Container _color="alert">EXPIRED</Container>
        ) : (
          <Container _color={days > 0 ? 'promo' : 'alert'}>
            {days > 0 && `${to2Digit(days)}:`}
            {to2Digit(hours)}:{to2Digit(minutes)}:{to2Digit(seconds)}
            {days < 1 && ' 🔥'}
          </Container>
        );
      }}
    />
  );
}

const Container = styled.div<{ _color: ThemeColor }>`
  color: ${({ _color, theme }) => theme.colors[_color]};
  ${({ theme }) => theme.font.title3}
`;

const to2Digit = (number: number) =>
  `${number}`.length === 1 ? `0${number}` : number;
