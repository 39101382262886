import { SelectOption } from '../select-option';

export const getFilteredSelectOptions = ({
  items,
  filter = ''
}: {
  items: SelectOption[];
  filter?: string;
}): SelectOption[] => {
  return items.slice().filter(({ value, label }) => {
    const lowerFilter = filter.toLowerCase().trim();

    return (
      value.toLowerCase().includes(lowerFilter) ||
      label.toLowerCase().includes(lowerFilter)
    );
  });
};
