import { ReactNode, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRect } from 'react-use-rect';
import { RemoveScroll } from 'react-remove-scroll';
import { Modal, ModalProps, Y_OFFSET } from './modal';
import { useWindowHeight } from '../../utils';
import { customScrollbarMixin } from '../custom-scrollbar-mixin';

export type ModalWithScrollableBodyProps = Omit<
  ModalProps,
  'title' | 'actions'
> & {
  header?: ReactNode;
  body?: ReactNode;
  padding?: [number, number];
  headerOffsetBottom?: number;
  disableBodyScroll?: boolean;
};

export function ModalWithScrollableBody({
  isOpen,
  onRequestClose,
  header,
  children,
  maxWidth,
  headerOffsetBottom = 0,
  padding = [20, 24],
  disableBodyScroll = true,
  ...forwardingProps
}: ModalWithScrollableBodyProps) {
  const windowHeight = useWindowHeight();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [containerRef] = useRect((rect) => setHeaderHeight(rect.height), {
    resize: true
  });

  const bodyHeight = useMemo(
    () => (windowHeight ? windowHeight - headerHeight - Y_OFFSET : undefined),
    [windowHeight, headerHeight, Y_OFFSET]
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      withClose
      padding={[0, 0]}
      maxWidth={maxWidth}
      fitInViewPort
      {...forwardingProps}
    >
      <RemoveScroll enabled={isOpen && disableBodyScroll}>
        <ContentWrapper>
          <Header
            ref={containerRef}
            _padding={padding}
            _offsetBottom={headerOffsetBottom}
          >
            {header}
          </Header>

          <Body _maxHeight={bodyHeight} _padding={padding}>
            {children}
          </Body>
        </ContentWrapper>
      </RemoveScroll>
    </Modal>
  );
}

const ContentWrapper = styled.div``;

const Header = styled.div<{
  _padding: [number, number];
  _offsetBottom?: number;
}>`
  box-sizing: border-box;
  ${({ _padding }) => css`
    padding: ${_padding[0]}px ${_padding[1]}px 0 ${_padding[1]}px;
  `};
  ${({ _offsetBottom }) => css`
    padding-bottom: ${_offsetBottom}px;
  `};
`;

const Body = styled.div<{ _maxHeight?: number; _padding: [number, number] }>`
  box-sizing: border-box;
  overflow-y:  auto;

  ${({ _padding }) => css`
    padding: 0 ${_padding[1]}px ${_padding[0]}px ${_padding[1]}px;
  `};

  ${({ _maxHeight }) =>
    _maxHeight &&
    css`
      max-height: ${_maxHeight}px;
  `};

  ${customScrollbarMixin};
`;
