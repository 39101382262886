import { Exclude, Expose } from 'class-transformer';

export const GET_PROJECTS_USAGE_PATH = 'project/get-projects-usage';

@Exclude()
export class GetProjectsUsageResponse {
  @Expose()
  projectsLimit: number;

  @Expose()
  projectsCount: number;

  @Expose()
  projectsUsage: number;
}
