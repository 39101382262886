import styled from 'styled-components';
import {
  TabPanel,
  TabProps,
  P,
  CopiedCode,
  MOBILE,
  DESKTOP
} from '@elfsight-universe/ui-common';
import { generateWidgetCode } from '@elfsight-universe/generate-widget-installation';
import { EmbedToWebsiteTutorials } from './embed-to-website-tutorials';
import { WidgetInstallDraftCallout } from '../widget-install-draft-callout';
import { WidgetInstallData } from '../widget-install-data.interface';

const { NEXT_PUBLIC_SERVICE_CORE__BASE_URL } = process.env;

type EmbedToWebsiteTabProps = Omit<TabProps, 'children'> & WidgetInstallData;

export function EmbedToWebsiteTab({
  tabID,
  isDraft,
  widgetPid,
  onPublishDraft,
  isPublishingDraft
}: EmbedToWebsiteTabProps) {
  return (
    <TabPanel tabID={tabID}>
      <P gb>
        Copy and paste this code into desired place of your website (HTML
        editor, website template, theme, etc.).
      </P>

      <CopiedCode
        color="#0ec12f"
        textColor="#0e8b25"
        buttonText="Copy Code"
        copiedText="Code copied"
        disabled={isDraft}
      >
        {generateWidgetCode(widgetPid, NEXT_PUBLIC_SERVICE_CORE__BASE_URL)}
      </CopiedCode>

      {isDraft && onPublishDraft && (
        <StyledWidgetDraftCallout
          onPublishDraft={onPublishDraft}
          isPublishingDraft={isPublishingDraft}
        >
          This is a draft widget. Only published widgets work on a website. You
          need to publish the widget before you embed it.
        </StyledWidgetDraftCallout>
      )}

      <StyledEmbedToWebsiteTutorials />
    </TabPanel>
  );
}

const StyledWidgetDraftCallout = styled(WidgetInstallDraftCallout)`
  margin-top: 12px;
`;

const StyledEmbedToWebsiteTutorials = styled(EmbedToWebsiteTutorials)`
  ${MOBILE} {
    margin-top: 24px;
  }
  ${DESKTOP} {
    margin-top: 40px;
  }
`;
