import styled from 'styled-components';
import { UserAvatar } from './user-avatar';

type UserProfile = {
  email: string;
  firstName: string | null;
  lastName: string | null;
  profilePictureURL: string | null;
};

export type UserProps = UserProfile & {
  withoutAvatar?: boolean;
  large?: boolean;
};

export function User({
  email,
  profilePictureURL,
  firstName,
  lastName,
  withoutAvatar,
  large = false,
  ...forwardingProps
}: UserProps) {
  return (
    <Container _large={large} {...forwardingProps}>
      {!withoutAvatar && (
        <Avatar
          size={large ? 48 : 40}
          src={profilePictureURL}
          placeholderArgs={{ email, firstName, lastName }}
        />
      )}

      <Info>
        {(firstName || lastName) && (
          <Name>
            {firstName} {lastName}
          </Name>
        )}

        <Email title={email}>{email}</Email>
      </Info>
    </Container>
  );
}

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled(Ellipsis)`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 4px;
`;

const Email = styled(Ellipsis)`
  color: ${({ theme }) => theme.colors.gray70};
`;

const Avatar = styled(UserAvatar)``;

const Container = styled.div<{ _large: boolean }>`
  display: flex;
  align-items: center;

  ${Avatar} {
    margin-right: 8px;
  }
  ${Name} {
    ${({ theme: { font }, _large }) => (_large ? font.title3 : font.title4)};
    margin-bottom: ${({ _large }) => (_large ? 4 : 2)}px;
  }
  ${Email} {
    ${({ theme }) => theme.font.caption};
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
`;
