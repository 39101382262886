import { IconButton, IconButtonProps } from '@elfsight-universe/ui-common';
import { Close } from '@elfsight/icons';
import { useHistory } from '../_app';

export const HeaderClose = ({
  onClick,
  ...forwardingProps
}: Omit<IconButtonProps, 'icon'>) => {
  const { back } = useHistory();

  return (
    <IconButton
      large
      icon={Close}
      onClick={(e) => {
        onClick ? onClick(e) : back('/');
      }}
      {...forwardingProps}
    />
  );
};
