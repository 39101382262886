import {
  UseMutationOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import {
  SWITCH_SUBSCRIPTION_APP_PATH,
  SwitchSubscriptionAppResponseError,
  SwitchSubscriptionAppRequest
} from '@elfsight-universe/service-core-contracts/billing/switch-subscription-app';
import { GET_ME_PATH } from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useSwitchSubscriptionAppMutation(
  options: Pick<
    UseMutationOptions<
      Response,
      SwitchSubscriptionAppResponseError,
      SwitchSubscriptionAppRequest
    >,
    'onSuccess' | 'onError'
  >
) {
  const queryClient = useQueryClient();

  return useMutation<
    Response,
    SwitchSubscriptionAppResponseError,
    SwitchSubscriptionAppRequest
  >(
    ({ newAppAlias, currentAppAlias }) =>
      client.post(SWITCH_SUBSCRIPTION_APP_PATH, {
        json: { newAppAlias, currentAppAlias }
      }),
    {
      ...options,
      retry: false,
      onSuccess: (...args) => {
        options.onSuccess && options.onSuccess(...args);
        queryClient.refetchQueries([GET_ME_PATH]);
      }
    }
  );
}
