import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const ASSIGN_WIDGET_TO_PROJECT_PATH = 'widget/assign-widget-to-project';

export class AssignWidgetToProjectRequest {
  @IsUUID()
  projectPid: string;

  @IsUUID()
  widgetPid: string;
}

export type AssignWidgetToProjectResponseError =
  | WidgetError.PROJECT_DOES_NOT_EXIST
  | WidgetError.WIDGET_DOES_NOT_EXIST;
