import { IsUrl } from 'class-validator';
import { Exclude, Expose } from 'class-transformer';
import { InstallationError, IdentityAndAccessError } from '../errors';

export const REQUEST_EMAIL_RECOVERY_PATH =
  'identity-and-access/request-email-recovery';

export class RequestEmailRecoveryRequest {
  @IsUrl(undefined, { message: 'URL is invalid.' })
  url: string;
}

@Exclude()
export class RequestEmailRecoveryResponse {
  @Expose()
  emailHint: string;
}

export type RequestEmailRecoveryResponseError =
  | InstallationError.INSTALLATION_DOES_NOT_EXIST
  | InstallationError.URL_IS_INVALID
  | IdentityAndAccessError.USER_DOES_NOT_EXISTS;
