import { useMutation } from '@tanstack/react-query';
import {
  CREATE_INSTALLATION_REQUEST_APPEAL_PATH,
  CreateInstallationRequestAppealRequest
} from '@elfsight-universe/service-core-contracts/appeal/create-installation-request-appeal';
import { client } from '../client';

export function useCreateInstallationRequestAppealMutation(
  onSuccess?: () => void
) {
  return useMutation<Response, unknown, CreateInstallationRequestAppealRequest>(
    (data) =>
      client.post(CREATE_INSTALLATION_REQUEST_APPEAL_PATH, { json: data }),
    {
      onSuccess
    }
  );
}
