import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-hot-toast';
import addDays from 'date-fns/addDays';
import { useRouter } from 'next/router';
import { GA_DEBUG_VIEW_COOKIE_NAME } from '@elfsight-universe/service-core-contracts/analytics/cookie-name';

const { NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN } = process.env;

const QUERY_PARAM = 'gaDebugView';

export function useCatchGADebugViewParam() {
  const { query } = useRouter();
  const [, setCookie, removeCookie] = useCookies();

  const queryValue = query?.[QUERY_PARAM];

  useEffect(() => {
    if (!queryValue) return;

    if (queryValue === 'true') {
      setCookie(GA_DEBUG_VIEW_COOKIE_NAME, queryValue, {
        domain: NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN,
        path: '/',
        secure: true,
        sameSite: 'none',
        expires: addDays(new Date(), 30)
      });
      toast.success('Google Analytics Debug View was enabled');
    } else {
      removeCookie(GA_DEBUG_VIEW_COOKIE_NAME);
      toast.success('Google Analytics Debug View was disabled');
    }
  }, [queryValue]);
}
