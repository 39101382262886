import { useMutation } from '@tanstack/react-query';
import {
  CREATE_WIDGET_QUOTA_EXTENSION_APPEAL_PATH,
  CreateWidgetsQuotaExtensionAppealRequest
} from '@elfsight-universe/service-core-contracts/appeal/create-widgets-quota-extend-appeal';
import { client } from '../client';

export function useCreateWidgetQuotaExtensionAppealMutation(
  onSuccess: () => void
) {
  return useMutation<
    Response,
    unknown,
    CreateWidgetsQuotaExtensionAppealRequest
  >(
    (data) =>
      client.post(CREATE_WIDGET_QUOTA_EXTENSION_APPEAL_PATH, {
        json: data
      }),
    {
      onSuccess
    }
  );
}
