import { ReactNode } from 'react';
import styled from 'styled-components';
import Router from 'next/router';
import { Link } from '@elfsight-universe/ui-common';
import { HeadController, LayoutCenter, LayoutPage } from '@components';
import { Header } from '@modules/_header';
import { ContactSupport } from './contact-support';

const contactLink = <ContactSupport underline>Contact us</ContactSupport>;
const backLink = (
  <Link onClick={() => Router.back()} color="black" underline>
    Try going back
  </Link>
);

export type ErrorPageProps = {
  title?: ReactNode;
  text?: ReactNode;
  statusCode?: number;
  withHeader?: boolean;
};

export function ErrorPage({ statusCode, withHeader }: ErrorPageProps) {
  return (
    <>
      <HeadController title={`${statusCode}`} />
      <LayoutPage header={withHeader ? <Header /> : null}>
        <LayoutCenter width={600} spacer={[40, 80]}>
          <Container>
            <StatusCode>{statusCode}</StatusCode>
            <Title>
              {statusCode && statusCode in titleMap
                ? titleMap[statusCode as keyof typeof titleMap]
                : 'An unexpected error occurred'}
            </Title>
            <Text>
              {statusCode && statusCode in textMap ? (
                textMap[statusCode as keyof typeof textMap]
              ) : (
                <>
                  An unexpected error occurred. {contactLink} for more
                  information.
                </>
              )}
            </Text>
          </Container>
        </LayoutCenter>
      </LayoutPage>
    </>
  );
}

const titleMap = { 404: 'Sorry, page not found' };
const textMap = {
  404: (
    <>
      We can’t seem to find the page you’re looking for.
      <br />
      {backLink} to previous page or {contactLink} for more information.
    </>
  )
};

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const StatusCode = styled.div`
  font-size: 80px;
  font-weight: 500;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.brand};
`;

const Title = styled.div`
  ${({ theme }) => theme.font.title1};
`;

const Text = styled.div`
  margin-top: 24px;
  ${({ theme }) => theme.font.text};
`;
