import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const REMOVE_WIDGET_FROM_PROJECT_PATH =
  'widget/remove-widget-from-project';

export class RemoveWidgetFromProjectRequest {
  @IsUUID()
  projectPid: string;

  @IsUUID()
  widgetPid: string;
}

export type RemoveWidgetFromProjectResponseError =
  | WidgetError.PROJECT_DOES_NOT_EXIST
  | WidgetError.WIDGET_DOES_NOT_EXIST;
