import styled, { css } from 'styled-components';
import clamp from 'lodash/clamp';
import { AppIcon, MOBILE, Typography } from '@elfsight-universe/ui-common';
import { useUpgradeContext } from '@modules/upgrade/upgrade-context';
import { useIsMobile } from '@modules/_app';
import { useCountPublicAppsQuery, usePricingIncludedAppsQuery } from '@api';

const MAX_APPS_DESKTOP = 8;
const MAX_APPS_MOBILE = 7;

export function PricingTableAllAppsPackList() {
  const { setOpenAllAppsPackModal } = useUpgradeContext();
  const isMobile = useIsMobile();

  const {
    data: includedApps = { payload: [] },
    isLoading: isLoadingIncludedApps
  } = usePricingIncludedAppsQuery();
  const {
    data: publicAppsCountData = { count: 0 },
    isLoading: isLoadingPublicAppsCount
  } = useCountPublicAppsQuery();

  const appsCount = includedApps.payload.length;
  const appsMaxAmount = isMobile
    ? clamp(appsCount, 0, MAX_APPS_MOBILE)
    : clamp(appsCount, 0, MAX_APPS_DESKTOP);

  const allAppsButtonCount = publicAppsCountData.count - appsMaxAmount;
  const finalApps = includedApps.payload.slice(0, appsMaxAmount);

  if (
    isLoadingIncludedApps ||
    isLoadingPublicAppsCount ||
    !publicAppsCountData
  ) {
    return <Placeholder />;
  }

  return (
    <Container>
      <Typography variant="title4">Every plan includes</Typography>
      <Block onClick={() => setOpenAllAppsPackModal(true)}>
        <BestsellerApps $appCount={appsMaxAmount + 1}>
          {finalApps.map(({ icon, alias, name }) => (
            <App key={alias}>
              <AppIcon icon={icon} size={32} />
              {!isMobile && <AppName variant="base">{name}</AppName>}
            </App>
          ))}
          <AllApps>
            <AllAppsCount>
              <AllAppsCountInner variant="text">
                +{allAppsButtonCount}
              </AllAppsCountInner>
            </AllAppsCount>
            {!isMobile && (
              <AllAppsText variant="base" color="accent">
                See all apps
              </AllAppsText>
            )}
          </AllApps>
        </BestsellerApps>
      </Block>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 40px;
`;

const Placeholder = styled.div`
  min-height: 122px;

  ${MOBILE} {
    min-height: 106px;
  }
`;

const AllAppsText = styled(Typography)`
  ${({ theme }) => theme.font.captionSmall};

  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: -0.5px;
`;

const Block = styled.button`
  box-sizing: border-box;
  padding: 0;
  background: ${({ theme }) => theme.colors.transparent};
  border: none;
  cursor: pointer;

  &:hover {
    ${AllAppsText} {
      text-decoration: underline;
      text-underline-offset: 2.5px;
    }
  }
`;

const BestsellerApps = styled.div<{ $appCount: number }>`
  display: grid;
  grid-template-columns: ${({ $appCount }) => css`
    repeat(${$appCount}, minmax(0, 1fr))
  `};
  gap: 6px;

  ${MOBILE} {
    gap: 12px;
  }
`;

const App = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const AppName = styled(Typography)`
  ${({ theme }) => theme.font.captionSmall};

  text-align: center;
  letter-spacing: -0.5px;
`;

const AllApps = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AllAppsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: 8px;
`;

const AllAppsCountInner = styled(Typography)`
  letter-spacing: -0.5px;
  ${({ theme }) => theme.font.captionBold};

  ${MOBILE} {
    font-size: 11px;
    line-height: 12px;
    font-weight: 700;
  }
`;
