export enum PaddleAlertName {
  HIGH_RISK_TRANSACTION_CREATED = 'high_risk_transaction_created',
  HIGH_RISK_TRANSACTION_UPDATED = 'high_risk_transaction_updated',
  SUBSCRIPTION_CREATED = 'subscription_created',
  SUBSCRIPTION_UPDATED = 'subscription_updated',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
  SUBSCRIPTION_PAYMENT_SUCCEEDED = 'subscription_payment_succeeded',
  SUBSCRIPTION_PAYMENT_FAILED = 'subscription_payment_failed',
  SUBSCRIPTION_PAYMENT_REFUNDED = 'subscription_payment_refunded'
}
