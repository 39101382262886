import { ReactNode } from 'react';
import styled from 'styled-components';
import { Button, MOBILE } from '@elfsight-universe/ui-common';

export type CommunityTabCTAProps = {
  title: ReactNode;
  caption: ReactNode;
  link: string;
  actionText: string;
};

export function CommunityTabCTA({
  link,
  title,
  caption,
  actionText
}: CommunityTabCTAProps) {
  return (
    <Container>
      <Body>
        {title}
        {caption}
      </Body>

      <Button
        href={link}
        variation="accentPrimary"
        disableShrink
        target="_blank"
        rel="noopener noreferrer"
      >
        {actionText}
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  background-color: rgba(25, 123, 255, 0.05);

  ${MOBILE} {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
`;

const Body = styled.div``;
