import { Type } from 'class-transformer';
import {
  IsHexColor,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString
} from 'class-validator';

export const UPSERT_ANNOUNCEMENT_TAG_PATH =
  'announcement/upsert-announcement-tag';

export class UpsertAnnouncementTagRequest {
  @Type(() => Number)
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  @IsNotEmpty({ message: 'Alias is required.' })
  alias: string;

  @IsString()
  @IsNotEmpty({ message: 'Title is required.' })
  title: string;

  @IsHexColor({ message: 'Color is invalid.' })
  @IsNotEmpty({ message: 'Color is required.' })
  color: string;
}

export type UpsertAnnouncementTagResponseError =
  'ANNOUNCEMENT_TAG_ALREADY_EXISTS';
