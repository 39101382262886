import { forwardRef, HTMLAttributes } from 'react';
import { ReactSVG } from 'react-svg';
import { ThemeColor } from '../../theme';
import { IconContainer } from './icon-container';

type ThemeColorOr = ThemeColor | 'currentColor' | 'transparent' | string;

export type IconInjectProps = HTMLAttributes<HTMLDivElement> & {
  stroke?: ThemeColorOr;
  fill?: ThemeColorOr;
  src: string;
  size: number;
};

export const IconInject = forwardRef<HTMLDivElement, IconInjectProps>(
  function _IconInject(
    { src, size, fill = 'currentColor', stroke, ...forwardingProps },
    forwardingRef
  ) {
    return (
      <IconContainer _size={size} ref={forwardingRef} {...forwardingProps}>
        <ReactSVG
          src={src}
          beforeInjection={(svg) => {
            svg.setAttribute('fill', `${fill}`);
            if (stroke) {
              svg.setAttribute('stroke', stroke);
            }
          }}
        />
      </IconContainer>
    );
  }
);
