import { Exclude, Expose } from 'class-transformer';

export const CAN_DELETE_ACCOUNT_PATH = 'identity-and-access/can-delete-account';

@Exclude()
export class CanDeleteAccountResponse {
  @Expose()
  canDeleteAccount: boolean;
  @Expose()
  reason?: string;
}
