import { ChangeEvent, ChangeEventHandler, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { ThemeColor } from '../../theme';
import { ControlProps } from './control-types';
import { Checkbox } from './checkbox';
import {
  ControlBaseInner,
  ControlCaption,
  ControlContainer,
  ControlError,
  ControlLabel
} from './control-layout';

export type CheckboxGroupOption = {
  value: string;
  label: string;
};

export type CheckboxGroupProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value' | 'onChange'
> &
  ControlProps<{
    value: string[];
    onChange: ChangeEventHandler<HTMLInputElement>;
    uncheckedColor?: ThemeColor;
    checkedColor?: ThemeColor;
    options: CheckboxGroupOption[];
  }>;

export function CheckboxGroup({
  uncheckedColor,
  checkedColor,
  options,
  label,
  captionTop,
  captionBottom,
  error,
  value = [],
  onChange,
  name,
  ...forwardingProps
}: CheckboxGroupProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextValue = value.includes(event.target.value)
      ? value.filter((valueItem) => valueItem !== event.target.value)
      : value.concat(event.target.value).sort();

    // @TODO make correct onChange type
    onChange({
      ...event,
      target: { ...event.target, value: nextValue as unknown as string }
    });
  };

  return (
    <ControlContainer {...forwardingProps}>
      {label && <ControlLabel>{label}</ControlLabel>}
      {captionTop && <ControlCaption>{captionTop}</ControlCaption>}

      <ControlBaseInner>
        {options.map((option) => (
          <GroupItem key={option.value}>
            <Checkbox
              checked={value.includes(option.value)}
              onChange={handleChange}
              name={name}
              uncheckedColor={uncheckedColor}
              checkedColor={checkedColor}
              {...option}
            />
          </GroupItem>
        ))}
      </ControlBaseInner>

      {captionBottom && <ControlCaption>{captionBottom}</ControlCaption>}
      {error && <ControlError>{error}</ControlError>}
    </ControlContainer>
  );
}

const GroupItem = styled.div`
  padding: 4px 0;
`;
