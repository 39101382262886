import styled from 'styled-components';
import titleRectangle from './assets/title-rectangle.svg?url';
import limitRectangle from './assets/limit-rectangle.svg?url';

export function Heading() {
  return (
    <Container>
      <Limit>Last Chance</Limit>
      <Title>
        12th <Uppercase>Birthday Sale</Uppercase>
      </Title>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Limit = styled.div`
  margin: 3px 0 16px;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;

  &::before {
    content: url(${limitRectangle.src});
    position: absolute;
    font-size: 0;
    line-height: 0;
    top: 50%;
    left: 50%;
    z-index: -1;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Title = styled.h1`
  margin: 0;
  display: block;
  position: relative;
  z-index: 0;
  text-align: center;
  font-size: 28px;
  font-style: italic;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.white};

  &::before {
    content: url(${titleRectangle.src});
    position: absolute;
    font-size: 0;
    line-height: 0;
    top: 50%;
    left: 50%;
    z-index: -1;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Uppercase = styled.span`
  text-transform: uppercase;
`;
