import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { client } from '../client';
import { NotificationsItemProps } from '@modules/notifications/notifications-item';
import { useUser } from '@modules/_app';

export function useNotificationsQuery({ enabled }: UseQueryOptions) {
  const { isAuthenticated } = useUser();
  return useQuery<{ payload: NotificationsItemProps[] }>(
    ['_mock/get-notifications-list'],
    () => client.get('_mock/get-notifications-list').json(),
    {
      enabled: enabled && isAuthenticated
    }
  );
}
