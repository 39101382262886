export const formatBadgeNumber = (amount: number) => {
  if (amount > 9) {
    return '9+';
  }

  if (amount > 0) {
    return amount.toString();
  }

  return null;
};
