import styled from 'styled-components';
import { Divider, MenuButton } from '@elfsight-universe/ui-common';
import { createCommunityAuthLink } from '@utils';
import CommunityIcon from '@icons/community-20.svg';
import HelpCenter from '@icons/help-center-20.svg';
import ContactSupport from '@icons/сontact-support-20.svg';
import BulbOutline from '@icons/bulb-outline-20.svg';
import Changelog from '@icons/changelog-20.svg';
import { beacon } from '../beacon';

export type HeaderUserPopoverProps = {
  onRequestClose: () => void;
};

export function HeaderUserHelpPopover({
  onRequestClose
}: HeaderUserPopoverProps) {
  const handleHelp = () => {
    onRequestClose();
    beacon.openChat();
  };

  const closePopover = () => {
    onRequestClose();
    return true;
  };

  return (
    <Content>
      <StyledMenuButton
        href={createCommunityAuthLink('https://community.elfsight.com/')}
        leftIcon={CommunityIcon}
        onClick={closePopover}
      >
        Ask the Community
      </StyledMenuButton>

      <StyledMenuButton
        href="https://help.elfsight.com/"
        leftIcon={HelpCenter}
        onClick={closePopover}
      >
        Explore Help Center
      </StyledMenuButton>

      <StyledMenuButton leftIcon={ContactSupport} onClick={handleHelp}>
        Contact Support
      </StyledMenuButton>

      <Divider _marginY={9} />

      <StyledMenuButton
        href={createCommunityAuthLink(
          'https://community.elfsight.com/c/wishlist/8/l/votes'
        )}
        leftIcon={BulbOutline}
        onClick={closePopover}
      >
        Request a Feature
      </StyledMenuButton>

      <StyledMenuButton
        href={createCommunityAuthLink(
          'https://community.elfsight.com/c/elfsight-changelog/48'
        )}
        leftIcon={Changelog}
        onClick={closePopover}
      >
        Changelog
      </StyledMenuButton>
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  box-sizing: border-box;
`;

const StyledMenuButton = styled(MenuButton).attrs({
  padding: 16,
  negativeOffset: -16,
  iconSize: 20,
  rel: 'noopener noreferrer',
  target: '_blank'
})``;
