import styled, { css } from 'styled-components';
import { ArrowMore } from '@elfsight/icons';
import { Icon } from '../../icons';

export const SelectIndicator = styled(Icon).attrs({
  component: ArrowMore,
  size: 24,
  fill: 'currentColor'
})<{ _opened: boolean }>`
  cursor: pointer;
  transition: transform 0.4s cubic-bezier(0.65, 0.05, 0.36, 1), opacity 0.3s;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.gray50};
  ${({ _opened }) =>
    _opened &&
    css`
      color: ${({ theme }) => theme.colors.black};
      transform: rotateX(180deg);
    `}
`;
