import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  SIGN_UP_BY_CREDENTIALS_PATH,
  SignUpByCredentialsRequest,
  SignUpByCredentialsResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/sign-up-by-credentials';
import { client } from '../client';

export function useSignUpByCredentialsMutation(
  options: Pick<
    UseMutationOptions<
      Response,
      SignUpByCredentialsResponseError,
      SignUpByCredentialsRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    Response,
    SignUpByCredentialsResponseError,
    SignUpByCredentialsRequest
  >((data) => client.post(SIGN_UP_BY_CREDENTIALS_PATH, { json: data }), {
    retry: false,
    ...options
  });
}
