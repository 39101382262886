import { Exclude, Expose, Type } from 'class-transformer';
import { AccountDeal } from './classes';

export const GET_ACCOUNT_DEALS_PATH = 'deals/get-account-deals';

@Exclude()
export class GetAccountDealsResponse {
  @Expose()
  @Type(() => AccountDeal)
  payload: AccountDeal[];
}
