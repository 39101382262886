import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { TabPanel, TabProps } from '@elfsight-universe/ui-common';
import { RequestInstallationForm } from './request-installation-form';
import { RequestInstallationSuccess } from './request-installation-success';
import { ProgressBlock } from '@modules/widget-install/request-installation/components';
import { CreateInstallationRequestAppealRequest } from '@elfsight-universe/service-core-contracts/appeal';

export type InstallationFormSteps = 1 | 2;

export function RequestInstallationTab({ tabID }: TabProps) {
  const {
    formState: { isSubmitSuccessful, isValid }
  } = useFormContext<CreateInstallationRequestAppealRequest>();

  const [step, setStep] = useState<InstallationFormSteps>(1);
  const [showSuccess, setShowSuccess] = useState(false);

  const showSuccessTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSubmitSuccessful) {
      showSuccessTimeoutRef.current = setTimeout(() => {
        setShowSuccess(true);
      }, 700);
    }

    return () => {
      if (showSuccessTimeoutRef.current) {
        clearTimeout(showSuccessTimeoutRef.current);
      }
    };
  }, [isSubmitSuccessful]);

  const getProgress = () => {
    if (isValid && isSubmitSuccessful && step === 2) return 100;
    if (step === 2) return 50;
    if (step === 1) return 0;
  };

  return (
    <StyledTabPanel tabID={tabID}>
      {showSuccess ? (
        <RequestInstallationSuccess />
      ) : (
        <Container>
          <StyledProgressBlock step={step} progress={getProgress()} />
          <StyledRequestInstallationForm currentStep={step} setStep={setStep} />
        </Container>
      )}
    </StyledTabPanel>
  );
}

const StyledRequestInstallationForm = styled(RequestInstallationForm)`
  flex-grow: 1;
`;

const StyledTabPanel = styled(TabPanel)`
  flex-grow: 1;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledProgressBlock = styled(ProgressBlock)`
  margin-bottom: 24px;
`;
