import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumber, IsOptional, IsString, Min } from 'class-validator';
import { BillingError, WidgetError } from '../errors';
import { WidgetVisibility } from '../widget';
import { PaginatedRequest } from '../paginated-request';
import { PaginationResponse } from '../pagination-response';

export const GET_APP_USAGE_STATISTICS_BY_WIDGETS_PATH =
  'billing/get-app-usage-statistics-by-widgets';

export class GetAppUsageStatisticsByWidgetsRequest extends PaginatedRequest {
  @IsString()
  appAlias: string;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(0)
  minusMonths: number;
}

@Exclude()
export class GetAppUsageStatisticsByWidgetsResponse {
  @Expose()
  @Type(() => PaginationResponse)
  meta: PaginationResponse;

  @Expose()
  @Type(() => GetAppUsageStatisticsByWidgetsResponseModel)
  payload: GetAppUsageStatisticsByWidgetsResponseModel[];
}

@Exclude()
export class GetAppUsageStatisticsByWidgetsResponseModel {
  @Expose()
  name: string;

  @Expose()
  isDeleted: boolean;

  @Expose()
  pid: string;

  @Expose()
  views: number;

  @Expose()
  visibility: WidgetVisibility;

  @Expose()
  percentPerLimit: number | null;
}

export type GetAppUsageStatisticsByWidgetsResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | WidgetError.APP_DOES_NOT_EXIST;
