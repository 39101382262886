import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_UPGRADE_CONTEXT_PATH,
  GetUpgradeContextRequest,
  GetUpgradeContextResponse,
  GetUpgradeContextResponseError
} from '@elfsight-universe/service-core-contracts/billing/get-upgrade-context';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useUpgradeContextQuery(
  dto: GetUpgradeContextRequest,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<GetUpgradeContextResponse, GetUpgradeContextResponseError>
) {
  const { isAuthenticated } = useUser();
  const searchParams = new URLSearchParams();

  if (dto.appAlias) {
    searchParams.append('appAlias', dto.appAlias);
  }
  if (dto.subscriptionPid) {
    searchParams.append('subscriptionPid', dto.subscriptionPid);
  }
  if (dto.requestedBillingInterval) {
    searchParams.append(
      'requestedBillingInterval',
      dto.requestedBillingInterval
    );
  }
  if (dto.preferablyCreateNewSubscription !== undefined) {
    searchParams.append(
      'preferablyCreateNewSubscription',
      dto.preferablyCreateNewSubscription.toString()
    );
  }

  return useQuery<GetUpgradeContextResponse, GetUpgradeContextResponseError>(
    [
      GET_UPGRADE_CONTEXT_PATH,
      dto.appAlias,
      dto.subscriptionPid,
      dto.requestedBillingInterval,
      dto.preferablyCreateNewSubscription
    ],
    () =>
      client
        .get(GET_UPGRADE_CONTEXT_PATH, { searchParams })
        .json<GetUpgradeContextResponse>(),
    {
      enabled: enabled && isAuthenticated,
      ...options
    }
  );
}
