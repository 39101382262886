import { JsonObject } from '../json.type';

export const SUBSCRIBE_CLIENT_MESSAGE_PATH =
  'client-message/subscribe-client-message';

export interface SubscribeClientMessageResponse {
  data: SubscribeClientMessageResponseData;
}

export interface SubscribeClientMessageResponseData {
  type: string;
  payload: JsonObject;
}
