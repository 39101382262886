import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// @TODO refactor usage

export function useRouterQueryState<D = string>(
  queryParam: string,
  defaultValue?: D
): [D, (value: D) => void] {
  const { query, pathname, isReady, replace } = useRouter();
  const value = query[queryParam] as string;

  const [state, setState] = useState<D>(defaultValue || ('' as unknown as D));

  useEffect(() => {
    if (isReady && value) {
      setState(value as unknown as D);
    }
  }, [isReady]);

  const updateRoute = (value?: D) => {
    if (value) {
      query[queryParam] = `${value}`;
    } else {
      delete query[queryParam];
    }

    replace({
      pathname,
      query
    });
  };

  const changeState = (value: D) => {
    setState(value);
    updateRoute(value);
  };

  return [state, changeState];
}
