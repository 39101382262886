import { IsOptional, IsString } from 'class-validator';

export const SSO_REDIRECT_PATH = 'community-integration/redirect';

export class SSORedirectRequest {
  @IsString()
  @IsOptional()
  mt?: string;

  @IsString()
  @IsOptional()
  at?: string;

  @IsString()
  communityURL: string;
}
