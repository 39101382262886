import styled from 'styled-components';
import {
  Countdown,
  getColorWithAlpha,
  Typography
} from '@elfsight-universe/ui-common';
import backgroundImage from './assets/birthday-banner@2x.png';
import { useInstantExtensionCampaignContext } from '@modules/instant-extension-campaign/context/instant-extension-campaign-context';

export const BirthdayTopBar = () => {
  const { dealExpiredAt } = useInstantExtensionCampaignContext();

  return (
    <Container>
      <Content>
        <Typography color="white" variant="title4Semibold">
          12th BIRTHDAY SALE
        </Typography>
        {dealExpiredAt && (
          <>
            <Divider />
            <Countdown
              font="title4Semibold"
              iconSize={14}
              expiredAt={dealExpiredAt}
              color="white"
            />
          </>
        )}
      </Content>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px;
  ${({ theme }) => theme.font.text}
`;

const Divider = styled.div`
  height: 10px;
  width: 1px;
  background-color: ${({ theme }) =>
    getColorWithAlpha(theme.colors['white'], 0.5)};
  border-radius: 1px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #782cdf;
  background-image: url(${backgroundImage.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 26px;
`;
