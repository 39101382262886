import styled, { css } from 'styled-components';
import { PropsWithChildren, ReactNode } from 'react';
import { ThemeColor } from '@elfsight-universe/ui-common';
import { customScrollbarMixin } from '@elfsight-universe/ui-common/src/components/custom-scrollbar-mixin';

export type LayoutAsideBlockProps = PropsWithChildren<{
  imageSrc?: string;
  title?: ReactNode;
  caption?: ReactNode;
  footer?: ReactNode;
  backgroundColor?: ThemeColor;
  maxHeight?: number;
}>;

export function LayoutSidebarBlock({
  children,
  maxHeight,
  imageSrc,
  title,
  footer,
  caption,
  backgroundColor = 'white'
}: LayoutAsideBlockProps) {
  const withHeader = title || caption;

  return (
    <Block _background={backgroundColor}>
      {imageSrc && <Image src={imageSrc} />}

      {withHeader && (
        <Header>
          {title && <HeaderTitle>{title}</HeaderTitle>}
          {caption && <HeaderCaption>{caption}</HeaderCaption>}
        </Header>
      )}

      <Body _maxHeight={maxHeight}>{children}</Body>

      {!!footer && <Footer>{footer}</Footer>}
    </Block>
  );
}
const Block = styled.div<{ _background: ThemeColor }>`
  padding: 20px 0;
  border-radius: 4px;
  background: ${({ _background, theme }) => theme.colors[_background]};

  :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Body = styled.div<{ _maxHeight?: number }>`
  position: relative;
  overflow-y: auto;
  padding: 0 24px;

  ${({ _maxHeight }) =>
    _maxHeight &&
    css`
      max-height: ${_maxHeight}px;
    `}

  ${customScrollbarMixin}
`;

const Image = styled.img`
  width: 100%;
  border-radius: 2px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  padding: 0 24px;
  margin-bottom: 16px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.font.title3};
`;

const HeaderCaption = styled.div`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.gray70};
  ${({ theme }) => theme.font.caption};
`;

const Footer = styled.div`
  margin-top: 16px;
  padding: 0 24px;
`;
