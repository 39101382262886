import { IsString } from 'class-validator';
import { IdentityAndAccessError } from '../errors';

export const SIGN_IN_BY_TRANSITION_TOKEN_PATH =
  'identity-and-access/transition-token';

export class SignInByTransitionTokenRequest {
  @IsString()
  token: string;
}

export type SignInByTransitionTokenResponseError =
  | IdentityAndAccessError.USER_DOES_NOT_EXISTS
  | IdentityAndAccessError.TRANSITION_TOKEN_IS_INVALID
  | IdentityAndAccessError.USER_DELETED
  | IdentityAndAccessError.ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT;
