import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import {
  GET_WIDGETS_LIMIT_PATH,
  GetWidgetsLimitRequest,
  GetWidgetsLimitResponse,
  GetWidgetsLimitResponseError
} from '@elfsight-universe/service-core-contracts/widget';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useWidgetsLimitQuery(
  dto: GetWidgetsLimitRequest,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<GetWidgetsLimitResponse, GetWidgetsLimitResponseError> = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetWidgetsLimitResponse, GetWidgetsLimitResponseError>(
    [GET_WIDGETS_LIMIT_PATH, dto.appAlias],
    () =>
      client
        .get(GET_WIDGETS_LIMIT_PATH, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json<GetWidgetsLimitResponse>(),
    {
      enabled: enabled && isAuthenticated,
      ...options
    }
  );
}
