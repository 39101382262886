import { Exclude, Expose } from 'class-transformer';
import { IdentityAndAccessError } from '../errors';

export const GET_PROFILE_INFO_PATH = 'identity-and-access/get-profile-info';

@Exclude()
export class GetProfileInfoResponse {
  @Expose()
  firstName: string | null;
  @Expose()
  lastName: string | null;
  @Expose()
  phone: string | null;
  @Expose()
  birthday: Date | null;
  @Expose()
  website: string | null;
  @Expose()
  occupationPid?: string;
  @Expose()
  industryPid?: string;
  @Expose()
  preferredMonetizationPid?: string;
  @Expose()
  profilePictureURL: string | null;
}

export type GetProfileInfoResponseError =
  IdentityAndAccessError.USER_DOES_NOT_EXISTS;
