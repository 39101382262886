import { ComponentClass, ReactNode } from 'react';
import { Button, ButtonProps } from '../../buttons';
import { useContextMenu } from './context-menu-context';

export type ContextMenuButtonProps = Omit<
  ButtonProps,
  'icon' | 'leftIcon' | 'textColor' | 'borderRadius' | 'fullWidth'
> & {
  danger?: boolean;
  icon?: ComponentClass;
  badge?: ReactNode;
};

export function ContextMenuButton({
  icon,
  danger,
  onClick,
  badge,
  ...forwardingProps
}: ContextMenuButtonProps) {
  const { setOpen } = useContextMenu();

  return (
    <Button
      rightIcon={icon}
      rightSlot={badge}
      textColor={danger ? 'alert' : 'black'}
      borderRadius={0}
      fullWidth
      fontWeight={500}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }

        setOpen(false);
        return true;
      }}
      {...forwardingProps}
    />
  );
}
