import Script from 'next/script';
import { useUser } from '@modules/_app';

const PUBLIC_API_TOKEN = 'e5976a73fba2c7cbac783bf65076a362';

export function ProfitWellEngagement() {
  const { user } = useUser();

  if (!user?.email) {
    return null;
  }

  return (
    <Script
      id="profitwell-js"
      strategy="afterInteractive"
      data-pw-auth={PUBLIC_API_TOKEN}
      dangerouslySetInnerHTML={{
        __html: `
          (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
          a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
          s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
          })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');

          profitwell('start', { 'user_email': '${user.email}' });
        `
      }}
    ></Script>
  );
}
