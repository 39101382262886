import { useMutation } from '@tanstack/react-query';
import {
  UPDATE_PASSWORD_PATH,
  UpdatePasswordRequest,
  UpdatePasswordResponseError
} from '@elfsight-universe/service-core-contracts/identity-and-access/update-password';
import { client } from '../client';

export function usePasswordChangeMutation(onSuccess: () => void) {
  return useMutation<
    unknown,
    UpdatePasswordResponseError,
    UpdatePasswordRequest
  >((data) => client.post(UPDATE_PASSWORD_PATH, { json: data }), {
    onSuccess
  });
}
