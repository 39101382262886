import NextHead from 'next/head';

export interface HeadProps {
  title: string;
  titlePostfix?: string;
  description?: string;
  noindex?: boolean;
}

export const HeadController = ({
  title,
  titlePostfix = 'Elfsight',
  description,
  noindex = false
}: HeadProps) => {
  const finalTitle =
    title && titlePostfix ? `${title} | ${titlePostfix}` : titlePostfix;

  return (
    <NextHead>
      <title>{finalTitle}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="description" content={description} />
      {noindex && <meta name="robots" content="noindex" />}
    </NextHead>
  );
};
