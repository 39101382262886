import { ReactElement, useState } from 'react';
import { Rect, useRect } from 'react-use-rect';
import styled from 'styled-components';

export const MeasureText = styled.span<{
  _fontSize: number;
  _fontFamily?: string;
}>`
  font-size: ${({ _fontSize }) => _fontSize}px;
  font-family: ${({ _fontFamily = 'inherit' }) => _fontFamily};
  position: absolute;
  height: 0;
  overflow: hidden;
  white-space: pre;
  text-align: left;
  min-width: 20px;
`;

export interface InlineInputMeasureProps {
  children: ({ width }: { width: number }) => ReactElement | null;
  text: string | number | readonly string[];
  fontSize: number;
  fontFamily?: string;
}

export function InlineInputMeasure({
  text,
  fontSize,
  fontFamily,
  children
}: InlineInputMeasureProps) {
  const [rect, setRect] = useState<Rect | null>(null);
  const [measureRef] = useRect(setRect, { resize: true });

  return (
    <>
      <MeasureText
        ref={measureRef}
        _fontSize={fontSize}
        _fontFamily={fontFamily}
      >
        {text}
      </MeasureText>

      {rect && children(rect)}
    </>
  );
}
