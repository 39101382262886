import { useQuery } from '@tanstack/react-query';
import {
  GET_ACCOUNT_DEALS_COUNT_PATH,
  GetAccountDealsCountResponse
} from '@elfsight-universe/service-core-contracts/deals';
import { useUser } from '@modules/_app';
import { useEvents } from '@modules/_event-bus';
import { client } from '../client';

export function useDealsCountQuery() {
  const { isAuthenticated } = useUser();
  const query = useQuery<GetAccountDealsCountResponse>(
    [GET_ACCOUNT_DEALS_COUNT_PATH],
    () =>
      client
        .get(GET_ACCOUNT_DEALS_COUNT_PATH)
        .json<GetAccountDealsCountResponse>(),
    {
      enabled: isAuthenticated
    }
  );

  useEvents(['DealsUpdated'], () => query.refetch());

  return query;
}
