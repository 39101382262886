import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';
import { BillingError } from '../errors';

export const GET_SWITCH_SUBSCRIPTION_PLAN_ESTIMATE_PATH =
  'billing/get-switch-subscription-plan-estimate';

export class GetSwitchSubscriptionPlanEstimateRequest {
  @IsNotEmpty()
  planPid: string;

  @IsNotEmpty()
  subscriptionPid: string;
}

@Exclude()
export class GetSwitchSubscriptionPlanEstimateResponse {
  @Expose()
  immediatePaymentAmount: number;

  @Expose()
  nextPaymentAmount: number;

  @Expose()
  nextPaymentDate: Date;
}

export type GetSwitchSubscriptionPlanEstimateResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.PLAN_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANNOT_BE_UPDATED;
