const LOCALES = 'en';

const formatRelative = (duration: number, unit: Intl.RelativeTimeFormatUnit) =>
  new Intl.RelativeTimeFormat(LOCALES, {
    numeric: 'auto'
  }).format(Math.round(duration), unit);

const formatAbsolute = (timestamp: number) =>
  new Date(timestamp).toLocaleDateString(LOCALES, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

const DIVISIONS: { amount: number; unit: Intl.RelativeTimeFormatUnit }[] = [
  { amount: 60, unit: 'seconds' },
  { amount: 60, unit: 'minutes' },
  { amount: 24, unit: 'hours' },
  { amount: 7, unit: 'days' },
  { amount: 4.34524, unit: 'weeks' }
];

export function formatTimeAgo(date: Date | string) {
  const timestamp =
    date instanceof Date ? date.getTime() : new Date(date).getTime();
  let duration = (timestamp - new Date().getTime()) / 1000;

  if (!Intl.RelativeTimeFormat) {
    return formatAbsolute(timestamp);
  }

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];

    if (!division) {
      return formatAbsolute(timestamp);
    }

    if (Math.abs(duration) < division.amount) {
      return formatRelative(duration, division.unit);
    }

    duration /= division.amount;
  }
}
