import { useEffect } from 'react';
import { FINISH_IMPERSONATE_CLIENT_PATH } from '@elfsight-universe/service-core-contracts/identity-and-access';

const { NEXT_PUBLIC_APP_DASHBOARD__BASE_URL } = process.env;

export function useFinishImpersonateClientBeforeUnload(
  isInImpersonateSession: boolean
) {
  useEffect(() => {
    if (!isInImpersonateSession) {
      return;
    }
    function finishImpersonateSessions() {
      navigator.sendBeacon(
        `${NEXT_PUBLIC_APP_DASHBOARD__BASE_URL}/core/${FINISH_IMPERSONATE_CLIENT_PATH}`
      );
    }
    window.addEventListener('beforeunload', finishImpersonateSessions);
    return () => {
      window.removeEventListener('beforeunload', finishImpersonateSessions);
    };
  }, [isInImpersonateSession]);
}
