import urlJoin from 'url-join';

const { NEXT_PUBLIC_SERVICE_CORE__COMMUNITY_BASE_URL } = process.env;

export const createCommunityAuthLink = (path: string) => {
  const ssoURL = urlJoin(
    NEXT_PUBLIC_SERVICE_CORE__COMMUNITY_BASE_URL as string,
    'session/sso'
  );
  const urlObject = new URL(ssoURL);
  urlObject.searchParams.append('return_path', path);
  return urlObject.toString();
};
