import { Exclude, Expose, Type } from 'class-transformer';

export const GET_PRICING_INCLUDED_APPS_PATH =
  'widget/get-pricing-included-apps';

@Exclude()
export class GetPricingIncludedAppsResponse {
  @Expose()
  @Type(() => GetPricingIncludedAppsResponseApp)
  payload: GetPricingIncludedAppsResponseApp[];
}

@Exclude()
export class GetPricingIncludedAppsResponseApp {
  @Expose()
  alias: string;

  @Expose()
  name: string;

  @Expose()
  icon: string;

  @Expose()
  @Type(() => GetPricingIncludedAppsResponseCategory)
  category: {
    color: string;
    alias: string;
  };
}

@Exclude()
export class GetPricingIncludedAppsResponseCategory {
  @Expose()
  color: string;

  @Expose()
  alias: string;
}
