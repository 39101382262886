import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumber, IsOptional, IsString, Max, Min } from 'class-validator';
import { Project } from './classes';

export const GET_PROJECTS_LIST_PATH = 'project/get-projects-list';

export class GetProjectsListRequest {
  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  page = 1;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  @Max(50)
  itemsPerPage = 15;

  @IsString()
  @IsOptional()
  search?: string;
}

@Exclude()
export class GetProjectsListResponse {
  @Expose()
  @Type(() => GetProjectsListResponseMeta)
  meta: {
    totalItems: number;
    lastPage: number;
  };

  @Expose()
  @Type(() => GetProjectsListResponseProject)
  payload: GetProjectsListResponseProject[];
}

@Exclude()
export class GetProjectsListResponseMeta {
  @Expose()
  totalItems: number;

  @Expose()
  lastPage: number;
}

@Exclude()
export class GetProjectsListResponseProject extends Project {
  @Expose()
  widgetsCount: number;

  @Expose()
  projectThumbnailURL?: string;
}
