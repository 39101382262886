import { Exclude, Expose, Type } from 'class-transformer';
import {
  IsBoolean,
  IsEnum,
  IsOptional,
  IsString,
  IsUUID
} from 'class-validator';
import { WidgetError } from '../errors';
import { BillingInterval, PlanGrade, PlanType } from './enums';
import { SelectPlanAction } from './enums/select-plan-action.enum';
import { TransformBool } from '../transform-bool';

export const GET_UPGRADE_CONTEXT_PATH = 'billing/get-upgrade-context';

export class GetUpgradeContextRequest {
  @IsString()
  @IsOptional()
  appAlias?: string;

  @IsEnum(BillingInterval)
  @IsOptional()
  requestedBillingInterval?: BillingInterval;

  @TransformBool()
  @IsBoolean()
  @IsOptional()
  preferablyCreateNewSubscription?: boolean;

  @IsUUID()
  @IsOptional()
  subscriptionPid?: string;
}

@Exclude()
export class GetUpgradeContextResponse {
  @Expose()
  @Type(() => GetUpgradeContextResponseApp)
  app?: {
    alias: string;
    name: string;
  };

  @Expose()
  haveAllAppsPackSubscription: boolean;

  @Expose()
  isAllAppsPackEffectiveForApp: boolean;

  @Expose()
  effectiveSubscriptionPid: string;

  @Expose()
  defaultBillingInterval: BillingInterval;

  @Expose()
  effectivePlanPid?: string;

  @Expose()
  effectivePlanType?: PlanType;

  @Expose()
  effectivePlanGrade?: PlanGrade;

  @Expose()
  effectivePlanBillingInterval?: BillingInterval;

  @Expose()
  showEnterprisePricing: boolean;

  @Expose()
  displayCoupon: boolean;

  @Expose()
  displaySingleAppPlans: boolean;

  @Expose()
  displayAllAppsPackPlans: boolean;

  @Expose()
  displayBillingIntervalToggle: boolean;

  @Expose()
  displayEnterprisePlanBanner: boolean;

  @Expose()
  hasPaidSubscription: boolean;

  @Expose()
  isLiteSubscription: boolean;

  @Expose()
  isLiteSubscriptionConfirmed: boolean;

  @Expose()
  selectPlanAction: SelectPlanAction;
}

@Exclude()
export class GetUpgradeContextResponseApp {
  @Expose()
  alias: string;

  @Expose()
  name: string;
}

export type GetUpgradeContextResponseError = WidgetError.APP_DOES_NOT_EXIST;
