import { useEffect, useRef } from 'react';

export function useAutoFocus<T extends HTMLElement>(shouldFocus: boolean) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const animationFrame = requestAnimationFrame(() => {
      if (ref.current && shouldFocus) {
        ref.current.focus();
      }
    });

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [shouldFocus]);

  return ref;
}
