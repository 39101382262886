import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  ACTIVATE_WIDGET_PATH,
  ActivateWidgetResponseError
} from '@elfsight-universe/service-core-contracts/widget';
import { client } from '../client';

export function useActivateWidgetMutation(
  widgetPid: string,
  options: Pick<
    UseMutationOptions<Response, ActivateWidgetResponseError>,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<Response, ActivateWidgetResponseError>(
    [ACTIVATE_WIDGET_PATH, widgetPid],
    () =>
      client.post(ACTIVATE_WIDGET_PATH, {
        json: { widgetPid }
      }),
    options
  );
}
